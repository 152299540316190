import React from "react";
import { useNavigate } from "react-router-dom";


import simpleAuth from "../../core/auth/simpleAuth";

export default function Logout(props) {

    const navigate = useNavigate();

    setTimeout(() => {
        localStorage.removeItem(simpleAuth.JWT_TOKEN);
        navigate("/login");
    }, 1000);

    return (
        <>
            <div className="mb-2 text-white">
                <div className="text-center animated fadeInDown">
                    Logging you out...
                </div >

            </div >
        </>
    )

}