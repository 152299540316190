import MyAccount from "../pages/app/my-account";

import StandardLoader from "./standard-loader";


export default function MyAccountRouter() {
    return {
        path: "/my-account",

        children: [
            {
                index: true,
                element: <MyAccount />,
                loader: (props) => StandardLoader({
                    ...props,
                    crumbs: [
                        {
                            "name": "My Account",
                        },
                    ]
                }),
            },
        ]
    };
}