import escapeHtml from 'escape-html'
import {
    Text,
} from 'slate';

const serializeBlocksToHtml = node => {
    if (Text.isText(node)) {
        let string = escapeHtml(node.text)
        if (node.bold) {
            string = `<strong>${string}</strong>`
        }
        return string
    }

    const children = node.children.map(n => serializeBlocksToHtml(n)).join('')

    switch (node.type) {
        case 'quote':
            return `<blockquote><p>${children}</p></blockquote>`
        case 'paragraph':
            return `<p>${children}</p>`
        case 'span':
            return `<span>${children}</span>`
        case 'br':
            return '<br />';
        case 'link':
            return `<a href="${escapeHtml(node.url)}">${children}</a>`
        default:
            return children
    }
}

export default serializeBlocksToHtml;