import React from "react";
import { connect } from "react-redux";

import { resolve, mapStateToProps } from "../../components/smart/delegator/DataDelegator";

import ReactJson from "react-json-view";
import axiosBackend from "../../core/api/backend";

class CacheStackManagement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pipeline: [],
            results: [],

            resources: [],

            selectedApplication: null,

            status: "ready",
        };

        this.runFlush = this.runFlush.bind(this);
    }

    runFlush() {
        this.setState({
            status: "running",
        }, () => {
            const config = {
                method: "POST",
                url: "/administration/cache/flush",
                data: {}
            };

            axiosBackend(config)
                .then((response) => {
                    this.setState({
                        results: response.data,
                        status: "ready",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "error",
                            title: "Task Pipeline Failed",
                            message: [
                                "Due to a server error, we were unable to run the task pipeline.",
                                "Please check the syntax or try again in a little while."
                            ],
                            okayButtonText: "Okay"
                        },
                    });

                    this.setState({
                        status: "error",
                    });
                })
        })
    }

    render() {
        return (
            <>
                <div className="col-sm-12 mt-4">
                    <div className="ibox-content">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-8">
                                        <button
                                            onClick={this.runFlush}
                                            className="btn btn-primary mt-4 btn-lg"
                                        >
                                            {this.state.status == "running" ? "Flushing..." : "Flush"}
                                        </button>
                                    </div>

                                    <div className="col-sm-4">
                                        <label className="font-bold">Results:</label>
                                        <ReactJson
                                            collapsed={false}
                                            src={this.state.results}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps)(CacheStackManagement);