1
import React from "react";
import { connect } from "react-redux";

import update from 'immutability-helper';

import * as DataDelegator from "../../../../components/smart/delegator/DataDelegator";

import axiosBackend from "../../../../core/api/backend";
import Select from "react-select";

import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import steps from "./help-steps";
import supportedFieldTypes from "./supported-field-types";
import supportedAggregateFormula from "./supported-aggregate-formula";
import withLoaderData from "../../../../components/withLoaderData";
import CreatableSelect from 'react-select/creatable';
import isNumericField from "../../../../core/utils/datatypes/isNumericField";
import MarkIntroductionTourDone from "../../../../core/onboarding/mark-introduction-tour-done";
import API_STATES from "../../../../api-states";

import * as HotFormulaParser from "hot-formula-parser";
import { isLength } from "validator";

export const introductionIdentifier = "resources/create-new-resource";

class CreateResource extends React.Component {
    constructor(props) {
        super(props);

        this.supportedDefaultForumlas = [{
            value: "",
            label: "Basic Equation",
        }].concat(HotFormulaParser.SUPPORTED_FORMULAS.map((formula) => {
            return {
                value: formula,
                label: formula,
            }
        }));

        this.defaultFieldState = {
            fieldName: "",
            fieldType: null,

            defaultValue: "",
            fieldSize: "",

            selectedLookupApplication: null,
            selectedLookupResource: null,

            resourceLinkingKey: null,
            resourceFilterValue: null,

            resourceDisplayKey: null,
            resourceDisplayKeyMultiple: null,

            aggregationType: null,
            aggregationConfiguration: null,

            selectedFormula: null,
            aggregateFieldValues: null,

            defaultComputedValue: "",

            toggleResourceDisplayKeyMultiple: false,
            toggleDefaultComputedValue: false,
        }

        // This is used to track which fields will get sorted to go to the very end of the model 
        this.endDisplayPosition = 1000;

        this.defaultModel = {
            "id": {
                "type": "AUTONUMBER",
                "displayPosition": 1,
            },

            "createdBy": {
                "type": "USER",
                "displayPosition": this.endDisplayPosition,
            },
            "createdOn": {
                "type": "AUTODATE",
                "displayPosition": this.endDisplayPosition + 1,
            },
            "createdByIp": {
                "type": "IP_ADDRESS",
                "displayPosition": this.endDisplayPosition + 2,
            },

            "updatedBy": {
                "type": "USER_UPDATE",
                "displayPosition": this.endDisplayPosition + 3,
            },
            "updatedOn": {
                "type": "AUTODATE_UPDATE",
                "displayPosition": this.endDisplayPosition + 4,
            },
            "updatedByIp": {
                "type": "IP_ADDRESS_UPDATE",
                "displayPosition": this.endDisplayPosition + 5,
            }
        }

        this.state = {
            showTour: false,
            stepIndex: 0,

            previousFieldType: null, // Used by joyride so that we don't lose data
            previousFieldTypeSet: false,

            applications: [],
            lookupResources: [],
            resourceFields: [],

            isFieldBeingEdited: false,
            fieldBeingEdited: null,

            ...this.defaultFieldState,

            // Add error fields for every default field
            ...Object.keys(this.defaultFieldState).reduce((acc, field) => {
                acc[`${field}Error`] = "";

                return acc;
            }, {}),

            model: {
                ...this.defaultModel,
            },

            // These are set when the API returns an error and will be shown when the user tries to save the model
            errors: {

            },

            resourceName: "",
            resourceDescription: "",

            resourceNameError: "",
            resourceDescriptionError: "",
            modelError: "",

            status: API_STATES.IDLE,
        };

        this.handleInputFieldChange = this.handleInputFieldChange.bind(this);
        this.handleFieldTypeChange = this.handleFieldTypeChange.bind(this);
        this.addField = this.addField.bind(this);
        this.toggleRequired = this.toggleRequired.bind(this);

        this.cancelEditField = this.cancelEditField.bind(this);

        this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this);

        this.handleLookupApplicationChange = this.handleLookupApplicationChange.bind(this);
        this.handleLookupResourceChange = this.handleLookupResourceChange.bind(this);
        this.handleResourceLinkingKeyChange = this.handleResourceLinkingKeyChange.bind(this);
        this.handleResourceFilterValueChange = this.handleResourceFilterValueChange.bind(this);
        this.handleResourceFilterKeyChange = this.handleResourceFilterKeyChange.bind(this);
        this.handleResourceDisplayKeyChange = this.handleResourceDisplayKeyChange.bind(this);
        this.handleResourceDisplayKeyMultipleChange = this.handleResourceDisplayKeyMultipleChange.bind(this);

        this.handleAggregateFieldValuesChange = this.handleAggregateFieldValuesChange.bind(this);
        this.handleSelectedFormulaChange = this.handleSelectedFormulaChange.bind(this);

        this.handleDefaultComputedValueChange = this.handleDefaultComputedValueChange.bind(this);

        this.toggleHelp = this.toggleHelp.bind(this);

        this.createModel = this.createModel.bind(this);
    }

    toggleHelp() {
        this.setState({
            showTour: true,
        });
    }

    componentDidMount() {
        DataDelegator.resolve(this.props, () => {
            if (this.props.edit === true) {
                // Load the model for the resource
                const config = {
                    method: "POST",
                    url: `/service/resources/${this.props.router.params.appUUID}/${this.props.router.params.resourceName}/model`,
                    data: {},
                };

                axiosBackend(config)
                    .then((response) => {
                        const resourceInformation = response.data.results[0];

                        // Convert the model to the format we need
                        let modelWithDefaults = {};
                        Object.keys(resourceInformation.model).forEach((fieldName) => {
                            if (modelWithDefaults[fieldName] === undefined) {
                                modelWithDefaults[fieldName] = {};
                            }

                            Object.keys(resourceInformation.model[fieldName]).forEach((prop) => {
                                modelWithDefaults[fieldName][prop] = resourceInformation.model[fieldName][prop] || this.defaultFieldState[prop];
                            });
                        });

                        this.setState({
                            resourceName: resourceInformation.name,
                            resourceDescription: resourceInformation.hint,
                            model: modelWithDefaults,
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                show: true,
                                type: "error",
                                title: "Unable to fetch resource information",
                                message: [
                                    "Due to a server error, we were unable to fetch the resource information.",
                                    "Please try again in a little while."
                                ],
                                okayButtonText: "Okay"
                            },
                        });
                    })
            }

            if (this.props.router.loaderData.shouldRun) {
                this.setState({
                    showTour: true,
                });
            }
        });
    }

    handleJoyrideCallback(data) {
        if (data.step.lookupStepIsNext === true && data.lifecycle === "complete") {
            this.setState((state) => {
                return {
                    previousFieldType: state.fieldType,
                    previousFieldTypeSet: true,
                    fieldType: {
                        "value": "LOOKUP",
                        "label": "Lookup From"
                    },
                };
            });
        }

        if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.CLOSE].includes(data.status) || ACTIONS.CLOSE == data.action) {
            // Need to set our running state to false, so we can restart if we click start again.
            let stateToUpdate = {
                showTour: false,
                stepIndex: 0,
            };

            if (this.props.router.loaderData.shouldRun) {
                MarkIntroductionTourDone({ identifier: introductionIdentifier });
            }

            if (this.state.previousFieldTypeSet === true) {
                stateToUpdate.previousFieldTypeSet = false;
                stateToUpdate.fieldType = this.state.previousFieldType;
                stateToUpdate.previousFieldType = null;
            }

            this.setState(stateToUpdate);
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(data.type)) {
            // Update state to advance the tour
            this.setState({
                stepIndex: data.index + (data.action === ACTIONS.PREV ? -1 : 1),
            });
        }
    }

    handleInputFieldChange(event) {
        let value = event.target.value;

        if (event.target.type == "checkbox") {
            value = event.target.checked;
        }

        let stateToUpdate = {
            [event.target.name]: value,
            [`${event.target.name}Error`]: "",
        }

        this.setState(stateToUpdate);
    }

    handleDefaultComputedValueChange(event) {
        let value = event.target.value;

        let stateToUpdate = {
            defaultComputedValue: value,
        };

        const selectedFormula = this.state.selectedFormula?.value;
        const basicEquation = this.supportedDefaultForumlas[0];

        // Check if it matches a formula
        // Check the position of the first "("
        const firstOpenBracket = value.indexOf("(");
        if (firstOpenBracket !== -1) {
            const formulaName = value.substring(0, firstOpenBracket);

            if (formulaName != selectedFormula) {
                stateToUpdate.selectedFormula = this.supportedDefaultForumlas.find((formula) => formula.value === formulaName) || basicEquation;
            }

        } else {
            stateToUpdate.selectedFormula = basicEquation; // No function used since the bracket is missing
        }

        this.setState(stateToUpdate);
    }

    createModel() {
        let isValid = true;
        let stateToUpdate = {
            resourceNameError: "",
            resourceDescriptionError: "",
            modelError: "",
        };

        if (typeof this.state.resourceName != "string" || !isLength(this.state.resourceName, { min: 1 })) {
            isValid = false;
            stateToUpdate.resourceNameError = "Please enter a name for your resource";
        }

        if (typeof this.state.resourceDescription != "string" || !isLength(this.state.resourceDescription, { min: 1 })) {
            isValid = false;
            stateToUpdate.resourceDescriptionError = "Please enter a description for your resource";

        }

        if (Object.keys(this.state.model).length === 0) {
            isValid = false;
            stateToUpdate.modelError = "You cannot create a resource with no fields";
        }

        if (!isValid) {
            this.setState(stateToUpdate);
        } else {
            this.setState({
                status: API_STATES.LOADING,
            }, () => {
                let config = {};

                if (this.props.edit == true) {
                    config = {
                        method: "PATCH",
                        url: `/service/resources/${this.props.router.params.appUUID}/${this.props.router.params.resourceName}/edit`,
                        data: {
                            resourceName: this.state.resourceName,
                            hint: this.state.resourceDescription,
                            fieldsList: this.state.model,
                        }
                    }
                } else {
                    config = {
                        method: "PUT",
                        url: `/service/resources/${this.props.router.params.appUUID}`,
                        data: {
                            resourceName: this.state.resourceName,
                            hint: this.state.resourceDescription,
                            fieldsList: this.state.model,
                        }
                    };
                }

                let messages = [];
                let title = "";

                if (this.props.edit === true) {
                    title = "Updating Resource";
                    messages = [
                        "This might take a few seconds.",
                        "You will be shown a confirmation screen once the resource has been updated."
                    ]
                } else {
                    title = "Creating Resource";
                    messages = [
                        "This might take a few seconds.",
                        "You will be shown a confirmation screen once the resource has been created."
                    ]
                }


                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        "show": true,
                        "type": "processing",
                        "title": title,
                        "message": messages
                    },
                });

                setTimeout(() => {
                    axiosBackend(config)
                        .then((response) => {
                            this.setState({
                                results: response.data,
                                status: API_STATES.IDLE,
                            }, () => {
                                if (this.props.edit !== true) {
                                    this.props.router.navigate(`../${this.state.resourceName}`);
                                }

                                let messages = [];
                                let title = "";

                                if (this.props.edit === true) {
                                    title = "Resource Updated Successfully";
                                    messages.push("Your resource was updated successfully.");
                                } else {
                                    title = "Resource Created Successfully";
                                    messages.push("Your new resource was created successfully.");
                                }

                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: title,
                                        message: messages,
                                        okayButtonText: "Okay",
                                    },
                                });
                            });
                        })
                        .catch((err) => {
                            console.error(err);
                            if (Array.isArray(err.response?.data?.errors) && err.response?.data?.errors.length > 0) {
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: false
                                    },
                                });

                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Model Creation Failed",
                                        message: [
                                            "There are some errors in your model.",
                                            "Please rectify them and try again."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });

                                this.setState({
                                    status: API_STATES.IDLE,
                                    errors: err.response.data.errors[0],
                                })
                            } else if(Array.isArray(err.response?.data?.messages) && err.response?.data?.messages.length > 0) {
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: false
                                    },
                                });

                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Model Creation Failed",
                                        message: err.response.data.messages,
                                        okayButtonText: "Okay"
                                    },
                                });

                                this.setState({
                                    status: API_STATES.IDLE,
                                })

                            } else {
                                this.setState({
                                    status: API_STATES.IDLE
                                })

                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Model Creation Failed",
                                        message: [
                                            "Due to a server error, we were unable to run create the model.",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            }
                        })
                }, 1000);
            })
        }
    }

    loadResourceFields(siteUUID, resourceName, callback) {
        const config = {
            method: "POST",
            url: `/service/resources/${siteUUID}/${resourceName}/fields`,
            data: {},
        };

        axiosBackend(config)
            .then((response) => {
                this.setState({
                    resourceFields: response.data.results.map((field) => {
                        field.value = field.fieldName;
                        field.label = field.fieldName;

                        return field;
                    }).sort((a, b) => {
                        if (a.displayPosition === null) {
                            return 1; // move `a` to a higher index
                        } else if (b.displayPosition === null) {
                            return -1; // move `b` to a higher index
                        } else {
                            return a.displayPosition - b.displayPosition;
                        }
                    }).filter(field => field.fieldName !== "robostack_id")
                }, () => {
                    typeof callback == "function" && callback();
                })
            })
            .catch((error) => {
                console.log(error);
                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Unable to fetch list of fields",
                        message: [
                            "Due to a server error, we were unable",
                            "to fetch the list of fields for the given resource.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
    }

    handleLookupResourceChange(selectedLookupResource, callback) {
        this.setState({
            selectedLookupResource
        }, () => {
            this.loadResourceFields(this.state.selectedLookupApplication.value, selectedLookupResource.value, callback);
        })
    }

    handleLookupApplicationChange(selectedLookupApplication, callback) {
        this.setState({
            selectedLookupApplication
        }, () => {
            if (selectedLookupApplication !== null) {
                axiosBackend({
                    method: "POST",
                    url: `/service/resources/${selectedLookupApplication.value}`,
                    data: {},
                })
                    .then((response) => {
                        this.setState({
                            lookupResources: response.data.results.map((resource) => {
                                resource.label = resource.name;
                                resource.value = resource.name;

                                return resource;
                            }).sort((a, b) => a.label.localeCompare(b.label)),

                            selectedLookupResource: null,
                            resourceFields: [],
                        }, () => {
                            typeof callback == "function" && callback();
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                show: true,
                                type: "error",
                                title: "Unable to fetch list of resources",
                                message: [
                                    "Due to a server error, we were unable",
                                    "to fetch the list of resources for the given application.",
                                    "Please try again in a little while."
                                ],
                                okayButtonText: "Okay"
                            },
                        });
                    })
            } else {
                this.setState({
                    lookupResources: [],
                    resourceFields: [],
                })
            }
        })
    }

    handleResourceLinkingKeyChange(resourceLinkingKey) {
        this.setState({
            resourceLinkingKey,
        });
    }

    handleResourceFilterValueChange(resourceFilterValue) {
        this.setState({
            resourceFilterValue,
        });
    }

    handleResourceFilterKeyChange(resourceFilterKey) {
        this.setState({
            resourceFilterKey,
        });
    }

    handleResourceDisplayKeyChange(resourceDisplayKey) {
        this.setState({
            resourceDisplayKey,
        });
    }

    handleSelectedFormulaChange(selectedFormula) {

        let stateToUpdate = {
            selectedFormula,
        };

        if (selectedFormula) {
            stateToUpdate.defaultComputedValue = selectedFormula.value + "()";
        }

        this.setState(stateToUpdate);
    }

    handleAggregateFieldValuesChange(aggregateFieldValues) {
        this.setState({
            aggregateFieldValues,
        });
    }

    handleResourceDisplayKeyMultipleChange(resourceDisplayKeyMultiple) {
        this.setState({
            resourceDisplayKeyMultiple,
        });
    }

    handleFieldTypeChange(selectedType, callback) {
        this.setState({
            fieldType: selectedType,
        }, () => {
            if (selectedType != null && ["LOOKUP", "AGGREGATE"].includes(selectedType.value)) {
                axiosBackend({
                    method: "POST",
                    url: `/apps`,
                    data: {},
                })
                    .then((appsResponse) => {
                        const applications = appsResponse.data.results.map((app) => {
                            app.label = app.name;
                            app.value = app.uuid;

                            return app;
                        }).sort((a, b) => a.label.localeCompare(b.label));

                        this.setState({
                            applications,
                        }, () => {
                            const selectedLookupApplication = applications.find((app) => app.value === this.props.router.params.appUUID) || this.defaultFieldState.selectedLookupApplication

                            this.handleLookupApplicationChange(selectedLookupApplication, () => {
                                typeof callback == "function" && callback();
                            })
                        })


                    })
                    .catch((error) => {
                        console.log(error)
                        this.props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                show: true,
                                type: "error",
                                title: "Unable to fetch list of applications",
                                message: [
                                    "Due to a server error, we were unable to fetch the list of applications.",
                                    "Please try again in a little while."
                                ],
                                okayButtonText: "Okay"
                            },
                        });
                    })
            }
        })
    }

    cancelEditField() {
        this.setState({
            isFieldBeingEdited: false,
            fieldBeingEdited: null,

            ...this.defaultFieldState,
        })
    }

    editField(fieldName) {
        let stateToUpdate = this.state;

        stateToUpdate = update(stateToUpdate, {
            isFieldBeingEdited: {
                $set: true
            },
            fieldBeingEdited: {
                $set: fieldName,
            },
            fieldName: {
                $set: fieldName
            },
            fieldType: {
                $set: supportedFieldTypes.filter((type) => type.value === this.state.model[fieldName].type).pop()
            },
            defaultValue: {
                $set: this.state.model[fieldName].default || this.defaultFieldState.defaultValue
            },
            defaultComputedValue: {
                $set: this.state.model[fieldName].defaultComputedValue || this.defaultFieldState.defaultComputedValue
            },
            fieldSize: {
                $set: this.state.model[fieldName].size || this.defaultFieldState.fieldSize
            },

            toggleDefaultComputedValue: {
                $set: this.state.model[fieldName].defaultComputedValue !== undefined && this.state.model[fieldName].defaultComputedValue !== null,
            }
        });

        this.setState(stateToUpdate, () => {
            this.handleFieldTypeChange(this.state.fieldType, () => {

                if (this.state.model[fieldName].type === "AGGREGATE") {
                    const linksToSiteUUID = this.state.model[fieldName].linksToSiteUUID || this.props.router.params.appUUID;

                    // this.state.applications is loaded by handleFieldTypeChange
                    const lookupApplication = this.state.applications.filter((app) => app.value === linksToSiteUUID).pop();

                    this.handleLookupApplicationChange(lookupApplication, () => {
                        const lookupResource = this.state.lookupResources.filter((resource) => resource.value === this.state.model[fieldName].linksToResource).pop();

                        this.handleLookupResourceChange(lookupResource, () => {
                            let stateToUpdate = this.state;

                            stateToUpdate = update(stateToUpdate, {
                                resourceLinkingKey: {
                                    $set: this.state.resourceFields.filter((field) => field.value === this.state.model[fieldName].resourceLinkingKey).pop(),
                                },

                                resourceFilterValue: {
                                    $set: this.state.resourceFields.filter((field) => field.value === this.state.model[fieldName].resourceFilterValue).pop(),
                                },

                                selectedFormula: {
                                    $set: supportedAggregateFormula.filter((formula) => formula.value === this.state.model[fieldName].aggregationConfiguration.formula).pop(),
                                },

                                aggregateFieldValues: {
                                    $set: this.state.model[fieldName].aggregationConfiguration.properties.map((field) => {
                                        return {
                                            value: field,
                                            label: field,
                                        }
                                    }),
                                }
                            });

                            this.setState(stateToUpdate);
                        });
                    });
                }

                if (this.state.model[fieldName].type === "LOOKUP") {
                    const linksToSiteUUID = this.state.model[fieldName].linksToSiteUUID || this.props.router.params.appUUID;

                    // this.state.applications is loaded by handleFieldTypeChange
                    const lookupApplication = this.state.applications.filter((app) => app.value === linksToSiteUUID).pop();

                    this.handleLookupApplicationChange(lookupApplication, () => {
                        const lookupResource = this.state.lookupResources.filter((resource) => resource.value === this.state.model[fieldName].linksToResource).pop();

                        this.handleLookupResourceChange(lookupResource, () => {
                            let resourceDisplayKeysMultiple = this.defaultFieldState.resourceDisplayKeysMultiple;

                            if (Array.isArray(this.state.model[fieldName].resourceDisplayKeysMultiple)) {
                                resourceDisplayKeysMultiple = this.state.model[fieldName].resourceDisplayKeysMultiple.map((key) => {
                                    let toReturn = {
                                        value: key,
                                        label: key,
                                    }

                                    if (key[0] != "$") {
                                        toReturn.__isNew__ = true;
                                    } else {
                                        toReturn.value = key.substring(1);
                                        toReturn.label = key.substring(1);
                                    }

                                    return toReturn;
                                })
                            }

                            let stateToUpdate = this.state;

                            stateToUpdate = update(stateToUpdate, {
                                resourceLinkingKey: {
                                    $set: this.state.resourceFields.filter((field) => field.value === this.state.model[fieldName].resourceLinkingKey).pop(),
                                },
                                resourceDisplayKey: {
                                    $set: this.state.resourceFields.filter((field) => field.value === this.state.model[fieldName].resourceDisplayKey).pop(),
                                },
                                resourceDisplayKeyMultiple: {
                                    $set: resourceDisplayKeysMultiple,
                                },

                                toggleResourceDisplayKeyMultiple: {
                                    $set: Array.isArray(this.state.model[fieldName].resourceDisplayKeysMultiple),
                                },
                            });

                            this.setState(stateToUpdate);
                        });
                    });
                }
            });
        });
    }

    removeField(fieldName) {
        let stateToUpdate = this.state;

        stateToUpdate = update(stateToUpdate, {
            model: {
                $unset: [fieldName]
            }
        });

        this.setState(stateToUpdate);
    }

    moveField(fieldName, direction) {
        const thisField = this.state.model[fieldName];

        // Find the field that is immediately above it
        const fields = Object.keys(this.state.model);
        let fieldsDistance = fields.sort((fieldA, fieldB) => {
            let a = this.state.model[fieldA];
            let b = this.state.model[fieldB];

            if (a.displayPosition === null) {
                return 1; // move `a` to a higher index
            } else if (b.displayPosition === null) {
                return -1; // move `b` to a higher index
            } else {
                return a.displayPosition - b.displayPosition;
            }
        }).map((currentFieldName) => {
            const field = this.state.model[currentFieldName];
            return field.displayPosition - thisField.displayPosition;
        })

        const selfIndex = fieldsDistance.indexOf(0);
        let swapIndex;

        if (direction == "up") {
            if (selfIndex == 0) {
                swapIndex = 1;
            } else {
                swapIndex = selfIndex - 1;
            }
        } else if (direction == "down") {
            if (selfIndex == fields.length) {
                swapIndex = fields.length - 1;
            } else {
                swapIndex = selfIndex + 1;
            }
        } else {
            console.log("Unsupported move direction");

            return;
        }

        const swapFieldName = fields[swapIndex];
        const swapField = this.state.model[swapFieldName];

        let stateToUpdate = this.state;

        stateToUpdate = update(stateToUpdate, {
            model: {
                [fieldName]: {
                    displayPosition: {
                        $set: swapField.displayPosition
                    },
                    fieldModified: {
                        $set: new Date(),
                    }
                },
                [swapFieldName]: {
                    displayPosition: {
                        $set: thisField.displayPosition,
                    },
                }
            }
        });

        this.setState(stateToUpdate);
    }

    addField() {
        let valid = true;
        let stateToUpdate = update(this.state, {
            // Add error fields for every default field
            ...Object.keys(this.defaultFieldState).reduce((acc, field) => {
                acc[`${field}Error`] = {
                    $set: ""
                };

                return acc;
            }, {}),
        });

        if (this.state.fieldName.length == 0) {
            valid = false;

            stateToUpdate = update(stateToUpdate, {
                fieldNameError: {
                    $set: "Please enter a field name",
                }
            })
        }

        if (this.state.fieldType == null) {
            valid = false;

            stateToUpdate = update(stateToUpdate, {
                fieldTypeError: {
                    $set: "Please select a field type",
                }
            })
        } else if (this.state.fieldType.value === "LOOKUP") {
            if (this.state.selectedLookupApplication == null) {
                valid = false;

                stateToUpdate = update(stateToUpdate, {
                    selectedLookupApplicationError: {
                        $set: "Please select an application to lookup from",
                    }
                })
            }

            if (this.state.selectedLookupResource == null) {
                valid = false;

                stateToUpdate = update(stateToUpdate, {
                    selectedLookupResourceError: {
                        $set: "Please select a resource to link to",
                    }
                })
            }

            if (this.state.resourceLinkingKey == null) {
                valid = false;

                stateToUpdate = update(stateToUpdate, {
                    resourceLinkingKeyError: {
                        $set: "Please select a field to link to in the resource",
                    }
                })
            }

            if (!this.state.toggleResourceDisplayKeyMultiple && this.state.resourceDisplayKey == null) {
                valid = false;

                stateToUpdate = update(stateToUpdate, {
                    resourceDisplayKeyError: {
                        $set: "Please select a field to display from the resource",
                    }
                })
            }

            if (this.state.toggleResourceDisplayKeyMultiple && this.state.resourceDisplayKeyMultiple == null) {
                valid = false;

                stateToUpdate = update(stateToUpdate, {
                    resourceDisplayKeyMultipleError: {
                        $set: "Please create a custom value to display from the resource",
                    }
                })
            }
        } else if (this.state.fieldType.value === "AGGREGATE") {
            if (this.state.selectedLookupApplication == null) {
                valid = false;

                stateToUpdate = update(stateToUpdate, {
                    selectedLookupApplicationError: {
                        $set: "Please select an application to lookup from",
                    }
                })
            }

            if (this.state.selectedLookupResource == null) {
                valid = false;

                stateToUpdate = update(stateToUpdate, {
                    selectedLookupResourceError: {
                        $set: "Please select a resource to link to",
                    }
                })
            }

            if (this.state.resourceLinkingKey == null) {
                valid = false;

                stateToUpdate = update(stateToUpdate, {
                    resourceLinkingKeyError: {
                        $set: "Please select a field to link to in the resource",
                    }
                })
            }

            if (this.state.resourceFilterValue == null) {
                valid = false;

                stateToUpdate = update(stateToUpdate, {
                    resourceFilterValueError: {
                        $set: "Please select a field to match to in this resource",
                    }
                })
            }

            if (this.state.selectedFormula == null) {
                valid = false;

                stateToUpdate = update(stateToUpdate, {
                    selectedFormulaError: {
                        $set: "Please select a formula to use",
                    }
                })
            }

            if (!Array.isArray(this.state.aggregateFieldValues) || this.state.aggregateFieldValues.length == 0) {
                valid = false;

                stateToUpdate = update(stateToUpdate, {
                    aggregateFieldValuesError: {
                        $set: "Please select some fields or enter values to be used for the formula",
                    }
                })
            } else {
                // Check if there are any invalid values
                const invalidValues = this.state.aggregateFieldValues.filter((field) => {
                    if (field.__isNew__ === true) {
                        if (isNaN(Number(field.value))) {
                            return true;
                        }
                    }

                    return false;
                })

                if (invalidValues.length > 0) {
                    valid = false;

                    stateToUpdate = update(stateToUpdate, {
                        aggregateFieldValuesError: {
                            $set: "Please ensure that your custom values are only numeric values",
                        }
                    })
                }
            }
        } else if (this.state.fieldType.value == "FIXEDLENGTH") {
            const size = parseInt(this.state.fieldSize);

            if (size <= 0) {
                valid = false;

                stateToUpdate = update(stateToUpdate, {
                    fieldSizeError: {
                        $set: "The field size must be at least 1 character",
                    }
                })
            } else if (this.state.defaultValue.length > size) {
                valid = false;

                stateToUpdate = update(stateToUpdate, {
                    defaultValueError: {
                        $set: "The default value must be less than or equal to field size you have provided",
                    }
                })
            }
        }

        // Check if there is a default computed value
        if (this.state.defaultComputedValue != this.defaultFieldState.defaultComputedValue && this.defaultFieldState.defaultComputedValue != undefined && this.defaultFieldState.defaultComputedValue != null) {
            // Check if all the fields exist which it references
            const fieldsInString = this.state.defaultComputedValue.match(/(\$[a-zA-Z0-9_]+)/g);

            if (fieldsInString != null) {
                const nonExistentFields = fieldsInString.filter((field) => {
                    const fieldName = field.substring(1);
                    return this.state.model[fieldName] === undefined;
                })

                if (nonExistentFields.length > 0) {
                    valid = false;

                    stateToUpdate = update(stateToUpdate, {
                        defaultComputedValueError: {
                            $set: `The fields ${nonExistentFields.reduce((acc, field) => `${acc} ${field},`, "").slice(0, -1)} do not exist in the model and will cause an error`,
                        }
                    })
                }
            }
        }

        if (valid) {
            // Calculate next display position
            let latestDisplayPosition = Object.keys(stateToUpdate.model).reduce((acc, fieldName) => {
                const field = stateToUpdate.model[fieldName];

                if (field.displayPosition !== undefined) {
                    if (field.displayPosition > acc && field.displayPosition < this.endDisplayPosition) {
                        acc = field.displayPosition;
                    }
                }

                return acc;
            }, 0);

            let stateToSetForField = {
                type: this.state.fieldType.value,

                fieldModified: new Date(), // this is just for the component to add the animation class to show where it was added in the table
            }

            if (!this.state.isFieldBeingEdited || this.state.model[this.state.fieldName]?.displayPosition === undefined) {
                stateToSetForField.displayPosition = latestDisplayPosition + 1;
            } else {
                stateToSetForField.displayPosition = this.state.model[this.state.fieldName].displayPosition;
            }

            if (stateToUpdate.isFieldBeingEdited && stateToUpdate.errors[this.state.fieldName] !== undefined) {
                stateToUpdate = update(stateToUpdate, {
                    errors: {
                        $unset: [this.state.fieldName]
                    }
                })
            }

            // Check if the field name has been changed
            if (this.state.isFieldBeingEdited && this.state.fieldName != this.state.fieldBeingEdited) {
                stateToUpdate = update(stateToUpdate, {
                    model: {
                        $unset: [this.state.fieldBeingEdited]
                    }
                })

                stateToSetForField.originalFieldName = this.state.fieldBeingEdited;
                // So that it is visually in the same place
                stateToSetForField.displayPosition = this.state.model[this.state.fieldBeingEdited].displayPosition;
            }

            stateToUpdate = update(stateToUpdate, {
                model: {
                    [this.state.fieldName]: {
                        $set: stateToSetForField,
                    }
                },
            });

            // Reset all the field values 
            Object.keys(this.defaultFieldState).forEach((field) => {
                stateToUpdate = update(stateToUpdate, {
                    [field]: {
                        $set: this.defaultFieldState[field],
                    }
                });
            })

            if (this.state.fieldType.value === "AGGREGATE") {
                stateToUpdate = update(stateToUpdate, {
                    model: {
                        [this.state.fieldName]: {
                            linksToResource: {
                                $set: this.state.selectedLookupResource.value,
                            },

                            linksToSiteUUID: {
                                $set: this.state.selectedLookupApplication.value,
                            },

                            linksToSiteUUIDName: {
                                $set: this.state.selectedLookupApplication.label,
                            },

                            resourceLinkingKey: {
                                $set: this.state.resourceLinkingKey.value,
                            },

                            resourceFilterValue: {
                                $set: this.state.resourceFilterValue.value,
                            },

                            aggregationType: {
                                $set: "lookup"
                            },
                            aggregationConfiguration: {
                                $set: {
                                    formula: this.state.selectedFormula.value,
                                    properties: this.state.aggregateFieldValues.map((field) => field.value),
                                }
                            }
                        }
                    }
                })
            }

            if (this.state.fieldType.value === "LOOKUP") {
                stateToUpdate = update(stateToUpdate, {
                    model: {
                        [this.state.fieldName]: {
                            linksToResource: {
                                $set: this.state.selectedLookupResource.value,
                            },

                            linksToSiteUUID: {
                                $set: this.state.selectedLookupApplication.value,
                            },

                            linksToSiteUUIDName: {
                                $set: this.state.selectedLookupApplication.label,
                            },

                            resourceLinkingKey: {
                                $set: this.state.resourceLinkingKey.value,
                            },
                        }
                    }
                })

                if (this.state.toggleResourceDisplayKeyMultiple == true && Array.isArray(this.state.resourceDisplayKeyMultiple)) {
                    stateToUpdate = update(stateToUpdate, {
                        model: {
                            [this.state.fieldName]: {
                                resourceDisplayKey: {
                                    $set: null
                                },
                                resourceDisplayKeysMultiple: {
                                    $set: this.state.resourceDisplayKeyMultiple.map((key) => {
                                        if (key.__isNew__) {
                                            return key.value;
                                        } else {
                                            return `$${key.value}`;
                                        }
                                    }),
                                },
                            }
                        }
                    });
                } else {
                    stateToUpdate = update(stateToUpdate, {
                        model: {
                            [this.state.fieldName]: {
                                resourceDisplayKeysMultiple: {
                                    $set: null
                                },
                                resourceDisplayKey: {
                                    $set: this.state.resourceDisplayKey.value,
                                },
                            }
                        }
                    });
                }
            }

            // Check if there is a default value
            if (!["LOOKUP", "AGGREGATE"].includes(this.state.fieldType.value)) {
                if (this.state.toggleDefaultComputedValue == true) {
                    if (this.state.defaultComputedValue?.length > 0) {
                        stateToUpdate = update(stateToUpdate, {
                            model: {
                                [this.state.fieldName]: {
                                    defaultComputedValue: {
                                        $set: this.state.defaultComputedValue,
                                    },
                                }
                            }
                        })
                    }
                } else {
                    if (this.state.defaultValue.length > 0) {
                        stateToUpdate = update(stateToUpdate, {
                            model: {
                                [this.state.fieldName]: {
                                    default: {
                                        $set: this.state.defaultValue,
                                    },
                                }
                            }
                        })
                    }
                }
            }

            // Check if there is a size
            if (parseInt(this.state.fieldSize) > 0 && ["FIXEDLENGTH"].includes(this.state.fieldType.value)) {
                stateToUpdate = update(stateToUpdate, {
                    model: {
                        [this.state.fieldName]: {
                            size: {
                                $set: parseInt(this.state.fieldSize),
                            },
                        }
                    }
                })
            }

            if (stateToUpdate.isFieldBeingEdited) {
                stateToUpdate = update(stateToUpdate, {
                    isFieldBeingEdited: {
                        $set: false,
                    },
                    fieldBeingEdited: {
                        $set: null,
                    }
                });
            }
        }

        this.setState(stateToUpdate);
    }

    toggleRequired(fieldName) {
        this.setState((currentState) => {
            return update(currentState, {
                model: {
                    [fieldName]: {
                        required: {
                            $set: !!!currentState.model[fieldName].required,
                        }
                    }
                }
            })
        })
    }

    render() {
        const colourStyles = {
            control: (styles) => ({ ...styles, backgroundColor: 'white' }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                    ...styles,
                    cursor: isDisabled ? 'not-allowed' : 'default',
                };
            },
            multiValue: (styles, { data }) => {
                return {
                    ...styles,
                    // backgroundColor: color,
                };
            },

            // This is what is shown in the input
            multiValueLabel: (styles, { data }) => {
                let color = "000000";

                if (!data.__isNew__) {
                    styles["fontWeight"] = "bold";
                }

                return {
                    ...styles,
                    color,
                    padding: 6,
                    background: "#e5e5e5"
                }
            },


            multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: data.color,
                ':hover': {
                    backgroundColor: "#e5e5e5",
                    color: 'white',
                    cursor: "pointer"
                },
            }),
        };

        return (
            <div className="col">
                <ReactJoyride
                    callback={this.handleJoyrideCallback}
                    run={this.state.showTour}
                    stepIndex={this.state.stepIndex}
                    steps={steps}
                    continuous={true}
                    showSkipButton={true}
                    showProgress={true}
                    styles={{
                        options: {
                            zIndex: 10000,
                        },
                    }}
                    disableScrollParentFix={true}
                />

                <div className="row">
                    <div className="col-sm-12 pr-4 pl-4 pt-2 pb-2">

                        <button className="btn btn-gray" onClick={this.toggleHelp}>
                            <span className="far fa-question-circle fa-lg"></span>
                        </button>

                        <button
                            id="resource-create"
                            className="btn btn-primary float-right ml-2"
                            onClick={this.createModel}
                            disabled={this.state.status === API_STATES.LOADING}
                        >
                            {this.props.edit == true
                                ?
                                this.state.status == API_STATES.LOADING ? "Updating Resource..." : "Update Resource"
                                :
                                this.state.status == API_STATES.LOADING ? "Creating Resource..." : "Create Resource"
                            }
                        </button>
                    </div>

                </div>

                <div className="">
                    <div className="ibox-content">

                        <div className="row">


                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {Object.keys(this.state.errors).length > 0 &&
                                            <div className="alert alert-danger">
                                                Please rectify the errors in the fields below and then try again.
                                            </div>
                                        }


                                        <div className="row mb-4">
                                            <div className="col-sm-12">
                                                <label className="font-bold">
                                                    {this.props.edit === true ?
                                                        <>Resource Name:</>
                                                        :
                                                        <>New Resource Name:</>
                                                    }
                                                </label>
                                                <input
                                                    id="resource-name"
                                                    onChange={this.handleInputFieldChange}
                                                    name="resourceName"
                                                    type="text"
                                                    className={`form-control ${this.state.resourceNameError.length > 0 ? "is-invalid" : ""}`}
                                                    placeholder="Name of the new resource"
                                                    value={this.state.resourceName}
                                                />
                                                <small className="form-text text-danger">{this.state.resourceNameError}</small>

                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <div className="col-sm-12">
                                                <label className="font-bold">New Resource Description:</label>
                                                <input
                                                    id="resource-description"
                                                    onChange={this.handleInputFieldChange}
                                                    name="resourceDescription"
                                                    type="text"
                                                    className={`form-control ${this.state.resourceDescriptionError.length > 0 ? "is-invalid" : ""}`}
                                                    placeholder="Description for the resource that will be shown to users"
                                                    value={this.state.resourceDescription}
                                                />
                                                <small className="form-text text-danger">{this.state.resourceDescriptionError}</small>

                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-sm-6">
                                        <label className="font-bold">Fields For Resource:</label>

                                        <table id="resource-model" className="table table-bordered-head">
                                            <thead>
                                                <tr>
                                                    <th className="col-3">Field Name</th>
                                                    <th className="col-3">Type</th>
                                                    <th className="col-2">Default Value</th>
                                                    <th className="col-1">Required</th>
                                                    <th className="col-2">
                                                        Actions
                                                        <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(this.state.model).length == 0 ? <div className="p-3">No fields added yet</div> : undefined}

                                                {Object.keys(this.state.model).sort((fieldA, fieldB) => {
                                                    let a = this.state.model[fieldA];
                                                    let b = this.state.model[fieldB];

                                                    if (a.displayPosition === null) {
                                                        return 1; // move `a` to a higher index
                                                    } else if (b.displayPosition === null) {
                                                        return -1; // move `b` to a higher index
                                                    } else {
                                                        return a.displayPosition - b.displayPosition;
                                                    }
                                                }).map((fieldName, index, originalArray) => {
                                                    const field = this.state.model[fieldName];

                                                    let fieldType = supportedFieldTypes.filter((type) => field.type === type.value).pop();

                                                    let isAutomatic = false;
                                                    if (fieldType !== undefined) {
                                                        isAutomatic = !!fieldType.automatic;
                                                    }

                                                    let fieldHasErrors = this.state.errors[fieldName] !== undefined && Array.isArray(this.state.errors[fieldName].messages);

                                                    return (
                                                        <tr className={`${fieldHasErrors ? "border-top-bottom border-danger border-left" : ""} ${field.fieldModified - new Date() > -1000 ? "backgroundAnimated" : ""}`} key={fieldName} id={`resource-model-field-${fieldName}`}>
                                                            <td className={`${fieldHasErrors ? "border-top-bottom border-danger" : ""} border-left`}>
                                                                {fieldName}
                                                            </td>

                                                            <td className={`${fieldHasErrors ? "border-top-bottom border-danger" : ""}`}>
                                                                {fieldType !== undefined ? fieldType.label : "Unknown field type"}

                                                                {fieldType?.value === "FIXEDLENGTH" ? <>
                                                                    <div className="mt-3">
                                                                        Field Size - <strong>{field.size} characters</strong><br />
                                                                    </div>
                                                                </> : undefined}

                                                                {fieldType?.value === "AGGREGATE" ? <>
                                                                    <div >
                                                                        {field.linksToSiteUUIDName &&
                                                                            <>
                                                                                Application - <strong>
                                                                                    {field.linksToSiteUUIDName}
                                                                                </strong><br />
                                                                            </>}
                                                                        <strong>
                                                                            {field.linksToSiteUUIDName && `${field.linksToSiteUUIDName}->`}
                                                                            {field.linksToResource}
                                                                        </strong><br />
                                                                        <br />

                                                                        Formula to use on resource: <br />
                                                                        <strong>{field.aggregationConfiguration.formula}
                                                                            ({field.aggregationConfiguration.properties.join(", ")})
                                                                        </strong><br />
                                                                        <br />

                                                                        Fields Match For Computation: <br />
                                                                        <strong>
                                                                            {field.linksToResource}-&gt;{field.resourceLinkingKey} = {field.resourceFilterValue}<br />
                                                                        </strong>
                                                                    </div>
                                                                </> : undefined}

                                                                {fieldType?.value === "LOOKUP" ? <>
                                                                    <div >
                                                                        {field.linksToSiteUUIDName &&
                                                                            <>
                                                                                Application - <strong>
                                                                                    {field.linksToSiteUUIDName}
                                                                                </strong><br />
                                                                            </>}
                                                                        <strong>
                                                                            {field.linksToSiteUUIDName && `${field.linksToSiteUUIDName}->`}
                                                                            {field.linksToResource}-&gt;{field.resourceLinkingKey}
                                                                        </strong><br />
                                                                        <br />

                                                                        {Array.isArray(field.resourceDisplayKeysMultiple) ?
                                                                            <>
                                                                                Custom Field To Display: <br />
                                                                                {field.resourceDisplayKeysMultiple.map((field) => {
                                                                                    if (field[0] == "$") {
                                                                                        return <strong key={field}>{field}</strong>
                                                                                    } else {
                                                                                        return <span key={field}>{field}</span>
                                                                                    }
                                                                                })}
                                                                            </> :
                                                                            <>
                                                                                Field To Display: <br />
                                                                                <strong>{field.resourceDisplayKey}</strong>
                                                                            </>
                                                                        }<br />
                                                                    </div>
                                                                </> : undefined}

                                                                {fieldHasErrors &&
                                                                    <div className="mt-4 text-danger">
                                                                        <hr className="m-0 mb-2" />

                                                                        {this.state.errors[fieldName].messages.map((error, index) => {
                                                                            return <div key={index}>{error}</div>
                                                                        })}
                                                                    </div>
                                                                }
                                                            </td>

                                                            <td className={`${fieldHasErrors ? "border-top-bottom border-danger" : ""} text-center`}>
                                                                {field.defaultComputedValue?.length > 0 ? <>
                                                                    <br />
                                                                    <div className="mt-3">
                                                                        {field.defaultComputedValue &&
                                                                            <>
                                                                                <strong>
                                                                                    {field.defaultComputedValue}
                                                                                </strong>
                                                                            </>}
                                                                    </div>
                                                                </> : field.default}
                                                            </td>

                                                            <td className={`${fieldHasErrors ? "border-top-bottom border-danger" : ""} text-center`}>
                                                                <input
                                                                    id={`resource-field-${fieldName}-required`}
                                                                    disabled={isAutomatic}
                                                                    onChange={() => this.toggleRequired(fieldName)}
                                                                    type="checkbox"
                                                                    checked={isAutomatic || field.required === true}
                                                                />
                                                            </td>
                                                            <td className={`col-3 ${fieldHasErrors ? "border-top-bottom border-danger" : ""} border-right`}>
                                                                <button
                                                                    id={`resource-model-field-${fieldName}-move-up`}
                                                                    onClick={() => this.moveField(fieldName, "up")}
                                                                    className="btn btn-sm btn-primary ml-2"
                                                                    disabled={index === 0}
                                                                >
                                                                    <i className="fa fa-arrow-up" />
                                                                </button>

                                                                <button
                                                                    id={`resource-model-field-${fieldName}-move-down`}
                                                                    onClick={() => this.moveField(fieldName, "down")}
                                                                    className="btn btn-sm btn-primary ml-2"
                                                                    disabled={index === (originalArray.length - 1)}
                                                                >
                                                                    <i className="fa fa-arrow-down" />
                                                                </button>

                                                                <button
                                                                    id={`resource-model-field-${fieldName}-edit`}
                                                                    onClick={() => this.editField(fieldName)}
                                                                    className="btn btn-sm btn-info ml-2 "
                                                                >
                                                                    <i className="fa fa-edit" />
                                                                </button>

                                                                <button
                                                                    id={`resource-model-field-${fieldName}-remove`}
                                                                    onClick={() => this.removeField(fieldName)}
                                                                    className="btn btn-sm btn-danger ml-2 "
                                                                >
                                                                    <i className="fa fa-times" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        <small className="form-text text-danger">{this.state.modelError}</small>

                                    </div>
                                    <div className="col-sm-6">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <label className="font-bold">
                                                            Add Field:
                                                            <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>
                                                        </label>
                                                        <input
                                                            id="resource-fieldName"
                                                            value={this.state.fieldName}
                                                            onChange={this.handleInputFieldChange}
                                                            name="fieldName"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter a field name"
                                                        />

                                                        <small className="form-text text-danger">{this.state.fieldNameError}</small>

                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label className="font-bold">
                                                            Field Type:
                                                            <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>
                                                        </label>
                                                        <Select
                                                            isClearable
                                                            id="resource-fieldType"
                                                            placeholder="Select the field type"
                                                            value={this.state.fieldType}
                                                            onChange={this.handleFieldTypeChange}
                                                            options={supportedFieldTypes}
                                                        />

                                                        <small className="form-text text-danger">{this.state.fieldTypeError}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {!(this.state.fieldType !== null && ["LOOKUP", "AGGREGATE"].includes(this.state.fieldType.value)) &&
                                                <>
                                                    {this.state.toggleDefaultComputedValue == true ?
                                                        <>
                                                            <div className="col-sm-6 mt-4">
                                                                <label className="font-bold">
                                                                    Compute Default Value Using Formula:
                                                                    <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>
                                                                </label>
                                                                <Select
                                                                    isClearable
                                                                    id="resource-selectedFormula"
                                                                    placeholder="Select the formula to use"
                                                                    options={this.supportedDefaultForumlas}
                                                                    onChange={this.handleSelectedFormulaChange}
                                                                    value={this.state.selectedFormula}
                                                                />
                                                                <small className="form-text text-danger">{this.state.selectedFormulaError}</small>

                                                                <input
                                                                    id="resource-toggleDefaultComputedValue"
                                                                    checked={this.state.toggleDefaultComputedValue}
                                                                    onChange={this.handleInputFieldChange}
                                                                    name="toggleDefaultComputedValue"
                                                                    type="checkbox"
                                                                />
                                                                <label
                                                                    htmlFor="resource-toggleDefaultComputedValue"
                                                                    className={"ml-1 mt-1 small form-checkbox form-icon active"}
                                                                >
                                                                    &nbsp;Use Computed Value From A Formula
                                                                    <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>

                                                                </label>
                                                            </div>

                                                            <div className="col-sm-6 mt-4">
                                                                <label className="font-bold">
                                                                    Formula:
                                                                    <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>
                                                                </label>
                                                                <input
                                                                    id="resource-defaultComputedValue"
                                                                    onChange={this.handleDefaultComputedValueChange}
                                                                    name="defaultComputedValue"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter the formula arguments"
                                                                    value={this.state.defaultComputedValue}
                                                                />
                                                                <small className="form-text text-danger">{this.state.defaultComputedValueError}</small>
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="col-sm-6 mt-4">
                                                            <label className="font-bold">
                                                                Default Value:

                                                                <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>
                                                            </label>
                                                            <input
                                                                id="resource-defaultValue"
                                                                value={this.state.defaultValue}
                                                                onChange={this.handleInputFieldChange}
                                                                name="defaultValue"
                                                                type="text"
                                                                disabled={this.state.fieldType !== null && this.state.fieldType.value == "LOOKUP"}
                                                                className="form-control"
                                                                placeholder="(Optional) Value to use when field is left empty"
                                                            />

                                                            <small className="form-text text-danger">{this.state.defaultValueError}</small>

                                                            <input
                                                                id="resource-toggleDefaultComputedValue"
                                                                checked={this.state.toggleDefaultComputedValue}
                                                                onChange={this.handleInputFieldChange}
                                                                name="toggleDefaultComputedValue"
                                                                type="checkbox"
                                                            />
                                                            <label
                                                                htmlFor="resource-toggleDefaultComputedValue"
                                                                className={"ml-1 mt-1 small form-checkbox form-icon active"}
                                                            >
                                                                &nbsp;Use Computed Value From A Formula
                                                                <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>

                                                            </label>
                                                        </div>
                                                    }
                                                </>
                                            }

                                            {this.state.fieldType !== null && this.state.fieldType.value == "FIXEDLENGTH" &&
                                                <div className="col-sm-6 mt-4">
                                                    <label className="font-bold">
                                                        Field Size:
                                                        <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>
                                                    </label>
                                                    <input
                                                        id="resource-fieldSize"
                                                        value={this.state.fieldSize}
                                                        onChange={this.handleInputFieldChange}
                                                        name="fieldSize"
                                                        type="number"
                                                        min={1}
                                                        className="form-control"
                                                        placeholder="Size of the field"
                                                    />

                                                    <small className="form-text text-danger">{this.state.fieldSizeError}</small>

                                                </div>
                                            }
                                        </div>

                                        {this.state.fieldType !== null && ["LOOKUP", "AGGREGATE"].includes(this.state.fieldType.value) ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-6 mt-4">
                                                        <label className="font-bold">
                                                            Lookup From Application:
                                                            <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>
                                                        </label>
                                                        <Select
                                                            isClearable
                                                            id="resource-selectedLookupApplication"
                                                            placeholder="Select the resource to lookup from"
                                                            onChange={this.handleLookupApplicationChange}
                                                            options={this.state.applications}
                                                            value={this.state.selectedLookupApplication}
                                                        />
                                                        <small className="form-text text-danger">{this.state.selectedLookupApplicationError}</small>
                                                    </div>

                                                    <div className="col-sm-6 mt-4">
                                                        <label className="font-bold">
                                                            Lookup From Resource:
                                                            <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>
                                                        </label>
                                                        <Select
                                                            isClearable
                                                            id="resource-selectedLookupResource"
                                                            placeholder="Select the resource to lookup from"
                                                            onChange={this.handleLookupResourceChange}
                                                            options={this.state.lookupResources}
                                                            value={this.state.selectedLookupResource}
                                                        />
                                                        <small className="form-text text-danger">{this.state.selectedLookupResourceError}</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 mt-4">
                                                        <label className="font-bold">
                                                            Field To Lookup To:
                                                            <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>
                                                        </label>
                                                        <Select
                                                            isClearable
                                                            id="resource-resourceLinkingKey"
                                                            placeholder="Select the field you want to lookup to"
                                                            onChange={this.handleResourceLinkingKeyChange}
                                                            options={this.state.resourceFields}
                                                            value={this.state.resourceLinkingKey}
                                                        />
                                                        <small className="form-text text-danger">{this.state.resourceLinkingKeyError}</small>
                                                    </div>

                                                    {this.state.fieldType.value == "LOOKUP" &&
                                                        <>

                                                            <div className="col-sm-6 mt-4">
                                                                <label className="font-bold">
                                                                    {this.state.toggleResourceDisplayKeyMultiple == true ?
                                                                        "Custom Field Value To Display:" : "Single Field Value To Display:"}
                                                                    <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>

                                                                </label>

                                                                {this.state.toggleResourceDisplayKeyMultiple == true ?
                                                                    <CreatableSelect
                                                                        id="resource-resourceDisplayKeyMultiple"
                                                                        placeholder="Type anything to get started"
                                                                        isMulti
                                                                        formatCreateLabel={(inputString) => `Add text "${inputString}"`}
                                                                        onChange={this.handleResourceDisplayKeyMultipleChange}
                                                                        options={this.state.resourceFields}
                                                                        value={this.state.resourceDisplayKeyMultiple}
                                                                        getOptionLabel={option => {
                                                                            if (option.__isNew__) {
                                                                                return option.label
                                                                            } else {
                                                                                return "$" + option.label
                                                                            }
                                                                        }}

                                                                        closeMenuOnSelect={false}
                                                                        styles={colourStyles}
                                                                    />
                                                                    :
                                                                    <Select
                                                                        isClearable
                                                                        id="resource-resourceDisplayKey"
                                                                        placeholder="Select the field you want to display after lookup"
                                                                        onChange={this.handleResourceDisplayKeyChange}
                                                                        options={this.state.resourceFields}
                                                                        value={this.state.resourceDisplayKey}
                                                                    />
                                                                }
                                                                {this.state.toggleResourceDisplayKeyMultiple == true ?
                                                                    <small className="form-text text-danger">{this.state.resourceDisplayKeyMultipleError}</small>
                                                                    :
                                                                    <small className="form-text text-danger">{this.state.resourceDisplayKeyError}</small>
                                                                }

                                                                <input
                                                                    id="resource-toggleResourceDisplayKeyMultiple"
                                                                    checked={this.state.toggleResourceDisplayKeyMultiple}
                                                                    onChange={this.handleInputFieldChange}
                                                                    name="toggleResourceDisplayKeyMultiple"
                                                                    type="checkbox"
                                                                />
                                                                <label
                                                                    htmlFor="resource-toggleResourceDisplayKeyMultiple"
                                                                    className={"ml-1 mt-1 small form-checkbox form-icon active"}
                                                                >
                                                                    &nbsp;Use Custom Field Value
                                                                    <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>

                                                                </label>
                                                            </div>
                                                        </>
                                                    }

                                                    {this.state.fieldType.value == "AGGREGATE" &&
                                                        <>

                                                            <div className="col-sm-6 mt-4">
                                                                <label className="font-bold">
                                                                    Field To Match To In This Resource:
                                                                    <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>
                                                                </label>
                                                                <Select
                                                                    isClearable
                                                                    id="resource-resourceLinkingKey"
                                                                    placeholder="Select the field you want to match to in this resource"
                                                                    onChange={this.handleResourceFilterValueChange}
                                                                    options={Object.keys(this.state.model).map((key) => {
                                                                        return {
                                                                            value: key,
                                                                            label: key,
                                                                        }
                                                                    })}
                                                                    value={this.state.resourceFilterValue}
                                                                />
                                                                <small className="form-text text-danger">{this.state.resourceFilterValueError}</small>
                                                            </div>

                                                            <div className="col-sm-6 mt-4">
                                                                <label className="font-bold">
                                                                    Formula Function To Use:
                                                                    <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>
                                                                </label>
                                                                <Select
                                                                    isClearable
                                                                    id="resource-selectedFormula"
                                                                    placeholder="Select the formula to use"
                                                                    options={supportedAggregateFormula}
                                                                    onChange={this.handleSelectedFormulaChange}
                                                                    value={this.state.selectedFormula}
                                                                />
                                                                <small className="form-text text-danger">{this.state.selectedFormulaError}</small>
                                                            </div>

                                                            <div className="col-sm-6 mt-4">
                                                                <label className="font-bold">
                                                                    Fields and values to use:
                                                                    <sup className="pointer ml-1 fa-sm far fa-question-circle fa-lg"></sup>
                                                                </label>
                                                                <CreatableSelect
                                                                    id="resource-aggregateFieldValues"
                                                                    placeholder="Type field names to search or enter numeric values"
                                                                    isMulti
                                                                    formatCreateLabel={(inputString) => `Add value "${inputString}"`}
                                                                    onChange={this.handleAggregateFieldValuesChange}
                                                                    options={this.state.resourceFields.filter((field) => isNumericField(field.fieldType))}
                                                                    getOptionLabel={option => {
                                                                        if (option.__isNew__) {
                                                                            return option.label
                                                                        } else {
                                                                            return "$" + option.label
                                                                        }
                                                                    }}
                                                                    value={this.state.aggregateFieldValues}

                                                                    closeMenuOnSelect={false}
                                                                    styles={colourStyles}
                                                                />
                                                                <small className="form-text text-danger">{this.state.aggregateFieldValuesError}</small>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </>
                                            :
                                            undefined
                                        }

                                        <div className="row">
                                            <div className="col-sm-12 mt-3 text-right">
                                                {this.state.isFieldBeingEdited ?
                                                    <>
                                                        <div className="btn-group btn-block" role="group" aria-label="Basic example">

                                                            <button
                                                                id="resource-addField"
                                                                onClick={this.addField}
                                                                type="button"
                                                                className="btn btn-success mr-2"
                                                            >
                                                                <span className="fas fa-edit"></span>&nbsp;
                                                                Update Field
                                                            </button>

                                                            <button
                                                                id="resource-addField"
                                                                onClick={this.cancelEditField}
                                                                type="button"
                                                                className="btn btn-danger btn"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    <button
                                                        id="resource-addField"
                                                        onClick={this.addField}
                                                        type="button"
                                                        className="btn btn-success btn-block"
                                                    >
                                                        <span className="fas fa-plus"></span>&nbsp;
                                                        Add Field
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CreateResource.propsInformation = {
};

export default withLoaderData(connect(DataDelegator.mapStateToProps)(CreateResource));