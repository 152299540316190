import { getFromStore } from "./DataDelegator";
import { render } from "ejs";

// Return the true value for consideration based on the first character
// $ - fetch from object
// # - fetch from redux store
// nothing - render the value using ejs with the given object
export default function (obj, value) {
    if (value[0] == "$") {
        const getNestedData = value.substr(1).split(".");

        value = getNestedData.reduce((acc, key) => {
            if(acc[key]) {
                acc = acc[key];
            } else {
                acc = null;
            }

            return acc;
        }, obj);

        // value = obj[value.substr(1)] || null;
    }

    else if (value[0] == "#") {
        value = getFromStore(value.substr(1)) || null;
    }

    else if(typeof value == "string") {
        try {
            value = render(value, obj);
        } catch(err) {
            console.error(err);
        }
    }

    return value;
}