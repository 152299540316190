const initialValue = [
    {
        type: 'paragraph',
        children: [{ text: 'Hello,' }],
    },
    {
        type: 'paragraph',
        children: [{ text: '' }],
    },
    {
        type: 'span',
        children: [
            { text: 'With love,' },
        ],
    },
    {
        type: 'br',
        children: [
            { text: '' }
        ],
    },

    {
        type: 'span',
        children: [
            { text: 'The team at Robostack.' }
        ],
    },

]

export default initialValue;