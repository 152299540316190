import { useState } from 'react';

import withLoaderData from 'components/withLoaderData';
import { connect } from 'react-redux';
import * as DataDelegator from "components/smart/delegator/DataDelegator";
import MagicForm from 'components/smart/supported/MagicForm';

const AdminSiteCloner = (props) => {
    const [errors, setErrors] = useState([]);

    return (
        <div className='col-sm-12'>
            <div className='ibox-content mt-2'>
                <div className='row'>

                    <div className='col-sm-12 mt-2'>
                        <MagicForm
                            robostackResolveData={[
                                {
                                    "name": "ListOfAllSites",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/administration/resources/sites",
                                        "data": {}
                                    },
                                    "transformMergeIfArray": true,
                                    "transformMerge": true,
                                    "transformArrayMergeStratergy": "overwriteTarget",
                                    "transform": {
                                        "fields": {
                                            "existingSite": {
                                                "options": "`data.results | sort:name | map | set-prop:display,'<%- name %> - <%- domainName %> - <%- uuid %>' | set-prop:value,$uuid`",
                                                "forceUpdate": true
                                            },
                                        }
                                    }
                                },
                                {
                                    "name": "ListOfAllCustomers",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/administration/resources/billing-customers",
                                        "data": {}
                                    },
                                    "transformMergeIfArray": true,
                                    "transformMerge": true,
                                    "transformArrayMergeStratergy": "overwriteTarget",
                                    "transform": {
                                        "fields": {
                                            "customerAccount": {
                                                "options": "`data.results | sort:name | map | set-prop:display,'<%- name %>' | set-prop:value,$uuid`",
                                                "forceUpdate": true
                                            },
                                        }
                                    }
                                },
                            ]}
                            submitApi={{
                                "method": "POST",
                                "endpoint": "/administration/site-cloner",
                                "data": {}
                            }}
                            fields={
                                [
                                    {
                                        "existingSite": {
                                            "label": "Existing site to clone",
                                            "type": "dropdown",
                                            "placeholder": "Select an existing site to clone",
                                            "required": true,
                                            "position": 10,
                                        },
                                        "customerAccount": {
                                            "label": "Customer account to associate the cloned site with",
                                            "type": "dropdown",
                                            "placeholder": "Customer account to associate the cloned site with",
                                            "required": true,
                                            "position": 20,
                                        },
                                        "name": {
                                            "label": "Name of the new application",
                                            "type": "text",
                                            "placeholder": "Bat Cave",
                                            "required": true,
                                            "position": 21,
                                        },
                                        "domainName": {
                                            "label": "Domain for the new application",
                                            "type": "text",
                                            "placeholder": "batcave.wayne-enterprises.com",
                                            "required": true,
                                            "position": 30,
                                            "validator": "domain",
                                            "validationFailedMessage": "Please provide a valid domain name",
                                        },
                                        "image": {
                                            "label": "Image for the new application",
                                            "type": "file",
                                            "placeholder": "Choose file...",
                                            "required": true,
                                            "position": 40,
                                            "value": "https://www.robostack.ai/img/app.jpg"
                                        },

                                        "with_settings": {
                                            "label": "Clone with application settings?",
                                            "type": "checkbox",
                                            "position": 50,
                                            value: true,
                                        },

                                        "with_resources": {
                                            "label": "Clone with resources?",
                                            "type": "checkbox",
                                            "position": 60,
                                            value: true,
                                        },

                                        "with_resources_data": {
                                            "label": "Clone with resources data?",
                                            "type": "checkbox",
                                            "position": 70,
                                            value: true,
                                        },

                                        "with_triggers": {
                                            "label": "Clone with triggers?",
                                            "type": "checkbox",
                                            "position": 80,
                                            value: true,
                                        },

                                        "with_forms": {
                                            "label": "Clone with forms?",
                                            "type": "checkbox",
                                            "position": 90,
                                            value: true,
                                        },

                                        "with_api_playground": {
                                            "label": "Clone with API playground queries?",
                                            "type": "checkbox",
                                            "position": 100,
                                            value: true,
                                        },

                                        "with_reports": {
                                            "label": "Clone with reports?",
                                            "type": "checkbox",
                                            "position": 110,
                                            value: true,
                                        },

                                        "with_scheduled_tasks": {
                                            "label": "Clone with scheduled tasks?",
                                            "type": "checkbox",
                                            "position": 120,
                                            value: true,
                                        },

                                        "with_roles": {
                                            "label": "Clone with roles?",
                                            "type": "checkbox",
                                            "position": 130,
                                            value: true,
                                        },

                                        "with_roles_permissions": {
                                            "label": "Clone with permissions for each role?",
                                            "type": "checkbox",
                                            "position": 140,
                                            value: true,
                                        },

                                        "with_users": {
                                            "label": "Clone with users assigned?",
                                            "type": "checkbox",
                                            "position": 150,
                                            value: true,
                                        },

                                        "with_users_roles": {
                                            "label": "Clone with roles assigned to assigned users?",
                                            "type": "checkbox",
                                            "position": 160,
                                            value: true,
                                        },

                                        "with_api_access": {
                                            "label": "Clone with API acess tokens?",
                                            "type": "checkbox",
                                            "position": 170,
                                            value: true,
                                        },
                                    }
                                ]
                            }
                        />
                    </div>

                    <div className='col-sm-12 mt-2'>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(AdminSiteCloner));