import Triggers, { introductionIdentifier } from "pages/app/triggers";
import StandardLoader from "./standard-loader";

export default function TriggersRouter() {
    return {
        path: "/app/:appUUID/triggers",

        children: [
            {
                index: true,
                element: <Triggers />,
                loader: (props) => StandardLoader({
                    ...props,
                    crumbs: [
                        {
                            "name": "Triggers",
                        },
                    ],
                    introductionIdentifier,
                }),
            },
        ]
    };
}