import React from "react";


function CodeBlock(props) {
    return (
        <>
            <code>
                <pre className="text-left">
                    {props.children}
                </pre>
            </code>
        </>
    )
}

export default CodeBlock;