import {
    useLoaderData,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

export default function withLoaderData(Component) {
    return function ComponentWithLoaderData(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        const loaderData = useLoaderData();

        return (
            <Component
                {...props}

                router={{
                    location,
                    navigate,
                    params,
                    loaderData,
                }}
            />
        );
    }
}