
import React from "react";
import { connect } from "react-redux";

import * as DataDelegator from "../delegator/DataDelegator";

import JSON_ARRAY_Simple_Render from "../../datatypes/json_array/simple";
import JSON_Simple_Render from "../../datatypes/json/simple";
import File_Full_Render from "../../datatypes/file/full";
import Email_Simple_Render from "../../datatypes/email/simple";
import Currency_Simple_Render from "../../datatypes/currency/simple";
import User_Simple_Render from "../../datatypes/user/simple";
import Lookup_Simple_Render from "../../datatypes/lookup/simple";
import Boolean_Simple_Render from "../../datatypes/boolean/simple";
import Markdown_Simple_Render from "../../datatypes/markdown/simple";

import Loader from "../../Loader";

import { Link } from "react-router-dom";
import ToTitleCase from "../../../core/utils/toTitleCase";
import AttachTrailingSlash from "../../../core/utils/attachTrailingSlash";

export async function RecordViewerLoader(props) {
    return new Promise(async (resolve, reject) => {
        const { params } = props;
        const url = new URL(props.request.url);

        try {
            DataDelegator.resolve({
                robostackResolveData:
                    [
                        {
                            "name": "ListOfApps",
                            "api": {
                                "method": "POST",
                                "endpoint": "/apps",
                                "data": {}
                            },
                            "transformName": "Breadcrumbs",
                            "transform": {
                                "title": "`data.results | search-array-for-object: uuid," + params.appUUID + " | pop | prop: name`",
                                "uuid": params.appUUID,
                                "root": AttachTrailingSlash(url.pathname) + "../../"
                            }
                        },
                        {
                            "name": "Breadcrumbs",
                            "forceApiReload": true,
                            "mergeWithSourceAfterTransform": true,
                            "transformArrayMergeStratergy": "overwriteTarget",
                            "props": {
                                crumbs: [
                                    {
                                        "name": "Resources",
                                    },
                                    {
                                        "name": params.resourceName,
                                    },
                                    {
                                        "name": "View",
                                    },
                                ]
                            }
                        },
                    ]
            }, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(null);
                }
            });
        } catch (err) {
            reject(err);
        }
    })

}

class RecordViewer extends React.Component {
    constructor(props) {
        super(props);

        this.simpleRender = {
            "JSON_ARRAY": JSON_ARRAY_Simple_Render,
            "JSON": JSON_Simple_Render,
            "FILE": File_Full_Render,
            "EMAIL": Email_Simple_Render,
            "CURRENCY": Currency_Simple_Render,
            "USER": User_Simple_Render,
            "USER_UPDATE": User_Simple_Render,
            "BOOLEAN": Boolean_Simple_Render,
            "MARKDOWN": Markdown_Simple_Render,
        };

        this.state = {
            zoom: 1,
        };

        this.tableRef = React.createRef();

        this.changeFontSize = this.changeFontSize.bind(this);
    }

    componentDidMount() {
        DataDelegator.resolve(this.props);
    }

    componentDidUpdate(prevProps) {
        // Update only if the site and the resource name has changed
        if (this.props.site !== prevProps.site || this.props.resource !== prevProps.resource || this.props.identifier !== prevProps.identifier || this.props.value !== prevProps.value) {
            DataDelegator.resolve(this.props);
        }
    }

    changeFontSize(direction) {
        let sizeInEm = this.state.zoom;

        if (direction == "-") {
            sizeInEm -= .2;
        } else {
            sizeInEm += .2;
        }

        this.setState({
            zoom: sizeInEm
        }, () => {
            if (this.tableRef.current !== null) {
                this.tableRef.current.style.fontSize = `${sizeInEm}em`;
            }
        });
    }

    print() {
        window.print();
    }

    render() {
        return (
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-4 pb-4">
                        <div className="col-sm-4">
                            <a onClick={() => this.changeFontSize("-")} className="btn btn-default d-print-none"><i className="fas fa-search-minus"></i>&nbsp;Zoom Out</a>
                            <a onClick={() => this.changeFontSize("+")} className="btn btn-default d-print-none"><i className="fas fa-search-plus"></i>&nbsp;Zoom In</a>
                        </div>

                        <div className="col-sm-8">
                            <a onClick={print} className="btn btn-default btn d-print-none float-right"><i className="fas fa-print"></i>&nbsp;Print</a>

                            <Link className="btn-link" to={`./../../../edit/${this.props.identifier}/${this.props.value}`}>
                                <button className="btn btn-default d-print-none float-right text-default"><i className="fas fa-edit"></i>&nbsp;Edit</button>
                            </Link>
                        </div>
                    </div>

                    <div className="text-center ">
                        <h1 className="mar-no">{this.props.resourceName} </h1>
                        <h3 className="pad-btm">{this.props.identifier} : {this.props.value} </h3>
                    </div>

                    <table ref={this.tableRef} id='data-record' className="table table-hover table-vcenter">
                        {this.props.data.length == 0 && <tbody>
                            <tr>
                                <td className="no-borders no-pointer-events">
                                    <Loader />
                                </td>
                            </tr>
                        </tbody>
                        }

                        {this.props.data.map((data, index) => { // In case there are multiple values returned
                            const body = this.props.columns.filter((column) => column.fieldName != "robostack_id").map((column) => {
                                let value = data[column.fieldName];

                                if ((this.simpleRender[column.fieldType] !== undefined)) {
                                    value = this.simpleRender[column.fieldType](data[column.fieldName], column.fieldName, data);
                                } else if (column.fieldType == "LOOKUP") {
                                    if (Array.isArray(column.resourceDisplayKeysMultiple)) {
                                        value = column.resourceDisplayKeysMultiple;
                                    }

                                    value = Lookup_Simple_Render(value, column.fieldName, data);
                                }

                                return (
                                    <tr key={column.fieldName}>
                                        <td><strong>{ToTitleCase(column.fieldName)}</strong></td>
                                        <td>{value}</td>
                                    </tr>
                                )
                            });

                            return (
                                <tbody key={index} className="m-b-xl">
                                    {body}
                                </tbody>
                            );
                        })}
                    </table>
                </div>
            </div>
        );
    }
}

RecordViewer.propsInformation = {

}

export default connect(DataDelegator.mapStateToProps)(RecordViewer);