import React, { useEffect } from "react";
import { useLoaderData } from "react-router-dom";

import axiosBackend from "../../core/api/backend";

import RenderComponents from "../../components/smart/RenderComponents";

import * as DataDelegator from "../../components/smart/delegator/DataDelegator";
import AttachTrailingSlash from "../../core/utils/attachTrailingSlash";

import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import ShouldIntroductionTourRun from "../../core/onboarding/should-introduction-tour-run";
import MarkIntroductionTourDone from "../../core/onboarding/mark-introduction-tour-done";


const introductionIdentifier = "app/index";

export async function loader(props) {
    return new Promise(async (resolve, reject) => {
        const { params } = props;
        const url = new URL(props.request.url);

        try {
            const [dashboard, shouldRun] = await Promise.all([
                axiosBackend.post(`/page/${params.appUUID}`, {
                    identifier: "dashboard",
                }),
                ShouldIntroductionTourRun({ identifier: introductionIdentifier })
            ]);

            DataDelegator.resolve({
                robostackResolveData:
                    [
                        {
                            "name": "ListOfApps",
                            "api": {
                                "method": "POST",
                                "endpoint": "/apps",
                                "data": {}
                            },
                            "transformName": "Breadcrumbs",
                            "transform": {
                                "title": "`data.results | search-array-for-object: uuid," + params.appUUID + " | pop | prop: name`",
                                "uuid": params.appUUID,
                                "root": AttachTrailingSlash(url.pathname),
                                "crumbs": [],
                            }
                        },
                    ]
            }, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve({
                        list: dashboard.data.results,
                        shouldRun
                    });
                }
            });
        } catch (err) {
            reject(err);
        }
    })

}

export default function AppDashboard(props) {
    const {
        list,
        shouldRun,
    } = useLoaderData();

    const propsToRender = {
        ...props,
    };

    delete propsToRender.robostackResolveData;

    const components = RenderComponents(list, {
        ...propsToRender
    });

    const [showTour, setShowTour] = React.useState(false);
    const [stepIndex, setStepIndex] = React.useState(0);

    useEffect(() => {
        if (shouldRun) {
            setShowTour(true);
        }
    }, [shouldRun]);

    const toggleHelp = () => {
        setShowTour(true);
    }

    const handleJoyrideCallback = (data) => {
        if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.CLOSE].includes(data.status) || ACTIONS.CLOSE == data.action) {
            // Need to set our running state to false, so we can restart if we click start again.
            setShowTour(false);
            setStepIndex(0);

            if(shouldRun) {
                MarkIntroductionTourDone({ identifier: introductionIdentifier });
            }
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(data.type)) {
            // Update state to advance the tour
            setStepIndex(data.index + (data.action === ACTIONS.PREV ? -1 : 1));
        }
    }

    const steps = list.filter((item) => item.name !== undefined).map((item) => {
        return {
            target: `#${item.parent.props.id}`,
            title: item.name.replace(/([A-Z])/g, ' $1').trim(),
            content: (
                <div>
                    {item["data-hint"]}<br />
                </div>
            ),
            disableBeacon: true,
        };
    });

    steps.push({
        target: "#toggle-help",
        content: (
            <div>
                Click on this button to view this tutorial at any time.<br />
            </div>
        )
    })

    return (
        <>
            <ReactJoyride
                callback={handleJoyrideCallback}
                run={showTour}
                stepIndex={stepIndex}
                steps={steps}
                continuous={true}
                showSkipButton={true}
                showProgress={true}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
                disableScrollParentFix={true}
            />

            <div className="col-lg-12 pb-2">

                <button id="toggle-help" className="btn btn-gray" onClick={toggleHelp}>
                    <span className="far fa-question-circle fa-lg"></span>
                </button>
            </div>

            <div id="dashboard" className="col-lg-12 ">
                <div className="row">
                    {components}

                </div>
            </div>
        </>
    )
}