// Remove all our custom props before we render it on the dom
export default function(props) {
    delete props.inlineLabels;
    delete props.inlineLabelSize;

    delete props.fieldName;
    delete props.instanceIndex;

    delete props.classes;
    delete props.position;

    delete props.validationFailedMessage;
    delete props.validation;

    delete props.error;
    delete props.success;
    delete props.warning;

    delete props.required; // This is to avoid the browser's default ugly required handler

    delete props.lookup;

    delete props.defaultComputedValue;
    delete props.customOnChange;

    delete props.manuallyChanged;

    return props;
} 