import axiosBackend from "../api/backend";

export default function MarkIntroductionTourDone({ identifier }) {
    return axiosBackend({
        method: "PUT",
        url: "/onboarding/done",
        data: {
            identifier,
        },
    })
}