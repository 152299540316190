export default function supportStatusLabelColor(supportStatus) {
    let labelClass;

    switch (supportStatus) {
        case "open": {
            labelClass = "label label-warning";
            break;
        }

        case "in progress": {
            labelClass = "label label-info";
            break;
        }

        case "duplicate": {
            labelClass = "label label-danger";
            break;
        }

        case "resolved": {
            labelClass = "label label-success";
            break;
        }

        case null: {
            labelClass = "label label-danger";
            break;
        }

        default: {
            labelClass = "label label-default";
            break;
        }
    }
    
    return labelClass;
}
