import * as DataDelegator from "../../../../components/smart/delegator/DataDelegator";
import AttachTrailingSlash from "../../../../core/utils/attachTrailingSlash";

import MagicForm from "../../../../components/smart/supported/MagicForm";
import withLoaderData from "../../../../components/withLoaderData";
import { connect } from "react-redux";
import axiosBackend from "../../../../core/api/backend";

function AddToResource(props) {
    const apiStartUrl = props.apiStartUrl || "service/resources";
    const appUUID = props.appUUID || props.router.params.appUUID;
    const resourceName = props.resourceName || props.router.params.resourceName;

    const resourcesBaseUrl = `/${apiStartUrl}/${appUUID}/${resourceName}`;

    const sharePublicForm = () => {
        props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Share Public Form",
                "message": [
                    "Are you sure you want to share this form publicly?",
                    "‏‏‎ ‎",
                    "Anyone outside your organization will be able to submit data to this form.",
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Creating Public Form",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "POST",
                            url: `/service/resources/${appUUID}/${resourceName}/make-form-into-page`
                        })
                            .then((response) => {
                                props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        modalSize: "large",
                                        messages: [
                                            "Use the following code to embed this form on your website."
                                        ],
                                        components: [{
                                            "component": "CodeBlock",
                                            "size": 12,
                                            "props": {
                                                children: `
<head>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" />
    <link rel="stylesheet" href="https://app.robostack.ai/static/css/smartapp.css" />

    <script src="https://app.robostack.ai/static/js/smartapp.js"></script>
</head>

<body>
    <div class="container-fluid">
        <div id="root"></div>
    </div>

    <script>
        let root = document.getElementById("root");

        let app = document.createElement("div");
        app.classList.add("robostack-app");
        app.setAttribute("identifier", "${response.data.results[0]}");

        root.appendChild(app);

        loadRobostackApp(); // This is injected into the window scope by smartapp.js
    </script>
</body>
                                            `
                                            }
                                        }],
                                        okayButtonText: "Okay",
                                    },
                                });

                            })
                            .catch((err) => {
                                console.error(err);
                                props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Error",
                                        message: [
                                            "Due to an error, we were unable to make the form public",
                                            "‏‏‎ ‎",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    return <>
        <div className="col ibox m-3">
            <div className="ibox-title">
                <h3>Add New Data to {props.router.params.resourceName}</h3>

                <div className="ibox-tools">
                    <a onClick={sharePublicForm} className="">
                        <i className="fa fa-share-alt"></i>
                    </a>
                </div>
            </div>

            <div className="ibox-content">
                <MagicForm
                    appUUID={appUUID}
                    resourceName={resourceName}
                    submitApi={{
                        method: "PUT",
                        endpoint: `${resourcesBaseUrl}`,
                        data: {}
                    }}
                    robostackResolveData={[
                        {
                            "name": "LookupDataFor<%= appUUID %>_<%= resourceName %>",
                            "api": {
                                "method": "POST",
                                "endpoint": `${resourcesBaseUrl}/lookup`,
                                "data": {}
                            },
                            "transformMerge": true,
                            "transform": {
                                "lookup": "`data.results[0]`",
                            }
                        },
                        {
                            "name": "FieldsFor<%= appUUID %>_<%= resourceName %>",
                            "api": {
                                "method": "POST",
                                "endpoint": `${resourcesBaseUrl}/form`,
                                "data": {}
                            },
                            "transformMerge": true,
                            "transform": {
                                "fields": "`data.results`",
                            }
                        },
                    ]}
                />
            </div>
        </div>
    </>;

}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(AddToResource));