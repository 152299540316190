const steps = [

    {
        target: '#api-access-tokens-list',
        title: "API access tokens",
        content: (
            <div>
                All the tokens that have access to this application and its resources are listed here.<br />
            </div>
        ),
        disableBeacon: true,
    },

    {
        target: '#tokens-list-owner-column',
        title: "Token owner",
        content: (
            <div>
                You can see the user who owns a token by looking in this column. <br />
            </div>
        ),
    },

    {
        target: '#tokens-list-expiry-column',
        title: "Token expiry time",
        content: (
            <div>
                A token might have passed its expiry time so make sure that it is set to expire in the future.<br />
                <br />
                A token whose expiry is in the past will not be valid for use with the API endpoints. <br />
            </div>
        ),
    },
    

    {
        target: '.btn-delete-token',
        title: "Remove token access",
        content: (
            <div>
                Click on this button to immediately remove a token's access to this application and resources.<br />
            </div>
        ),
    },

    {
        target: '#tokens-list-create',
        title: "Add token access",
        content: (
            <div>
                You can allow any of your tokens to access this application via the API at any time by clicking on this button.<br />
            </div>
        ),
    },
];

export default steps;