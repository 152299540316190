const steps = [

    {
        target: '#user-profile',
        title: "Your profile information",
        content: (
            <div>
                This your name and email address that is registered with Robostack.<br />
            </div>
        ),
        disableBeacon: true,
    },

    {
        target: '#user-profile-edit-button',
        title: "Edit your profile",
        content: (
            <div>
                Click on this button to change your profile information at any time.<br />
            </div>
        ),
    },

    {
        target: '#tokens-list',
        title: "API tokens list",
        content: (
            <div>
                All your API tokens are listed here.<br />
            </div>
        ),
    },

    {
        target: '#tokens-list-active',
        title: "Available tokens",
        content: (
            <div>
                The API tokens which are available for use are shown here.<br />
            </div>
        ),
    },

    {
        target: '#tokens-list-expiry-column',
        title: "Token expiry time",
        content: (
            <div>
                A token might have passed its expiry time so make sure that it is set to expire in the future.<br />
                <br />
                A token whose expiry is in the past will not be valid for use with the API endpoints. <br />
            </div>
        ),
    },

    {
        target: '.view-secret-button',
        title: "Token secret",
        content: (
            <div>
                You can view the token secret by clicking on the view button. <br />
            </div>
        ),
    },

    {
        target: '#tokens-list-create',
        title: "Create new token",
        content: (
            <div>
                You can create a new api token at any time by clicking on this button.<br />
            </div>
        ),
    },


    {
        target: '.btn-edit-token',
        title: "Editing a token",
        content: (
            <div>
                You can edit the name and expiry time of a token by clicking on the edit button.<br />
            </div>
        ),
    },

    {
        target: '.btn-delete-token',
        title: "Deactivating a token",
        content: (
            <div>
                You can also permanantly deactivate a token by clicking on the deactivate button.<br />
            </div>
        ),
    },

    {
        target: '#tokens-list-deactive',
        title: "Deactivated tokens",
        content: (
            <div>
                All permanantly deactivated tokens are listed here.<br />
            </div>
        ),
    },
];

export default steps;