import React from "react";

function basename(path) {
    return path.split(/[\\/]/).pop();
}

export default function render(value, title) {
    if (!!value) {
        return (<a href={value} title={`Download ${title}`}>
            <button className="btn btn-primary btn-sm btn-icon">
                <i className="icon-lg fas fa-file-download"></i>&nbsp;{decodeURI(basename(value))}
            </button>
        </a>)
    }

    return value;
}