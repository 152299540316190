import React from "react";

import Loader from "../../components/Loader";

import { Link } from "react-router-dom";

import withLoaderData from "../../components/withLoaderData";
import axiosBackend from "../../core/api/backend";

import { isLength } from "validator";
import ReCAPTCHA from "react-google-recaptcha";

import "../../scss/style.scss";
import "../../scss/components/login.scss";



class ConfirmPassword extends React.Component {
    constructor(props) {
        super(props);

        this.recaptchaRef = React.createRef();


        this.state = {
            token: null,

            status: "confirming",
            messages: [],

            password: "",
            passwordError: "",

            passwordRepeat: "",
            passwordRepeatError: "",

            signingIn: false,
            signInError: "",
        };

        this.onSubmitBound = this.onSubmit.bind(this);
    }

    componentDidMount() {

        axiosBackend.post("/authentication/confirm-account", {
            hash1: this.props.router.params.hash1,
            hash2: this.props.router.params.hash2,
        })
            .then((response) => {
                let stateToUpdate = {
                    
                };

                if (response.data.token && response.data.token !== null) {
                    stateToUpdate.token = response.data.token;
                    stateToUpdate.status = "set";
                } else {
                    stateToUpdate.status = "confirmed";
                }

                this.setState(stateToUpdate);
            })
            .catch((error) => {
                console.error(error);

                let stateToUpdate = {
                    status: "error",
                }

                if (error?.response?.data?.messages) {
                    stateToUpdate.messages = error.response.data.messages;
                }

                this.setState(stateToUpdate)
            })
    }

    async onSubmit(e) {
        e.preventDefault();

        let errors = {};

        if (!isLength(this.state.password, { min: 8 })) {
            errors["passwordError"] = "Please enter a password with a minimum of 8 characters";
        }

        if (!isLength(this.state.passwordRepeat, { min: 8 })) {
            errors["passwordRepeatError"] = "Please repeat the same password as you entered above";
        } else {
            if (this.state.password != this.state.passwordRepeat) {
                errors["passwordRepeatError"] = "The password does not match what you entered above";
            }
        }

        if (Object.keys(errors).length > 0) {
            this.setState(errors);
        } else {
            const captchaResponse = await this.recaptchaRef.current.executeAsync();

            this.setState({
                passwordError: "",
                passwordRepeatError: "",
                signInError: "",
                signingIn: true,
            }, () => {
                axiosBackend({
                    method: "POST",
                    url: "/authentication/set-first-password",
                    data: {
                        password: this.state.password,
                        passwordRepeat: this.state.passwordRepeat,
                    },
                    headers: {
                        "Authorization": this.state.token,
                    }
                })
                    .then((response) => {
                        try {
                            if (response.data.status === "success") {
                                this.setState({
                                    status: "confirmed",
                                });
                            } else {
                                this.setState({
                                    signingIn: false,
                                    signInError: response.data.message || "We ran into an issue while trying to set your password. Please check the data you have entered and try again after a few minutes.",
                                });
                            }
                        } catch (err) {
                            this.setState({
                                signingIn: false,
                                signInError: response.data.message || "We ran into an issue while trying to set your password. Please try again after a few minutes.",
                            });
                        }
                    })
                    .catch((error) => {
                        const response = error.response;
                        console.error(response);

                        let errorMessage = "We ran into an issue while trying to set your password. Please try again after a few minutes.";

                        if (response?.data && Array.isArray(response?.data?.messages)) {
                            errorMessage = error.response.data.messages.join(" ");
                        }

                        this.setState({
                            signingIn: false,
                            signInError: errorMessage,
                        });
                    });
            });
        }

        return false;
    }

    render() {
        return (
            <div>
                <div className="middle-box animated">
                    <div className="ibox ibox-content mb-2 text-black">
                        <h1 className="mt-2 mb-4 text-center">Confirm Your Account</h1>

                        {this.state.status == "confirming" ? <>
                            <div className="row">
                                <div className="col-xs-4 offset-sm-6">
                                    <Loader showText={false} />
                                </div>
                            </div>

                            Please wait while your account is being confirmed.
                        </> : null}

                        {this.state.status == "set" ? <>
                            {this.state.signInError.length > 0 ? <div className="alert alert-danger">
                                {this.state.signInError}
                            </div> : undefined}

                            <form className="m-t" role="form" onSubmit={this.onSubmitBound}>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            <i className="fa fa-key"></i>
                                        </div>
                                        <input disabled={this.state.signingIn} onChange={(e) => this.setState({ password: e.target.value, passwordError: "" })} type="password" className={"form-control " + (this.state.passwordError.length > 0 ? "is-invalid" : "")} name="password" placeholder="Enter your new password" />
                                        <div className="invalid-feedback text-normal">
                                            {this.state.passwordError}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            <i className="fa fa-asterisk"></i>
                                        </div>
                                        <input disabled={this.state.signingIn} onChange={(e) => this.setState({ passwordRepeat: e.target.value, passwordRepeatError: "" })} type="password" className={"form-control " + (this.state.passwordRepeatError.length > 0 ? "is-invalid" : "")} name="passwordRepeat" placeholder="Repeat your new password" />
                                        <div className="invalid-feedback text-normal">
                                            {this.state.passwordRepeatError}
                                        </div>
                                    </div>
                                </div>

                                <ReCAPTCHA
                                    ref={this.recaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                                    size="invisible"
                                />

                                <button disabled={this.state.signingIn} type="submit" className="btn btn-login block full-width">
                                    {this.state.signingIn ? "Setting Password..." : "Set Password"}
                                </button>
                            </form>
                        </> : null}

                        {this.state.status == "confirmed" ? <div className="text-center">
                            <div className="pad-btm text-success"><i className="fas fa-check-circle fa-5x"></i></div>

                            Your account has been successfully confirmed. <br />
                            You can now <Link className="text-inherit" to="/login"><u>login</u></Link> to your account.
                        </div> : null}

                        {this.state.status == "error" ? <div className="text-center">
                            <div className="pad-btm text-danger"><i className="fas fa-times-circle fa-5x"></i></div>

                            {Array.isArray(this.state.messages) && this.state.messages.length > 0 ?
                                this.state.messages.map((message, index) => {
                                    return <span key={index}>{message}<br /></span>
                                })
                                :

                                <span>
                                    Unfortunately, we could not confirm the status of your account. <br /><br />
                                    Please try again after some time.</span>
                            }
                            <br />
                            Or <Link to="/#contact" className="text-inherit"><u>contact us</u></Link> for assistance if this persists.
                        </div> : null}
                    </div>
                </div>
                <div className="container">
                    <div className="row mt-0 text-center">
                        <div className="justify-content-center col-md-6 col-sm-12 md-end">
                            <Link to="/login">
                                <div className="helper">
                                    <>Login</>
                                </div>
                            </Link>
                        </div>
                        <div className="justify-content-center col-md-6 col-sm-12 md-start">
                            <Link to="/signup">
                                <div className="helper">
                                    <>Create a new account</>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withLoaderData(ConfirmPassword);