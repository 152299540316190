import React from "react";

export default class ImageWithFallback extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errored: false,
        };

        this.onErrorBound = this.onError.bind(this);
    }

    onError() {
        if (!this.state.errored) {
            this.setState({
                errored: true,
            });
        }
    }

    render() {
        const {
            src,
            fallbackSrc,
            ...props
        } = this.props;

        return (
            <img
                src={this.state.errored ? fallbackSrc : src}
                onError={this.onErrorBound}
                {...props}
            />
        );
    }
}