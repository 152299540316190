export default (state = {}, action) => {
	switch (action.type) {
		case "ResolvedData": {
			return {
				...state,
				[action.name]: action.data
			}
		}

		case "RemoveData": {
			const newState = {
				...state
			};

			delete newState[action.name];

			return newState;
		}

		default: {
			return state;
		}
	}
}