const steps = [
    {
        target: `#locals-panel`,
        title: "Available Data",
        content: (
            <div>
                The data that you can use in your report will be displayed here.<br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#select-query`,
        title: "Query Playground Query",
        content: (
            <div>
                You can load data by selecting an existing query from your query playground.<br />
                <br />
                The data will reflect in the report panel immediately.
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#data-count`,
        title: "Number of Records",
        content: (
            <div>
                The number of records that have been loaded from the query will display here.<br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#template-panel`,
        title: "Design your report ",
        content: (
            <div>
                You can use the data from the data panel to design your report using EJS and HTML here.<br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#report-type`,
        title: "Different report types ",
        content: (
            <div>
                You can select the type of report you would like to design here.<br />
                <br />
                Single reports will be displayed as a single page.<br />
                <br />
                Multi reports will be displayed with a menu item for every record in the data.<br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: "#toggle-help",
        content: (
            <div>
                Click on this button to view this tutorial again at any time.<br />
            </div>
        ),
        disableBeacon: true,

    }
];

export default steps;