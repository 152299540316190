import Settings, { SettingsLoader } from '../pages/app/settings';

export default function SettingsRouter() {
    return {
        path: "/app/:appUUID/settings",

        children: [
            {
                index: true,
                element: <Settings />,
                loader: SettingsLoader,
            },
        ]
    };
}