const steps = [
    
    {
        target: '#resource-name',
        title: "Enter a name for your resource",
        content: (
            <div>This will be the name you see in the list of resources.<br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: '#resource-description',
        title: "Describe your new resource",
        content: (
            <div>Describe the type of data that your resource will be used to store. <br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: '#resource-model',
        title: "List of fields",
        content: (
            <div>The names and types of the fields that you want in your new resource will appear here. <br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: '#resource-model-field-id',
        title: "Pre-existing field",
        content: (
            <div>We have pre-populated your resource with some common fields that you might need. <br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: '#resource-model-field-id-remove',
        title: "Remove field",
        content: (
            <div>You can remove a field at any time if you no longer want it. <br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: '#resource-model-field-id-move-up',
        title: "Move field up",
        content: (
            <div>You can change the position the field appears at by moving it up. <br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: '#resource-model-field-id-move-down',
        title: "Move field down",
        content: (
            <div>Or by moving it down. <br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: '#resource-fieldName',
        title: "Add a new field",
        content: (
            <div>You can add a new field by giving it a unique name. <br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: '#resource-fieldType',
        title: "Select a field type",
        content: (
            <div>And then select the type of field you want it to be.
            </div>
        ),
        lookupStepIsNext: true,
        disableBeacon: true,
    },
    {
        target: '#resource-fieldType',
        title: "Lookup field",
        content: (
            <div>Lookup fields get their values from other resources.
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: '#resource-selectedLookupResource',
        title: "Lookup field",
        content: (
            <div>If you want a field that gets its value from another resource, you must select the resource you want it to lookup to.
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: '#resource-resourceLinkingKey',
        title: "Matching value",
        content: (
            <div>And then select the field you want the value to match to.
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: '#resource-resourceDisplayKey',
        title: "Display value",
        content: (
            <div>You can also select the field in the matched row that you want to display. <br />
            <br />
            <small>(Or choose the same field you matched with)</small>
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: '#resource-addField',
        title: "Add Field",
        content: (
            <div>When you have entered the field information, click on Add Field to add it to the resource.
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: '#resource-create',
        title: "Create Resource",
        content: (
            <div>Click on the Create Resource button when you are done setting up your resource and it will be created for you.
            </div>
        ),
        disableBeacon: true,
    },
];

export default steps;