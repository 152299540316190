import React from "react";

import axiosBackend from "core/api/backend";

import { connect } from "react-redux";
import { resolve, mapStateToProps } from "components/smart/delegator/DataDelegator";

import { Link } from "react-router-dom";
import Loader from "components/Loader";
import API_STATES from "api-states";

class Review extends React.Component {
    constructor(props) {
        super(props);

        this.TEMPLATE_USES = {
            APPROVAL_PROCESS: "approval-process",
            EMAIL_DIGEST: "email-digest",
            SCHEDULED_REPORT: "scheduled-report",
            INJECTABLE_PAGE: "injectable-page",
            REPORT_VIEWER: "report-viewer",
        }

        this.state = {
            status: API_STATES.IDLE,

            templateUse: "report-viewer",

            name: "",
            description: "",

            nameError: "",
            descriptionError: "",
        }

        this.saveReport = this.saveReport.bind(this);
        this.moveToPrevStep = this.moveToPrevStep.bind(this);

        this.changeTemplateUse = this.changeTemplateUse.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    saveReport() {
        let isValid = true;
        let stateToUpdate = {
            nameError: "",
            descriptionError: "",
        }

        if (this.state.name === "") {
            isValid = false;
            stateToUpdate.nameError = "Please enter a name for your report";
        }

        if (this.state.description === "") {
            isValid = false;
            stateToUpdate.descriptionError = "Please enter a description for your report";
        }

        if (isValid) {
            this.setState({
                status: API_STATES.LOADING,
                ...stateToUpdate,
            })

            setTimeout(() => {
                axiosBackend({
                    method: "PUT",
                    url: `/service/reports/${this.props.router.params.appUUID}/`,
                    data: {
                        name: this.state.name,
                        description: this.state.description,
                        template: this.props.template,
                        menuTemplate: this.props.menuTemplate,
                        reportType: this.props.reportType,
                        reportImage: this.props.image,
                        query_uuid: this.props.selectedQuery.uuid,
                    }
                })
                    .then((response) => {
                        if (response.data.results.length > 0) {
                            let uuid = response.data.results[0].uuid;

                            this.setState({
                                status: API_STATES.LOADED,
                                savedReportUUID: uuid,
                            })
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.setState({
                            status: API_STATES.IDLE,
                        });

                        this.props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                show: true,
                                type: "error",
                                title: "Could not save report",
                                message: [
                                    "Due to an error, we were unable to save your report",
                                    "‏‏‎ ‎",
                                    "Please try again in a little while."
                                ],
                                okayButtonText: "Okay"
                            },
                        });
                    })
            }, 1000);
        } else {
            this.setState(stateToUpdate);
        }
    }

    moveToPrevStep() {
        this.props.jumpToStep(2);
    }

    componentDidMount() {
        resolve(this.props, (err) => {
            if (this.props.templateUse !== undefined) {
                this.setState({
                    templateUse: this.props.templateUse,
                })
            }
        });
    }

    changeTemplateUse(event) {
        this.setState({
            templateUse: event.target.id,
        })
    }

    handleInputChange(name) {
        return (event) => {
            this.setState({
                [name]: event.target.value,
                [`${name}Error`]: "",
            })
        }
    }

    render() {
        return (
            <>
                <div className="row mt-4 mb-2">
                    <div className="col-lg-12">
                        <button disabled={this.state.status != API_STATES.IDLE} className="btn btn-primary" onClick={this.moveToPrevStep}>Previous</button>
                        <button disabled={this.state.status != API_STATES.IDLE} className="btn btn-primary float-right" onClick={this.saveReport}>Finish</button>
                    </div>
                </div>

                <div className="">
                    <div className="ibox-content">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3 className="pb-2 border-bottom">Review Report</h3>
                            </div>
                        </div>

                        {this.state.status == API_STATES.LOADING ? <div className="row d-flex justify-content-center ml-2 pl-2 pb-2 pt-4"><Loader text="Saving Report..." /></div> : undefined}

                        {this.state.status === API_STATES.LOADED ?
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center">
                                        <h1 className="error-code text-success"><i className="fas fa-check fa-3x"></i></h1>
                                        <br />
                                        <p className="h4 text-uppercase text-bold">{this.props.title}</p>
                                        <div className="pad-btm">
                                            Your report was saved successfully.<br />

                                            {this.state.savedReportUUID !== undefined ? <Link className="text-success" to={`../view/${this.state.savedReportUUID}`}>Click here to view it.</Link> : undefined}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            undefined
                        }

                        {this.state.status === API_STATES.IDLE ?
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="row mb-4">
                                        <div className={`col-sm-12 ${this.state.nameError.length > 0 ? "has-error" : ""}`}>
                                            <label className="font-bold">Name your report:</label>
                                            <input defaultValue={this.state.name} onChange={this.handleInputChange("name")} className="form-control" type="text" placeholder="Enter a name for your report" />

                                            {this.state.nameError.length > 0 && <span className="text-danger">{this.state.nameError}</span>}
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className={`col-sm-12 ${this.state.descriptionError.length > 0 ? "has-error" : ""}`}>
                                            <label className="font-bold">Describe your report:</label>
                                            <input defaultValue={this.state.description} onChange={this.handleInputChange("description")} className="form-control" type="text" placeholder="Enter a description for your report" />

                                            {this.state.descriptionError.length > 0 && <span className="text-danger">{this.state.descriptionError}</span>}

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <label className="font-bold">Select how you want to use your report:</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input
                                                        onChange={this.changeTemplateUse}
                                                        type="radio"
                                                        id="approval-process"
                                                        name="use-template-in"
                                                        disabled
                                                        checked={this.state.templateUse === "approval-process"}
                                                    />&nbsp;<label for="approval-process">Use it in an approval process </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input
                                                        disabled={this.props.reportType === "multiple"}
                                                        onChange={this.changeTemplateUse}
                                                        type="radio"
                                                        id="email-digest"
                                                        name="use-template-in"
                                                        disabled
                                                        checked={this.state.templateUse === "email-digest"}
                                                    />&nbsp;<label className={this.props.reportType === "multiple" ? "text-muted" : ""} for="email-digest">Send it as an email digest</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input
                                                        onChange={this.changeTemplateUse}
                                                        type="radio"
                                                        id="scheduled-report"
                                                        name="use-template-in"
                                                        disabled
                                                        checked={this.state.templateUse === "scheduled-report"}
                                                    />&nbsp;<label for="scheduled-report">Generate it on a regular basis</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input
                                                        disabled={this.props.reportType === "multiple"}
                                                        onChange={this.changeTemplateUse}
                                                        type="radio"
                                                        id="injectable-page"
                                                        name="use-template-in"
                                                        disabled
                                                        checked={this.state.templateUse === "injectable-page"}
                                                    />&nbsp;<label for="injectable-page" className={this.props.reportType === "multiple" ? "text-muted" : ""}>Add it on your web page</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input
                                                        onChange={this.changeTemplateUse}
                                                        type="radio"
                                                        id="report-viewer"
                                                        name="use-template-in"
                                                        checked={this.state.templateUse === "report-viewer"}
                                                    />&nbsp;<label for="report-viewer">Save it for team use</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <label className="font-bold">Report Preview:</label><br />
                                    <img src={this.props.image} className="img-fluid" />
                                </div>
                            </div>
                            :
                            undefined
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps)(Review);