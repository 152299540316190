export const permissionTitles = {
    "users": "Users Management",
    "roles": "Roles Management",
    "roles_mapping": "Roles Assignment Management",
    "permissions": "Permissions Management",
}

export const permissionLabels = {
    "resources:fields": {
        "allow": "Allow Viewing",
        "deny": "Deny Viewing",
    },

    "resources:rows": {
        "allow": "Restrict Rows Based On Conditions",
        "deny": "Restrict Rows Based On Conditions",
    },

    "users:remove": {
        "allow": "Can Remove Users",
        "deny": "Cannot Remove Users",

        generic: "Remove Users"
    },

    "users:add": {
        "allow": "Can Add Users",
        "deny": "Cannot Add Users",

        generic: "Add Users"
    },

    "users:list": {
        "allow": "Can View Users",
        "deny": "Cannot View Users",

        generic: "View Users"
    },

    "roles:list": {
        "allow": "Can View Roles",
        "deny": "Cannot View Roles",

        generic: "View Roles"
    },

    "roles:add": {
        "allow": "Can Add Roles",
        "deny": "Cannot Add Roles",

        generic: "Add Roles"
    },

    "roles:remove": {
        "allow": "Can Remove Roles",
        "deny": "Cannot Remove Roles",

        generic: "Remove Roles"
    },

    "permissions:list": {
        "allow": "Can View Permissions Of A Role",
        "deny": "Cannot View Permissions Of A Role",

        generic: "View Permissions Of A Role"
    },

    "permissions:add": {
        "allow": "Can Add Permissions To A Role",
        "deny": "Cannot Add Permissions To A Role",

        generic: "Add Permissions To A Role"
    },

    "permissions:remove": {
        "allow": "Can Remove Permissions From A Role",
        "deny": "Cannot Remove Permissions From A Role",

        generic: "Remove Permissions From A Role"
    },

    "permissions:edit": {
        "allow": "Can Edit Permissions Of A Role",
        "deny": "Cannot Edit Permissions Of A Role",

        generic: "Edit Permissions Of A Role"
    },

    "roles_mapping:list": {
        "allow": "Can View Roles Assigned To Users",
        "deny": "Cannot View Roles Assigned To Users",

        generic: "View Roles Assigned To Users"
    },

    "roles_mapping:edit": {
        "allow": "Can Change Roles Assigned To Users",
        "deny": "Cannot Change Roles Assigned To Users",

        generic: "Change Roles Assigned To Users"
    },
}