import React from "react";
import removeCustomProps from "../../removeCustomProps";
import defaultUpdateProps from "../../defaultUpdateProps";

import DateTime from "react-datetime";
import moment from "moment";

import "react-datetime/css/react-datetime.css";

export const defaultValidationMessages = {
    required: "This is a required value",
    invalidInitialValue: "The value provided is not a valid date and time and cannot be displayed correctly",
}

// The purpose of this function is to format the value as needed for the field type in the event it needs to be set exteranlly for any reason
export function formatValue({ fieldConfiguration, value }) {    
    let formattedValue = value;

    if (["datetime"].indexOf(fieldConfiguration.type) > -1) {
        formattedValue = moment(formattedValue).format('YYYY-MM-DD HH:mm:ss');
    } else if (["date"].indexOf(fieldConfiguration.type) > -1) {
        formattedValue = moment(formattedValue).format('YYYY-MM-DD');
    } else if (["time"].indexOf(fieldConfiguration.type) > -1) {
        formattedValue = moment(formattedValue).format('HH:mm:ss');
    }

    return formattedValue;
}

// The purpose of the process function is to handle the value as needed after validation and then return the processed value as well as information if it is valid or not
export function process({ fieldConfiguration, value }, callbackFn) {
    validate(fieldConfiguration, value, (err, updateProps) => {
        if (err) {
            callbackFn(err);
        } else {
            const hasError = updateProps.error !== undefined && updateProps.error.length > 0;

            callbackFn(null, {
                valid: hasError === false,
                fieldConfiguration,
                value,
            })
        }
    })
}

export function validate(fieldConfiguration, value, callbackFn) {
    let updateProps = defaultUpdateProps(fieldConfiguration);

    if (["", undefined].includes(value)) {
        if (fieldConfiguration.required === true) {
            updateProps.error = defaultValidationMessages.required;
        }
    } else {
        if (value != null && !moment(value).isValid()) {
            updateProps.error = defaultValidationMessages.invalidInitialValue;
        }
    }

    if (typeof callbackFn == "function") {
        callbackFn(null, updateProps);
    }
}

export function render(fieldConfiguration) {
    console.log("datetime render yess", JSON.stringify(fieldConfiguration.value, null, 4))

    // We only want to consider `value` as the official value to avoid any confusions
    delete fieldConfiguration.defaultValue;

    let props = {
        ...fieldConfiguration,

        // Remove form-control from this component since it causes the style to look weird
        className: fieldConfiguration.classes.filter(className => className != "form-control").join(" "),
    };

    if (props.value == null) {
        props.value = undefined;
    }

    const renderInput = (props, openCalendar, closeCalendar) => {
        let style = {};

        if (!props.disabled) {
            style = {
                backgroundColor: "white"
            };
        }

        props.value = fieldConfiguration.value;

        return (
            <div className="input-group">
                <input {...props} style={style} />

                <span className="input-group-btn">
                    <button
                        aria-label="Date Picker"
                        disabled={props.disabled}
                        onClick={openCalendar}
                        className="btn btn-default"
                        type="button"
                        data-test="datetime-open-calendar"
                    >
                        <i className="fas fa-calendar-alt"></i>
                    </button>

                    <button
                        disabled={props.disabled}
                        onClick={() => props.onChange({ target: { value: '' } })}
                        className="btn btn-danger"
                        type="button"
                        data-test="datetime-clear-value"
                    >
                        <i className="fas fa-trash-alt"></i>
                    </button>
                </span>
            </div>
        );
    };

    if (props.type == "date") {
        props.timeFormat = false;
    } else if (props.type == "time") {
        props.dateFormat = false;
    }

    props.onChange = (value) => {
        let formattedValue = value;

        if (value != "") {
            formattedValue = formatValue({
                fieldConfiguration,
                value: formattedValue,
            })
        }

        validate(fieldConfiguration, formattedValue, (err, updateProps) => {
            updateProps.manuallyChanged = true;

            fieldConfiguration.onChange(formattedValue, {
                ...updateProps,

                forceUpdate: true, // Because we depend on the value for rendering the react-datetime component to show the currently selected datetime
            });
        })

        return false;
    }

    props = removeCustomProps(props);

    return <DateTime
        inputProps={{
            readOnly: false,
            placeholder: props.placeholder,
            disabled: props.disabled
        }}

        renderInput={renderInput}

        {...props}
    />;
}