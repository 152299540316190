import React from "react";
import { connect } from "react-redux";
import {render} from "ejs";

import SupportedComponents from "../SupportedComponents";

import { resolve, mapStateToProps } from "../delegator/DataDelegator";


/**
    * Generate a tile that represents a numeric value with an icon, a description and can link to something
 */
class UnorderedList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        resolve(this.props);
    }

    search(event) {
        const value = event.target.value.trim().toLowerCase();

        const list = [...this.props.list]
            .map((item) => {
                item.hidden = (
                    value.length > 0 &&
                    Array.isArray(this.props.search) &&
                    this.props.search.every((search) => item[search] !== undefined && item[search] !== null && item[search].toLowerCase().indexOf(value) == -1)
                );
                return item;
            });

        this.setState({list});
    }

    render() {
        console.log(JSON.stringify(this.props, null, 4))

        let ListComponent = SupportedComponents[this.props.listComponent];
        
        return (
            <>
                {this.props.searchEnabled ? 
                    <div className="form-group">
                        <input autoFocus={!!this.props.searchAutoFocus} onChange={this.search.bind(this)} placeholder={this.props.searchText ? this.props.searchText : "Click to search"} className="form-control input-lg search" type="text" />
                    </div>
                    :
                    null 
                }

                <ul className={[].concat(this.props.listClasses).join(" ")}>
                
                    {this.props.list == undefined || !Array.isArray(this.props.list) || this.props.list.filter(item => !item.hidden).length == 0 ?
                        <span>{this.props.noDataMessage || "No data found"}</span>
                        :
                        undefined
                    }

                    {!!this.props.list && this.props.list.filter((item) => !item.hidden).map((item, index) => {
                        let componentProps = {...this.props.itemDefaults, ...item};

                        componentProps.item = {...componentProps};

                        // Manually attach robostack information
                        componentProps.site = this.props.site;

                        let Component = (
                            <li key={`${this.props.listComponent}_${index}`} className={[].concat(this.props.itemClasses).join(" ")}>
                                <ListComponent {...componentProps} />
                            </li>
                        );

                        if(this.props.link !== undefined && this.props.link.length > 0) {
                            const link = render(this.props.link, componentProps);

                            Component = <a data-hint={this.props["data-hint"]} data-step={this.props["data-step"]} href={link}>
                                {Component}
                            </a>;
                        }

                        return Component;
                    })}
                </ul>
            </>
        );
    }
}

UnorderedList.propsInformation = {
    listClasses: {
        type: "Array",
        description: "The icon classes to be used for the list"
    },
    itemClasses: {
        type: "Array",
        description: "The classes to be used on each list item"
    },
    list: {
        type: "Array",
        description: "The list that will be rendered"
    },
    searchEnabled: {
        type: "Boolean",
        description: "Should search be enabled for the list"
    },
    searchText: {
        type: "String",
        description: "The placeholder text for the search field"
    },
    search: {
        type: "Array",
        description: "The list of keys to search"
    },
    noDataMessage: {
        type: "String",
        description: "The message that will be shown if the list is empty or undefined"
    },
    listComponent: {
        type: "React.Component",
        description: "The component that will be used to render each item in the list"
    },
    itemDefaults: {
        type: "Object",
        description: "The properties to pass down to the list component if any of the list objects do not have them available"
    },
};

export default connect(mapStateToProps)(UnorderedList);