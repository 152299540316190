const ejs = require("ejs");

export default function renderTemplate(template, locals) {
    let renderedTemplate;
    let status = "success";

    try {
        renderedTemplate = ejs.render(
            template,
            {
                ...locals,
            }
        )
    } catch (err) {
        console.error(err);

        status = "error";

        switch (err.name) {
            default: {
                // Since the error most likely came from ejs due to tags or data not being available
                try {
                    // This is to prevent programming issues from ejs when data is missing
                    let encodedStr = template.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
                        return '&#' + i.charCodeAt(0) + ';';
                    });

                    renderedTemplate = encodedStr;
                } catch (error) {
                    console.log(error);
                }
                break;
            }
        }

        return {
            rendered: renderedTemplate,
            status,
            error: err,
        };
    }

    return {
        rendered: renderedTemplate,
        status,
    };
}