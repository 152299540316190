import React from "react";

const imgUrl = '/img/bg-img.jpg'

/* Image */
class ImageBg extends React.Component {
  constructor() {
    super();
    this.state = { 
      loaded: false
    }
  }

  render() {    
    return (        
      <img
        src={imgUrl}          
        alt="Robostack image"
        loading="eager"
        style={
          this.state.loaded 
            ? {opacity: '0.8'} 
            : {}
        }
        onLoad={
          () => this.setState({
            loaded: true
          })
        }
      />     
    )
  }
}

export default ImageBg;