
const fields = [
    {
        "value": "STRING",
        "label": "Text"
    },
    {
        "value": "MARKDOWN",
        "label": "Markdown"
    },
    {
        "value": "DATE",
        "label": "Date"
    },
    {
        "value": "TIME",
        "label": "Time"
    },
    {
        "value": "DATETIME",
        "label": "Date & Time"
    },
    {
        "value": "AUTODATE",
        "label": "Record Creation Date",
        "automatic": true
    },
    {
        "value": "AUTODATE_UPDATE",
        "label": "Record Update Date",
        "automatic": true
    },
    {
        "value": "AUTOUUID",
        "label": "Universally Unique Identifier",
        "automatic": true
    },
    {
        "value": "BOOLEAN",
        "label": "Yes/No"
    },
    {
        "value": "CURRENCY",
        "label": "Currency"
    },
    {
        "value": "DECIMAL",
        "label": "Decimal Number"
    },
    {
        "value": "INTEGER",
        "label": "Integer Number"
    },
    {
        "value": "FIXEDLENGTH",
        "label": "Fixed Length"
    },
    {
        "value": "EMAIL",
        "label": "Email Address"
    },
    {
        "value": "IP_ADDRESS",
        "label": "Record Creating IP Address",
        "automatic": true
    },
    {
        "value": "IP_ADDRESS_UPDATE",
        "label": "Record Updating IP Address",
        "automatic": true
    },
    {
        "value": "PASSWORD",
        "label": "Hashed Password"
    },
    {
        "value": "LOOKUP",
        "label": "Lookup From"
    },
    {
        "value": "JSON",
        "label": "JSON"
    },
    {
        "value": "JSON_ARRAY",
        "label": "Multiple Values"
    },
    {
        "value": "AUTONUMBER",
        "label": "Automatic Number",
        "automatic": true
    },
    {
        "value": "FILE",
        "label": "Uploaded File"
    },
    {
        "value": "USER",
        "label": "Record Creating User",
        "automatic": true
    },
    {
        "value": "USER_UPDATE",
        "label": "Record Updating User",
        "automatic": true
    },
    {
        "value": "APPLICATION_USER",
        "label": "Single Team Member"
    },
    {
        "value": "APPLICATION_USERS",
        "label": "Multiple Team Members"
    },
    {
        "value": "AGGREGATE",
        "label": "Compute From Resource"
    }
].sort((a, b) => {
    return a.label.localeCompare(b.label);
});

export default fields;