const SelectWithDescriptionQueryOption = (props) => {
    const { innerProps, isDisabled, isFocused, isSelected, data, className, cx, getStyles } = props;

    return !isDisabled
        ? <div
            style={getStyles('option', props)}
            className={cx(
                {
                    option: true,
                    'option--is-disabled': isDisabled,
                    'option--is-focused': isFocused,
                    'option--is-selected': isSelected
                },
                className
            )}
            {...innerProps}>

            <div className="issue-info" style={{ "width": "100%" }}>

                <a>
                    {props.label}
                </a>

                {props.data?.badge != undefined &&
                    <span className="ml-1 badge badge-info">
                        {props?.data.badge}
                    </span>
                }

                <small>
                    {!!data.description ? data.description : <em>No description provided</em>}
                </small>
            </div>
        </div>
        : null
}

export const filterQueryOptionsWithDescription = (candidate, input) => {
    if (input) {
        return (
            candidate.label.toLowerCase().includes(input.toLowerCase()) ||
            (
                typeof candidate.data.description === "string" &&
                candidate.data.description.toLowerCase().includes(input.toLowerCase())
            ) ||
            (
                typeof candidate.data.badge === "string" &&
                candidate.data.badge.toLowerCase().includes(input.toLowerCase())
            )
        )
    }
    return true;
};

export default SelectWithDescriptionQueryOption;