import React from "react";
import { connect } from "react-redux";

import * as DataDelegator from "../../../../components/smart/delegator/DataDelegator";

import StepZilla from "react-stepzilla";

import SelectTemplate from "./SelectTemplate";
import withLoaderData from "../../../../components/withLoaderData";
import Designer from "./Designer";
import DesignReportMenu from "./DesignReportMenu";
import Review from "./Review";

class ReportBuilder extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const appUUID = this.props.router.params.appUUID;
        const steps = [
            {
                name: "1. Select a template",
                component: (
                    <SelectTemplate
                        robostackResolveData={[
                            {
                                "name": "ListOfReportTemplates",
                                "api": {
                                    "method": "POST",
                                    "endpoint": `/service/reports/templates/${appUUID}/`,
                                    "data": {}
                                },
                                "transform": {
                                    "templates": "`data.results`"
                                }
                            },
                            {
                                "name": "ListOfExistingReports",
                                "api": {
                                    "method": "POST",
                                    "endpoint": `/service/reports/${appUUID}/`,
                                    "data": {}
                                },
                                "transform": {
                                    "reports": "`data.results | map | set-prop: image,$reportImage`"
                                }
                            },
                            {
                                "name": "Report_SelectedTemplate",
                                "transformName": "Report_SelectedTemplate",
                                "transform": {
                                    "selectedTemplate": "`data | prop: selectedTemplate`"
                                }
                            },
                            {
                                "name": "Report_SelectedReport",
                                "transformName": "Report_SelectedReport",
                                "transform": {
                                    "selectedReport": "`data | prop: selectedReport`"
                                }
                            },
                            {
                                "name": "Report_Design",
                                "transformName": "Report_Design",
                                "transform": {
                                    "selectedQuery": "`data | prop: selectedQuery`",
                                    "reportType": "`data | prop: reportType`",
                                    "template": "`data | prop: template`",
                                    "image": "`data | prop: image`"
                                }
                            }
                        ]}
                    />
                )
            },
            {
                name: "2. Design report",
                component: (
                    <Designer
                        {...this.props} 
                        robostackResolveData={[
                            {
                                "name": "Report_SelectedTemplate",
                                "transformName": "Report_SelectedTemplate",
                                "transform": {
                                    "selectedTemplate": "`data | prop: selectedTemplate`"
                                }
                            },
                            {
                                "name": "Report_Design",
                                "transformName": "Report_Design",
                                "transform": {
                                    "selectedQuery": "`data | prop: selectedQuery`",
                                    "reportType": "`data | prop: reportType`",
                                    "template": "`data | prop: template`",
                                    "image": "`data | prop: image`"
                                }
                            },
                        ]}
                    />
                )
            },
            {
                name: "3. Design report menu",
                component: <DesignReportMenu
                    {...this.props} 
                    robostackResolveData={[
                        {
                            "name": "Report_TemplateUse",
                            "transformName": "Report_TemplateUse",
                            "transform": {
                                "templateUse": "`data | prop: templateUse`"
                            }
                        },
                        {
                            "name": "Report_Design",
                            "transformName": "Report_Design",
                            "transform": {
                                "selectedQuery": "`data | prop: selectedQuery`",
                                "reportType": "`data | prop: reportType`",
                                "template": "`data | prop: template`",
                                "image": "`data | prop: image`"
                            }
                        },
                        {
                            "name": "Report_MenuDesign",
                            "transformName": "Report_MenuDesign",
                            "transform": {
                                "menuTemplate": "`data | prop: menuTemplate`"
                            }
                        }
                    ]}
                />
            },
            {
                name: "4. Review",
                component: <Review 
                    {...this.props} 
                    robostackResolveData={[
                        {
                            "name": "Report_Design",
                            "transformName": "Report_Design",
                            "transform": {
                                "selectedQuery": "`data | prop: selectedQuery`",
                                "reportType": "`data | prop: reportType`",
                                "template": "`data | prop: template`",
                                "image": "`data | prop: image`"
                            }
                        },
                        {
                            "name": "Report_MenuDesign",
                            "transformName": "Report_MenuDesign",
                            "transform": {
                                "menuTemplate": "`data | prop: menuTemplate`"
                            }
                        },
                    ]}
                />
            },
        ];

        return (
            <>
                <div className="col-sm-12">
                    <div className="ibox-content bg-none">
                        <div className="row">
                            <div className='wizard wizard-big clearfix'>
                                <StepZilla
                                    showNavigation={false}
                                    stepsNavigation={false}
                                    steps={steps}
                                    nextButtonCls='btn btn-prev btn-primary btn-lg float-right'
                                    backButtonCls='btn btn-next btn-primary float-left'
                                    nextButtonText='Next'
                                    backButtonText='Previous'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(ReportBuilder));