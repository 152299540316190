import {
    getMiddleCenterBias,
    GridCellKind,
} from "@glideapps/glide-data-grid";

export function roundedRect(
    ctx,
    x,
    y,
    width,
    height,
    radius,
) {
    if (radius === 0) {
        ctx.rect(x, y, width, height);
        return;
    }
    if (typeof radius === "number") {
        radius = { tl: radius, tr: radius, br: radius, bl: radius };
    }

    // restrict radius to a reasonable max
    radius = {
        tl: Math.min(radius.tl, height / 2, width / 2),
        tr: Math.min(radius.tr, height / 2, width / 2),
        bl: Math.min(radius.bl, height / 2, width / 2),
        br: Math.min(radius.br, height / 2, width / 2),
    };

    ctx.moveTo(x + radius.tl, y);
    ctx.arcTo(x + width, y, x + width, y + radius.tr, radius.tr);
    ctx.arcTo(x + width, y + height, x + width - radius.br, y + height, radius.br);
    ctx.arcTo(x, y + height, x, y + height - radius.bl, radius.bl);
    ctx.arcTo(x, y, x + radius.tl, y, radius.tl);
}

function onClickSelect(e) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d", { alpha: false });
    if (ctx === null) return;

    const { posX: hoverX, bounds: rect, cell, theme } = e;
    const font = `${theme.baseFontStyle} ${theme.fontFamily}`;
    ctx.font = font;

    const width = Math.ceil(rect.width - theme.cellHorizontalPadding * 2 - 1);

    const xPad = theme.cellHorizontalPadding;

    let drawX = rect.x + xPad;

    const rectHoverX = rect.x + hoverX;
    if ((rectHoverX - drawX) <= width) {
        return 1;
    }

    return undefined;
}

const renderer = {
    kind: GridCellKind.Custom,
    isMatch: (c) => (c.data).kind === "file",
    drawPrep: args => {
        const { ctx } = args;

        ctx.textAlign = "center";

        return {
            deprep: a => {
                a.ctx.textAlign = "start";
            },
        };
    },
    draw: (args, cell) => {
        const { ctx, theme, rect } = args;
        const { value } = cell.data;

        if (typeof value == "string" && value.length > 0) {


            const x = Math.floor(rect.x + theme.cellHorizontalPadding + 1);
            const y = Math.floor(rect.y + theme.cellVerticalPadding + 1);
            const width = Math.ceil(60 - theme.cellHorizontalPadding * 2 - 1);
            const height = Math.ceil(rect.height - theme.cellVerticalPadding * 2 - 1);

            let font = "900 1.3em 'Font Awesome 5 Free'"
            let backgroundColor = "#2c4869";
            let borderRadius = 5;

            if (backgroundColor !== undefined) {
                ctx.beginPath();
                roundedRect(
                    ctx,
                    x,
                    y,
                    width,
                    height,
                    borderRadius
                );
                ctx.fillStyle = backgroundColor;
                ctx.fill();
            }

            let borderColor = "#2c4869";

            if (borderColor !== undefined) {
                ctx.beginPath();
                roundedRect(
                    ctx,
                    x + 0.5,
                    y + 0.5,
                    width - 1,
                    height - 1,
                    borderRadius
                );
                ctx.strokeStyle = borderColor;
                ctx.lineWidth = 1;
                ctx.stroke();
            }

            ctx.fillStyle = "white";
            ctx.font = font;
            ctx.fillText(
                '\uf56d',
                x + width / 2,
                y + height / 2 + getMiddleCenterBias(ctx, `${theme.baseFontStyle} ${theme.fontFamily}`) - 1
            );

            // ctx.font = "1em Times";
            // ctx.fillStyle = "black";
            // ctx.fillText(
            //     "Download File",
            //     x + width / 3.33 + 10,
            //     y + height / 2 + getMiddleCenterBias(ctx, `${theme.baseFontStyle} ${theme.fontFamily}`)
            // );

        }


        return true;
    },
    onClick: (e) => {
        const hovered = onClickSelect(e);
        if (hovered !== undefined) {
            e.preventDefault();

            const url = e.cell.data.value;

            if (typeof url == "string" && url.length > 0) {
                window.open(url, "_blank");
            }
        }

        return undefined;
    },
};

export default renderer;