import React from "react";

import MagicForm from "../components/smart/supported/MagicForm";
import { useNavigate } from "react-router-dom";
import * as DataDelegator from "components/smart/delegator/DataDelegator";
import axiosBackend from "core/api/backend";
import Loader from "components/Loader";

export default function CreateNewApplication(props) {
    const navigate = useNavigate();

    const [customerAccounts, setCustomerAccounts] = React.useState(null);
    const [availablePlans, setAvailablePlans] = React.useState([]);
    const [billingCycles, setBillingCycles] = React.useState([]);

    React.useEffect(() => {
        axiosBackend.post("/apps/customer-accounts-available", {}).then((response) => {
            setCustomerAccounts(response.data.results);

            if(response.data.results.length == 0) {
                // Load the available plans that the user can subscribe to since they don't have any customer accounts
                axiosBackend.post("/company/billing/plans", {}).then((response) => {
                    setAvailablePlans(response.data.results);
                });

                // Load the available billing cycles that the user can subscribe to since they don't have any customer accounts
                axiosBackend.post("/company/billing/cycles", {}).then((response) => {
                    setBillingCycles(response.data.results);
                });
            }
        });
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {/* <div className="row">
                        <div className="col-sm-12 text-center">
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <h2>What type of application do you want to create?</h2>
                                    <div className="d-flex flex-row justify-content-center">
                                        <div className="p-2 mar-hor">
                                            <span className="fas fa-table fa-6x"></span><br />
                                            <h4>Start From Scratch</h4>
                                        </div>

                                        <div className="p-2 mar-hor">
                                            <span className="fas fa-columns fa-6x"></span><br />
                                            <h4>Quick Start</h4>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div> */}

                    {customerAccounts == null && <Loader />}

                    {customerAccounts != null && customerAccounts.length === 0 && (
                        <div>
                            <h1>Create Your Organization</h1>
                            <h5>After you have created your organization, you will be able to create your first application</h5>

                            <MagicForm
                                submitApi={{
                                    "method": "PUT",
                                    "endpoint": "/company/billing",
                                    "data": {}
                                }}
                                onSubmitApiSuccess={(response) => {
                                    window.location.reload();
                                }}
                                fields={[{
                                    "name": {
                                        "label": "Name",
                                        "type": "text",
                                        "placeholder": "Your name or your company's name",
                                        "classes": [],
                                        "parentClasses": [],
                                        "sizeClass": 2,
                                    },
                                    "plan": {
                                        "label": "Plan",
                                        "type": "dropdown",
                                        "placeholder": "Select Plan",
                                        "classes": [],
                                        "parentClasses": [],
                                        "sizeClass": 2,
                                        "options": availablePlans.map((plan) => {
                                            return {
                                                "value": plan.uuid,
                                                "display": plan.name + " - " + (Math.ceil(plan.cost + (plan.cost) * .18)).toLocaleString("en-IN", { style: "currency", currency: "INR" }) + " / month"
                                            }
                                        })
                                    },
                                    "billingCycle": {
                                        "label": "Plan Subscription",
                                        "type": "dropdown",
                                        "placeholder": "Select Plan Subscription",
                                        "classes": [],
                                        "parentClasses": [],
                                        "sizeClass": 2,
                                        "options": billingCycles.map((cycle) => {
                                            return {
                                                "value": cycle.uuid,
                                                "display": cycle.name
                                            }
                                        })
                                    },
                                    "gstRegistration": {
                                        "label": "GST ID",
                                        "type": "text",
                                        "placeholder": "GSTID (Optional)",
                                        "classes": [],
                                        "parentClasses": [],
                                        "sizeClass": 2,
                                        "value": "",
                                    },
                                    "address1": {
                                        "label": "Address Line 1",
                                        "type": "text",
                                        "placeholder": "Address Line 1",
                                        "classes": [],
                                        "parentClasses": [],
                                        "sizeClass": 2,
                                    },
                                    "address2": {
                                        "label": "Address Line 2",
                                        "type": "text",
                                        "placeholder": "Address Line 2",
                                        "classes": [],
                                        "parentClasses": [],
                                        "sizeClass": 2,
                                    },
                                    "address3": {
                                        "label": "Address Line 3",
                                        "type": "text",
                                        "placeholder": "Address Line 3",
                                        "classes": [],
                                        "parentClasses": [],
                                        "sizeClass": 2,
                                    },
                                    "city": {
                                        "label": "City",
                                        "type": "text",
                                        "placeholder": "City",
                                        "classes": [],
                                        "parentClasses": [],
                                        "sizeClass": 2,
                                    },
                                    "state": {
                                        "label": "State",
                                        "type": "dropdown",
                                        "placeholder": "State",
                                        "classes": [],
                                        "parentClasses": [],
                                        "sizeClass": 2,
                                        "options": [
                                            { "value": "Andaman and Nicobar Islands", "display": "Andaman and Nicobar Islands" },
                                            { "value": "Andhra Pradesh", "display": "Andhra Pradesh" },
                                            { "value": "Arunachal Pradesh", "display": "Arunachal Pradesh" },
                                            { "value": "Assam", "display": "Assam" },
                                            { "value": "Bihar", "display": "Bihar" },
                                            { "value": "Chandigarh", "display": "Chandigarh" },
                                            { "value": "Chhattisgarh", "display": "Chhattisgarh" },
                                            { "value": "Dadra and Nagar Haveli", "display": "Dadra and Nagar Haveli" },
                                            { "value": "Daman and Diu", "display": "Daman and Diu" },
                                            { "value": "Delhi", "display": "Delhi" },
                                            { "value": "Goa", "display": "Goa" },
                                            { "value": "Gujarat", "display": "Gujarat" },
                                            { "value": "Haryana", "display": "Haryana" },
                                            { "value": "Himachal Pradesh", "display": "Himachal Pradesh" },
                                            { "value": "Jammu and Kashmir", "display": "Jammu and Kashmir" },
                                            { "value": "Jharkhand", "display": "Jharkhand" },
                                            { "value": "Karnataka", "display": "Karnataka" },
                                            { "value": "Kerala", "display": "Kerala" },
                                            { "value": "Ladakh", "display": "Ladakh" },
                                            { "value": "Lakshadweep", "display": "Lakshadweep" },
                                            { "value": "Madhya Pradesh", "display": "Madhya Pradesh" },
                                            { "value": "Maharashtra", "display": "Maharashtra" },
                                            { "value": "Manipur", "display": "Manipur" },
                                            { "value": "Meghalaya", "display": "Meghalaya" },
                                            { "value": "Mizoram", "display": "Mizoram" },
                                            { "value": "Nagaland", "display": "Nagaland" },
                                            { "value": "Odisha", "display": "Odisha" },
                                            { "value": "Puducherry", "display": "Puducherry" },
                                            { "value": "Punjab", "display": "Punjab" },
                                            { "value": "Rajasthan", "display": "Rajasthan" },
                                            { "value": "Sikkim", "display": "Sikkim" },
                                            { "value": "Tamil Nadu", "display": "Tamil Nadu" },
                                            { "value": "Telangana", "display": "Telangana" },
                                            { "value": "Tripura", "display": "Tripura" },
                                            { "value": "Uttar Pradesh", "display": "Uttar Pradesh" },
                                            { "value": "Uttarakhand", "display": "Uttarakhand" },
                                            { "value": "West Bengal", "display": "West Bengal" },
                                            
                                        ]
                                    },
                                    "pin": {
                                        "label": "Pin",
                                        "type": "text",
                                        "placeholder": "Pin",
                                        "classes": [],
                                        "parentClasses": [],
                                        "sizeClass": 2,
                                    },
                                    "country": {
                                        "label": "Country",
                                        "type": "text",
                                        "placeholder": "Country",
                                        "classes": [],
                                        "parentClasses": [],
                                        "sizeClass": 2,
                                        "value": "India",
                                        "disabled": true
                                    },
                                }]}
                            />
                        </div>
                    )}

                    {customerAccounts != null && customerAccounts.length > 0 && (
                        <MagicForm
                            robostackResolveData={[
                                {
                                    "name": "ListOfCustomerAccounts",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/apps/customer-accounts-available",
                                        "data": {}
                                    },
                                    "transformMergeIfArray": true,
                                    "transformMerge": true,
                                    "transformArrayMergeStratergy": "overwriteTarget",
                                    "transform": {
                                        "fields": {
                                            "customerAccountUUID": {
                                                "options": "`data.results | sort:name | map | set-prop:display,'<%- name %>' | set-prop:value,$uuid`",
                                                "forceUpdate": true
                                            },
                                        }
                                    }
                                },
                            ]}
                            submitApi={{
                                "method": "PUT",
                                "endpoint": "/apps",
                                "data": {}
                            }}
                            onSubmitApiSuccess={(response) => {
                                if (Array.isArray(response.results) && response.results.length > 0) {
                                    const newSite = response.results[0];

                                    // This is so that the breadcrumbs will load the new site data
                                    DataDelegator.removeFromStore("ListOfApps");
                                    DataDelegator.removeFromStore("Breadcrumbs");

                                    navigate(`/app/${newSite.uuid}`);
                                }
                            }}
                            fields={
                                [
                                    {
                                        "customerAccountUUID": {
                                            "label": "Customer Account To Create The Application Under",
                                            "type": "dropdown",
                                            "placeholder": "Wayne Enterprises",
                                            "required": true,
                                            "position": 10,
                                        },
                                        "name": {
                                            "label": "Name For Your Application",
                                            "type": "text",
                                            "placeholder": "Bat Cave",
                                            "required": true,
                                            "position": 20,
                                        },
                                        "domainName": {
                                            "label": "Domain For Your Application",
                                            "type": "text",
                                            "placeholder": "batcave.wayne-enterprises.com",
                                            "required": true,
                                            "position": 30,
                                            "validator": "domain",
                                            "validationFailedMessage": "Please provide a valid domain name",
                                        },
                                        "image": {
                                            "label": "Image For Your Application",
                                            "type": "file",
                                            "placeholder": "Choose file...",
                                            "required": true,
                                            "position": 40,
                                            "value": "https://www.robostack.ai/img/app.jpg"
                                        },
                                    }
                                ]
                            }
                        />
                    )}

                </div>
            </div>
        </>
    );
}