import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import validator from "validator";

import simpleAuth from "../../core/auth/simpleAuth";
import axiosBackend from "../../core/api/backend";

import setWindowTitle from "../../core/utils/setWindowTitle";

import "../../scss/style.scss";
import "../../scss/components/login.scss";

import ReCAPTCHA from "react-google-recaptcha";

export default function Signup(props) {
    setWindowTitle("Create New Account");

    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState("");

    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState("");

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [repeatPassword, setRepeatPassword] = useState("");
    const [repeatPasswordError, setRepeatPasswordError] = useState("");

    const [checked, setChecked] = useState(false);

    const [signupComplete, setSignupComplete] = useState(false);

    const [signingInStatus, setSigningInStatus] = useState(false);
    const [signInError, setSignInError] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        if (simpleAuth.isAuthenticated) {
            navigate("/");
        }
    }, [simpleAuth.isAuthenticated]);

    const onSubmit = async (e) => {
        e.preventDefault();

        // Reset recaptcha because once a validation error occurs, the recaptcha is not reset and prevents the form from being submitted again
        window?.grecaptcha?.reset();

        let allOkay = true;

        setSignInError("");

        if (!validator.isLength(firstName, { min: 1 })) {
            allOkay = false;
            setFirstNameError("Please enter your first name");
        }

        if (!validator.isLength(lastName, { min: 1 })) {
            allOkay = false;
            setLastNameError("Please enter your last name");
        }

        if (!validator.isEmail(email)) {
            allOkay = false;
            setEmailError("Please enter a valid email address");
        }

        if (!validator.isLength(password, { min: 1 })) {
            allOkay = false;
            setPasswordError("Please enter a password");
        }

        if (!validator.isLength(repeatPassword, { min: 1 })) {
            allOkay = false;
            setRepeatPasswordError("Please repeat your password");
        }

        if (password != repeatPassword) {
            allOkay = false;
            setRepeatPasswordError("Both passwords do not match");
        }

        if (allOkay) {
            if (!checked) {
                allOkay = false;
                setSignInError("Please accept the terms and conditions");
                return
            }

            const captchaResponse = await recaptchaRef.current.executeAsync();

            setSigningInStatus(true);
            setFirstNameError("");
            setLastNameError("");
            setEmailError("");
            setPasswordError("");
            setRepeatPasswordError("");
            setSignInError("");

            let response;

            try {
                response = await axiosBackend.post("authentication/signup", {
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                    email: email.trim(),
                    password: password,
                    passwordRepeat: repeatPassword,
                    terms: checked
                });

                if (response.data.status === "success") {
                    setSignupComplete(true);

                    return
                }

                // If we come to this point, the login was not successful for some reason anyway
                setSigningInStatus(false);
                setSignInError(
                    response.data.error || "An error occurred. Please try again in some time."
                );
            } catch (err) {
                console.error(err);

                setSigningInStatus(false);

                let errorSet = false;

                if (err.name == "AxiosError") {
                    if (err.response?.data?.status == "error") {
                        errorSet = true;

                        const errors = err.response.data.errors;
                        const messages = err.response.data.messages;

                        if (Array.isArray(errors)) {
                            const error = errors[0];

                            if (error.firstName) {
                                setFirstNameError(error.firstName.messages.join(" "));
                            }

                            if (error.lastName) {
                                setLastNameError(error.lastName.messages.join(" "));
                            }

                            if (error.email) {
                                setEmailError(error.email.messages.join(" "));
                            }

                            if (error.password) {
                                setPasswordError(error.password.messages.join(" "));
                            }

                            if (error.passwordRepeat) {
                                setRepeatPasswordError(error.passwordRepeat.messages.join(" "));
                            }
                        }

                        if (Array.isArray(messages)) {
                            setSignInError(messages.join(" "));
                        }
                    }
                }

                if (!errorSet) {
                    setSignInError("An error occurred. Please try again in some time.");
                }
            }
        }

        return false;
    };

    const toggleCheckbox = () => {
        setChecked(!checked);
    };

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
        setFirstNameError("");
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
        setLastNameError("");
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setEmailError("");
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordError("");
    };

    const handleRepeatPasswordChange = (event) => {
        setRepeatPassword(event.target.value);
        setRepeatPasswordError("");
    };

    const recaptchaRef = React.createRef();

    return (
        <div>
            <div className="middle-box animated">
                <div className="ibox ibox-content mb-2 text-black">
                    <h1 className="mt-2 mb-4 text-center">Create New Account</h1>

                    {signupComplete ?
                        <div className="text-center">
                            <div className="pad-btm text-success"><i className="fas fa-check-circle fa-5x"></i></div>


                            Your account has been successfully created. <br /><br />
                            A confirmation email has been sent to your email inbox.<br />
                            Please click on the link in the email to confirm your account.
                        </div>
                        :

                        <form className="m-t" role="form" onSubmit={onSubmit}>
                            {signInError.length > 0 && (
                                <div className="alert alert-danger">{signInError}</div>
                            )}

                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-addon">
                                        <i className="fas fa-user-tie"></i>
                                    </div>

                                    <input
                                        onChange={handleFirstNameChange}
                                        type="text"
                                        className={
                                            "form-control " +
                                            (firstNameError.length > 0 ? "is-invalid" : "")
                                        }
                                        name="firstName"
                                        placeholder="Enter your first name"
                                    />
                                    <div className="invalid-feedback text-normal">{firstNameError}</div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-addon">
                                        <i className="fas fa-user"></i>
                                    </div>

                                    <input
                                        onChange={handleLastNameChange}
                                        type="text"
                                        className={
                                            "form-control " +
                                            (lastNameError.length > 0 ? "is-invalid" : "")
                                        }
                                        name="lastName"
                                        placeholder="Enter your last name"
                                    />
                                    <div className="invalid-feedback text-normal">{lastNameError}</div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-addon">
                                        <i className="fas fa-envelope"></i>
                                    </div>

                                    <input
                                        onChange={handleEmailChange}
                                        type="text"
                                        className={
                                            "form-control " +
                                            (emailError.length > 0 ? "is-invalid" : "")
                                        }
                                        name="email"
                                        placeholder="Enter your email address"
                                    />
                                    <div className="invalid-feedback text-normal">{emailError}</div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-addon">
                                        <i className="fas fa-key"></i>
                                    </div>
                                    <input
                                        onChange={handlePasswordChange}
                                        type="password"
                                        className={
                                            "form-control " +
                                            (passwordError.length > 0 ? "is-invalid" : "")
                                        }
                                        name="password"
                                        placeholder="Enter your password"
                                    />
                                    <div className="invalid-feedback text-normal">{passwordError}</div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-addon">
                                        <i className="fas fa-asterisk"></i>
                                    </div>
                                    <input
                                        onChange={handleRepeatPasswordChange}
                                        type="password"
                                        className={
                                            "form-control " +
                                            (repeatPasswordError.length > 0 ? "is-invalid" : "")
                                        }
                                        name="repeatPassword"
                                        placeholder="Repeat your password"
                                    />
                                    <div className="invalid-feedback text-normal">{repeatPasswordError}</div>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-1">
                                    <label
                                        className={"mb-3 form-checkbox form-icon " + (checked ? "active" : "")}>
                                        <input
                                            defaultChecked={checked}
                                            onClick={toggleCheckbox}
                                            type="checkbox"
                                            name="terms"
                                            id="terms"
                                        />
                                    </label>
                                </div>
                                <div className="col-10 align-middle">
                                    <label for="terms" className="small">I have read and agree to the Terms and Conditions</label>
                                </div>
                            </div>


                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                                size="invisible"
                            />

                            <button
                                disabled={signingInStatus}
                                type="submit"
                                className={`btn btn-login ${signingInStatus ? "btn-danger" : ""
                                    }  block full-width`}>
                                {signingInStatus ? "Creating Account..." : "Create Account"}
                            </button>
                        </form>
                    }
                </div>
            </div>
            <div className="container">
                <div className="row mt-0 text-center justify-content-center">
                    <Link className="col-xs-3" to="/forgot-password">
                        <div className="helper">
                            <>Forgot password?</>
                        </div>
                    </Link>

                    <Link className="col-xs-3" to="/request-confirm-account">
                        <div className="helper">
                            <>Confirm Account</>
                        </div>
                    </Link>

                    <Link className="col-xs-3" to="/login">
                        <div className="helper">
                            <>Login</>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
