import React from "react";

/**
    * Wrap the given component inside a tag.
    * @param {object} WrappedComponent - A HOC or function that is to be wrapped
    * @param {object} tag - The object representing the tag that is to be used for wrapping
    *    @property {object} props - The props to pass to the wrapping tag
    *    @property {string} tag - The name of the tag to be used for wrapping
    *    @property {boolean} execute - A boolean flag representing if the WrappedComponent should be executed as a function or used as a Component directly 
    * @returns A react component
 */

export default function WrapInTag(WrappedComponent, tag) {
    return class extends React.Component {
        constructor(props) {
            super(props);
        }

        render() {
            const CustomTag = `${tag.tag}`;
            if (tag.execute && tag.execute === true) {
                return (
                    <CustomTag {...tag.props}>
                        {WrappedComponent(this.props)}
                    </CustomTag>
                );
            } else {
                return (
                    <CustomTag {...tag.props}>
                        <WrappedComponent {...this.props} />
                    </CustomTag>
                );
            }
        }
    }
}
