import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { resolve, mapStateToProps } from "../delegator/DataDelegator";
import AttachTrailingSlash from "../../../core/utils/attachTrailingSlash";

/**
    * Generate a bootstrap media block
 */
class MediaBlock extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        resolve(this.props);
    }

    render() {
        return (
            <Link data-hint={this.props["data-hint"]} data-step={this.props["data-step"]} to={AttachTrailingSlash(this.props.link)}>
                <div className="media">
                    <i className={this.props.iconClasses.join(" ")}></i>
                    
                    <div className="media-body ml-2">
                        <strong className="file-name mt-2">{this.props.title}</strong><br />
                        <small>{this.props.description}</small>
                    </div>
                </div>
            </Link>
        );
    }
}

MediaBlock.propsInformation = {
    link: {
        type: "STRING",
        description: "The link the media block will open when clicked"
    },
    iconClasses: {
        type: "ARRAY",
        description: "The icon classes to be used to render the icon"
    },
    title: {
        type: "STRING",
        description: "The text to be placed at the right of the icon"
    },
    description: {
        type: "STRING",
        description: "The text to be placed under the title"
    },
};

export default connect(mapStateToProps)(MediaBlock);