import hash from "object-hash";
import axiosBackend from "../../../core/api/backend";
import ejs from "ejs";
import { series } from "async";
import merge from "deepmerge";

import * as DataTransformer from "./DataTransformer";
import RecursiveRender from "./RecursiveRender";

var store = null;
var pendingTransforms = {};

export function setStore(s) {
    store = s;

    // This is here so that any pending resolves can execute automatically
    store.subscribe(() => {
        const state = store.getState();

        Object.keys(pendingTransforms).forEach((name) => {
            if (Array.isArray(pendingTransforms[name])) {
                if (state[name] !== undefined) {
                    pendingTransforms[name].forEach((callback) => {
                        callback(state[name]);
                    });

                    pendingTransforms[name] = undefined;
                }
            }
        });
    });
}

export function getStore() {
    return store;
}

export function getFromStore(name) {
    return store.getState()[name];
}

export function removeFromStore(name) {
    store.dispatch({
        type: "RemoveData",
        name: name,
    });
}

function mergeData(config, source, destination) {
    let mergeOptions = undefined;

    if(config.transformArrayMergeStratergy === "overwriteTarget") {
        mergeOptions = {
            arrayMerge: (destinationArray, sourceArray, options) => sourceArray,
        }
    }

    if(config.transformArrayMergeStratergy === "overwriteDestination") {
        mergeOptions = {
            arrayMerge: (destinationArray, sourceArray, options) => destinationArray,
        }
    }

    return merge(source, destination, mergeOptions);
}

function transform(resolveConfig, data, dataProps) {
    return new Promise((resolve, reject) => {
        // Do we have to transform the data?
        if (resolveConfig.transform) {
            try {
                let name = resolveConfig.transformName || (ejs.render(resolveConfig.name, dataProps) + "_transformed_" + hash(resolveConfig.transform));

                // This is needed because the redux store data is not mutable when we get it inside store.subscribe
                // and this will make it mutable so that the data transformer can work on it without an issue
                if (Array.isArray(data)) {
                    data = [...data];
                } else if (typeof data == "object") {
                    data = { ...data };
                }

                DataTransformer.transform({ ...dataProps, data: data }, { ...resolveConfig.transform })
                    .then(data => {
                        let resolvedData = data[resolveConfig.transformKey] || data;

                        if (resolveConfig.mergeWithSourceAfterTransform === true) {
                            resolvedData = mergeData(resolveConfig, getFromStore(name), resolvedData);
                        }
                        
                        store.dispatch({
                            type: "ResolvedData",
                            name: name,
                            data: resolvedData,
                        });

                        // Set all the keys inside the resolved object as their respective
                        // key-value set in redux
                        if (resolveConfig.transformDirect === true) {
                            Object.keys(resolvedData).forEach((key) => {
                                store.dispatch({
                                    type: "ResolvedData",
                                    name: key,
                                    data: resolvedData[key],
                                });
                            });
                        }

                        resolve(resolvedData);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } catch (err) {
                reject(err);
            }
        } else {
            resolve(data);
        }
    });
}

function robostackResolveData(resolve, dataProps, callback) {
    try {
        let name = ejs.render(resolve.name, dataProps);

        if (!name) {
            callback("Resolve data not named", null);
            console.error("Resolve data not named");
            console.error(resolve);
        } else {
            const state = store.getState();

            // Only get the source data if we are not already resolving it and if we don't already have it
            if (pendingTransforms[name] === undefined && (state[name] === undefined || resolve.forceApiReload === true)) {
                pendingTransforms[name] = [];

                if (resolve.api && resolve.api.method && resolve.api.endpoint) {
                    // Format the endpoint as required
                    let endpoint = ejs.render(resolve.api.endpoint, dataProps);

                    if (resolve.attachFromRedux) {
                        Object.keys(resolve.attachFromRedux).forEach((key) => {
                            dataProps[key] = getFromStore(resolve.attachFromRedux[key]);
                        });
                    }

                    const data = RecursiveRender(resolve.api.data, dataProps, resolve.api.removeAllEmptyValues);

                    axiosBackend({
                        method: resolve.api.method,
                        url: endpoint,
                        data: data,
                    })
                        .then((response) => {

                            store.dispatch({
                                type: "ResolvedData",
                                name: name,
                                data: response.data,
                            });

                            transform(resolve, response.data, dataProps)
                                .then((resolvedData) => {
                                    callback(null, resolvedData);
                                })
                                .catch((error) => {
                                    callback(error, null);
                                });
                        })
                        .catch((error) => {
                            callback(error, null);
                        });
                } else if (resolve.props) {
                    let resolvedData = resolve.props;
                    const storedData = getFromStore(name);

                    if (resolve.mergeWithSourceAfterTransform === true && storedData !== undefined) {
                        resolvedData = mergeData(resolve, storedData, resolvedData);
                    }

                    store.dispatch({
                        type: "ResolvedData",
                        name: name,
                        data: resolvedData,
                    });

                    callback(null, resolvedData);
                } else {
                    callback("Invalid resolve object", null);
                }
            } else {
                // If we have the data, transform now
                // Otherwise, transform it once it is available
                if (state[name] !== undefined) {
                    transform(resolve, state[name], dataProps)
                        .then((data) => callback(null, data))
                        .catch((error) => callback(error, null));
                } else {
                    pendingTransforms[name].push((data) => {
                        transform(resolve, data, dataProps)
                            .then((data) => callback(null, data))
                            .catch((error) => callback(error, null));
                    });
                }
            }
        }
    } catch (err) {
        console.error(err);
        callback(err, null);
    }
}

export function resolve(props, resolveCallback) {
    if (props.robostackResolveData && Array.isArray(props.robostackResolveData)) {
        let resolutions = [];

        let dataProps = { ...props };
        delete dataProps.robostackResolveData;

        for (let i = 0; i < props.robostackResolveData.length; i++) {
            resolutions.push((callback) => robostackResolveData(props.robostackResolveData[i], dataProps, callback));
        }

        if (resolutions.length == 0) {
            resolveCallback && resolveCallback(null, []);
        } else {
            series(resolutions, (err, results) => {
                if (err) {
                    console.error(err)
                    resolveCallback && resolveCallback(err, results);
                } else {
                    resolveCallback && resolveCallback(null, results);
                }
            });
        }
    } else {
        resolveCallback && resolveCallback(null, []);
    }
}

// Syntactic sugar for calling manually
export function delegate(resolveData, dataProps, callback) {
    return resolve({ ...dataProps, robostackResolveData: resolveData }, callback);
}

export { default as mapStateToProps } from "./mapStateToProps";
