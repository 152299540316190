import React from "react";
import removeCustomProps from "../../removeCustomProps";
import defaultUpdateProps from "../../defaultUpdateProps";

import { NumericFormat } from "react-number-format";

function isValidNumber(n) {
    return typeof n == 'number' && !isNaN(n) && isFinite(n);
}

export const defaultValidationMessages = {
    required: "This is a required value",
    invalidInitialValue: "The value provided is not a valid number and cannot be displayed",
}

// The purpose of this function is to format the value as needed for the field type in the event it needs to be set exteranlly for any reason
export function formatValue({ fieldConfiguration, value }) {
    let formattedValue = value;

    return formattedValue;
}

// The purpose of the process function is to handle the value as needed after validation and then return the processed value as well as information if it is valid or not
export function process({ fieldConfiguration, value }, callbackFn) {
    validate(fieldConfiguration, value, (err, updateProps) => {
        if (err) {
            callbackFn(err);
        } else {
            const hasError = updateProps.error !== undefined && updateProps.error.length > 0;

            callbackFn(null, {
                valid: hasError === false,
                fieldConfiguration,
                value,
            })
        }
    })
}

export function validate(fieldConfiguration, value, callbackFn) {
    let updateProps = defaultUpdateProps(fieldConfiguration);

    if (["", undefined].includes(value)) {
        if (fieldConfiguration.required === true) {
            updateProps.error = defaultValidationMessages.required;
        }
    } else {
        if (isValidNumber(Number(value)) === false) {
            updateProps.error = defaultValidationMessages.invalidInitialValue;
        }
    }

    if (typeof callbackFn == "function") {
        callbackFn(null, updateProps);
    }
}

export function render(fieldConfiguration) {
    console.log("currency render");

    // We only want to consider `value` as the official value to avoid any confusions
    delete fieldConfiguration.defaultValue;


    let props = {
        ...fieldConfiguration,

        className: fieldConfiguration.classes.join(" "),
    };

    // Because we do not want it in props but we want it inside fieldConfiguration
    props.onChange = undefined;

    props.onValueChange = (valueInfo, sourceInfo) => {
        const value = valueInfo.floatValue;

        validate(fieldConfiguration, value, (err, updateProps) => {
            if(sourceInfo.source == "event") {
                updateProps.manuallyChanged = true;
            }

            fieldConfiguration.onChange(value, updateProps);
        })

        return false;
    }

    props = removeCustomProps(props);

    return <NumericFormat
        thousandSeparator={true}
        thousandsGroupStyle="lakh"
        prefix={'₹'}
        {...props}
    />
}