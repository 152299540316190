import ApiAccessManagement, { introductionIdentifier } from "../pages/app/api-access-management";
import StandardLoader from "./standard-loader";


export default function APIAccessManagementRouter() {
    return {
        path: "/app/:appUUID/api-access",

        children: [
            {
                index: true,
                element: <ApiAccessManagement />,
                loader: (props) => StandardLoader({
                    ...props,
                    crumbs: [
                        {
                            "name": "API Access Management",
                        },
                    ],
                    introductionIdentifier,
                }),
            },
        ]
    };
}