import React from "react"; // Imported because of syntatic sugar for ComponentName
import WrapInTag from "./WrapInTag";

import SupportedComponents from "./SupportedComponents";
import merge from "deepmerge";

/**
    * Render multiple components based on their json configuration. Has support for nested configurations.
    *
    * @param {Array<any>} componentConfiguration - The array of components that are to be configured and rendered
    * @param {string} keyPrefix - The prefix that will be used with the index of the component object for the react key prop
    *
    * @returns An array of rendered React.Component
 */
export default function RenderComponents(componentConfiguration, propsFromDOM = {}, keyPrefix = "0") {
    return componentConfiguration.map((component, index) => {
        if(component.component == "RenderComponents" && Array.isArray(component.components)) {
            let implicitParentComponent = {
                tag: "div",
                props: {},
            }

            // Set the parent tag information is available
            if(component.parent) {
                if(component.parent.tag) {
                    implicitParentComponent.tag = component.parent.tag;
                }

                if(component.parent.props) {
                    implicitParentComponent.props = component.parent.props;

                    if(Array.isArray(component.parent.props.classes)) {
                        implicitParentComponent.props.className = component.parent.props.classes.join(" ");
                    }
                }
            }


            if (component.parent.size) { 
                implicitParentComponent.props.className = implicitParentComponent.props.className + ` grid-item col-md-${component.parent.size}`; 
            }

            // This line needs checking as duplicate classes are being shown up here
    
            const WrappedComponent = WrapInTag(() => RenderComponents(component.components, {...propsFromDOM, execute: true}, index), implicitParentComponent);
            return <WrappedComponent />;
        } else if (Array.isArray(component)) {
            return RenderComponents(component, propsFromDOM, index);
        } else if (!(component.component in SupportedComponents)) {
            return;
        } else {
            const ComponentName = SupportedComponents[component.component];

            // Define what the parent div will do
            // Supported props are
            //? @property {Number} size - Define what size classes are applied to the parent div of the component
            let implicitParentComponent = {
                tag: "div",
                props: {},
            };

            // Set the parent tag information is available
            if(component.parent) {
                if(component.parent.tag) {
                    implicitParentComponent.tag = component.parent.tag;
                }

                if(component.parent.props) {
                    implicitParentComponent.props = component.parent.props;
                }
            }

            if(!implicitParentComponent.props.className) {
                implicitParentComponent.props.className = "";
            }

            if (component.size) { 
                implicitParentComponent.props.className = implicitParentComponent.props.className + ` grid-item col-xl-${component.size} col-lg-${component.size*2} col-md-${component.size*2}`; 
            }

            let componentProps = {
                key: `${keyPrefix}-${index}`,
            };
            if (component.props) { componentProps = {...componentProps, ...component.props}; }

            // Attach the DOM props last so that it overrides any props that were specified by the configuration
            componentProps = merge(componentProps, propsFromDOM);

            if(component.noParent === true) {
                return <ComponentName {...componentProps} />;
            } else {    
                const WrappedComponent = WrapInTag(ComponentName, implicitParentComponent);
                return <WrappedComponent {...componentProps} />;
            }
            
        }
    });
}
