import * as DataDelegator from "../components/smart/delegator/DataDelegator";
import ShouldIntroductionTourRun from "../core/onboarding/should-introduction-tour-run";
import AttachTrailingSlash from "../core/utils/attachTrailingSlash";

export default function StandardLoader(props) {
    return new Promise(async (resolve, reject) => {
        const { params } = props;
        const url = new URL(props.request.url);

        try {
            let shouldRun = false;

            if (props.introductionIdentifier) {
                [shouldRun] = await Promise.all([
                    ShouldIntroductionTourRun({ identifier: props.introductionIdentifier })
                ]);
            }

            DataDelegator.resolve({
                robostackResolveData:
                    [
                        {
                            "name": "ListOfApps",
                            "api": {
                                "method": "POST",
                                "endpoint": "/apps",
                                "data": {}
                            },
                            "transformName": "Breadcrumbs",
                            "transform": {
                                "title": "`data.results | search-array-for-object: uuid," + params.appUUID + " | pop | prop: name`",
                                "uuid": params.appUUID,
                                "root": AttachTrailingSlash(url.pathname),
                            }
                        },
                        {
                            "name": "Breadcrumbs",
                            "forceApiReload": true,
                            "mergeWithSourceAfterTransform": true,
                            "transformArrayMergeStratergy": "overwriteTarget",
                            "props": {
                                "root": AttachTrailingSlash(url.pathname),
                                crumbs: props.crumbs,
                                rootUrl: props.rootUrl
                            }
                        },
                        {
                            "name": "ShouldIntroductionTourRun",
                            "forceApiReload": true,
                            "props": {
                                shouldRun,
                            }
                        },
                    ]
            }, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    const resolveData = {
                        ...data,
                    }

                    if(props.introductionIdentifier) {
                        resolveData.shouldRun = shouldRun;
                    }

                    resolve(resolveData);
                }
            });
        } catch (err) {
            reject(err);
        }
    })
}