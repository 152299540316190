import StandardLoader from "./standard-loader";

import ResourcesList, { loader as resourcesListLoader } from "../pages/app/resources";
import CreateResource, { introductionIdentifier as CreateResourceIntroductionIdentifier } from '../pages/app/resources/create-resource';

import DataTable from '../pages/app/resources/data-table';
import { RecordViewerLoader } from '../components/smart/supported/RecordViewer';
import AddToResource from '../pages/app/resources/add';

import Page from '../components/smart/Page';

export default function ResourceRoutes() {
    return {
        path: "/app/:appUUID/resources",

        children: [
            {
                index: true,
                element: <ResourcesList
                    robostackResolveData={[
                        {
                            "name": "Breadcrumbs",
                            "forceApiReload": true,
                            "mergeWithSourceAfterTransform": true,
                            "transformArrayMergeStratergy": "overwriteTarget",
                            "props": {
                                crumbs: [
                                    {
                                        "name": "Resources",
                                    }
                                ]
                            }
                        },
                    ]}
                />,
                loader: resourcesListLoader,
            },

            {
                path: "/app/:appUUID/resources/create-new-resource",

                children: [
                    {
                        index: true,
                        element: <CreateResource />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Resources",
                                },
                                {
                                    "name": "Create New Resource",
                                },
                            ],
                            introductionIdentifier: CreateResourceIntroductionIdentifier,
                        }),
                    }
                ]
            },

            {
                path: "/app/:appUUID/resources/:resourceName/edit-resource",

                children: [
                    {
                        index: true,
                        element: <CreateResource
                            edit={true}
                        />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Resources",
                                },
                                {
                                    "name": props.params.resourceName
                                },
                                {
                                    "name": "Edit Resource",
                                },
                            ],
                            introductionIdentifier: CreateResourceIntroductionIdentifier,
                        }),
                    }
                ]
            },


            {
                path: "/app/:appUUID/resources/:resourceName/add",

                children: [
                    {
                        index: true,
                        element: <AddToResource />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Resources",
                                },
                                {
                                    "name": props.params.resourceName,
                                },
                                {
                                    "name": "Add",
                                },
                            ]
                        }),
                    }
                ]
            },

            {
                path: "/app/:appUUID/resources/:resourceName",

                children: [
                    {
                        index: true,
                        element: <DataTable />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Resources",
                                },
                                {
                                    "name": props.params.resourceName,
                                },
                            ]
                        }),
                    }
                ]
            },

            {
                path: "/app/:appUUID/resources/:resourceName/view/:identifier/:value",

                children: [
                    {
                        index: true,
                        loader: RecordViewerLoader,
                        element: <Page
                            robostackResolveData={[
                                {
                                    "component": "RecordViewer",
                                    "size": 12,
                                    "props": {
                                        "data": [],
                                        "columns": [],
                                        "robostackResolveData": [
                                            {
                                                "name": "DataForRecord<%= appUUID %>_<%= resourceName %>_view_<%= identifier %>_<%= value %>",
                                                "api": {
                                                    "method": "POST",
                                                    "endpoint": "/service/resources/<%= appUUID %>/<%= resourceName %>/view/<%= identifier %>/<%= value %>",
                                                    "data": {}
                                                },
                                                "transform": {
                                                    "data": "`data.results`"
                                                }
                                            },
                                            {
                                                "name": "ColumnsFor<%= appUUID %>_<%= resourceName %>",
                                                "api": {
                                                    "method": "POST",
                                                    "endpoint": "/service/resources/<%= appUUID %>/<%= resourceName %>/fields",
                                                    "data": "{}"
                                                },
                                                "transformMerge": true,
                                                "transform": {
                                                    "columns": "`data.results | sort: displayPosition`"
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                            }
                        />,
                    }
                ]
            },

            {
                path: "/app/:appUUID/resources/:resourceName/edit/:identifier/:value",

                children: [
                    {
                        index: true,
                        loader: (props) => StandardLoader({
                            ...props,
                            rootUrl: `/app/${props.params.appUUID}`,
                            crumbs: [
                                {
                                    "name": "Resources",
                                    url: "../../../../"
                                },
                                {
                                    "name": props.params.resourceName,
                                    url: "../../../"
                                },
                                {
                                    "name": "Edit",
                                },
                            ],
                        }),
                        element: <Page
                            robostackResolveData={[
                                {
                                    "component": "MagicForm",
                                    "size": 12,
                                    "props": {
                                        "fields": {},
                                        "formClasses": [
                                            "form-horizontal"
                                        ],
                                        "submitApi": {
                                            "submitData": {
                                                "selection": "$original",
                                                "updation": "$fields"
                                            }
                                        },
                                        "robostackResolveData": [
                                            {
                                                "name": "LookupDataFor<%= appUUID %>_<%= resourceName %>",
                                                "api": {
                                                    "method": "POST",
                                                    "endpoint": "/service/resources/<%= appUUID %>/<%= resourceName %>/lookup",
                                                    "data": {}
                                                },
                                                "transformMerge": true,
                                                "transform": {
                                                    "lookup": "`data.results[0]`"
                                                }
                                            },
                                            {
                                                "name": "Form<%= appUUID %>_<%= resourceName %>",
                                                "api": {
                                                    "method": "POST",
                                                    "endpoint": "/service/resources/<%= appUUID %>/<%= resourceName %>/form",
                                                    "data": {}
                                                },
                                                "transformMerge": true,
                                                "transform": {
                                                    "fields": "`data.results | map | add-prop-to-all-nested-objects:manuallyChanged,1`", // 1 is for loose equality for true 
                                                    "submitApi": "`set-prop:method,'PATCH' | set-prop:endpoint,'/service/resources/<%= appUUID %>/<%= resourceName %>' | set-prop:data,'$submitApi.submitData' | remove-all-keys-except:'data,method,endpoint'`"
                                                }
                                            },
                                            {
                                                "name": "Data<%= appUUID %>_<%= resourceName %>_<%= identifier %>_<%= value %>",
                                                "api": {
                                                    "method": "POST",
                                                    "endpoint": "/service/resources/<%= appUUID %>/<%= resourceName %>/view/<%= identifier %>/<%= value %>/form",
                                                    "data": {}
                                                },
                                                "transformMerge": true,
                                                "transformMergeIfArray": true,
                                                "transformName": "View",
                                                "transform": {
                                                    "submitApi": "`set-prop:original,$data.results.0 | remove-all-keys-except:'data,method,endpoint,original'`",
                                                    "fields": "`data.results | pop | key-value-to-object: value | unset-prop:robostack_id`",
                                                }
                                            },
                                        ]
                                    }
                                }
                            ]
                            }
                        />,
                    }
                ]
            },
        ]
    };
}