import React from "react";
import moment from "moment";

import { connect } from "react-redux";

import axiosBackend from "../../../core/api/backend";

import { Link } from "react-router-dom";
import withLoaderData from "../../../components/withLoaderData";

import * as DataDelegator from "../../../components/smart/delegator/DataDelegator";

import AttachTrailingSlash from "../../../core/utils/attachTrailingSlash";

export async function ReportsManagementLoader(props) {
    return new Promise((resolve, reject) => {
        const { params } = props;
        const url = new URL(props.request.url);

        DataDelegator.resolve({
            robostackResolveData:
                [
                    {
                        "name": "ListOfApps",
                        "api": {
                            "method": "POST",
                            "endpoint": "/apps",
                            "data": {}
                        },
                        "transformName": "Breadcrumbs",
                        "transform": {
                            "title": "`data.results | search-array-for-object: uuid," + params.appUUID + " | pop | prop: name`",
                            "uuid": params.appUUID,
                            "root": AttachTrailingSlash(url.pathname)
                        }
                    },
                    {
                        "name": "Breadcrumbs",
                        "forceApiReload": true,
                        "mergeWithSourceAfterTransform": true,
                        "transformArrayMergeStratergy": "overwriteTarget",
                        "props": {
                            crumbs: [
                                {
                                    "name": "Reports",
                                },
                            ]
                        }
                    },
                ]
        }, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    })
}

class ReportsManagement extends React.Component {
    constructor(props) {
        super(props);

        this.STATES = {
            LOADING: "loading",
            LOADED: "loaded",
            ERROR: "error",
        };

        this.state = {
            reports: [],

            status: this.STATES.LOADING,
            search: "",
        };

        this.onSearch = this.onSearch.bind(this);

    }

    loadData() {
        const config = {
            method: "POST",
            url: `/service/reports/${this.props.router.params.appUUID}`,
            data: {}
        };

        axiosBackend(config)
            .then((response) => {
                this.setState({
                    reports: response.data.results,
                    status: this.STATES.LOADED,
                })
            })
            .catch((error) => {
                console.error(error);

                this.setState({
                    status: this.STATES.ERROR,
                })
            })
    }

    componentDidMount() {
        DataDelegator.resolve(this.props, () => {
            this.loadData();
        })
    }

    onSearch(event) {
        this.setState({
            search: event.target.value,
        })
    }

    onDelete(report) {
        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Delete " + report.name,
                "message": [
                    "Are you sure you would like to delete the " + report.name + " report?"
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                components: [],
                onYes: (onClose) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Deleting Report",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "DELETE",
                            url: `/service/reports/${this.props.router.params.appUUID}/${report.uuid}`,
                        })
                            .then((response) => {
                                this.loadData();    
                                
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            report.name + " was successfully deleted"
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });
                            })
                            .catch((err) => {
                                console.error(err);
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Could not delete report",
                                        message: [
                                            "Due to an error, we were unable to delete the " + report.name + " report",
                                            "‏‏‎ ‎",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    render() {
        let templates = [];
        if (Array.isArray(this.state.reports)) {
            templates = this.state.reports.filter((template) => {
                const search = this.state.search.toLowerCase().trim();

                if (search.length == 0) {
                    return true;
                } else {
                    return template.name.toLowerCase().indexOf(search) > -1 || template.description.toLowerCase().indexOf(search) > -1;
                }
            });
        }

        return (
            <>
                <div className="col-sm-12">
                    <Link to="./add" className="btn btn-success float-right"><i className="fas fa-plus"></i>&nbsp;New Report</Link>
                </div>

                <div className="col-sm-12 mt-2">
                    <div className="ibox-content">
                        <div className="row m-b-sm m-t-sm">
                            <div className="col-md-12">
                                <div className="input-group">
                                    <input type="text" placeholder="Search" className="form-control" autoFocus={true} onChange={this.onSearch} />
                                    <span className="input-group-btn">
                                        <button type="button" className="btn btn-sm btn-primary"> Search</button>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Existing Reports</h3>
                            </div>
                        </div>

                        <div className="row">
                            {templates == undefined || templates.length == 0 ? <div className="col-lg-3">No existing reports available</div> : undefined}

                            {templates && templates.map(report => {
                                return (
                                    <div className="col-lg-3 text-center">
                                        <div className="template pointer col-lg-12 border-top pad-all">
                                            <div>
                                                <Link to={`./view/${report.uuid}`}><img className="mt-2 mb-2 fh-300 img-fluid" src={report.reportImage} /></Link>
                                                <div className="profile-content">
                                                    <h5>
                                                        {report.name}
                                                    </h5>
                                                    <p>
                                                        {report.description}
                                                    </p>
                                                    <div>
                                                        <Link to={`./view/${report.uuid}`} className="btn btn-info btn-sm ml-1"><i className="fa fa-eye"></i> View </Link>
                                                        {/* <Link to={`./edit/${report.uuid}`} className="btn btn-warning btn-sm ml-1"><i className="fa fa-edit"></i> Edit </Link> */}
                                                        <a onClick={() => this.onDelete(report)} className="text-white btn btn-danger btn-sm ml-1"><i className="fa fa-times"></i> Delete </a>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(ReportsManagement));