import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { RiMessage2Line, RiKeyLine } from 'react-icons/ri'

import validator from "validator";

import simpleAuth from "../../core/auth/simpleAuth";
import axiosBackend from "../../core/api/backend";

import "../../scss/style.scss";
import "../../scss/components/login.scss";
import setWindowTitle from "../../core/utils/setWindowTitle";

export default function Login(props) {
    setWindowTitle("Login");
    const [checked, setChecked] = useState(false);

    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState("");

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [signingInStatus, setSigningInStatus] = useState(false);
    const [signInError, setSignInError] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        if (simpleAuth.isAuthenticated) {
            navigate("/");
        }
    }, [simpleAuth.isAuthenticated]);

    const onSubmit = async (e) => {
        e.preventDefault();

        let allOkay = true;

        setSignInError("");

        if (!validator.isEmail(username)) {
            allOkay = false;
            setUsernameError("Please enter a valid email address");
        }

        if (!validator.isLength(password, { min: 1 })) {
            allOkay = false;
            setPasswordError("Please enter your password");
        }

        if (allOkay) {
            setSigningInStatus(true);
            setUsernameError("");
            setPasswordError("");
            setSignInError("");

            let response;

            try {
                response = await axiosBackend.post("authentication/login", {
                    email: username.trim(),
                    password: password,
                    remember: checked ? "on" : "off"
                });

                if (response.data.status === "success") {
                    if (response.data.token) {
                        simpleAuth.login(response.data.token);

                        navigate("/");

                        return false;
                    }
                }

                // If we come to this point, the login was not successful for some reason anyway
                setSigningInStatus(false);
                setSignInError(
                    response.data.error || "An error occurred. Please try again in some time."
                );
            } catch (err) {
                console.error(err);

                setSigningInStatus(false);

                let errorSet = false;

                if (err.name == "AxiosError") {
                    if (err.response?.data?.status == "error") {
                        errorSet = true;

                        const errors = err.response.data.errors;
                        const messages = err.response.data.messages;

                        if (Array.isArray(errors)) {
                            const error = errors[0];

                            if (error.email) {
                                setUsernameError(error.email.messages.join(" "));
                            }

                            if (error.password) {
                                setPasswordError(error.password.messages.join(" "));
                            }
                        }

                        if (Array.isArray(messages)) {
                            setSignInError(messages.join(" "));
                        }
                    }
                }

                if (!errorSet) {
                    setSignInError("An error occurred. Please try again in some time.");
                }
            }
        }

        return false;
    };

    const toggleCheckbox = () => {
        setChecked(!checked);
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
        setUsernameError("");
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordError("");
    };

    return (
        <div>
            <div className="middle-box animated">
                <div className="ibox ibox-content mb-2 text-black">
                    <h1 className="mt-2 mb-4 text-center">Sign in to your account</h1>
                    <form className="m-t" role="form" onSubmit={onSubmit}>
                        {signInError.length > 0 && (
                            <div className="alert alert-danger">{signInError}</div>
                        )}
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-addon">
                                    <RiMessage2Line></RiMessage2Line>
                                </div>

                                <input
                                    onChange={handleUsernameChange}
                                    type="text"
                                    className={
                                        "form-control " +
                                        (usernameError.length > 0 ? "is-invalid" : "")
                                    }
                                    name="username"
                                    placeholder="Enter your email address"
                                />
                                <div className="invalid-feedback text-normal">{usernameError}</div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-addon">
                                    <RiKeyLine></RiKeyLine>
                                </div>
                                <input
                                    onChange={handlePasswordChange}
                                    type="password"
                                    className={
                                        "form-control " +
                                        (passwordError.length > 0 ? "is-invalid" : "")
                                    }
                                    name="password"
                                    placeholder="Enter your password"
                                />
                                <div className="invalid-feedback text-normal">{passwordError}</div>
                            </div>
                        </div>
                        <label
                            className={"mb-3 form-checkbox form-icon " + (checked ? "active" : "")}>
                            <input
                                defaultChecked={checked}
                                onClick={toggleCheckbox}
                                type="checkbox"
                                name="remember"
                                className="mr-2"
                            />
                            <span className="small">Remember me for 30 days</span>
                        </label>
                        <button
                            aria-label="Submit Sign In"
                            disabled={signingInStatus}
                            type="submit"
                            className={`btn btn-login ${signingInStatus ? "btn-danger" : ""
                                }  block full-width`}>
                            {signingInStatus ? "Signing In..." : "Sign In"}
                        </button>
                    </form>
                </div>
            </div>
            <div className="container">
                <div className="row mt-0 text-center justify-content-center">
                    <Link className="col-xs-3" to="/signup">
                        <div className="helper">
                            <>Signup</>
                        </div>
                    </Link>

                    <Link className="col-xs-3" to="/forgot-password">
                        <div className="helper">
                            <>Forgot Password?</>
                        </div>
                    </Link>

                    <Link className="col-xs-3" to="/request-confirm-account">
                        <div className="helper">
                            <>Confirm Account</>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
