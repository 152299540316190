import React from "react";
import withLoaderData from "../../../components/withLoaderData";
import { connect } from "react-redux";

import * as DataDelegator from "../../../components/smart/delegator/DataDelegator";
import { Editor } from "@monaco-editor/react";

class UpdateEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectionValid: true,
            updationValid: true,
        }

        this.handleSelectionValidation = this.handleSelectionValidation.bind(this);
        this.handleUpdationValidation = this.handleUpdationValidation.bind(this);
    }

    handleUpdationValidation(markers) {
        this.setState({
            updationValid: markers.length == 0,
        })
    }

    handleSelectionValidation(markers) {
        this.setState({
            selectionValid: markers.length == 0,
        })
    }

    render() {
        return (
            <div className="row mt-4">
                <div className="col-sm-12">
                    <label className="font-bold">Selection Editor:</label>

                    {!this.state.selectionValid &&
                        <div className="alert alert-danger" role="alert">
                            <div className="row">
                                <div className="col-9">
                                    Invalid Selection JSON
                                </div>
                            </div>
                        </div>
                    }

                    <Editor
                        height="50vh"
                        defaultLanguage="json"
                        theme="vs-dark"
                        autoIndent={true}
                        onChange={this.props.onSelectionChange}
                        onValidate={this.handleSelectionValidation}
                        value={this.props.selection}
                        
                    />

                    <label className="font-bold mt-4">Updation Editor:</label>

                    {!this.state.updationValid &&
                        <div className="alert alert-danger" role="alert">
                            <div className="row">
                                <div className="col-9">
                                    Invalid Updation JSON
                                </div>
                            </div>
                        </div>
                    }

                    <Editor
                        height="50vh"
                        defaultLanguage="json"
                        theme="vs-dark"
                        autoIndent={true}
                        onChange={this.props.onUpdationChange}
                        onValidate={this.handleUpdationValidation}
                        value={this.props.updation}
                    />
                </div>
            </div>
        )
    }
}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(UpdateEditor));