import React from "react";

import Editor from "@monaco-editor/react";

import removeCustomProps from "../../removeCustomProps";
import defaultUpdateProps from "../../defaultUpdateProps";

export const defaultValidationMessages = {
    required: "This is a required value",

    notFound: "The provided existing value was not found in the given options"
}

// The purpose of this function is to format the value as needed for the field type in the event it needs to be set exteranlly for any reason
export function formatValue({ fieldConfiguration, value }) {
    let formattedValue = value;

    return formattedValue;
}

// The process function will validate the value and then return if it is valid or not along with the value itself
export function process({ fieldConfiguration, value }, callbackFn) {
    validate(fieldConfiguration, value, (err, updateProps) => {
        if (err) {
            callbackFn(err);
        } else {
            const hasError = updateProps.error !== undefined && updateProps.error.length > 0;

            callbackFn(null, {
                valid: hasError === false,
                fieldConfiguration,
                value,
            })
        }
    })
}

export function validate(fieldConfiguration, value, callbackFn) {
    let updateProps = defaultUpdateProps(fieldConfiguration);

    if (["", undefined].includes(value)) {
        if (fieldConfiguration.required === true) {
            updateProps.error = defaultValidationMessages.required;
        }
    }

    if (typeof callbackFn == "function") {
        callbackFn(null, updateProps);
    }
}



export function render(fieldConfiguration) {
    console.log("array render");

    // We only want to consider `value` as the official value to avoid any confusions
    delete fieldConfiguration.defaultValue;

    let props = {
        ...fieldConfiguration,
    };

    if(typeof props.value == "object") {
        props.value = JSON.stringify(props.value, null, 4);
    }

    props.onChange = (value) => {

        validate(fieldConfiguration, value, (err, updateProps) => {
            updateProps.manuallyChanged = true;
            
            fieldConfiguration.onChange(value, {
                ...updateProps,

                forceUpdate: true, // Because we depend on the value for rendering the react-tagsinput component to show the currently selected option
            });
        })

        return false;
    }

    props.placeholder = {};

    props.height="50vh";
    props.width="100%";

    props.defaultLanguage="json"
    props.theme="vs-dark"
    props.autoIndent=true
    
    // props.confirmGood=true;
    // props.onKeyPressUpdate=false;

    return <Editor {...props} />;
}