export default function(colors){
    let instance = null;
    return {
        next: function stringToColor(str, rotational = true) {
            if(!rotational) {
                // Compute an integer value of the string so that
                // usage across different arrays of colors will still
                // yield the color at the same index
                const stringValue = str.split("").reduce((acc, character) => {
                    return acc + character.charCodeAt(0);
                }, 0);
    
                return colors[stringValue % colors.length];
            } else {
                if(instance === null) {
                    instance = {};
                    instance.stringToColorHash = {};
                    instance.nextVeryDifferentColorIdx = 0;
                    instance.veryDifferentColors = colors;
                }
    
                if(!instance.stringToColorHash[str]) {
                    let nextColorIndex = instance.nextVeryDifferentColorIdx++;
    
                    if(instance.nextVeryDifferentColorIdx + 1 >= instance.veryDifferentColors.length) {
                        nextColorIndex = nextColorIndex % instance.veryDifferentColors.length;
                    }
    
                    instance.stringToColorHash[str] = instance.veryDifferentColors[nextColorIndex];
                }
    
                return instance.stringToColorHash[str];
            }
        }
    };
}