import {
    Editor,
    Transforms,
    Element as SlateElement,
} from 'slate';
import {  useSlate } from 'slate-react'



const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];


const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}



const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    )

    return !!match
}


const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes < SlateElement > (editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

const BlockButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <button
            className={`btn btn-default ${isBlockActive(
                editor,
                format,
                TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
            ) ? 'active' : ''}`}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            <i className={icon} />
        </button>
    )
}

const MarkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <button
            className={`btn btn-default ${isMarkActive(editor, format) ? 'active' : ''}`}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <i className={icon} />
        </button>
    )
}

function Toolbar(props) {
    return (
        <div>
            <MarkButton format="bold" icon="fas fa-bold" />
            <MarkButton format="italic" icon="fas fa-italic" />
            <MarkButton format="underline" icon="fas fa-underline" />
            <MarkButton format="code" icon="fas fa-code" />
            <BlockButton format="heading-one" icon="fas fa-heading" />
            <BlockButton format="heading-two" icon="fas fa-heading" />
            <BlockButton format="block-quote" icon="fas fa-quote-right" />
            <BlockButton format="numbered-list" icon="fas fa-list-ol" />
            <BlockButton format="bulleted-list" icon="fas fa-list-ul" />
            <BlockButton format="left" icon="fas fa-align-left" />
            <BlockButton format="center" icon="fas fa-align-center" />
            <BlockButton format="right" icon="fas fa-align-right" />
            <BlockButton format="justify" icon="fas fa-align-justify" />
        </div>
    )
}

export default Toolbar;