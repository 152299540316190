import { HttpStatusCode } from "axios";
import { useRouteError, Link } from "react-router-dom";
import Navbar from "../components/Navbar";

export default function ErrorPage(props) {
    const error = useRouteError();
    if (error) {
        console.error(error);
    }

    let message = Array.isArray(props.message) ? props.message : [props.message];
    let title = props.title || "Application Error";
    let icon = props.icon || "fa fa-exclamation-triangle";

    let wrapperClasses = props.wrapperClasses  || "default-bg dashbard-1";

    if (typeof error == "object") {
        if (error.status == 404 || (error.name == "AxiosError" && error.response.status == HttpStatusCode.NotFound)) {
            icon = "fa fa-robot";
            title = "Application Not Found";
            message = [
                "The application you are looking for could not be found on this URL.",
                "Please check the URL you have entered.",
                "‏‏‎ ‎",
            ];
        }
    }

    // Remove unwanted values from messages 
    message = message.filter((text) => {
        return text !== undefined && text !== null && text !== "";
    });

    return (
        <>
            {props.hideSidebar !== true && <Navbar />}

            <div id="wrapper">
                <div id="page-wrapper" className={wrapperClasses}>
                    <div className={`mb-2 ${props.className}`}>
                        <div className="text-center animated fadeInDown">
                            <h1 className={`${icon} fa-5x`}></h1>
                            <h3 className="font-bold">{title}</h3>

                            <div className="error-desc">
                                {message.length == 0 &&
                                    <>
                                        Unfortunately, we ran into an error while trying to process your request.<br />
                                        Please try again in some time.
                                    </>
                                }

                                {message.map((text, index) => {
                                    return <div key={index}>{text}</div>
                                })}
                            </div>

                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}
