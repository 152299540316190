import React from "react";

import { connect } from "react-redux";

import * as DataDelegator from "../../components/smart/delegator/DataDelegator";

import StringToColor from "../../core/utils/stringToColor";
import ToTitleCase from "../../core/utils/toTitleCase";

import StatisticCard from "../../components/smart/supported/StatisticCard";
import { Link } from "react-router-dom";
import AddSearchToComponent from "../../components/smart/supported/AddSearchToComponent";
import axiosBackend from "core/api/backend";
import moment from "moment";

class StackManagement extends React.Component {
    constructor(props) {
        super(props);

        const resourcesList = [
            "metadata",
            "resources",
            "users",
            "loginstore",
            "password-resets",
            "sites",
            "sites-that-users-control",
            "forms",
            "permissions",
            "tokens",
            "token-authentication-history",
            "services",
            "services-available-on-sites",
            "communication-presets",
            "settings",
            "custom-dashboard-components",
            "oauth-tokens",
            "triggers",
            "billing-tiers",
            "billing-cycles",
            "billing-customers",
            "billing-subscriptions",
            "billing-access",
            "invoices",
            "page-introductions",
            "pages",
            "sent-emails",
            "sent-sms",
            "cron-jobs",
            "cron-jobs-pipeline",
            "notifications",
            "api-tokens",
            "api-tokens-access",
            "query-playground-saved",
            "reports-saved",
            "reports-templates",
            "migrations",
            "support-requests",
            "support-requests-messages",
            "files",
            "file_downloads",
            "roles",
            "users-mapped-to-roles",
            "webhooks",
            "billing-tiers-services",
        ].sort();

        this.resourcesToRender = resourcesList.map((resource) => {
            return {
                resourceName: resource,
                label: resource.replaceAll("-", " "),
                className: "",
                iconClasses: ["fa fa-"],
            }
        })

        this.backgroundColor = StringToColor(["lazur-bg", "navy-bg", "yellow-bg", "red-bg", "blue-bg"]);
        this.buttonColor = StringToColor(["btn-info", "btn-primary", "btn-warning", "btn-danger", "btn-success"]);

        this.state = {
            activityLogLoading: true,
            activityLog: [],

            usersMap: {},
            sitesMap: {},

            endDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000).toISOString().split('T')[0], // Adding 1 day because the date time field will default to 00:00:00 when we compare with a date value
            startDate: new Date(new Date().getTime() - 60 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        }

        this.showMore = this.showMore.bind(this);
    }

    componentDidMount() {
        DataDelegator.resolve(this.props, () => {
            this.setState({
                activityLog: [],
            });

            // Load all users
            Promise.all([
                axiosBackend.post("/administration/resources/users", {}),
                axiosBackend.post("/administration/resources/sites", {}),
            ]).then(([users, sites]) => {
                this.setState({
                    usersMap: users.data.results.reduce((acc, user) => {
                        acc[user.uuid] = user;
                        return acc;
                    }, {}),

                    sitesMap: sites.data.results.reduce((acc, site) => {
                        acc[site.uuid] = site;
                        return acc;
                    }, {}),
                }, () => {
                    this.showMore();
                });
            });
        });
    }

    async showMore() {
        this.setState({
            activityLogLoading: true,
        }, async () => {
            try {
                // Load the activity log
                const log = await axiosBackend.post("/administration/activity-log", {
                    "endDate": this.state.startDate,
                    "startDate": this.state.endDate,
                });

                this.setState({
                    activityLogLoading: false,

                    // Add 7 days to startDate and endDate 
                    endDate: new Date(new Date(this.state.endDate).getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
                    startDate: new Date(new Date(this.state.startDate).getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],

                    activityLog: [
                        ...this.state.activityLog,
                        ...log.data.results
                    ].sort((a, b) => {
                        return new Date(b.createdOn) - new Date(a.createdOn);
                    })
                });
            } catch (err) {
                console.error(err);
            }
        })

    }

    render() {
        console.log("StackManagement.render");

        return (
            <div className="p-w-md m-t-sm" >
                <div className="row mb-4">
                    <div className="col-sm-12 mb-4">
                        <div className="">
                            <Link className="btn btn-sm " to="./billing">Billing</Link>
                            <Link className="btn btn-sm " to="./mysql">MySQL</Link>
                            <Link className="btn btn-sm " to="./cache">Cache</Link>
                            <Link className="btn btn-sm " to="./datalog">Datalog</Link>

                        </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="ibox-title pl-4">
                                    <h5><i className="fab fa-expeditedssl"></i>&nbsp;SSL Cerificate Validity</h5>
                                    <div className="ibox-tools">
                                        <a className="collapse-link">
                                            <i className="fa fa-chevron-up"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="ibox-content">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Domain</th>
                                                <th>Validity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.certificates && this.props.certificates.map((certificate) => {
                                                return (
                                                    <tr key={certificate.domain}>
                                                        <td>{certificate.domain}</td>
                                                        <td>{certificate.validity}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="ibox-title pl-4">
                                    <h5><i className="fab fa-expeditedssl"></i>&nbsp;Service Availability</h5>
                                    <div className="ibox-tools">
                                        <a className="collapse-link">
                                            <i className="fa fa-chevron-up"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="ibox-content">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Service</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.serviceChecker && this.props.serviceChecker.map((certificate) => {
                                                return (
                                                    <tr key={certificate.name}>
                                                        <td>{certificate.name}</td>
                                                        <td className={certificate.status == true ? "text-success" : "text-danger"}>
                                                            {certificate.status == true ? "online" : "offline"}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <AddSearchToComponent
                            className="mt-4"
                            searchPlaceholder={`Search in ${this.resourcesToRender.length} resources`}
                            data={this.resourcesToRender}
                            searchKeys={["resourceName", "label"]}
                            render={(props) => {
                                return (
                                    <div key={props.resourceName} className="col-sm-4">
                                        <StatisticCard
                                            link={`./resources/${props.resourceName}/`}
                                            classes={["m-b-none", this.backgroundColor.next(props.resourceName), props.className]}
                                            value="-"
                                            iconClasses={["fa-3x"].concat(props.iconClasses)}
                                            valueClasses={["font-bold h4 mt-1 ml-0 mr-0 mb-0"]}
                                            description={ToTitleCase(props.label || props.resourceName)}
                                            descriptionClasses={["font-bold text-90"]}
                                            robostackResolveData={[
                                                {
                                                    "name": `StackManagement_CountOf${props.resourceName}`,
                                                    "api": {
                                                        "method": "POST",
                                                        "endpoint": `/administration/resources/${props.resourceName}/aggregation`,
                                                        "data": [
                                                            {
                                                                "$group": {
                                                                    "_id": "uuid",
                                                                    "count": {
                                                                        "$count": 1
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    },
                                                    "transform": {
                                                        "value": "`data.results[0].count`"
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>
                                )
                            }}
                        />

                        <div className="row">
                            <div className="col-lg-12 mt-4">
                                <div className="">
                                    <div className="ibox-title pl-4">
                                        <h3><i className="fas fa-toolbox"></i>&nbsp;Data and Resource Utilities</h3>
                                        <div className="ibox-tools">
                                            <a className="collapse-link">
                                                <i className="fa fa-chevron-up"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ibox-content pt-0">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="forum-item active">
                                                    <div className="forum-icon">
                                                        <i className="fa fa-seedling"></i>
                                                    </div>
                                                    <Link to="./playground/site-seeder" className="forum-item-title">Site Seeder</Link>
                                                    <div className="forum-sub-title">Seed data to all sites or a particular site</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12">
                                                <div className="forum-item active">
                                                    <div className="forum-icon">
                                                        <i className="fa fa-clone"></i>
                                                    </div>
                                                    <Link to="./playground/site-cloner" className="forum-item-title">Site Cloner</Link>
                                                    <div className="forum-sub-title">Clone an entire application</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12">
                                                <div className="forum-item active">
                                                    <div className="forum-icon">
                                                        <i className="fa fa-key"></i>
                                                    </div>
                                                    <Link to="./management/api-keys" className="forum-item-title">Mass API Keys Management</Link>
                                                    <div className="forum-sub-title">Perform actions on a number of api keys</div>
                                                </div>
                                            </div>

                                            {/* <div className="col-sm-12">
                                        <Link to="./playground/aggregation">
                                            <div className="forum-item active">
                                                <div className="forum-icon">
                                                    <i className="fa fa-database"></i>
                                                </div>
                                                <a className="forum-item-title">Aggregation Playground</a>
                                                <div className="forum-sub-title">Run aggregation queries using a MongoDB like language.</div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="forum-item active">
                                            <div className="forum-icon">
                                                <i className="fa fa-list"></i>
                                            </div>
                                            <Link to="./playground/pipeline" className="forum-item-title">Pipeline Playground</Link>
                                            <div className="forum-sub-title">Run pipeline queries instantly to see how they work.</div>
                                        </div>
                                    </div>


                                    <div className="col-sm-12">
                                        <div className="forum-item active">
                                            <div className="forum-icon">
                                                <i className="fa fa-table"></i>
                                            </div>
                                            <Link to="./playground/manual-creator" className="forum-item-title">Resource Creator</Link>
                                            <div className="forum-sub-title">Create resources using the JSON model.</div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="forum-item active">
                                            <div className="forum-icon">
                                                <i className="fa fa-edit"></i>
                                            </div>
                                            <Link to="./playground/manual-modifier" className="forum-item-title">Resource Modifier</Link>
                                            <div className="forum-sub-title">Modify existing fields or add new field to an existing resource in an existing site</div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="forum-item active">
                                            <div className="forum-icon">
                                                <i className="fa fa-sort text-muted"></i>
                                            </div>
                                            <Link to="./playground/reorder-fields" className="forum-item-title text-muted">Reorder Fields</Link>
                                            <div className="forum-sub-title">Change the order in which the fields in an existing resource in an existing site are displayed in forms, data tables, etc</div>
                                        </div>
                                    </div> */}

                                            <div className="col-sm-12">
                                                <div className="forum-item active">
                                                    <div className="forum-icon ">
                                                        <i className="fa fa-table text-muted"></i>
                                                    </div>
                                                    <Link to="./playground/erd-creator" className="forum-item-title text-muted">ERD Resource Creator</Link>
                                                    <div className="forum-sub-title">Create resources using an ERD designer.</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12">
                                                <div className="forum-item active">
                                                    <div className="forum-icon">
                                                        <i className="fa fa-envelope-open"></i>
                                                    </div>
                                                    <Link to="./bulk/email" className="forum-item-title">Send Mass Email</Link>
                                                    <div className="forum-sub-title">Run emails to a large number of users at once.</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12">
                                                <div className="forum-item active">
                                                    <div className="forum-icon">
                                                        <i className="fa fa-envelope-open-text text-muted"></i>
                                                    </div>
                                                    <Link to="./bulk/sms" className="forum-item-title text-muted">Send Mass SMS</Link>
                                                    <div className="forum-sub-title">Send SMS to a large number of users at once.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 pull-right">
                        <div className=" ">
                            <div className="ibox-title">
                                <h5>
                                    Activity log
                                    <small className="pull-right">{moment(this.state.startDate).format("MMM Do YYYY")} - Today</small>
                                </h5>
                            </div>
                            <div className="ibox-content">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="feed-activity-list">

                                            {this.state.activityLog.length == 0 && <div className="feed-element">
                                                <div className="media-body ">
                                                    <small className="float-right">No activity found for the given time period</small>
                                                </div>
                                            </div>}


                                            {this.state.activityLog.map((activity, index) => {
                                                let user = this.state.usersMap[activity.data.user_uuid];
                                                if (!user) {
                                                    user = activity.data.user_uuid;
                                                } else {
                                                    user = `${user.first_name} ${user.last_name} <${user.email}>`;
                                                }

                                                let site = this.state.sitesMap[activity.data.site_uuid];
                                                if (!site) {
                                                    site = activity.data.site_uuid;
                                                } else {
                                                    site = `${site.name} <${site.domainName}>`;
                                                }

                                                let createdBy = this.state.usersMap[activity.data.createdBy];
                                                if (!createdBy) {
                                                    createdBy = activity.data.createdBy;
                                                } else {
                                                    createdBy = `${createdBy.first_name} ${createdBy.last_name} <${createdBy.email}>`;
                                                }

                                                switch (activity.type) {
                                                    case "users": {
                                                        return (
                                                            <div className="feed-element">
                                                                <div className="float-left m-2 p-0" to={""}>
                                                                    <i className="fas fa-2x fa-user"></i>
                                                                </div>
                                                                <div className="media-body ">
                                                                    <small className="float-right">{moment(activity.createdOn).fromNow()}</small>
                                                                    <strong>{activity.data.first_name} {activity.data.last_name} &lt;{activity.data.email}&gt;</strong> signed up for an account. <br />
                                                                    <small className="text-muted">{moment(activity.createdOn).format("MMMM Do YYYY, h:mm:ss a z")}</small>

                                                                </div>
                                                            </div>
                                                        )
                                                        break;
                                                    }

                                                    case "sites": {
                                                        return (
                                                            <div className="feed-element">
                                                                <div className="float-left m-2 p-0" to={""}>
                                                                    <img alt="image" className="rounded-circle" src={activity.data.image} />
                                                                </div>
                                                                <div className="media-body ">
                                                                    <small className="float-right">{moment(activity.createdOn).fromNow()}</small>
                                                                    The application <strong>{activity.data.name} &lt;{activity.data.domainName}&gt;</strong> was created by <strong>{createdBy}</strong>. <br />
                                                                    <small className="text-muted">{moment(activity.createdOn).format("MMMM Do YYYY, h:mm:ss a z")}</small>

                                                                </div>
                                                            </div>
                                                        )
                                                        break;
                                                    }

                                                    case "sites-that-users-control": {
                                                        return (
                                                            <div className="feed-element">
                                                                <div className="float-left m-2 p-0" to={""}>
                                                                    <i className="fas fa-2x fa-people-carry"></i>
                                                                </div>
                                                                <div className="media-body ">
                                                                    <small className="float-right">{moment(activity.createdOn).fromNow()}</small>
                                                                    <strong>{user}</strong> was added to <strong>{site}</strong> by <strong>{createdBy}</strong>. <br />
                                                                    <small className="text-muted">{moment(activity.createdOn).format("MMMM Do YYYY, h:mm:ss a z")}</small>

                                                                </div>
                                                            </div>
                                                        )
                                                        break;
                                                    }

                                                    case "resources": {
                                                        return (
                                                            <div className="feed-element">
                                                                <div className="float-left m-2 p-0" to={""}>
                                                                    <i className="fas fa-2x fa-database"></i>
                                                                </div>
                                                                <div className="media-body ">
                                                                    <small className="float-right">{moment(activity.createdOn).fromNow()}</small>
                                                                    <strong>{activity.data.name}</strong> was created on <strong>{site}</strong> by <strong>{createdBy}</strong>. <br />
                                                                    <small className="text-muted">{moment(activity.createdOn).format("MMMM Do YYYY, h:mm:ss a z")}</small>

                                                                </div>
                                                            </div>
                                                        )
                                                        break;
                                                    }

                                                    case "reports": {
                                                        return (
                                                            <div className="feed-element">
                                                                <div className="float-left m-2 p-0" to={""}>
                                                                    <i className="fas fa-2x fa-file"></i>
                                                                </div>
                                                                <div className="media-body ">
                                                                    <small className="float-right">{moment(activity.createdOn).fromNow()}</small>
                                                                    <strong>{activity.data.name}</strong> was created on <strong>{site}</strong> by <strong>{createdBy}</strong>. <br />
                                                                    <small className="text-muted">{moment(activity.createdOn).format("MMMM Do YYYY, h:mm:ss a z")}</small>

                                                                </div>
                                                            </div>
                                                        )
                                                        break;
                                                    }

                                                    case "triggers": {
                                                        return (
                                                            <div className="feed-element">
                                                                <div className="float-left m-2 p-0" to={""}>
                                                                    <i className="fas fa-2x fa-bolt"></i>
                                                                </div>
                                                                <div className="media-body ">
                                                                    <small className="float-right">{moment(activity.createdOn).fromNow()}</small>
                                                                    A <strong>{activity.data.onEvent}</strong> trigger with an <strong>{activity.data.actionToExecute}</strong> action <strong>&lt;{activity.data.action_uuid}&gt;</strong> for <strong>{activity.data.resource_name}</strong> was created on <strong>{site}</strong> by <strong>{createdBy}</strong>. <br />
                                                                    <small className="text-muted">{moment(activity.createdOn).format("MMMM Do YYYY, h:mm:ss a z")}</small>

                                                                </div>
                                                            </div>
                                                        )
                                                        break;
                                                    }

                                                    case "billing-customers": {
                                                        let control_user = this.state.usersMap[activity.data.control_user_uuid];
                                                        if (!control_user) {
                                                            control_user = activity.data.control_user_uuid;
                                                        } else {
                                                            control_user = `${control_user.first_name} ${control_user.last_name} <${control_user.email}>`;
                                                        }

                                                        return (
                                                            <div className="feed-element">
                                                                <div className="float-left m-2 p-0" to={""}>
                                                                    <i className="fas fa-2x fa-building"></i>
                                                                </div>
                                                                <div className="media-body ">
                                                                    <small className="float-right">{moment(activity.createdOn).fromNow()}</small>
                                                                    A new billing customer <strong>{activity.data.name}</strong> was created for <strong>{control_user}</strong> by <strong>{createdBy}</strong>. <br />
                                                                    <small className="text-muted">{moment(activity.createdOn).format("MMMM Do YYYY, h:mm:ss a z")}</small>

                                                                </div>
                                                            </div>
                                                        )
                                                        break;
                                                    }

                                                    case "billing-subscriptions": {
                                                        return (
                                                            <div className="feed-element">
                                                                <div className="float-left m-2 p-0" to={""}>
                                                                    <i className="fas fa-2x fa-hand-holding-usd"></i>
                                                                </div>
                                                                <div className="media-body ">
                                                                    <small className="float-right">{moment(activity.createdOn).fromNow()}</small>
                                                                    A new <strong>{activity.data.cycleName} - {activity.data.tierName}</strong> subscription was created for the customer <strong>{activity.data.customerName}</strong> by <strong>{createdBy}</strong>. <br />
                                                                    <small className="text-muted">{moment(activity.createdOn).format("MMMM Do YYYY, h:mm:ss a z")}</small>

                                                                </div>
                                                            </div>
                                                        )
                                                        break;
                                                    }

                                                    default: {
                                                        return (
                                                            <div className="feed-element">
                                                                <div className="float-left m-2 p-0" to={""}>
                                                                    <i className="fas fa-2x fa-ban"></i>
                                                                </div>
                                                                <div className="media-body ">
                                                                    <small className="float-right">{moment(activity.createdOn).fromNow()}</small>
                                                                    Unsupported activity type - {activity.type} <br />
                                                                    <small className="text-muted">{moment(activity.createdOn).format("MMMM Do YYYY, h:mm:ss a z")}</small>

                                                                </div>
                                                            </div>
                                                        )
                                                        break;
                                                    }
                                                }
                                            })}
                                        </div>

                                        <button
                                            disabled={this.state.activityLogLoading}
                                            onClick={this.showMore}
                                            className="btn btn-primary btn-block m-t"
                                        >
                                            {this.state.activityLogLoading ? <>
                                                <i className="fa fa-spin fa-spinner"></i> Loading
                                            </> :
                                                <>
                                                    <i className="fa fa-arrow-down"></i> Show More
                                                </>}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="mb-4">
                    {/* <div className="mb-4">
                            <div className="ibox-title pl-4">
                                <h3><i className="fas fa-chart-area"></i>&nbsp;Mongo Performance</h3>
                                <div className="ibox-tools">
                                    <a className="collapse-link">
                                        <i className="fa fa-chevron-up"></i>
                                    </a>
                                </div>
                            </div>

                            <div className="ibox-content pt-0">
                                <div className="row">
                                    <div className="col-md-12">

                                        <div>
                                            <span className="float-right text-right">
                                                <small>
                                                    Average MongoDB Speed
                                                </small>
                                                <br />
                                                100ms
                                            </span>
                                        </div>

                                        <div>
                                            <canvas id="lineChart" height="70"></canvas>
                                        </div>

                                        <div className="">
                                            <div className="row text-center">
                                                <div className="col">
                                                    <div className=" m-l-md">
                                                        <span className="h5 font-bold m-t block">15 ms</span>
                                                        <small className="text-muted m-b block">C speed average</small>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <span className="h5 font-bold m-t block">15 ms</span>
                                                    <small className="text-muted m-b block">R speed average</small>
                                                </div>
                                                <div className="col">
                                                    <span className="h5 font-bold m-t block">15 ms</span>
                                                    <small className="text-muted m-b block">U speed average</small>
                                                </div>

                                                <div className="col">
                                                    <span className="h5 font-bold m-t block">15 ms</span>
                                                    <small className="text-muted m-b block">D speed average</small>
                                                </div>

                                                <div className="col">
                                                    <span className="h5 font-bold m-t block">15 ms</span>
                                                    <small className="text-muted m-b block">A speed average</small>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div> */}
                </div>
            </div>
        );
    }
}

export default connect(DataDelegator.mapStateToProps)(StackManagement);