import * as React from "react";
import { drawTextCell, GridCellKind } from "@glideapps/glide-data-grid"

const renderer = {
    kind: GridCellKind.Custom,
    isMatch: (cell) => {
        return cell.data.kind === "url"
    },

    draw: a => {
        return drawTextCell(a, a.cell.displayData ?? a.cell.data.value, a.cell.contentAlign)
    },
    provideEditor: () => p => {
        return (
            <a className="text-primary" href={p.value.url} target="_blank" rel="noopener noreferrer">
                <u>
                    {p.value.displayData}
                </u>
            </a>
        );
    },
};

export default renderer;