import React from 'react';

import { connect } from 'react-redux';

import { v4 as uuid } from "uuid";



import { resolve, mapStateToProps } from "../smart/delegator/DataDelegator";
import Page from '../smart/Page';

class ProcessingModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            instanceIdentifier: uuid(),
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            if (this.props.show === true) {
                $(`#modal-processing-${this.state.instanceIdentifier}`).modal({ backdrop: 'static', keyboard: false });
            } else {
                $(`#modal-processing-${this.state.instanceIdentifier}`).modal('hide');
            }
        }
    }

    componentDidMount() {
        resolve(this.props, () => {
            if(this.props.show === true) {
                $(`#modal-processing-${this.state.instanceIdentifier}`).modal({ backdrop: 'static', keyboard: false });
            }
        });
    }

    onClose() {
        if (typeof this.props.onColse == "function") {
            this.props.onClose();
        }

        $(`#modal-processing-${this.state.instanceIdentifier}`).modal('hide');
    }

    render() {
        const message = Array.isArray(this.props.message) ? this.props.message : [this.props.message];

        return (
            <div id={`modal-processing-${this.state.instanceIdentifier}`} className="modal fade" tabIndex="-1">
                <div className={`modal-dialog ${this.props.modalSize === "large" ? "modal-xl" : ""}`}>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-center">
                                <div className="loader-lg"></div>
                                <br />
                                <p className="h4 text-uppercase text-bold pad-top">{this.props.title}</p>
                                <div className="pad-btm">
                                    {message.map((m, index) => <div key={`processing_${index}_${m}`}>{m}</div>)}
                                </div>

                                {this.props.components !== undefined ? <Page robostackResolveData={this.props.components} /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ProcessingModal);