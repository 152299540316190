import React from "react";
import removeCustomProps from "../../removeCustomProps";
import defaultUpdateProps from "../../defaultUpdateProps";

export const defaultValidationMessages = {
    required: "This is a required value",
}

// The purpose of this function is to format the value as needed for the field type in the event it needs to be set exteranlly for any reason
export function formatValue({ fieldConfiguration, value }) {
    let formattedValue = value;

    return formattedValue;
}

// The process function will validate the value and then return if it is valid or not along with the value itself
export function process({fieldConfiguration, value}, callbackFn) {
    validate(fieldConfiguration, value, (err, updateProps) => {
        if (err) {
            callbackFn(err);
        } else {
            const hasError = updateProps.error !== undefined && updateProps.error.length > 0;

            callbackFn(null, {
                valid: hasError === false,
                fieldConfiguration,
                value,
            })
        }
    })
}

export function validate(fieldConfiguration, value, callbackFn) {
    let updateProps = defaultUpdateProps(fieldConfiguration);

    if (["", undefined].includes(value)) {
        if (fieldConfiguration.required === true) {
            updateProps.error = defaultValidationMessages.required;
        }
    }

    if (typeof callbackFn == "function") {
        callbackFn(null, updateProps);
    }
}

export function render(fieldConfiguration) {
    console.log("textarea render");

    // We only want to consider `value` as the official value to avoid any confusions
    delete fieldConfiguration.defaultValue;

    // But we still merge it back to defaultValue here because that is what react expects
    if (fieldConfiguration.value !== undefined) {
        fieldConfiguration.defaultValue = fieldConfiguration.value;

        delete fieldConfiguration.value;
    }

    let props = {
        ...fieldConfiguration,

        className: fieldConfiguration.classes.join(" "),
    };

    props.onChange = (event) => {
        const value = event.target.value;

        validate(fieldConfiguration, value, (err, updateProps) => {
            updateProps.manuallyChanged = true;
            
            fieldConfiguration.onChange(value, updateProps);
        })

        return false;
    }

    props = removeCustomProps(props);

    return <textarea {...props} />;
}