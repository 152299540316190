import React from "react";

import MagicForm from "../../../components/smart/supported/MagicForm";
import mapStateToProps from "../../../components/smart/delegator/mapStateToProps";

import { connect } from "react-redux";
import withLoaderData from "../../../components/withLoaderData";
import axiosBackend from "../../../core/api/backend";

class AddUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            roles: [],
        };

        this.addUser = this.addUser.bind(this);
    }

    addUser({ tuple }, callback) {
        axiosBackend({
            method: "PUT",
            url: `/service/sites-that-users-control/${this.props.router.params.appUUID}/`,
            data: tuple,
        })
            .then((response) => {
                this.props.onComplete && this.props.onComplete();
                
                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "success",
                        title: "Success",
                        message: [
                            "The new user was added to your application successfully.",
                            "Please ask them to check their email address for further instructions."
                        ],
                        okayButtonText: "Okay",
                    },
                });
            })
            .catch((err) => {
                console.error(err);

                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Could not add user",
                        message: [
                            "Due to an error, we were unable to add the new user to your application.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
            .finally(callback);
    }

    loadRoles() {
        axiosBackend({
            method: "POST",
            url: `/service/sites-that-users-control/roles/${this.props.router.params.appUUID}/`,
        })
            .then((response) => {
                this.setState({
                    roles: response.data.results.map((role) => {
                        role.display = role.name;
                        role.value = role.uuid;
                        return role;
                    }),
                });
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    error: true,
                })
            })
    }

    componentDidMount() {
        this.loadRoles();
    }

    render() {
        return (
            <div className="col-sm-12">
                <h2>Add New User</h2>
                <p>
                    Enter the email address of the user you want to grant access to.
                </p>
                <MagicForm
                    onSubmit={this.addUser}
                    fields={[
                        {
                            "email": {
                                "label": "Email Address",
                                "type": "email",
                                "placeholder": "user@yourcompany.com",
                                "classes": [],
                            },
                            "roles": {
                                "label": "Roles",
                                "type": "dropdown",
                                "placeholder": "Select the roles to assign",
                                "classes": [],
                                "options": this.state.roles,
                                "isMulti": true
                            }   
                        }
                    ]}
                />
            </div>
        );
    }
}

export default withLoaderData(connect(mapStateToProps)(AddUser));
