import { createStore } from 'redux';
import rootReducer from './reducers';

export default function ConfigureStore(initialState) {
    return createStore(rootReducer, initialState,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({
            trace: true
        })
    );
}
