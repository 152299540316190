import React from "react";

import { isLength } from "validator";
import ImageBg from "../../components/ImageBg"

import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import withLoaderData from "../../components/withLoaderData";
import axiosBackend from "../../core/api/backend";

import ReCAPTCHA from "react-google-recaptcha";

import "../../scss/style.scss";
import "../../scss/components/login.scss";

class SetForgottenPassword extends React.Component {
    constructor(props) {
        super(props);

        this.recaptchaRef = React.createRef();

        this.state = {
            status: "resetting",

            password: "",
            passwordError: "",

            passwordRepeat: "",
            passwordRepeatError: "",

            signingIn: false,
            signInError: "",

            token: null,
        };

        this.onSubmitBound = this.onSubmit.bind(this);
    }

    async onSubmit(e) {
        e.preventDefault();

        let errors = {};

        if (!isLength(this.state.password, { min: 8 })) {
            errors["passwordError"] = "Please enter a password with a minimum of 8 characters";
        }

        if (!isLength(this.state.passwordRepeat, { min: 8 })) {
            errors["passwordRepeatError"] = "Please repeat the same password as you entered above";
        } else {
            if (this.state.password != this.state.passwordRepeat) {
                errors["passwordRepeatError"] = "The password does not match what you entered above";
            }
        }

        if (Object.keys(errors).length > 0) {
            this.setState(errors);
        } else {
            const captchaResponse = await this.recaptchaRef.current.executeAsync();

            this.setState({
                passwordError: "",
                passwordRepeatError: "",
                signInError: "",
                signingIn: true,
            }, () => {
                axiosBackend({
                    method: "POST",
                    url: "/authentication/set-forgotten-password",
                    data: {
                        password: this.state.password,
                        passwordRepeat: this.state.passwordRepeat,
                    },
                    headers: {
                        "Authorization": this.state.token,
                    }
                })
                    .then((response) => {
                        try {
                            if (response.data.status === "success") {
                                this.setState({
                                    status: "completed",
                                });
                            } else {
                                this.setState({
                                    signingIn: false,
                                    signInError: response.data.message || "We ran into an issue while trying to set your password. Please check the data you have entered and try again after a few minutes.",
                                });
                            }
                        } catch (err) {
                            this.setState({
                                signingIn: false,
                                signInError: response.data.message || "We ran into an issue while trying to set your password. Please try again after a few minutes.",
                            });
                        }
                    })
                    .catch((error) => {
                        const response = error.response;
                        console.error(response);

                        let errorMessage = "We ran into an issue while trying to set your password. Please try again after a few minutes.";

                        if (response?.data && Array.isArray(response?.data?.messages)) {
                            errorMessage = error.response.data.messages.join(" ");
                        }

                        this.setState({
                            signingIn: false,
                            signInError: errorMessage,
                        });
                    });
            });
        }

        return false;
    }

    componentDidMount() {

        axiosBackend.post("/authentication/reset-password-validate", {
            hash1: this.props.router.params.hash1,
            hash2: this.props.router.params.hash2,
        })
            .then((response) => {
                if (response.data.token) {
                    this.setState({
                        status: "set",
                        token: response.data.token,
                    });
                }
            })
            .catch((error) => {
                const stateToUpdate = {
                    status: "error"
                };

                if (error?.response?.data && Array.isArray(error?.response?.data?.messages)) {
                    stateToUpdate.signInError = error.response.data.messages.join(" ");
                }

                this.setState(stateToUpdate)
            })
    }

    render() {
        return (
            <div>
                <div id="bg-overlay">
                    <ImageBg></ImageBg>
                </div>
                <div className="middle-box animated">
                    <div className="ibox ibox-content mb-2 text-black">
                        <h1 className="mt-2 mb-4 text-center">Reset Your Password</h1>

                        {this.state.status == "resetting" ? <>
                            <div className="row">
                                <div className="col-xs-4 offset-sm-6">
                                    <Loader showText={false} />
                                </div>
                            </div>

                            Please wait while we verify your password reset request.
                        </> : null}

                        {this.state.status == "completed" ? <div className="text-center">
                            <div className="pad-btm text-success"><i className="fas fa-check-circle fa-5x"></i></div>

                            Your password has been successfully reset. <br />
                            You can now <Link className="text-inherit" to="/login"><u>login</u></Link> to your account using your new account.
                        </div> : null}

                        {this.state.status == "error" ? <div className="text-center">
                            <div className="pad-btm text-danger"><i className="fas fa-times-circle fa-5x"></i></div>


                            <div className="">
                                {this.state.signInError.length > 0 ?
                                    <span>{this.state.signInError}<br /></span> : <span>
                                        Unfortunately, we could not confirm the status of your password reset request. <br /><br />
                                        Please try again after some time.
                                    </span>
                                }
                                <br />
                                Or <a href="https://www.robostack.ai/support" className="text-inherit"><u>contact us</u></a> for assistance if this persists.

                            </div>
                        </div> : null}

                        {this.state.status == "set" ? <>
                            {this.state.signInError.length > 0 ? <div className="alert alert-danger">
                                {this.state.signInError}
                            </div> : undefined}

                            <form className="m-t" role="form" onSubmit={this.onSubmitBound}>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            <i className="fa fa-key"></i>
                                        </div>
                                        <input disabled={this.state.signingIn} onChange={(e) => this.setState({ password: e.target.value, passwordError: "" })} type="password" className={"form-control " + (this.state.passwordError.length > 0 ? "is-invalid" : "")} name="password" placeholder="Enter your new password" />
                                        <div className="invalid-feedback text-normal">
                                            {this.state.passwordError}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            <i className="fa fa-asterisk"></i>
                                        </div>
                                        <input disabled={this.state.signingIn} onChange={(e) => this.setState({ passwordRepeat: e.target.value, passwordRepeatError: "" })} type="password" className={"form-control " + (this.state.passwordRepeatError.length > 0 ? "is-invalid" : "")} name="passwordRepeat" placeholder="Repeat your new password" />
                                        <div className="invalid-feedback text-normal">
                                            {this.state.passwordRepeatError}
                                        </div>
                                    </div>
                                </div>

                                <ReCAPTCHA
                                    ref={this.recaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                                    size="invisible"
                                />

                                <button disabled={this.state.signingIn} type="submit" className="btn btn-login block full-width">
                                    {this.state.signingIn ? "Setting Password..." : "Set Password"}
                                </button>
                            </form>
                        </> : null}

                    </div>
                </div>
                <div className="container">
                    <div className="row mt-0 text-center justify-content-center">
                        <div className="col-sm-12">
                            <Link to="/signup">
                                <div className="helper inline">
                                    <>Signup</>
                                </div>
                            </Link>

                            <Link to="/request-confirm-account">
                                <div className="helper inline">
                                    <>Confirm Account</>
                                </div>
                            </Link>

                            <Link to="/login">
                                <div className="helper inline">
                                    <>Login</>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withLoaderData(SetForgottenPassword);