import React, { useState, useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";

import * as DataDelegator from "../../../../components/smart/delegator/DataDelegator";

import Select from "react-select";

const HtmlToReactParser = require('html-to-react').Parser;

const minifier = require('string-minify');
const pretty = require('pretty');

import renderTemplate from '../render-template';
import exportToImage, { generateImage, generateBlob } from '../export-to-image';

import SelectWithDescriptionQueryOption, { filterQueryOptionsWithDescription } from "../../../../components/select-with-description/SelectWithDescriptionQueryOption";
import axiosBackend from "../../../../core/api/backend";
import withLoaderData from "../../../../components/withLoaderData";

import ReactJson from "react-json-view";
import Editor from "@monaco-editor/react";
import MarkIntroductionTourDone from "../../../../core/onboarding/mark-introduction-tour-done";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import steps from "./help-steps/designer-help-steps";

const introductionIdentifier = "reports/builder/designer";

import { useLoaderData } from "react-router-dom";
import { upload } from "core/api/fileserver";

function Designer(props) {
    const htmlToReactParser = useMemo(() => new HtmlToReactParser(), []);
    const editorRef = React.useRef(null);

    const {
        shouldRun,
    } = useLoaderData();

    const [showTour, setShowTour] = React.useState(false);
    const [stepIndex, setStepIndex] = React.useState(0);

    useEffect(() => {
        if (shouldRun) {
            setShowTour(true);
        }
    }, [shouldRun]);

    const toggleHelp = () => {
        setShowTour(true);
    }

    const handleJoyrideCallback = (data) => {
        if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.CLOSE].includes(data.status) || ACTIONS.CLOSE == data.action) {
            // Need to set our running state to false, so we can restart if we click start again.
            setShowTour(false);
            setStepIndex(0);

            if (shouldRun) {
                MarkIntroductionTourDone({ identifier: introductionIdentifier });
            }
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(data.type)) {
            // Update state to advance the tour
            setStepIndex(data.index + (data.action === ACTIONS.PREV ? -1 : 1));
        }
    }

    const [isResultsCollapsed, setIsResultsCollapsed] = useState(false);
    const toggleLocals = () => {
        setIsResultsCollapsed(!isResultsCollapsed)
    }

    const handleEditorDidMount = (editor, monaco) => {
        // here is another way to get monaco instance
        // you can also store it in `useRef` for further usage
        editorRef.current = editor;
    }

    const onLocalsCopy = (copy) => {
        let reference = "";
        let namespace = [...copy.namespace];

        namespace.shift();

        if (namespace.length > 1) {
            reference = namespace.reduce((acc, current) => {
                return `${acc}['${current}']`;
            }, "locals");
        } else {
            reference = namespace[0];
        }

        insertEJSVariable({
            text: `<%= ${reference}  %>`,
            classToUse: classesToUseForEJS.variable,
        })
    }

    const insertEJSVariable = ({ text }) => {
        const selection = editorRef.current.getSelection();
        const id = { major: 1, minor: 1 };
        const op = { identifier: id, range: selection, text: text, forceMoveMarkers: true };
        editorRef.current.executeEdits("my-source", [op]);
    }

    const insertEJSForLoop = ({ }) => {
        const text = "<% for (const index in locals) { %>\n    <%= typeof locals[index] %>\n<% } %>\n";

        const selection = editorRef.current.getSelection();
        const id = { major: 1, minor: 1 };
        const op = { identifier: id, range: selection, text: text, forceMoveMarkers: true };
        editorRef.current.executeEdits("my-source", [op]);
    }

    const reportTypes = [
        {
            value: "multiple",
            label: "Multiple reports - One for each record"
        },
        {
            value: "single",
            label: "Single report - One for all records"
        }
    ];

    const [locals, setLocals] = useState([]); // This will change as needed by the user or depending on what data is needed by the template

    const [pipelineResults, setPipelineResults] = useState([]); // This will be the data returned from the api
    const [selectedIndex, setSelectedIndex] = useState(null); // This will be used to indicate which index of the pipelineResults is loaded into locals

    const [reportType, setReportType] = useState("");
    const [template, setTemplate] = useState("");

    const [queries, setQueries] = useState([]);
    const [selectedQuery, setSelectedQuery] = useState(null);

    const [panelDisplay, setPanelDisplay] = useState({
        locals: true,
        template: true,
        rendered: true,
    })

    const [panelDisplaySize, setPanelDisplaySize] = useState({
        locals: 3,
        template: 4,
        rendered: 5,
    })

    const loadQueries = async () => {
        const queriesConfig = {
            method: "POST",
            url: "/service/query-playground/" + props.router.params.appUUID,
            data: {}
        };

        return axiosBackend(queriesConfig)
            .then((response) => {
                const newQueries = response.data.results.map((query) => {
                    query.label = query.name;
                    query.description = query.description;
                    query.value = query.uuid;
                    return query
                });

                setQueries(newQueries)

                if (props.selectedQuery !== undefined && props.selectedQuery !== null) {
                    const selectedQuery = newQueries.filter((query) => query.uuid == props.selectedQuery.uuid);

                    // If nothing was found for any reason, we simply do not select it in the dropdown
                    if (selectedQuery.length > 0) {
                        setSelectedQuery(selectedQuery[0])
                    }
                }

                return newQueries;
            })
            .catch((error) => {
                console.error(error);
                props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Fetching Queries List of Application Failed",
                        message: [
                            "Due to a server error, we were unable to fetch the list of queries in the given application.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
    }

    const exportTemplateAsImage = () => {
        exportToImage("rendered-template", "rendered-template")
            .catch((error) => {
                console.error(error);
                props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Could Not Export To An Image",
                        message: [
                            "Due to an error, we were unable to export your template to an image.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
    }

    const loadOne = () => {
        let newLocals;
        let newSelectedIndex;

        if (Array.isArray(pipelineResults)) {
            newSelectedIndex = 0;
            newLocals = pipelineResults[newSelectedIndex];
        } else {
            newLocals = pipelineResults;
            newSelectedIndex = null;
        }

        setLocals(newLocals);
        setSelectedIndex(newSelectedIndex);
    }

    const loadResults = () => {
        setLocals(pipelineResults);
    }

    const next = () => {
        if (pipelineResults.length > 0) {

            let nextIndex = 0;

            if (selectedIndex != null) {
                if (selectedIndex < pipelineResults.length - 1) {
                    nextIndex = selectedIndex + 1;
                }
            }

            setSelectedIndex(nextIndex);
            setLocals(pipelineResults[nextIndex]);
        }
    }

    const previous = () => {
        if (pipelineResults.length > 0) {
            let previousIndex = pipelineResults.length - 1;

            if (selectedIndex != null) {
                if (selectedIndex > 0) {
                    previousIndex = selectedIndex - 1;
                }
            }

            setSelectedIndex(previousIndex);
            setLocals(pipelineResults[previousIndex]);
        }
    }

    const loadQueryData = async () => {
        if (selectedQuery) {
            const config = {
            };

            switch (selectedQuery.operation) {
                case "read": {
                    config.method = "POST";
                    config.url = `/service/resources/${props.router.params.appUUID}/${selectedQuery.resource}/`;
                    config.data = selectedQuery.configuration;
                    break;
                }

                case "aggregate": {
                    config.method = "POST";
                    config.url = `/service/resources/${props.router.params.appUUID}/${selectedQuery.resource}/aggregation`;
                    config.data = selectedQuery.configuration.pipeline;
                    break;
                }

                default: {
                    console.log("Unknown operation: " + selectedQuery.operation)
                    break;
                }
            }

            return axiosBackend(config)
                .then((response) => {
                    setLocals(response.data.results)
                    setPipelineResults(response.data.results)
                })
                .catch(console.error)
        }
    }

    useEffect(() => {
        loadQueryData();
    }, [selectedQuery]);

    const handleSelectedQueryChange = (selectedQuery) => {
        setSelectedQuery(selectedQuery)
    }

    const clear = () => {
        setTemplate("");
    }

    const minify = () => {
        const newTemplate = minifier(template);
        setTemplate(newTemplate)
    }

    const prettify = () => {
        const newTemplate = pretty(template, {
            indent_size: 4
        });

        setTemplate(newTemplate)
    }

    const handleLocalsDataChange = (json) => {
        setLocals(json)
    }

    const handleReportTypeChange = (reportType) => {
        setReportType(reportType.value)
    }

    useEffect(() => {
        if (reportType == "single") {
            loadResults();
        } else if (reportType == "multiple") {
            loadOne();
        } else {
            console.log("Unknown reportType:", reportType)
        }
    }, [reportType, pipelineResults]);

    useEffect(() => {
        DataDelegator.resolve(props, async (err) => {
            try {
                const queries = await loadQueries();

                if (props.template !== undefined && props.reportType !== undefined) {
                    setReportType(props.reportType);
                    setTemplate(props.template);

                    const selectedQuery = queries.find((query) => query.uuid == props.selectedQuery);
                    setSelectedQuery(selectedQuery);
                } else {
                    const templateConfig = {
                        method: "POST",
                        url: "/service/reports/templates/" + props.router.params.appUUID + "/" + props.selectedTemplate,
                    };

                    if (props.edit == true) {
                        templateConfig.url = "/service/reports/" + props.router.params.appUUID + "/" + props.router.params.reportUUID
                    }

                    axiosBackend(templateConfig)
                        .then((response) => {
                            if (response.data.results.length > 0) {
                                if (props.edit != true) {
                                    setLocals(response.data.results[0].locals);
                                    setPipelineResults(response.data.results[0].locals);
                                }

                                setTemplate(response.data.results[0].template);
                                setReportType(response.data.results[0].reportType);

                                if (props.edit == true) {
                                    const selectedQuery = queries.find((query) => query.uuid == response.data.results[0].query_uuid);
                                    setSelectedQuery(selectedQuery);
                                }
                            } else {
                                throw new Error("No data returned");
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            props.dispatch({
                                type: "ResolvedData",
                                name: "ModalData",
                                data: {
                                    show: true,
                                    type: "error",
                                    title: "Fetching Template Failed",
                                    message: [
                                        "Due to a server error, we were unable to fetch the templates in the given application.",
                                        "Please try again in a little while."
                                    ],
                                    okayButtonText: "Okay"
                                },
                            });
                        })
                }
            } catch (err) {
                console.error(err);
            }
        });
    }, []);

    const togglePanel = (type) => {
        return () => {
            setPanelDisplay({
                ...panelDisplay,
                [type]: !panelDisplay[type],
            })
        }
    }

    const moveToNextStep = () => {
        if (selectedQuery == null) {
            props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    show: true,
                    type: "error",
                    title: "Please select a query ",
                    message: [
                        "Please select an existing query to use with your report"
                    ],
                    okayButtonText: "Okay"
                },
            });
        } else if (reportType == "") {
            props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    show: true,
                    type: "error",
                    title: "Please select a report type",
                    message: [
                        "Please select the type of report you would like to design"
                    ],
                    okayButtonText: "Okay"
                },
            });
        } else if (template.length == 0) {
            props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    show: true,
                    type: "error",
                    title: "Please design your report",
                    message: [
                        "Please design your report before choosing where to use it"
                    ],
                    okayButtonText: "Okay"
                },
            });
        } else {
            generateBlob("rendered-template")
                .then((data) => {
                    return upload(data, "rendered-template")
                })
                .then((response) => {
                    props.dispatch({
                        type: "ResolvedData",
                        name: "Report_Design",
                        data: {
                            image: response.data.url,
                            selectedQuery: selectedQuery,
                            reportType: reportType,
                            template: template,
                        },
                    });

                    props.jumpToStep(2);
                })
                .catch((err) => {
                    console.error(err);

                    props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "error",
                            title: "Could not save report preview",
                            message: [
                                "Due to an error, we were unable to save the image preview for your report.",
                                "‏‏‎ ‎",
                                "Please try again in a little while."
                            ],
                            okayButtonText: "Okay"
                        },
                    });
                })
        }
    }

    const moveToPrevStep = () => {
        // To preserve state
        generateImage("rendered-template")
            .then((dataUrl) => {
                console.log(dataUrl);

                props.dispatch({
                    type: "ResolvedData",
                    name: "Report_Design",
                    data: {
                        image: dataUrl,
                        selectedQuery: this.state.selectedQuery,
                        reportType: this.state.reportType,
                        template: this.state.template,
                    },
                });
            })
            .finally(() => {
                props.jumpToStep(0);
            })
    }

    const classesToUseForEJS = {
        "variable": "text-primary",
        "control": "text-info",
    };

    let renderedTemplate;

    if (reportType == "multiple" && selectedIndex != null) {
        renderedTemplate = renderTemplate(template, locals);
    } else {
        renderedTemplate = renderTemplate(template, locals);
    }

    return (
        <>
            <ReactJoyride
                callback={handleJoyrideCallback}
                run={showTour}
                stepIndex={stepIndex}
                steps={steps}
                continuous={true}
                showSkipButton={true}
                showProgress={true}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
                disableScrollParentFix={true}
            />

            <div className="col-lg-12 mt-2 mb-2">
                <div className="row">
                    <div className="col-sm-8">
                        <button id="toggle-help" className="btn btn-gray" onClick={toggleHelp}>
                            <span className="far fa-question-circle fa-lg"></span>
                        </button>
                    </div>
                </div>

                <div className="btn btn-primary pointer" onClick={moveToPrevStep}>Previous</div>
                <div className="btn btn-primary pointer float-right" onClick={moveToNextStep} disabled={props.selectedTemplate == undefined}>Next</div>
            </div>

            <div className="col-sm-12">
                <div className="ibox-content">
                    <div className="row">
                        <div className="col-sm-12">
                            {
                                renderedTemplate.status == "error" ?
                                    <div className="row mt-4">
                                        <div className="col-sm-12">
                                            <div className="alert alert-danger">
                                                Please check your EJS data usage.<br />
                                                The template will not render correctly if your ejs values are incorrect.
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    undefined
                            }

                            <div className="row mt-2">
                                {panelDisplay.locals &&
                                    <div id="locals-panel" className={`col-sm-${panelDisplaySize.locals} panel panel-default p-0 mr-2`}>
                                        <div className="panel-heading">
                                            <label className="font-bold">Data:&nbsp;
                                                <span id="data-count">
                                                    {
                                                        Array.isArray(pipelineResults) ? (
                                                            reportType == "single" ?
                                                                "" + pipelineResults.length + " records"
                                                                :
                                                                (selectedIndex + 1) + " / " + pipelineResults.length + " records"
                                                        )
                                                            :
                                                            undefined
                                                    }
                                                </span>
                                            </label>

                                            <div className="float-right">
                                                <button onClick={togglePanel("locals")} className="btn btn-default btn-sm">
                                                    <i className="fa fa-times" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="row mb-2">
                                                <div className="col-12">
                                                    <button
                                                        disabled={reportType != "multiple"}
                                                        onClick={next}
                                                        type="button"
                                                        className="btn btn-xs btn-white float-right mr-1"
                                                    >
                                                        Next&nbsp;
                                                        <i className="fa fa-arrow-right"></i>&nbsp;
                                                    </button>

                                                    <button
                                                        disabled={reportType != "multiple"}
                                                        onClick={previous}
                                                        type="button"
                                                        className="btn btn-xs btn-white float-right"
                                                    >
                                                        <i className="fa fa-arrow-left"></i>&nbsp;Previous
                                                    </button>

                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <Select
                                                        id="select-query"
                                                        components={{ Option: SelectWithDescriptionQueryOption }}
                                                        filterOption={filterQueryOptionsWithDescription}
                                                        onChange={handleSelectedQueryChange}
                                                        options={queries && queries.map((query) => {
                                                            query.label = query.name;
                                                            query.description = query.description;
                                                            query.value = query.uuid;
                                                            return query;
                                                        })}
                                                        value={selectedQuery}
                                                        noOptionsMessage={() => "No existing queries found"}
                                                        placeholder="Select an existing query to load"
                                                        className="mb-2"
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <button
                                                        onClick={toggleLocals}
                                                        className="btn btn-xs btn-success float-right mr-2"
                                                    >
                                                        <span className={`fas ${isResultsCollapsed ? "fa-expand-alt" : "fa-compress-alt"}`}></span>&nbsp;
                                                        {isResultsCollapsed ? "Expand" : "Shrink"} Data
                                                    </button>
                                                </div>
                                                <div className="col-12">
                                                    <ReactJson
                                                        collapsed={isResultsCollapsed}
                                                        src={locals}
                                                        enableClipboard={onLocalsCopy}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }

                                {panelDisplay.template &&
                                    <div id="template-panel" className={`col-sm-${panelDisplaySize.template} panel panel-default p-0 mr-2`}>
                                        <div className="panel-heading">
                                            <label className="font-bold">EJS Template:</label>

                                            <div className="float-right">
                                                <button onClick={togglePanel("template")} className="btn btn-default btn-sm">
                                                    <i className="fa fa-times" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    {/* <input
                                                        className="form-control mb-2"
                                                        placeholder="Generate a template using AI"
                                                    /> */}

                                                    <button
                                                        onClick={() => insertEJSForLoop({
                                                        })}
                                                        className="btn btn-xs"
                                                    >
                                                        <i className="fa fa-code" />
                                                    </button>


                                                    <div className="float-right">

                                                        <button
                                                            className="btn btn-xs btn-white"
                                                            onClick={clear}
                                                        >
                                                            Clear
                                                        </button>

                                                        <button
                                                            className="btn btn-xs btn-white"
                                                            onClick={minify}
                                                        >
                                                            Minify
                                                        </button>

                                                        <button
                                                            className="btn btn-xs btn-white"
                                                            onClick={prettify}
                                                        >
                                                            Prettify
                                                        </button>
                                                    </div>

                                                    {/* <input
                                                        className="form-control form-control-sm mb-2 mt-2"
                                                        placeholder="Tell the AI what to do"
                                                    /> */}

                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <Select
                                                        id="report-type"
                                                        onChange={handleReportTypeChange}
                                                        options={reportTypes}
                                                        placeholder="Select the type of report you would like to create"
                                                        className="mb-2"
                                                        value={reportTypes.find(type => type.value == reportType)}
                                                    />
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-12">
                                                    <Editor
                                                        onMount={handleEditorDidMount}
                                                        ref={editorRef}
                                                        language="html"
                                                        height="100vh"
                                                        value={template}
                                                        onChange={(value) => setTemplate(value)}
                                                        options={{
                                                            minimap: {
                                                                enabled: false
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {panelDisplay.rendered &&

                                    <div id="rendered-panel" className={`col-sm-${panelDisplaySize.rendered} panel panel-default p-0`} style={{ flexBasis: ((100 / 12 * panelDisplaySize.rendered) - 1) + "%" }}>
                                        <div className="panel-heading">
                                            <label className="font-bold">Rendered Report:</label>

                                            <div className="float-right">
                                                <button onClick={togglePanel("rendered")} className="btn btn-default btn-sm">
                                                    <i className="fa fa-times" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="panel-body">

                                            <div className="row mb-2">
                                                <div className="col-12">

                                                    <button
                                                        className="float-right btn btn-xs btn-white"
                                                        onClick={exportTemplateAsImage}
                                                    >
                                                        Export As Image
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">

                                                    <div id="rendered-template" className="border-left-right border-top-bottom">
                                                        {htmlToReactParser.parse(
                                                            renderedTemplate.rendered
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withLoaderData(connect(DataDelegator.mapStateToProps)(Designer));