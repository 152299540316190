const steps = [
    {
        target: `#applications-list`,
        title: "Applications",
        content: (
            <div>
                The applications that you have access to will show here.<br />
                <br />
                If you are unable to see an application you are expecting, check if the owner has granted you access and then reload the page.
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#search-applications`,
        title: "Search Application",
        content: (
            <div>
                If you have a lot of applications, you can type any part of the name of the application to find it faster.<br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#create-new-application`,
        title: "Create New Application",
        content: (
            <div>
                You can create a new application by clicking here at any time.<br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: "#toggle-help",
        content: (
            <div>
                Click on this button to view this tutorial again at any time.<br />
            </div>
        )
    }
];

export default steps;