import React from "react";
import { NumericFormat } from 'react-number-format';

export default function render(value) {
    if (value != null && value != undefined) {
        return <NumericFormat
            thousandSeparator={true}
            thousandsGroupStyle="lakh"
            prefix={"₹"}
            displayType={"text"}
            value={value}
        />;
    }

    return value;
}