import React from "react";
import moment from "moment-timezone";

import { connect } from "react-redux";

import axiosBackend from "../../../core/api/backend";

import withLoaderData from "../../../components/withLoaderData";

import * as DataDelegator from "../../../components/smart/delegator/DataDelegator";

import cronstrue from 'cronstrue';

var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();

class ScheduledTasks extends React.Component {
    constructor(props) {
        super(props);

        this.COMPONENT_STATES = {
            LOADING: "LOADING",
            LOADED: "LOADED",

            ERROR: "ERROR",
        }

        this.tabs = {
            "CONFIG": "config",
            "LOGS": "logs",
        };

        this.state = {
            tasks: [],
            settings: {},
            roles: {},

            logs: [],

            selectedTab: this.tabs.CONFIG,

            selectedLogIndex: null,
            selectedTask: null,
            selectedTaskPipeline: [],
            mappedDataForPipelineItems: {},


            selectedTaskStatus: this.COMPONENT_STATES.LOADED,
            status: this.COMPONENT_STATES.LOADING,
        };

        this.testRun = this.testRun.bind(this);
        this.selectTab = this.selectTab.bind(this);
        this.selectLog = this.selectLog.bind(this);

        this.loadTaskLogs = this.loadTaskLogs.bind(this);
    }

    componentDidMount() {
        DataDelegator.resolve(this.props, (err) => {
            if (err) {
                throw err;
            } else {
                this.loadData();
            }
        });
    }


    selectLog(index) {
        this.setState({
            selectedLogIndex: this.state.selectedLogIndex === index ? null : index,
        })
    }

    selectTab(tab) {
        let functionToRun = (tab === this.tabs.CONFIG) ? this.loadTaskPipeline : this.loadTaskLogs;

        this.setState({
            selectedTab: tab,
        }, functionToRun);
    }


    loadData() {
        Promise.all([
            axiosBackend({
                method: "POST",
                url: `/service/settings/${this.props.router.params.appUUID}/`,
            }),
            axiosBackend({
                method: "POST",
                url: `/service/cron-jobs/${this.props.router.params.appUUID}/`,
            }),
            // !TECHNICAL DEBT: This needs to find a better way because it is using another service
            axiosBackend({
                method: "POST",
                url: `/service/sites-that-users-control/roles/${this.props.router.params.appUUID}/`,
            })
        ]).then(([settings, tasks, roles]) => {
            const mappedSettings = settings.data.results.reduce((acc, setting) => {
                acc[setting.name] = setting.value;

                return acc;
            }, {});

            this.setState({
                status: this.COMPONENT_STATES.LOADED,
                tasks: tasks.data.results,
                settings: mappedSettings,

                roles: roles.data.results.reduce((acc, role) => {
                    acc[role.uuid] = role.name;

                    return acc;
                }, {}),
            });
        })
            .catch((err) => {
                console.error(err);

                this.setState({
                    status: this.COMPONENT_STATES.ERROR,
                })
            })
    }

    loadTaskPipeline() {
        axiosBackend({
            method: "POST",
            url: `/service/cron-jobs/${this.props.router.params.appUUID}/${this.state.selectedTask.uuid}`,
        })
            .then(async (response) => {
                const dataToLoad = response.data.results.map((item) => {
                    if (item.actionToExecute == "query") {
                        return axiosBackend({
                            method: "POST",
                            url: `/service/query-playground/${this.props.router.params.appUUID}/${item.action_uuid}`,
                        })
                    }

                    if (item.actionToExecute == "communication") {
                        return axiosBackend({
                            method: "POST",
                            url: `/service/communication-presets/${this.props.router.params.appUUID}/${item.action_uuid}`,
                        })
                    }
                }).filter((item => item != undefined));


                const allData = await Promise.all(dataToLoad);
                const mappedData = allData.reduce((acc, result) => {
                    if (result?.data?.status == "success") {
                        if (result?.data?.results?.length > 0) {
                            const dataToUse = result?.data?.results[0];

                            acc[dataToUse.uuid] = dataToUse
                        }
                    }

                    return acc;
                }, {});

                this.setState({
                    selectedTaskStatus: this.COMPONENT_STATES.LOADED,

                    selectedTaskPipeline: response.data.results.sort((a, b) => {
                        return a.order > b.order;
                    }),
                    mappedDataForPipelineItems: mappedData,
                });
            })
            .catch((err) => {
                console.error(err);

                this.setState({
                    selectedTaskStatus: this.COMPONENT_STATES.ERROR,
                })
            })
    }

    loadTaskLogs() {
        axiosBackend({
            method: "POST",
            url: `/service/cron-jobs/${this.props.router.params.appUUID}/${this.state.selectedTask.uuid}/logs`,
        })
            .then(async (response) => {
                this.setState({
                    selectedTaskStatus: this.COMPONENT_STATES.LOADED,

                    logs: response.data.results.reverse(),
                })
            })
            .catch((err) => {
                console.error(err);

                this.setState({
                    selectedTaskStatus: this.COMPONENT_STATES.ERROR,
                })
            })
    }

    selectTask(task) {
        this.setState({
            selectedTask: task,
            selectedTaskStatus: this.COMPONENT_STATES.LOADING,
        }, () => {
            this.selectTab(this.state.selectedTab);
        })
    }

    testRun() {
        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Confirm Test Run",
                "message": [
                    "Are you sure you would like to test run this scheduled task?",
                    "‏‏‎ ‎",
                    "NOTE: Any emails that are to be sent will be to your email address"
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Running Task",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "POST",
                            url: `/service/cron-jobs/${this.props.router.params.appUUID}/${this.state.selectedTask.uuid}/test`,
                        })
                            .then((response) => {
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            "Your task was test run successfully but there might have been errors in some of the stages",
                                            "‏‏‎ ‎",
                                            "Please check the task logs for more information."
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });
                            })
                            .catch((err) => {
                                console.error(err);
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Could not test run task",
                                        message: [
                                            "Due to an error, we were unable to test run your task successfully",
                                            "‏‏‎ ‎",
                                            "Please check the task logs for more information."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    render() {
        let tzOffset = 0;
        // if (this.state.settings.Timezone) {
        //     tzOffset = moment.tz(this.state.settings.Timezone).utcOffset() / 60; // Because it comes back in minutes
        // }

        return (
            <>
                <div className="mt-1 mb-3 col-sm-12">
                    <div className="row">
                        <div className="col-sm-8">
                            <button id="toggle-help" className="btn btn-gray" onClick={this.toggleHelp}>
                                <span className="far fa-question-circle fa-lg"></span>
                            </button>
                        </div>

                        <div className="col-sm-4 text-right">
                            {/* <Link to="./add">
                                <button type="button" className="mr-2 btn btn-primary float-right"><i className="fas fa-plus"></i>&nbsp;Add Scheduled Task</button>
                            </Link> */}
                        </div>
                    </div>

                </div>

                <div className="col-sm-8">
                    <div className="">
                        <div className="ibox-content">

                            <h2>Scheduled Tasks Management</h2>
                            <p>
                                Configure a sequence of events on your data to occur frequently at a given time
                            </p>

                            <table className="mt-4 table table-hover">
                                <thead className="font-bold">
                                    <td>Name</td>
                                    <td className="text-center">Actions</td>
                                </thead>
                                <tbody>
                                    {this.state.tasks.length == 0 ?
                                        <tr className="pointer">
                                            <td>
                                                No scheduled tasks found
                                            </td>
                                            <td></td>
                                        </tr>
                                        :
                                        this.state.tasks.map((task) => {
                                            return (
                                                <tr onClick={() => this.selectTask(task)} className="pointer" key={task.uuid}>
                                                    <td className="">
                                                        <span className="label label-primary">Active</span>&nbsp;
                                                        <span>{task.name}</span><br />
                                                        <br />
                                                        <br />

                                                        <div className="mt-1 small">
                                                            <i className="fa fa-user" />&nbsp;
                                                            Run as role <u className="">{this.state.roles[task.role_to_run_as]}</u>
                                                        </div>

                                                        <div className="mt-1 small">
                                                            <i className="fa fa-calendar-alt" />&nbsp;
                                                            {cronstrue.toString(task.cron_schedule, {
                                                                tzOffset
                                                            })}
                                                        </div>

                                                    </td>
                                                    <td className="text-right">
                                                        <button disabled={true} className="btn btn-primary btn-xs ml-2">
                                                            <span className="fa fa-edit"></span>&nbsp;Edit
                                                        </button>

                                                        <button disabled={true} className="btn btn-danger btn-xs ml-2">
                                                            <span className="fa fa-times"></span>&nbsp;Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="col-sm-4">
                    <div className="ibox-content">
                        <strong>Scheduled Task</strong>


                        {this.state.selectedTask &&
                            <button onClick={() => this.testRun()} className="btn btn-primary btn-xs float-right ml-2">
                                <i className="fa fa-play"></i>&nbsp;
                                Test Run
                            </button>
                        }
                        <hr />


                        {this.state.selectedTask == null ?
                            <div>Select a task to view its configuration, test run it or view its logs</div>
                            :
                            <>
                                <h4>{this.state.selectedTask.name}</h4>

                                <ul className="nav nav-tabs mt-4">
                                    <li>
                                        <a
                                            className={`nav-link ${this.state.selectedTab == this.tabs.CONFIG && "active show"}`}
                                            onClick={() => this.selectTab(this.tabs.CONFIG)}
                                        >
                                            <i className="fa fa-stream"></i> Task Configuration
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className={`nav-link ${this.state.selectedTab == this.tabs.LOGS && "active show"}`}
                                            onClick={() => this.selectTab(this.tabs.LOGS)}
                                        >
                                            <i className="fa fa-folder-open"></i> Task Logs
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div id="tab-config" className={`tab-pane ${this.state.selectedTab == this.tabs.CONFIG ? "active" : ""}`}>
                                        <div id="vertical-timeline" className="vertical-container dark-timeline ">
                                            {this.state.selectedTaskPipeline.map((item) => {
                                                const mappedData = this.state.mappedDataForPipelineItems[item.action_uuid];

                                                let icon = "";
                                                let text = "";

                                                switch (item.actionToExecute) {
                                                    case "query": {
                                                        icon = "fa fa-database";
                                                        text = "Run query from Query Playground";
                                                        break;
                                                    }

                                                    case "communication": {
                                                        if (mappedData) {
                                                            switch (mappedData.type) {
                                                                case "email": {
                                                                    icon = "fa fa-envelope";
                                                                    text = "Send email";
                                                                }
                                                            }
                                                        }

                                                        break;
                                                    }
                                                }

                                                return (
                                                    <div key={item.uuid}>
                                                        <div className="timeline-item mb-2">
                                                            <div className="row">
                                                                <div className="col-1 date">
                                                                    <i className={icon}></i>
                                                                </div>
                                                                <div className="col-11 content no-top-border">
                                                                    <p className="m-b"><strong>{text}</strong></p>

                                                                    <p>
                                                                        {item.actionToExecute == "query" && <>
                                                                            <span className="badge badge-info text-uppercase">{mappedData.operation}</span>&nbsp;
                                                                            {mappedData.name}
                                                                        </>
                                                                        }

                                                                        {item.actionToExecute == "communication" && mappedData.type == "email" && <>
                                                                            <div className="mb-2">
                                                                                <span className="font-bold">To:</span> {mappedData.to}
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <span className="font-bold">Subject:</span> {mappedData.subject}
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <span className="font-bold">Template:</span> {htmlToReactParser.parse(
                                                                                    mappedData.template
                                                                                )}
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <span className="font-bold">Attachments:</span> {mappedData.attachments}
                                                                            </div>


                                                                        </>
                                                                        }
                                                                    </p>
                                                                    <hr className="mt-4" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>

                                    <div id="tab-logs" className={`tab-pane ${this.state.selectedTab == this.tabs.LOGS ? "active" : ""}`}>
                                        {this.state.logs.length == 0 ? <div className="mt-2">No logs yet</div>
                                            :

                                            <>
                                                <table className="mb-0 table table-hover issue-tracker">

                                                    <tbody>
                                                        {this.state.logs.map((log, index) => {
                                                            return <tr onClick={() => this.selectLog(index)} className="pointer" key={index}>
                                                                <td>
                                                                    {moment(log.createdOn).tz(this.state.settings.Timezone).format('LLL')}

                                                                    {this.state.selectedLogIndex == index &&
                                                                        <div className="mt-3">
                                                                            <code>
                                                                                <pre>
                                                                                    {JSON.stringify(log, null, 4)}
                                                                                </pre>
                                                                            </code>
                                                                        </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>

                                                <button onClick={this.loadTaskLogs} className="mt-2 btn btn-default btn-block">
                                                    <i className="fa fa-redo-alt"></i>&nbsp;Reload Logs
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(ScheduledTasks));