import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";

import validator from "validator";

import simpleAuth from "../../core/auth/simpleAuth";
import axiosBackend from "../../core/api/backend";

import "../../scss/style.scss";
import "../../scss/components/login.scss";

import setWindowTitle from "../../core/utils/setWindowTitle";

export default function RequestConfirmAccount(props) {
    setWindowTitle("Request Confirm Account");

    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState("");

    const [signingInStatus, setSigningInStatus] = useState(false);
    const [signInError, setSignInError] = useState("");

    const [formSubmitted, setFormSubmitted] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (simpleAuth.isAuthenticated) {
            navigate("/");
        }
    }, [simpleAuth.isAuthenticated]);

    const onSubmit = async (e) => {
        e.preventDefault();
        setSignInError("");
        setSigningInStatus(true);

        await recaptchaRef.current.executeAsync();

        let allOkay = true;

        if (!validator.isEmail(username)) {
            allOkay = false;
            setUsernameError("Please enter a valid email address");
        }

        if (allOkay) {
            setSigningInStatus(true);
            setUsernameError("");
            setSignInError("");

            let response;

            try {
                response = await axiosBackend.post("authentication/request-confirm-account", {
                    email: username.trim(),
                });

                if (response.data.status === "success") {
                    console.log(response);
                    setFormSubmitted(true);

                    return;
                }

                // If we come to this point, the login was not successful for some reason anyway
                setSigningInStatus(false);
                setSignInError(
                    response.data.error || "An error occurred. Please try again in some time."
                );
            } catch (err) {
                console.error(err);

                setSigningInStatus(false);

                let errorSet = false;

                if (err.name == "AxiosError") {
                    if (err.response?.data?.status == "error") {
                        errorSet = true;

                        const errors = err.response.data.errors;
                        const messages = err.response.data.messages;

                        if (Array.isArray(errors)) {
                            const error = errors[0];

                            if (error.email) {
                                setUsernameError(error.email.messages.join(" "));
                            }
                        }

                        if (Array.isArray(messages)) {
                            setSignInError(messages.join(" "));
                        }
                    }
                }

                if (!errorSet) {
                    setSignInError("An error occurred. Please try again in some time.");
                }
            }
        } else {
            setSigningInStatus(false);
        }

        return false;
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
        setUsernameError("");
    }

    const recaptchaRef = React.createRef();


    return (
        <div>
            <div className="middle-box animated">
                <div className="ibox ibox-content mb-2 text-black">
                    <h1 className="mt-2 text-center">Request To Confirm Account</h1>

                    {formSubmitted ?
                        <div className="text-center mb-2">
                            <div className="pad-btm text-success"><i className="fas fa-check-circle fa-5x"></i></div>


                            Your account confirmation request been accepted. <br /><br />

                            If your email is registered with us, a confirmation email has been sent to you.<br />
                            Please click on the link in the email to complete the confirmation process.
                        </div>
                        :
                        <>
                            <div className="small text-center mb-4 ">If you forgot to confirm your account, you can request a new confirmation link here</div>

                            <form className="m-t b-1" role="form" onSubmit={onSubmit}>
                                {signInError.length > 0 && (
                                    <div className="alert alert-danger">{signInError}</div>
                                )}
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            <i className="fas fa-envelope"></i>
                                        </div>

                                        <input
                                            onChange={handleUsernameChange}
                                            type="text"
                                            className={
                                                "form-control " +
                                                (usernameError.length > 0 ? "is-invalid" : "")
                                            }
                                            name="username"
                                            placeholder="Enter your email address"
                                        />
                                        <div className="invalid-feedback text-normal">{usernameError}</div>
                                    </div>
                                </div>

                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                                    size="invisible"
                                />

                                <button
                                    disabled={signingInStatus}
                                    type="submit"
                                    className={`btn btn-login ${signingInStatus ? "btn-danger" : ""
                                        }  block full-width`}>
                                    {signingInStatus ? "Requesting Confirmation..." : "Request Confirmation Email"}
                                </button>
                            </form>
                        </>
                    }
                </div>
            </div>
            <div className="container">
                <div className="row mt-0 text-center justify-content-center">
                    <Link className="col-xs-3" to="/signup">
                        <div className="helper inline">
                            <>Signup</>
                        </div>
                    </Link>

                    <Link className="col-xs-3" to="/forgot-password">
                        <div className="helper inline">
                            <>Forgot password?</>
                        </div>
                    </Link>

                    <Link className="col-xs-3" to="/login">
                        <div className="helper inline">
                            <>Login</>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
