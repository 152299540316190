import RootTemplate from "../pages/templates/template";
import NavbarTemplate from '../pages/templates/navbar';
import BreadcrumbsTemplate from '../pages/templates/breadcrumbs';
import ImageBgTemplate from '../pages/templates/imagebg';

import ErrorPage from "../pages/error";

import Landing, { loader as landingLoader } from "../pages/index";

import AppDashboard, { loader as appDashboardLoader } from "../pages/app";

import Login from "../pages/authentication/login";
import Logout from '../pages/authentication/logout';
import Signup from '../pages/authentication/signup';
import ConfirmAccount from '../pages/authentication/confirm-account';
import ForgotPassword from '../pages/authentication/forgot-password';
import SetForgottenPassword from '../pages/authentication/set-forgotten-password';
import RequestConfirmAccount from '../pages/authentication/request-confirm-account';
import SetFirstAndLastName from '../pages/authentication/set-first-and-last-name';

import ResourceRoutes from './resources-routes';
import UsersManagementRouter from './users-management-router';
import ReportsRouter from './reports-router';
import QueryPlaygroundRouter from './query-playground-router';

import StackManagementRouter from './stack-management-router';
import APIAccessManagementRouter from './api-access-management-router';
import MyAccountRouter from './my-account-router';
import ScheduledTasksRouter from './scheduled-tasks-router';
import SettingsRouter from './settings-router';

import SupportRequestsRouter from "./support-requests-router";

import CompanyDashboard from "pages/company";
import CreateNewApplication from '../pages/create-new-application';

import simpleAuth from '../core/auth/simpleAuth';
import { Navigate } from "react-router-dom";
import TriggersRouter from "./triggers-router";


const routes = () => [
    {
        errorElement: <ErrorPage />,
        children: [
            {
                element: <ImageBgTemplate />,
                children: [
                    {
                        path: "/forgot-password",
                        element: <ForgotPassword />,
                    },

                    {
                        path: "/reset-password/:hash1/:hash2",
                        element: <SetForgottenPassword />,
                    },

                    {
                        path: "/request-confirm-account",
                        element: <RequestConfirmAccount />,
                    },

                    {
                        path: "/confirm-account/:hash1/:hash2",
                        element: <ConfirmAccount />,
                    },

                    {
                        path: "/login",
                        element: <Login />,
                    },

                    {
                        path: "/signup",
                        element: <Signup />,
                    },

                    {
                        path: "/logout",
                        element: <Logout />,
                    },
                ]
            },

            {
                children: [
                    {
                        element: <RootTemplate />,

                        children: [
                            {
                                path: "/complete-signup",
                                element: <SetFirstAndLastName />,
                            },

                            {
                                element: (
                                    simpleAuth.first_name().trim() != "" && simpleAuth.last_name().trim() != ""
                                        ?
                                        <RootTemplate />
                                        :
                                        <Navigate to="/complete-signup" />
                                ),

                                children: [
                                    {
                                        element: <NavbarTemplate />,

                                        children: [
                                            {
                                                index: true,
                                                element: <Landing />,
                                                loader: landingLoader,
                                            },

                                            StackManagementRouter(),

                                            SupportRequestsRouter(),

                                            {
                                                path: "/company",
                                                element: <CompanyDashboard />,
                                            },

                                            {
                                                path: "/create-new-application",
                                                element: <CreateNewApplication />,
                                            },

                                            {
                                                element: <BreadcrumbsTemplate />,

                                                children: [
                                                    MyAccountRouter(),

                                                    {
                                                        path: "/app/:appUUID",

                                                        children: [
                                                            {
                                                                children: [
                                                                    {
                                                                        index: true,
                                                                        element: <AppDashboard />,
                                                                        loader: appDashboardLoader,
                                                                    },


                                                                    UsersManagementRouter(),

                                                                    APIAccessManagementRouter(),

                                                                    ReportsRouter(),

                                                                    ResourceRoutes(),
                                                                    
                                                                    QueryPlaygroundRouter(),
                                                                    
                                                                    TriggersRouter(),

                                                                    ScheduledTasksRouter(),

                                                                    SettingsRouter(),
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }

                        ]
                    },
                ]
            },
        ],
    },
]

export default routes;