import React from "react";

import { connect } from "react-redux";
import * as DataDelegator from "../../components/smart/delegator/DataDelegator";

import { Line, getElementAtEvent } from 'react-chartjs-2';
import moment from "moment";
import axiosBackend, { fetchBackend } from "../../core/api/backend";


import ndjsonStream from 'can-ndjson-stream';
import Select from "react-select";
import API_STATES from "../../api-states";
import Loader from "../../components/Loader";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const toMeasure = {
    all: 0,

    create: 0,
    find: 0,

    update: 0,
    delete: 0,

    aggregate: 0,
};

const toMeasureColors = {
    all: "",

    create: "#4361ee",
    find: "#80ed99",

    update: "#fee440",
    delete: "rgb(255, 99, 132)",

    aggregate: "#ffafcc",
}



class MysqlStackManagement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            logsStatus: API_STATES.LOADING,
            allLogs: [],
            mysqlPerformance: {
                average: {
                    ...toMeasure,
                }
            },
            logsComputation: {},
            allMinutes: [],

            showOnMysqlChart: [
                ...Object.keys(toMeasure),
            ],
            showOnUnfilteredMysqlChart: "all",

            listOfLogFolders: [],
            selectedLogDate: null,
        };

        this.mysqlChartDataNonComputedRef = React.createRef();

        this.changeSelectedLogDate = this.changeSelectedLogDate.bind(this);
    }

    async loadMysqlPerfLogs() {
        console.log("StackManagement.loadMysqlPerfLogs");

        const response = await fetchBackend("/administration/logging/mysql-perf", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                selectedLogDate: this.state.selectedLogDate?.value,
            })
        });
        const exampleReader = ndjsonStream(response.body).getReader();

        let allLogs = [];
        let result;
        while (!result || !result.done) {
            result = await exampleReader.read();

            if (!result.done) {
                const logMessage = JSON.parse(result.value);

                let elapsedTime = logMessage?.message?.queryElapsedTime;
                if (elapsedTime) {
                    elapsedTime = elapsedTime.trim();

                    const seconds = elapsedTime.substr(0, elapsedTime.indexOf("s"));
                    const ms = elapsedTime.substr(elapsedTime.indexOf("s") + 1, elapsedTime.length - 5);

                    const totalElapsedTimeInMs = ((parseFloat(seconds) * 1000) + parseFloat(ms));
                    logMessage.message.totalElapsedTimeInMs = totalElapsedTimeInMs;
                }

                allLogs.push(logMessage)
            }
        }

        const logsComputation = allLogs.reduce((acc, currentLog) => {
            acc.measurement.all += currentLog.message.totalElapsedTimeInMs;
            acc.measurements.all.push(currentLog);

            if (currentLog.message.type) {
                const type = currentLog.message.type;

                switch (type) {
                    case "create":
                    case "find":
                    case "update":
                    case "delete":
                    case "aggregate": {
                        acc.measurement[type] += currentLog.message.totalElapsedTimeInMs;
                        acc.measurements[type].push(currentLog);

                        break;
                    }

                    default: {
                        console.log(`Unknown query type ${type}`);
                        break;
                    }
                }
            }

            // Measure granually
            const currentGranule = moment(currentLog.timestamp, "YYYY-MM-DD HH:mm:ss:SSSS ZZ").minutes();

            if (acc.granually[currentGranule] == undefined) {
                acc.granually[currentGranule] = Object.keys(toMeasure).reduce((acc, key) => {
                    acc[key] = {
                        measurement: 0,
                        logs: []
                    };
                    return acc;
                }, {});
            }

            acc.granually[currentGranule].all.measurement += currentLog.message.totalElapsedTimeInMs;
            acc.granually[currentGranule].all.logs.push(currentLog);

            if (currentLog.message.type) {
                const type = currentLog.message.type;

                switch (type) {
                    case "create":
                    case "find":
                    case "update":
                    case "delete":
                    case "aggregate": {
                        acc.granually[currentGranule][type].measurement += currentLog.message.totalElapsedTimeInMs;
                        acc.granually[currentGranule][type].logs.push(currentLog);

                        break;
                    }

                    default: {
                        console.log(`Unknown query type for granule measurement ${type}`);
                        break;
                    }
                }
            }

            return acc;
        }, {
            measurement: {
                ...toMeasure,
            },
            measurements: {
                ...Object.keys(toMeasure).reduce((acc, key) => {
                    acc[key] = [];
                    return acc;
                }, {}),
            },
            granually: {

            },
        });

        const mysqlPerformance = JSON.parse(JSON.stringify(this.state.mysqlPerformance));

        mysqlPerformance.average.all = (logsComputation.measurement.all / logsComputation.measurements.all.length);

        mysqlPerformance.average.create = (logsComputation.measurement.create / logsComputation.measurements.create.length);
        mysqlPerformance.average.find = (logsComputation.measurement.find / logsComputation.measurements.find.length);
        mysqlPerformance.average.update = (logsComputation.measurement.update / logsComputation.measurements.update.length);
        mysqlPerformance.average.delete = (logsComputation.measurement.delete / logsComputation.measurements.delete.length);
        mysqlPerformance.average.aggregate = (logsComputation.measurement.aggregate / logsComputation.measurements.aggregate.length);

        // Validate that they are all not NaN
        mysqlPerformance.average = Object.keys(mysqlPerformance.average).reduce((acc, key) => {
            if (isNaN(mysqlPerformance.average[key])) {
                acc[key] = "-";
            } else {
                acc[key] = mysqlPerformance.average[key].toFixed(2);
            }

            return acc;
        }, {});

        this.setState({
            allLogs,
            logsComputation,
            mysqlPerformance,
            logsStatus: API_STATES.LOADED,
        });
    }

    componentDidMount() {
        DataDelegator.resolve(this.props, async () => {

            const listOfLogFolders = await axiosBackend({
                method: "POST",
                url: "/administration/logging/list",
            });

            this.loadMysqlPerfLogs();

            this.setState({
                listOfLogFolders: listOfLogFolders.data.results,
            })
        });
    }


    toggleOnMysqlChart(label) {
        this.setState((state) => {
            let showOnMysqlChart = [
                ...state.showOnMysqlChart,
            ]

            const index = showOnMysqlChart.indexOf(label);

            if (index > -1) {
                showOnMysqlChart.splice(index, 1);
            } else {
                showOnMysqlChart.push(label);
            }

            return {
                showOnMysqlChart,
            };
        })
    }

    

    changeSelectedLogDate(selectedLogDate) {
        console.log("StackManagement.changeSelectedLogDate");

        this.setState({
            logsStatus: API_STATES.LOADING,
            selectedLogDate
        }, this.loadMysqlPerfLogs)
    }



    render() {
        
        const mysqlChartData = {
            labels: [],
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                    },

                }
            }
        }

        const mysqlChartDataNonComputed = {
            labels: [],
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                    },

                }
            }
        };

        if (this.state.allLogs.length > 0) {
            const sortedLabels = Object.keys(this.state.logsComputation.granually).sort((a, b) => {
                return parseInt(a) - parseInt(b);
            });

            mysqlChartData.labels.push(...sortedLabels);

            mysqlChartData.datasets = [];

            this.state.showOnMysqlChart.includes("all") && mysqlChartData.datasets.push(
                {
                    label: 'Average Per Minute',
                    data: sortedLabels.map((granule) => {
                        if (this.state.logsComputation.granually[granule].all) {
                            return this.state.logsComputation.granually[granule].all.measurement / this.state.logsComputation.granually[granule].all.logs.length;
                        } else {
                            return 0;
                        }
                    }),
                }
            );

            this.state.showOnMysqlChart.includes("create") && mysqlChartData.datasets.push(
                {
                    label: 'Create Per Minute',
                    data: sortedLabels.map((granule) => {
                        if (this.state.logsComputation.granually[granule].create) {
                            return this.state.logsComputation.granually[granule].create.measurement / this.state.logsComputation.granually[granule].create.logs.length;
                        } else {
                            return 0;
                        }
                    }),
                    borderColor: toMeasureColors.create,
                    backgroundColor: toMeasureColors.create,
                }
            );

            this.state.showOnMysqlChart.includes("find") && mysqlChartData.datasets.push(

                {
                    label: 'Read Per Minute',
                    data: sortedLabels.map((granule) => {
                        if (this.state.logsComputation.granually[granule].find) {
                            return this.state.logsComputation.granually[granule].find.measurement / this.state.logsComputation.granually[granule].find.logs.length;
                        } else {
                            return 0;
                        }
                    }),
                    borderColor: toMeasureColors.find,
                    backgroundColor: toMeasureColors.find,
                }
            );

            this.state.showOnMysqlChart.includes("update") && mysqlChartData.datasets.push(

                {
                    label: 'Update Per Minute',
                    data: sortedLabels.map((granule) => {
                        if (this.state.logsComputation.granually[granule].update) {
                            return this.state.logsComputation.granually[granule].update.measurement / this.state.logsComputation.granually[granule].update.logs.length;
                        } else {
                            return 0;
                        }
                    }),
                    borderColor: toMeasureColors.update,
                    backgroundColor: toMeasureColors.update,
                }
            );

            this.state.showOnMysqlChart.includes("delete") && mysqlChartData.datasets.push(

                {
                    label: 'Delete Per Minute',
                    data: sortedLabels.map((granule) => {
                        if (this.state.logsComputation.granually[granule].delete) {
                            return this.state.logsComputation.granually[granule].delete.measurement / this.state.logsComputation.granually[granule].delete.logs.length;
                        } else {
                            return 0;
                        }
                    }),
                    borderColor: toMeasureColors.delete,
                    backgroundColor: toMeasureColors.delete,
                }
            );

            this.state.showOnMysqlChart.includes("aggregate") && mysqlChartData.datasets.push(
                {
                    label: 'Aggregate Per Minute',
                    data: sortedLabels.map((granule) => {
                        if (this.state.logsComputation.granually[granule].aggregate) {
                            return this.state.logsComputation.granually[granule].aggregate.measurement / this.state.logsComputation.granually[granule].aggregate.logs.length;
                        } else {
                            return 0;
                        }
                    }),
                    borderColor: toMeasureColors.aggregate,
                    backgroundColor: toMeasureColors.aggregate,
                }
            );



            mysqlChartDataNonComputed.datasets = [];
            mysqlChartDataNonComputed.labels = [];

            if (this.state.showOnUnfilteredMysqlChart != undefined) {
                let filteredData = this.state.allLogs;

                if (this.state.showOnUnfilteredMysqlChart != "all") {
                    filteredData = filteredData.filter((logMessage) => logMessage.message.type == this.state.showOnUnfilteredMysqlChart);

                    mysqlChartDataNonComputed.labels = filteredData.map((logMessage) => {
                        return logMessage.timestamp;
                    });

                    mysqlChartDataNonComputed.datasets.push(
                        {
                            label: this.state.showOnUnfilteredMysqlChart,
                            originalData: filteredData,
                            data: filteredData.map((logMessage) => {
                                return logMessage.message.totalElapsedTimeInMs;
                            }),
                            borderColor: toMeasureColors[this.state.showOnUnfilteredMysqlChart],
                            backgroundColor: toMeasureColors[this.state.showOnUnfilteredMysqlChart],
                        }
                    );
                } else {
                    const labels = new Set();

                    Object.keys(toMeasure).forEach((label) => {
                        if (label == "all") {
                            return;
                        }

                        filteredData = this.state.allLogs.filter((logMessage) => logMessage.message.type == label);
                        const data = filteredData.map((logMessage) => {
                            labels.add(logMessage.timestamp);

                            return logMessage.message.totalElapsedTimeInMs
                        });

                        mysqlChartDataNonComputed.datasets.push(
                            {
                                label: label,
                                originalData: filteredData,
                                data,
                                borderColor: toMeasureColors[label],
                                backgroundColor: toMeasureColors[label],
                            }
                        );
                    });

                    mysqlChartDataNonComputed.labels = Array.from(labels);
                }
            }
        }

        const logFolderOptions = this.state.listOfLogFolders.map((folder) => {
            return {
                value: folder,
                label: folder,
            }
        }).sort((a, b) => b.value.localeCompare(a.value));

        const selectedLogDateOption = logFolderOptions.find((option) => option.value == this.state.selectedLogDate?.value)

        return (
            <>
                <div className="col-sm-6">
                    <div className="mb-4">
                        <div className="ibox-title pl-4">
                            <h3><i className="fas fa-chart-area"></i>&nbsp;MySQL Performance</h3>
                            <div className="ibox-tools">
                                <a className="collapse-link">
                                    <i className="fa fa-chevron-up"></i>
                                </a>
                            </div>
                        </div>

                        <div className="ibox-content pt-0">
                            <div className="row">
                                <div className="col-md-12 mt-2">

                                    <Select
                                        onChange={this.changeSelectedLogDate}
                                        value={selectedLogDateOption}
                                        options={logFolderOptions}
                                        menuShouldScrollIntoView={true}
                                        placeholder="Select an available log date in UTC"
                                    />

                                    <div className="row">
                                        <div className="col-md-12">

                                            {this.state.logsStatus == API_STATES.LOADING && <div className="mt-2 ml-2"><Loader /></div>}

                                            {this.state.logsStatus == API_STATES.LOADED && this.state.allLogs.length == 0 && <>
                                                <div className="mt-2">
                                                    No logs found
                                                </div>
                                            </>}

                                            {this.state.logsStatus == API_STATES.LOADED && this.state.allLogs.length > 0 &&
                                                <>
                                                    <div className="mt-2">
                                                        <div className="row">
                                                            <div className='col-sm-6'>
                                                                {Object.keys(toMeasure).map((label) => {
                                                                    return <div
                                                                        onClick={() => this.setState({
                                                                            showOnUnfilteredMysqlChart: label,
                                                                        })}
                                                                        key={label}
                                                                        className={`badge ${this.state.showOnUnfilteredMysqlChart == label ? "badge-dark" : "badge-disable"} mr-2 pointer`}
                                                                        style={{ backgroundColor: this.state.showOnUnfilteredMysqlChart == label && toMeasureColors[label] }}
                                                                    >
                                                                        <div className="row">

                                                                            <div className="col-9">
                                                                                {label}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                })}
                                                            </div>
                                                        </div>

                                                    </div>


                                                    {this.state.showOnUnfilteredMysqlChart == undefined ?
                                                        <>
                                                            <div className="mt-4">Pick a log type to view</div>
                                                        </>
                                                        :
                                                        mysqlChartDataNonComputed.labels.length == 0 ?
                                                            <div className="mt-4">No {this.state.showOnUnfilteredMysqlChart} logs found</div>
                                                            :
                                                            <div className="mt-2">
                                                                {mysqlChartDataNonComputed.labels.length > 0 && <Line
                                                                    ref={this.mysqlChartDataNonComputedRef}
                                                                    options={mysqlChartDataNonComputed.options}
                                                                    data={mysqlChartDataNonComputed}
                                                                    onClick={(event) => {
                                                                        const element = getElementAtEvent(this.mysqlChartDataNonComputedRef?.current, event);

                                                                        if (element.length > 0) {
                                                                            console.log(element)
                                                                            const datasetIndex = element[0].datasetIndex;
                                                                            const index = element[0].index;

                                                                            const logMessage = mysqlChartDataNonComputed.datasets[datasetIndex].originalData[index];

                                                                            this.props.dispatch({
                                                                                type: "ResolvedData",
                                                                                name: "ModalData",
                                                                                data: {
                                                                                    show: true,
                                                                                    type: "success",
                                                                                    title: "Log Message",
                                                                                    message: [
                                                                                        JSON.stringify(logMessage, null, 4)
                                                                                    ],
                                                                                    okayButtonText: "Okay",
                                                                                },
                                                                            });
                                                                        }
                                                                    }}
                                                                />}
                                                            </div>
                                                    }
                                                </>
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="col-sm-6">
                    <div className="mb-4">
                        <div className="ibox-title pl-4">
                            <h3><i className="fas fa-chart-area"></i>&nbsp;Average MySQL Performance</h3>
                            <div className="ibox-tools">
                                <a className="collapse-link">
                                    <i className="fa fa-chevron-up"></i>
                                </a>
                            </div>
                        </div>

                        <div className="ibox-content pt-0">

                            <div className="row mt-4">
                                <div className="col-md-12">
                                    {this.state.logsStatus == API_STATES.LOADING && <div className="mt-2 ml-2"><Loader /></div>}

                                    {this.state.logsStatus == API_STATES.LOADED && this.state.allLogs.length == 0 && <>
                                        <div className="mt-2">
                                            No logs found
                                        </div>
                                    </>}

                                    {this.state.logsStatus == API_STATES.LOADED && this.state.allLogs.length > 0 && <>
                                        <div className="mt-2">
                                            <div className="row">
                                                <div className='col-sm-6'>
                                                    {Object.keys(toMeasure).map((label) => {
                                                        return <div
                                                            onClick={() => this.toggleOnMysqlChart(label)}
                                                            key={label}
                                                            className={`badge ${this.state.showOnMysqlChart.includes(label) ? "badge-dark" : "badge-disable"} mr-2 pointer`}
                                                            style={{ backgroundColor: this.state.showOnMysqlChart.includes(label) && toMeasureColors[label] }}
                                                        >
                                                            <div className="row">

                                                                <div className="col-9">
                                                                    {label}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    })}
                                                </div>

                                                <div className='col-sm-6'>

                                                    <div className="float-right text-right">
                                                        <small>
                                                            Average Query Speed
                                                        </small>
                                                        <br />
                                                        {this.state.mysqlPerformance?.average?.all} ms
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="mt-2">
                                            {mysqlChartData.labels.length > 0 && <Line options={mysqlChartData.options} data={mysqlChartData} />}
                                        </div>

                                        <hr className="mt-4" />
                                        <div className="mt-2">
                                            <div className="row text-center">
                                                <div className="col">
                                                    <div className=" m-l-md">
                                                        <span className="h5 font-bold m-t block">
                                                            {this.state.mysqlPerformance?.average?.create || "-"}

                                                            {this.state.mysqlPerformance?.average?.create > 0 ? " ms" : ""}
                                                        </span>
                                                        <small className="text-muted m-b block">C speed average</small>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <span className="h5 font-bold m-t block">
                                                        {this.state.mysqlPerformance?.average?.find || "-"}

                                                        {this.state.mysqlPerformance?.average?.find > 0 ? " ms" : ""}
                                                    </span>
                                                    <small className="text-muted m-b block">R speed average</small>
                                                </div>
                                                <div className="col">
                                                    <span className="h5 font-bold m-t block">
                                                        {this.state.mysqlPerformance?.average?.update || "-"}

                                                        {this.state.mysqlPerformance?.average?.update > 0 ? " ms" : ""}
                                                    </span>
                                                    <small className="text-muted m-b block">U speed average</small>
                                                </div>

                                                <div className="col">
                                                    <span className="h5 font-bold m-t block">
                                                        {this.state.mysqlPerformance?.average?.delete || "-"}

                                                        {this.state.mysqlPerformance?.average?.delete > 0 ? " ms" : ""}
                                                    </span>
                                                    <small className="text-muted m-b block">D speed average</small>
                                                </div>

                                                <div className="col">
                                                    <span className="h5 font-bold m-t block">
                                                        {this.state.mysqlPerformance?.average?.aggregate || "-"}

                                                        {this.state.mysqlPerformance?.average?.aggregate > 0 ? " ms" : ""}
                                                    </span>
                                                    <small className="text-muted m-b block">A speed average</small>
                                                </div>
                                            </div>


                                        </div>
                                    </>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default connect(DataDelegator.mapStateToProps)(MysqlStackManagement);