import React from "react";

import {
    Link,
    Outlet,
} from "react-router-dom";
import ImageBg from "../../components/ImageBg";

function ImageBgTemplate(props) {
    return (
        <>
            <div className="container-fluid pad-no container-login">
                <div id="bg-overlay">
                    <ImageBg></ImageBg>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Link id="brand-logo-white" className="hamburger ml-2" to="/">
                            <h3 id="brand-logo-white" className="text-white">ROBOSTACK</h3>
                        </Link>
                    </div>
                </div>

                <Outlet />
            </div>
        </>
    );
}

export default ImageBgTemplate;