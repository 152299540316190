import moment from "moment";
import React from "react";

import update from "immutability-helper";

import { connect } from "react-redux";

import supportStatusLabelColor from "./supportStatusLabelColor";
import axiosBackend from "core/api/backend";
import mapStateToProps from "components/smart/delegator/mapStateToProps";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import ErrorPage from "pages/error";
import ToTitleCase from "core/utils/toTitleCase";
import MagicForm from "components/smart/supported/MagicForm";

const TrLink = React.forwardRef(({ navigate, ...props }, ref) => {
    if (props.className == undefined) {
        props.className = "";
    }

    props.className += ' pointer';
    return (
        <tr ref={ref} {...props} onClick={navigate}>
            {props.children}
        </tr>
    )
})

class SupportAndTraining extends React.Component {
    constructor(props) {
        super(props);

        this.COMPONENT_STATES = {
            CREATE_CONFIRMED: "CREATE_CONFIRMED",
            CREATE: "CREATE",
            LIST: "LIST",
            ERROR: "ERROR",
        }

        this.state = {
            show: this.COMPONENT_STATES.LIST,
            issuesLoading: true,
            issues: [],

            search: "",
            searchKeys: ["title", "category"],

            docs: [
                // {
                //     "title": "Field Types",
                //     "link": "/docs/applications/field-types",
                //     "type": "article",
                //     "category": "Applications",
                // },
                // {
                //     "title": "Teams & Roles",
                //     "link": "/docs/applications/teams-and-roles",
                //     "type": "article",
                //     "category": "Applications",
                // },
                // {
                //     "title": "Scheduled Tasks",
                //     "link": "/docs/applications/scheduled-tasks",
                //     "type": "video",
                //     "category": "Applications",
                // },
                // {
                //     "title": "Triggers",
                //     "link": "/docs/applications/triggers",
                //     "type": "article",
                //     "category": "Applications",
                // },


                // {
                //     "title": "What are resources?",
                //     "link": "/docs/resources/what-are-resources",
                //     "type": "article",
                //     "category": "Resources",
                // },
                // {
                //     "title": "Creating a new resource",
                //     "link": "/docs/resources/creating-a-resource",
                //     "type": "video",
                //     "category": "Resources",
                // },
                // {
                //     "title": "Editing an existing resource",
                //     "link": "/docs/resources/editing-a-resource",
                //     "type": "video",
                //     "category": "Resources",
                // },
                // {
                //     "title": "Linking different resources",
                //     "link": "/docs/resources/linking-resources",
                //     "type": "video",
                //     "category": "Resources",
                // },

                // {
                //     "title": "What are reports?",
                //     "link": "/docs/reports/what-are-reports",
                //     "type": "article",
                //     "category": "Reports",
                // },
                // {
                //     "title": "Creating a new report",
                //     "link": "/docs/reports/creating-a-report",
                //     "type": "video",
                //     "category": "Reports",
                // },
                // {
                //     "title": "Sharing reports",
                //     "link": "/docs/reports/sharing-reports",
                //     "type": "video",
                //     "category": "Reports",
                // },
                // {
                //     "title": "Recurring reports",
                //     "link": "/docs/reports/recurring-reports",
                //     "type": "video",
                //     "category": "Reports",
                // },



                // {
                //     "title": "What are automations?",
                //     "link": "/docs/automations/what-are-automations",
                //     "type": "article",
                //     "category": "Automations",
                // },
                // {
                //     "title": "Creating a new automation",
                //     "link": "/docs/automations/creating-an-automation",
                //     "type": "article",
                //     "category": "Automations",
                // },


                // {
                //     "title": "What are workflows?",
                //     "link": "/docs/workflows/what-are-workflows",
                //     "type": "article",
                //     "category": "Workflows",
                // },
                // {
                //     "title": "Creating a new workflow",
                //     "link": "/docs/workflows/creating-a-workflow",
                //     "type": "video",
                //     "category": "Workflows",
                // },
                // {
                //     "title": "Testing workflows",
                //     "link": "/docs/workflows/testing-a-workflow",
                //     "type": "video",
                //     "category": "Workflows",
                // },
                // {
                //     "title": "Integrating workflows",
                //     "link": "/docs/workflows/integration",
                //     "type": "video",
                //     "category": "Workflows",
                // },


                // {
                //     "title": "What are smart applications?",
                //     "link": "/docs/smart-applications/what-are-smart-applications",
                //     "type": "article",
                //     "category": "Smart Applications",
                // },
                // {
                //     "title": "Building smart applications",
                //     "link": "/docs/smart-applications/building",
                //     "type": "video",
                //     "category": "Smart Applications",
                // },


                // {
                //     "title": "Why whitelabel?",
                //     "link": "/docs/whitelabel/why",
                //     "type": "article",
                //     "category": "Whitelabeling",
                // },
                // {
                //     "title": "Enabling for applications",
                //     "link": "/docs/whitelabel/enabling-for-applications",
                //     "type": "video",
                //     "category": "Whitelabeling",
                // },
                // {
                //     "title": "Enabling for teams",
                //     "link": "/docs/whitelabel/enabling-for-teams",
                //     "type": "video",
                //     "category": "Whitelabeling",
                // },
                // {
                //     "title": "Enabling for organizations",
                //     "link": "/docs/whitelabel/enabling-for-organizations",
                //     "type": "video",
                //     "category": "Whitelabeling",
                // },



                {
                    "title": "How to access the API",
                    "link": "https://www.robostack.ai/features/rest-api/",
                    "type": "article",
                    "category": "API",
                },
                // {
                //     "title": "REST API Documentation",
                //     "link": "/docs/api/rest-documentation",
                //     "type": "article",
                //     "category": "API",
                // },
                // {
                //     "title": "Using the API Playground",
                //     "link": "/docs/api/using-the-api-playground",
                //     "type": "video",
                //     "category": "API",
                // },

            ]
        };

        this.toggleSupportDisplay = this.toggleSupportDisplay.bind(this);
        this.search = this.search.bind(this);
    }

    loadRequests() {
        axiosBackend({
            method: "POST",
            url: `/support-requests`,
        })
            .then((response) => {
                this.setState({
                    issues: response.data.results.sort((a, b) => {
                        if (b.status === "resolved") {
                            return -1;
                        }

                        return 0;
                    }),
                }, async () => {
                    await this.loadApplicationsIdentifiers()
                    await this.loadUsersIdentifiers();
                });
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    show: this.COMPONENT_STATES.ERROR,
                })
            })
    }

    loadApplicationsIdentifiers() {
        const config = {
            method: "POST",
            url: `/apps`
        };

        return axiosBackend(config)
            .then((results) => {

                let stateToUpdate = this.state;

                this.state.issues.forEach((row, rowIndex) => {
                    const preLookedUpValue = row.site_uuid;

                    // Find the value
                    const lookedUpValue = results.data.results.filter((lookedUpData) => {
                        return lookedUpData.uuid == preLookedUpValue;
                    }).pop();


                    if (lookedUpValue !== undefined) {
                        stateToUpdate = update(stateToUpdate, {
                            issues: {
                                [rowIndex]: {
                                    site_uuid: {
                                        $set: `${lookedUpValue.name}`,
                                    }
                                }
                            }
                        })
                    }
                })

                this.setState(stateToUpdate);
            })
            .catch((err) => {
                console.error(err);
                // We still want the data to show even if the uuids are exposed so do nothing
            })
    }

    /**
     * Load the correct values for all the user fields available for the all the support requests
     * @returns Promise
     */
    loadUsersIdentifiers() {
        const config = {
            method: "POST",
            url: `/support-requests/users`
        };

        return axiosBackend(config)
            .then((results) => {
                const usersFields = ["createdBy", "updatedBy"];

                let stateToUpdate = this.state;

                this.state.issues.forEach((row, rowIndex) => {
                    usersFields.forEach((column) => {
                        const preLookedUpValue = row[column];

                        // Find the value
                        const lookedUpValue = results.data.results.filter((lookedUpData) => {
                            return lookedUpData.uuid == preLookedUpValue;
                        }).pop();


                        if (lookedUpValue !== undefined) {
                            stateToUpdate = update(stateToUpdate, {
                                issues: {
                                    [rowIndex]: {
                                        [column]: {
                                            $set: `${lookedUpValue.first_name} ${lookedUpValue.last_name}`,
                                        }
                                    }
                                }
                            })
                        }
                    })
                })

                stateToUpdate = update(stateToUpdate, {
                    issuesLoading: {
                        $set: false,
                    },
                })

                this.setState(stateToUpdate);
            })
            .catch((err) => {
                console.error(err);
                // We still want the data to show even if the uuids are exposed so do nothing
            })
    }

    componentDidMount() {
        this.loadRequests();
    }

    toggleSupportDisplay() {
        this.setState((currentState) => {
            let show = this.COMPONENT_STATES.LIST;

            if (currentState.show === this.COMPONENT_STATES.LIST) {
                show = this.COMPONENT_STATES.CREATE;
            };

            return {
                show
            }
        })
    }

    search(event) {
        const value = event.target.value.trim().toLowerCase();

        const docs = [...this.state.docs]
            .map((item) => {
                item.hidden = (
                    value.length > 0 &&
                    this.state.searchKeys.every((search) => item[search] !== undefined && item[search] !== null && item[search].toLowerCase().indexOf(value) == -1)
                );
                return item;
            });

        this.setState({
            search: value,
            docs
        });
    }

    render() {
        const docs = {};

        const docsCategories = [...this.state.docs.reduce((acc, doc) => {
            if (doc.hidden == true) {
                return acc;
            }

            acc.add(doc.category);

            if (docs[doc.category] == undefined) {
                docs[doc.category] = [];
            }

            docs[doc.category].push(doc);

            return acc;
        }, new Set())];

        return (
            <div className="col-sm-12">
                <div className="row ">
                    <div className="col-lg-12 pb-2">

                        <button className="btn btn-gray">
                            <span className="far fa-question-circle fa-lg"></span>
                        </button>
                    </div>


                    <div className="col-lg-6">
                        <div className="">
                            <div className="ibox-content">
                                <div className="row ">
                                    <div className="col-lg-12">
                                        <h3 className="fw-medium mb-3">Tutorials &amp; Training Videos</h3>

                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-lg-12">
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Search for a topic you would like to know more about"
                                            onChange={this.search}
                                        />

                                    </div>
                                </div>
                                <div className="row">
                                    {docsCategories.length == 0 ? (
                                        <div className="col-md-12">
                                            No support articles or videos found
                                            {
                                                this.state.search.length != 0 ?
                                                    <>&nbsp;for <em>{this.state.search}</em></>
                                                    :
                                                    undefined
                                            }
                                        </div>
                                    ) : undefined}

                                    {
                                        docsCategories.map((category) => {
                                            return (
                                                <div key={category} className="col-md-4 sub-column mb-3">
                                                    <h3 className="fw-medium mb-3">{category}</h3>

                                                    <ul className="list-unstyled">
                                                        {docs[category].map((currentDoc) => {
                                                            let icon = "far fa-file-alt";

                                                            if (currentDoc.type == "video") {
                                                                icon = "fas fa-video";

                                                            }

                                                            return (
                                                                <li key={currentDoc["link"]} className="mb-2">
                                                                    <i className={icon}></i>

                                                                    <a href={currentDoc.link} className="ml-1 va-middle text-primary">{currentDoc.title}</a>
                                                                </li>
                                                            )
                                                        })}

                                                        <li className="mb-2">
                                                            <i className="fas fa-ellipsis-h"></i>

                                                            <a href="" className="ml-1 va-middle text-primary">View More</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )
                                        })
                                    }

                                    {/* <div className="col-md-4 sub-column mt-4">
                                    <h3 className="fw-medium mb-3">Need More Assistance?</h3>
                                    <ul className="list-unstyled">
                                        <li className="mb-2">
                                            <button className="btn btn-primary  btn-block">
                                                <i className="fas fa-chalkboard-teacher"></i>&nbsp;
                                                Request Dedicated Training
                                            </button>
                                        </li>
                                    </ul>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-lg-6 ">
                        <div className="">
                            <div className="ibox-content">
                                <div className="row v-center mb-3">
                                    <div className="col-sm-7 col-md-8 tablet-top">
                                        {/* <p className="fw-light opc-75 mb-0">Showing 12 topics - 1 through 12 (of 832 total)</p> */}
                                        <h3 className="fw-medium mb-3">
                                            {this.state.show === this.COMPONENT_STATES.LIST ? "Support Requests" : "New Support Request"}
                                        </h3>
                                    </div>

                                    <div className="col-sm-5 col-md-4">
                                        {this.state.error !== true && this.state.issuesLoading !== true && this.state.show === this.COMPONENT_STATES.LIST ?
                                            <button onClick={this.toggleSupportDisplay} type="button" className="mr-2 btn btn-primary float-right mb-2"><i className="fas fa-plus"></i>&nbsp;New Support Request</button>
                                            :
                                            undefined
                                        }
                                    </div>
                                </div>


                                {this.state.show === this.COMPONENT_STATES.ERROR ? <ErrorPage wrapperClasses=" " hideSidebar={true} /> : undefined}

                                {this.state.show === this.COMPONENT_STATES.CREATE_CONFIRMED ?
                                    <>
                                        <div className="text-center">
                                            <div className="pad-btm text-success"><i className="fas fa-check-circle fa-5x"></i></div>


                                            Your support request has been registered. <br />
                                            You will hear back from us soon.<br />
                                            <br />
                                            <a className="text-primary" onClick={this.toggleSupportDisplay}>Click here to view all support requests.</a>
                                        </div>
                                    </>
                                    :
                                    undefined
                                }

                                {this.state.show === this.COMPONENT_STATES.CREATE ?
                                    <MagicForm
                                        robostackResolveData={[
                                            {
                                                "name": "ListOfSites",
                                                "api": {
                                                    "method": "POST",
                                                    "endpoint": "/apps",
                                                    "data": {}
                                                },
                                                "transformMergeIfArray": true,
                                                "transformMerge": true,
                                                "transform": {
                                                    "fields": {
                                                        "site_uuid": {
                                                            "options": "`data.results | sort:name | map | set-prop:display,$name | set-prop:value,$uuid`",
                                                            "forceUpdate": true
                                                        },
                                                    }
                                                }
                                            }
                                        ]}
                                        showCancelButton={true}
                                        onCancelButtonClick={() => {
                                            this.setState({
                                                show: this.COMPONENT_STATES.LIST,
                                            })
                                        }}
                                        submitApi={{
                                            "method": "PUT",
                                            "endpoint": "/support-requests",
                                            "data": {
                                                "status": "open"
                                            }
                                        }}
                                        onSubmitApiSuccess={(response) => {

                                            this.setState({
                                                show: this.COMPONENT_STATES.CREATE_CONFIRMED,
                                            }, () => {
                                                this.loadRequests();
                                            });
                                        }}
                                        
                                        submitSuccessMessage="Your support request was submitted successfully"
                                        fields={
                                            [
                                                {
                                                    "site_uuid": {
                                                        "label": "Which application are you having an issue with?",
                                                        "placeholder": "Select one of your applications",
                                                        "type": "dropdown",
                                                        "required": true,
                                                        "position": 1,
                                                        "options": []
                                                    },
                                                    "title": {
                                                        "label": "What issue are you having?",
                                                        "type": "text",
                                                        "placeholder": "Explain in brief",
                                                        "required": true,
                                                        "position": 3,
                                                    },
                                                    "description": {
                                                        "label": "Desribe your issue",
                                                        "type": "textarea",
                                                        "placeholder": "In detail",
                                                        "required": true,
                                                        "position": 4,
                                                    },
                                                }
                                            ]
                                        }
                                    />
                                    :
                                    undefined
                                }

                                {this.state.show === this.COMPONENT_STATES.LIST ?
                                    <>
                                        {this.state.issuesLoading === true ?
                                            <Loader />
                                            :
                                            <>
                                                {this.state.issues.length == 0 ?
                                                    <div>No existing support requests found</div>
                                                    :
                                                    <>
                                                        <div className="table-responsive">
                                                            <table className="table table-hover issue-tracker">
                                                                <thead className="font-bold">
                                                                    <tr>
                                                                        <td>
                                                                            Status
                                                                        </td>
                                                                        <td>
                                                                            Application
                                                                        </td>
                                                                        <td>
                                                                            Issue
                                                                        </td>
                                                                        <td>
                                                                            Raised By
                                                                        </td>
                                                                        <td className="text-right">
                                                                            Last Updated
                                                                        </td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {this.state.issues.map((issue) => {
                                                                        let labelClass = supportStatusLabelColor(issue.status);

                                                                        return (
                                                                            <tr key={issue.uuid}>
                                                                                <td>
                                                                                    <Link key={issue.uuid} component={TrLink} className="text-inherit" to={`./request/${issue.uuid}`}>
                                                                                        <span className={`${labelClass}`}>
                                                                                            {ToTitleCase(issue.status) || "Unknown"}
                                                                                        </span>
                                                                                    </Link>
                                                                                </td>
                                                                                <td className="">
                                                                                    <Link key={issue.uuid} component={TrLink} className="text-inherit" to={`./request/${issue.uuid}`}>

                                                                                        {issue.site_uuid}
                                                                                    </Link>
                                                                                </td>
                                                                                <td className="">
                                                                                    <Link key={issue.uuid} component={TrLink} className="text-inherit" to={`./request/${issue.uuid}`}>

                                                                                        <div>
                                                                                            {issue.title}
                                                                                        </div>
                                                                                    </Link>
                                                                                </td>
                                                                                <td className="">
                                                                                    <Link key={issue.uuid} component={TrLink} className="text-inherit" to={`./request/${issue.uuid}`}>

                                                                                        {issue.createdBy}
                                                                                    </Link>
                                                                                </td>

                                                                                <td className="text-right">
                                                                                    <Link key={issue.uuid} component={TrLink} className="text-inherit" to={`./request/${issue.uuid}`}>

                                                                                        {/* Last updated by <strong>{issue.createdBy}</strong><br /> */}
                                                                                        {/* <small>{moment(issue.updatedOn || issue.createdOn).local().fromNow()}</small> */}
                                                                                        {moment(issue.updatedOn || issue.createdOn).local().fromNow()}
                                                                                    </Link>
                                                                                </td>
                                                                            </tr>

                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                    :
                                    undefined
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}

export default connect(mapStateToProps)(SupportAndTraining);
