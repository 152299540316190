import React from "react";
import moment from "moment";

import { connect } from "react-redux";

import axiosBackend from "../../../core/api/backend";

import { Link } from "react-router-dom";
import withLoaderData from "../../../components/withLoaderData";

import Loader from "../../../components/Loader";
import * as DataDelegator from "../../../components/smart/delegator/DataDelegator";

import AttachTrailingSlash from "../../../core/utils/attachTrailingSlash";

export async function loader(props) {
    return new Promise((resolve, reject) => {
        const { params } = props;
        const url = new URL(props.request.url);

        DataDelegator.resolve({
            robostackResolveData:
                [
                    {
                        "name": "ListOfApps",
                        "api": {
                            "method": "POST",
                            "endpoint": "/apps",
                            "data": {}
                        },
                        "transformName": "Breadcrumbs",
                        "transform": {
                            "title": "`data.results | search-array-for-object: uuid," + params.appUUID + " | pop | prop: name`",
                            "uuid": params.appUUID,
                            "root": AttachTrailingSlash(url.pathname)
                        }
                    },
                    {
                        "name": "Breadcrumbs",
                        "forceApiReload": true,
                        "mergeWithSourceAfterTransform": true,
                        "transformArrayMergeStratergy": "overwriteTarget",
                        "props": {
                            crumbs: [
                                {
                                    "name": "Resources",
                                },
                            ]
                        }
                    },
                ]
        }, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    })
}

class ResourcesList extends React.Component {
    constructor(props) {
        super(props);

        this.STATES = {
            LOADING: "loading",
            LOADED: "loaded",
            ERROR: "error",
        };

        this.state = {
            resources: [],

            status: this.STATES.LOADING,
            searchKeys: ["name"],
            search: "",
        };

        this.search = this.search.bind(this);
    }

    onClone(event, resource) {
        event.preventDefault();

        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "data-collection",
                "title": "Clone Resource",
                yesButtonText: "Clone",
                noButtonText: "Cancel",
                modelBodyClasses: [],
                modalSize: "large",

                robostackResolveData: [
                    {
                        "name": "ListOfApps",
                        "api": {
                            "method": "POST",
                            "endpoint": "/apps",
                            "data": {}
                        },
                        "transformMergeIfArray": true,
                        "transformMerge": true,
                        "transformArrayMergeStratergy": "overwriteTarget",
                        "transform": {
                            "fields": {
                                "site_uuid": {
                                    "options": "`data.results | sort:name | map | set-prop:display,$name | set-prop:value,$uuid`",
                                    value: this.props.router.params.appUUID,
                                },
                            }
                        }
                    }
                ],
                showSubmitButton: false,
                fields: [
                    {
                        "site_uuid": {
                            "label": "Which application do you want to clone the resource into?",
                            "placeholder": "Select one of your applications",
                            "type": "dropdown",
                            "required": true,
                            "position": 1,
                            "options": []
                        },
                        "name": {
                            "label": "What do you want to call the cloned resource?",
                            "type": "text",
                            "placeholder": `${resource}-clone`,
                            "value": `${resource}-clone`,
                            "required": true,
                            "position": 2,
                        },
                        "with_data": {
                            "label": "Clone with data?",
                            "type": "checkbox",
                            "position": 3,
                            value: false,
                        },
                    }
                ],

                onYes: (onClose, tuple) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Cloning Resource",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "PUT",
                            url: `/service/resources/${this.props.router.params.appUUID}/${resource}/clone`,
                            data: {
                                new_siteUUID: tuple.site_uuid,
                                newResourceName: tuple.name,
                                withData: tuple.with_data,
                            }
                        })
                            .then((response) => {
                                const result = response.data;

                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            `The resource was cloned successfully`,
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });

                                this.loadData();
                            })
                            .catch((err) => {
                                console.error(err);
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Could not clone resource",
                                        message: [
                                            "Due to an error, we were unable to clone the resource you had selected",
                                            "‏‏‎ ‎",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    onDelete(event, resource) {
        event.preventDefault();

        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Confirm Delete",
                "message": [
                    `Are you sure you would like to delete the ${resource} resource?`,
                    "‏‏‎ ‎",
                    "You will still be able to restore it for upto 30 days after removal."
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Deleting Resource",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "DELETE",
                            url: `/service/resources/${this.props.router.params.appUUID}/${resource}/drop`,
                        })
                            .then((response) => {
                                const result = response.data;

                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            `The ${resource} resource was deleted successfully.`,
                                            "‏‏‎ ‎",
                                            "You can restore it for upto 30 days after removal."
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });

                                this.loadData();
                            })
                            .catch((err) => {
                                console.error(err);
                                
                                if(Array.isArray(err?.response?.data?.results) && err.response.data.results.length > 0) {
                                    this.props.dispatch({
                                        type: "ResolvedData",
                                        name: "ModalData",
                                        data: {
                                            show: true,
                                            type: "error",
                                            title: "Could not delete resource",
                                            message: [
                                                "We were unable to delete the resource you had selected as it has fields referencing it in the following resource:",
                                                "‏‏‎ ‎",
                                            ].concat(err.response.data.results.map((field) => {
                                                return field.belongsToResource;
                                            })).concat([
                                                "‏‏‎ ‎",
                                                "Please fix these issues and then try again in a little while."
                                            ]),
                                            okayButtonText: "Okay"
                                        },
                                    });
                                } else {
                                    this.props.dispatch({
                                        type: "ResolvedData",
                                        name: "ModalData",
                                        data: {
                                            show: true,
                                            type: "error",
                                            title: "Could not delete resource",
                                            message: [
                                                "Due to an error, we were unable to delete the resource you had selected",
                                                "‏‏‎ ‎",
                                                "Please try again in a little while."
                                            ],
                                            okayButtonText: "Okay"
                                        },
                                    });
                                }
                            })
                    }, 1000);
                }
            },
        });
    }

    loadData() {
        const config = {
            method: "POST",
            url: `/service/resources/${this.props.router.params.appUUID}`,
            data: {}
        };

        axiosBackend(config)
            .then((response) => {
                this.setState({
                    resources: response.data.results.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    }),
                    status: this.STATES.LOADED,
                }, () => {
                    this.searchData();
                })
            })
            .catch((error) => {
                console.error(error);

                this.setState({
                    status: this.STATES.ERROR,
                })
            })
    }

    componentDidMount() {
        DataDelegator.resolve(this.props);

        this.loadData();
    }

    searchData() {
        const value = this.state.search;

        const resources = [...this.state.resources]
            .map((item) => {
                item.hidden = (
                    value.length > 0 &&
                    Array.isArray(this.state.searchKeys) &&
                    this.state.searchKeys.every((search) => {
                        return item[search] !== undefined && item[search] !== null && item[search].toLowerCase().indexOf(value) == -1;
                    })
                );
                return item;
            });

        this.setState({
            resources
        })
    }

    search(event) {
        const value = event.target.value.trim().toLowerCase();

        this.setState({
            search: value,
        }, () => {
            this.searchData();
        });
    }

    render() {
        const resources = this.state.resources.filter(item => !item.hidden);

        return (
            <>
                {/* {this.state.status === this.STATES.ERROR ? <Error500 /> : undefined} */}

                {this.state.status === this.STATES.LOADING ?
                    <div className="row">
                        <div className="col-sm-12 pr-4 pl-4 pt-2 pb-2 ml-2">
                            <Loader />
                        </div>


                    </div>
                    :
                    undefined
                }

                <div className="col-sm-12 pb-2">
                    <button aria-label="Help" className="btn btn-gray" onClick={this.toggleHelp}>
                        <span className="far fa-question-circle fa-lg"></span>
                    </button>
                </div>

                {this.state.status === this.STATES.LOADED &&
                    <div className="col panel p-1">
                        <div className="panel-body ml-2">
                            <div className="form-group">
                                <input
                                    autoFocus={true}
                                    onChange={this.search}
                                    placeholder="Click to search for resources"
                                    className="form-control form-control-lg search"
                                    type="text"
                                />
                            </div>

                            <ul className="file-list row">
                                {resources.map((resource) => {
                                    let withoutTimezone = moment(resource["updatedOn"]).format("YYYY-MM-DD HH:mm:ss");
                                    let updatedOn = moment.utc(withoutTimezone).local().fromNow();

                                    return (
                                        <li key={`${resource.name}`} className="col-md-3 mb-2">
                                            <div className="media">
                                                <Link className="d-flex flex-grow-1" to={AttachTrailingSlash(resource.name)}>

                                                    <i className="fas fa-table fa-3x"></i>


                                                    <div className="media-body ml-2">
                                                        <strong className="file-name mt-2">{resource.name}</strong><br />
                                                        <small>Updated {updatedOn}</small>
                                                    </div>
                                                </Link>

                                                <div className="dropdown show pointer">
                                                    <button aria-label="Dropdown Options" onClick={(event) => event.preventDefault()} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="btn btn-outline">
                                                        <i className="fas fa-ellipsis-v" style={{ fontSize: "1.5em" }}></i>
                                                    </button>

                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <Link className="dropdown-item" to={`./${resource.name}/edit-resource/`}>
                                                            <i className="fas fa-edit"></i>&nbsp;Add or remove fields
                                                        </Link>

                                                        <span
                                                            onClick={(event) => this.onClone(event, resource.name)}
                                                            className="dropdown-item"
                                                        >
                                                            <i className="fas fa-copy"></i>&nbsp;Clone resource
                                                        </span>

                                                        <span
                                                            onClick={(event) => this.onDelete(event, resource.name)}
                                                            className="dropdown-item"
                                                        >
                                                            <i className="fas fa-trash-alt"></i>&nbsp;Delete Resource
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}


                                {this.state.search !== "" && resources.length == 0 ?
                                    <>No resources found for <em>&nbsp;{this.state.search}</em></>
                                    :
                                    <>
                                        {/* {resources.length == 0 ?
                                            <>
                                                <div className="col-md-12 mb-4">
                                                    No resources found
                                                </div>
                                            </>
                                            :
                                            undefined
                                        } */}
                                        <li className="col-md-3 mb-2">
                                            <Link to="./create-new-resource">
                                                <div className="media">
                                                    <i className="fas fa-plus fa-3x"></i>

                                                    <div className="media-body ml-2 d-flex align-items-center">
                                                        <strong className="file-name mt-2">Create New Resource</strong><br />
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    </>
                                }
                            </ul>

                        </div>
                    </div>
                }
            </>
        )
    }
}


export default withLoaderData(connect(DataDelegator.mapStateToProps)(ResourcesList));