import React from "react";

import { connect } from "react-redux";

import axiosBackend from "../../../../core/api/backend";

import withLoaderData from "../../../../components/withLoaderData";

import Loader from "../../../../components/Loader";
import * as DataDelegator from "../../../../components/smart/delegator/DataDelegator";

import { v4 as uuidV4 } from "uuid";

import renderTemplate from '../render-template';
import exportToImage from '../export-to-image';

import ErrorPage from "../../../error";

const HtmlToReactParser = require('html-to-react').Parser;

import Prism from "prismjs";
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';

class ReportViewer extends React.Component {
    constructor(props) {
        super(props);

        this.uniqueIdentifier = "_robostack_report_uuid_";

        this.state = {
            selectedTab: null,
            selectedIndex: null,

            status: "loading",

            defaultStatus: null,
            applyStatusToAll: false,

            title: "",
            description: "",

            locals: [],
            reportType: "",
            name: "",
            description: "",

            template: "",
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    componentDidMount() {
        DataDelegator.resolve(this.props, () => {
            const reportConfig = {
                method: "POST",
                url: "/service/reports/" + this.props.router.params.appUUID + "/" + this.props.router.params.reportUUID,
                data: {
                }
            };

            return axiosBackend(reportConfig)
                .then((response) => {
                    if (Array.isArray(response.data.results) && response.data.results.length == 1) {
                        const report = response.data.results[0];

                        return {
                            template: report.template,
                            name: report.name,
                            description: report.description,
                            query_uuid: report.query_uuid,
                            reportType: report.reportType,
                            menuTemplate: report.menuTemplate,
                        }
                    } else {
                        throw new Error("Did not get back the report from the server");
                    }
                })
                .then((stateToUpdate) => {
                    const queryConfig = {
                        method: "POST",
                        url: `/service/query-playground/${this.props.router.params.appUUID}/${stateToUpdate.query_uuid}`,
                        data: {
                        }
                    };

                    return axiosBackend(queryConfig)
                        .then((response) => {
                            if (Array.isArray(response.data.results) && response.data.results.length == 1) {
                                const query = response.data.results[0];

                                return {
                                    ...stateToUpdate,
                                    query,
                                };
                            } else {
                                throw new Error("Did not get back the query for the report from the server");
                            }
                        })
                })
                .then((stateToUpdate) => {
                    const config = {
                        method: "POST",
                        url: `/service/resources/${this.props.router.params.appUUID}/${stateToUpdate.query.resource}/aggregation`,
                        data: stateToUpdate.query.configuration.pipeline
                    };

                    return axiosBackend(config)
                        .then((response) => {
                            if (response.data.status == "success") {
                                const locals = response.data.results.map((local) => {
                                    local[this.uniqueIdentifier] = uuidV4();
                                    return local;
                                });

                                stateToUpdate = {
                                    ...stateToUpdate,
                                    pipelineResults: locals,
                                };

                                if (stateToUpdate.reportType == "multiple") {
                                    if (locals.length > 0) {
                                        stateToUpdate["locals"] = locals[0];
                                        stateToUpdate["selectedIndex"] = 0;

                                        stateToUpdate["selectedTab"] = locals[0][this.uniqueIdentifier];
                                    }
                                } else if (stateToUpdate.reportType == "single") {
                                    stateToUpdate["locals"] = locals;
                                } else {
                                    throw new Error("Unknown reportType: " + stateToUpdate.reportType);
                                }
                            } else {
                                throw new Error("Could not get results from the server");
                            }

                            return stateToUpdate;
                        })
                })
                .then((stateToUpdate) => {
                    stateToUpdate["status"] = "loaded";

                    this.setState(stateToUpdate);

                    Prism.highlightAll();
                })
                .catch((error) => {
                    console.error(error);

                    this.setState({
                        status: "error",
                    });
                })
        });
    }

    selectTab(uniqueIdentifier, index) {
        this.setState({
            selectedTab: uniqueIdentifier,
            selectedIndex: index,
            locals: this.state.pipelineResults[index],
        })
    }

    next() {
        if (this.state.pipelineResults.length > 0) {
            this.setState((prevState) => {
                let nextIndex = 0;

                if (prevState.selectedIndex != null) {
                    if (prevState.selectedIndex < this.state.pipelineResults.length - 1) {
                        nextIndex = prevState.selectedIndex + 1;
                    }
                }

                return {
                    selectedIndex: nextIndex,
                    locals: this.state.pipelineResults[nextIndex],
                    selectedTab: this.state.pipelineResults[nextIndex][this.uniqueIdentifier]
                };
            });
        }
    }

    previous() {
        if (this.state.pipelineResults.length > 0) {
            let previousIndex = this.state.pipelineResults.length - 1;

            if (this.state.selectedIndex != null) {
                if (this.state.selectedIndex > 0) {
                    previousIndex = this.state.selectedIndex - 1;
                }
            }

            this.setState({
                selectedIndex: previousIndex,
                locals: this.state.pipelineResults[previousIndex],
                selectedTab: this.state.pipelineResults[previousIndex][this.uniqueIdentifier]

            })
        }
    }

    exportTemplateAsImage(withTitle = false) {
        let id = withTitle ? "rendered-template-with-title" : "rendered-template";

        exportToImage(id, this.state.name)
            .catch((error) => {
                console.error(error);
                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Could Not Export To An Image",
                        message: [
                            "Due to an error, we were unable to export your template to an image.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
    }

    print() {
        window.print();
    }

    render() {
        let renderedTemplate;
        let render = false;

        if (this.state.locals == null || this.state.locals == undefined) {
            render = false;
        } else if (Array.isArray(this.state.locals) && this.state.locals.length > 0) {
            render = true;
        } else if (typeof this.state.locals == "object" && Object.keys(this.state.locals).length > 0) {
            render = true;
        }

        if (render) {
            if (this.state.reportType == "multiple" && this.state.selectedIndex != null) {
                renderedTemplate = renderTemplate(this.state.template, this.state.locals);
            } else {
                renderedTemplate = renderTemplate(this.state.template, this.state.locals);
            }
        }

        const htmlToReactParser = new HtmlToReactParser();

        return (
            <>
                <div className="col-sm-12">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <div className="dropdown float-right ml-2">
                                <button className="btn btn-white btn-xs dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Export As Image
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" onClick={() => this.exportTemplateAsImage(true)}>With Report Title</a>
                                    <a className="dropdown-item" onClick={() => this.exportTemplateAsImage(false)}>Without Report Title</a>
                                </div>
                            </div>

                            <button onClick={print} className="btn btn-white btn-xs float-right ml-2" type="button">
                                Print
                            </button>
                        </div>
                    </div>

                    <div className="" id="rendered-template-with-title">
                        {this.state.status == "loaded" ?
                            <div className="white-bg col-12 pt-2 border-bottom">
                                <h3 className="font-bold no-margins">{this.state.name}</h3>
                                <small>{this.state.description}</small>
                            </div>
                            :
                            undefined
                        }
                        <div className="white-bg col-12 pt-0 pb-0">

                            {this.state.status == "error" ?
                                <div className="row">
                                    <div className="col-md-12 ml-2 pl-2 pb-2 pt-4">
                                        <ErrorPage hideSidebar={true} />
                                    </div>
                                </div>
                                :
                                undefined
                            }


                            {this.state.status == "loading" ? <div className="row d-flex justify-content-center  ml-2 pl-2 pb-2 pt-4"><Loader /></div> : undefined}

                            {this.state.status == "loaded" ?
                                <div className="row">
                                    {this.state.reportType == "multiple" ?
                                        <div className="col-sm-3">
                                            <div className="row pb-2 pt-2 pl-2 border-bottom border-right">
                                                {render ?
                                                    <div className="col-sm-12">
                                                        <button
                                                            disabled={this.state.selectedIndex == 0}
                                                            onClick={this.previous}
                                                            type="button"
                                                            className="btn btn-sm btn-white"
                                                        >
                                                            <i className="fa fa-arrow-left"></i>&nbsp;Previous
                                                        </button>
                                                        <button
                                                            onClick={this.next}
                                                            type="button"
                                                            className="btn btn-sm btn-white float-right mr-1"
                                                        >
                                                            Next&nbsp;
                                                            <i className="fa fa-arrow-right"></i>&nbsp;
                                                        </button>
                                                    </div>
                                                    :
                                                    <>
                                                        <div className="col-12">
                                                            No data available in the report
                                                        </div>
                                                    </>
                                                }
                                            </div>

                                            <div className="full-height-scroll full-min-height">
                                                <ul className="list-group elements-list">
                                                    {Array.isArray(this.state.pipelineResults) && this.state.pipelineResults.map((element, index) => {
                                                        const renderedMenuTemplate = renderTemplate(this.state.menuTemplate, element);

                                                        return (
                                                            <li key={element.uuid} onClick={() => this.selectTab(element[this.uniqueIdentifier], index)} className="list-group-item">
                                                                <a className={`nav-link ${this.state.selectedTab == element[this.uniqueIdentifier] ? "active" : ""}`}>
                                                                    {htmlToReactParser.parse(
                                                                        renderedMenuTemplate.rendered
                                                                    )}

                                                                    {/* <div>
                                                                        {this.state.status[element.uuid] != undefined && this.state.status[element.uuid].comment != undefined ?
                                                                            <small>
                                                                                <strong>Comment:&nbsp;<br /></strong>
                                                                                <em>{this.state.status[element.uuid].comment}</em>
                                                                            </small>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div> */}

                                                                    {/* <div className="text-right">
                                                                        {this.state.status[element.uuid] != undefined && this.state.status[element.uuid].value == "rejected" ? <span className="label label-danger">Rejected</span> : null}

                                                                        {this.state.status[element.uuid] != undefined && this.state.status[element.uuid].value == "approved" ? <span className="label label-success">Approved</span> : null}
                                                                    </div> */}
                                                                </a>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        :
                                        undefined
                                    }

                                    <div className={`${this.state.reportType == "multiple" ? "col-sm-9" : "col-sm-12"} p-0 full-height`}>
                                        <div className={`full-height-scroll white-bg`}>
                                            <div className="element-detail-box p-0">

                                                <div id="rendered-template">

                                                    {render ? <>

                                                        {renderedTemplate.status == "error" && <>
                                                            <div className="alert alert-danger" role="alert">
                                                                <div className="row">
                                                                    <div className="col-9">
                                                                        An error occurred
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <pre>

                                                                    {renderedTemplate.error.message}
                                                                </pre>

                                                                <pre className="line-numbers">
                                                                    <code className="language-ejs">
                                                                        {htmlToReactParser.parse(
                                                                            renderedTemplate.rendered
                                                                        )}
                                                                    </code>
                                                                </pre>
                                                            </div>

                                                        </>}

                                                        {renderedTemplate.status == "success" && <>
                                                            {htmlToReactParser.parse(
                                                                renderedTemplate.rendered
                                                            )}
                                                        </>}
                                                    </>
                                                        :
                                                        <div className="col-12">
                                                            No data available in the report
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                undefined
                            }


                        </div>
                    </div>


                </div>
            </>
        );
    }
}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(ReportViewer));