import React from "react";

import { connect } from "react-redux";

import moment from "moment";
import update from "immutability-helper";
import Loader from "../../../components/Loader";

import copy from 'copy-to-clipboard';

import steps from "./help-steps";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import MagicForm from "../../../components/smart/supported/MagicForm";
import * as DataDelegator from "../../../components/smart/delegator/DataDelegator";
import axiosBackend from "../../../core/api/backend";

class MyAccount extends React.Component {
    constructor(props) {
        super(props);

        this.COMPONENT_STATES = {
            CREATE_CONFIRMED: "CREATE_CONFIRMED",
            CREATE: "CREATE",

            EDIT: "EDIT",

            LIST: "LIST",
            LIST_LOADING: "LIST_LOADING",

            ERROR: "ERROR",
        }

        this.state = {
            show: this.COMPONENT_STATES.LIST_LOADING,

            tokens: [],
            createdToken: {},
            tokenToEdit: null,

            showEditProfile: false,
            userProfile: {},

            showTour: false,
            stepIndex: 0,
        };

        this.viewSecret = this.viewSecret.bind(this);
        this.deactivateToken = this.deactivateToken.bind(this);
        this.editToken = this.editToken.bind(this);
        this.toggleCreateDisplay = this.toggleCreateDisplay.bind(this);
        this.toggleEditProfileDisplay = this.toggleEditProfileDisplay.bind(this);

        this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);

    }

    deactivateToken(token) {
        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Confirm Deactivation",
                "message": [
                    "Are you sure you would like to deactivate the token " + token.name + "?",
                    "‏‏‎ ‎",
                    "This action is permanant and cannot be undone."
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Deactivating Token",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "DELETE",
                            url: `/my-account/tokens`,
                            data: {
                                token: token.token,
                            },
                        })
                            .then((response) => {
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            " Your token was deactivated successfully",
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });

                                this.loadTokens();
                            })
                            .catch((err) => {
                                console.error(err);
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Could not deactivate token",
                                        message: [
                                            "Due to an error, we were unable to deactivate the token",
                                            "‏‏‎ ‎",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    editToken(token) {
        this.setState({
            tokenToEdit: token,
            show: this.COMPONENT_STATES.EDIT,
        })
    }

    loadTokens() {
        axiosBackend({
            method: "POST",
            url: `/my-account/tokens`,
        })
            .then((response) => {
                if (response.data.status == "success") {
                    this.setState({
                        tokens: response.data.results.sort((tokenA, tokenB) => {
                            return tokenB.exp - tokenA.exp;
                        }),
                        show: this.COMPONENT_STATES.LIST,
                    });
                } else {
                    this.setState({
                        show: this.COMPONENT_STATES.ERROR,
                    })
                }
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    show: this.COMPONENT_STATES.ERROR,
                })
            })
    }

    loadUserProfile() {
        axiosBackend({
            method: "POST",
            url: `/my-account/profile`,
        })
            .then((response) => {
                if (response.data.status == "success") {
                    console.log(response.data)
                    this.setState({
                        userProfile: response.data.results.pop(),
                    })
                } else {
                    // this.setState({
                    //     show: this.COMPONENT_STATES.ERROR,
                    // })
                }
            })
            .catch((err) => {
                console.error(err);
                // this.setState({
                //     show: this.COMPONENT_STATES.ERROR,
                // })
            })
    }

    componentDidMount() {
        this.loadTokens();
        this.loadUserProfile();
    }

    viewSecret(token) {
        const index = this.state.tokens.findIndex(currentToken => currentToken.token == token.token);

        if (index > -1) {

            if (this.state.tokens[index].viewSecret === true) {
                copy(this.state.tokens[index].secret);
            } else {
                this.setState(update(this.state, {
                    tokens: {
                        [index]: {
                            viewSecret: {
                                $set: true,
                            }
                        }
                    }
                }));
            }
        }
    }

    toggleCreateDisplay() {
        this.setState((currentState) => {
            let show = this.COMPONENT_STATES.CREATE;

            let statesToSwitchToList = [
                this.COMPONENT_STATES.CREATE,
                this.COMPONENT_STATES.CREATE_CONFIRMED,
                this.COMPONENT_STATES.EDIT,
                this.COMPONENT_STATES.EDIT_CONFIRMED,
            ]

            if (statesToSwitchToList.includes(currentState.show)) {
                this.loadTokens();

                show = this.COMPONENT_STATES.LIST;
            }

            return {
                show
            }
        })
    }

    toggleEditProfileDisplay() {
        this.setState((currentState) => {
            return {
                showEditProfile: !currentState.showEditProfile,
            }
        })
    }

    toggleHelp() {
        this.setState({
            showTour: true,
        });
    }

    handleJoyrideCallback(data) {
        if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.CLOSE].includes(data.status) || ACTIONS.CLOSE == data.action) {
            // Need to set our running state to false, so we can restart if we click start again.
            let stateToUpdate = {
                showTour: false,
                stepIndex: 0,
            };

            this.setState(stateToUpdate);
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(data.type)) {
            // Update state to advance the tour
            this.setState({
                stepIndex: data.index + (data.action === ACTIONS.PREV ? -1 : 1),
            });
        }
    }

    render() {
        return (
            <>
                <ReactJoyride
                    callback={this.handleJoyrideCallback}
                    run={this.state.showTour}
                    stepIndex={this.state.stepIndex}
                    steps={steps}
                    continuous={true}
                    showSkipButton={true}
                    showProgress={true}
                    styles={{
                        options: {
                            zIndex: 10000,
                        },
                    }}
                    disableScrollParentFix={true}
                />

                <div className="col-lg-12 pb-2">

                    <button className="btn btn-gray" onClick={this.toggleHelp}>
                        <span className="far fa-question-circle fa-lg"></span>
                    </button>
                </div>

                <div className="col-md-4">
                    <div className="">
                        <div>
                            <div className="ibox-content profile-content">
                                <div className="row">
                                    <div className="col-md-12" id="user-profile">

                                        <div className="profile-image">
                                            <img src="/img/user.svg" className="rounded-circle circle-border m-b-md" alt="profile" />
                                        </div>
                                        <div className="profile-info">
                                            <div className="pt-4">
                                                <div>
                                                    <h2 className="no-margins">
                                                        {this.state.userProfile.first_name} {this.state.userProfile.last_name}
                                                    </h2>
                                                    <h4>{this.state.userProfile.email}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.state.showEditProfile === false ?
                                    <div className="row" id="user-profile-edit-button">
                                        <div className="col-md-12">
                                            <button onClick={this.toggleEditProfileDisplay} type="button" className="btn btn-default btn-sm btn-block"><i className="fa fa-edit"></i> Edit Profile</button>
                                        </div>
                                    </div>

                                    :

                                    <div className="row">
                                        <div className="col-md-12">
                                            <MagicForm
                                                submitButtonText="Update Profile"
                                                cancelButtonText="Cancel"
                                                showCancelButton={true}
                                                onCancelButtonClick={this.toggleEditProfileDisplay}
                                                submitApi={{
                                                    "method": "PATCH",
                                                    "endpoint": "/my-account/profile",
                                                    "data": {
                                                    }
                                                }}
                                                onSubmitApiSuccess={(response, data) => {
                                                    this.setState({
                                                        userProfile: {
                                                            ...this.state.userProfile,
                                                            first_name: data.first_name,
                                                            last_name: data.last_name,
                                                        },
                                                    });
                                                }}
                                                submitSuccessMessage="Your profile was successfully updated"
                                                fields={
                                                    [
                                                        {
                                                            "first_name": {
                                                                "label": "First Name",
                                                                "type": "text",
                                                                "placeholder": "Bruce",
                                                                "required": true,
                                                                "position": 1,
                                                                "value": this.state.userProfile.first_name,
                                                            },
                                                            "last_name": {
                                                                "label": "Last Name",
                                                                "type": "text",
                                                                "placeholder": "Wayne",
                                                                "required": true,
                                                                "position": 2,
                                                                "value": this.state.userProfile.last_name
                                                            },
                                                        }
                                                    ]
                                                }
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-8">
                    <div className="">
                        <div className="ibox-content" id="tokens-list">
                            {this.state.show === this.COMPONENT_STATES.LIST ?
                                <button
                                    id="tokens-list-create"
                                    onClick={this.toggleCreateDisplay}
                                    type="button"
                                    className="mr-2 btn btn-primary btn-xs float-right"
                                >
                                    <i className="fas fa-plus"></i>&nbsp;Create New Token
                                </button>
                                :
                                undefined
                            }

                            <h2>
                                {this.state.show === this.COMPONENT_STATES.LIST && "API Tokens"}

                                {this.state.show === this.COMPONENT_STATES.CREATE && "New API Tokens"}

                                {this.state.show === this.COMPONENT_STATES.EDIT && "Edit API Tokens"}
                            </h2>

                            {this.state.show === this.COMPONENT_STATES.LIST_LOADING ? <Loader /> : undefined}

                            {this.state.show === this.COMPONENT_STATES.CREATE_CONFIRMED ?
                                <>
                                    <div className="text-center">
                                        <div className="pad-btm text-success"><i className="fas fa-check-circle fa-5x"></i></div>


                                        Your API token has been created successfully. <br />
                                        <br />

                                        <div className="row justify-content-md-center">
                                            <div className="col-md-5">
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className="no-borders text-right">
                                                                <strong>Token Name:</strong>
                                                            </td>
                                                            <td className="no-borders text-left">
                                                                {this.state.createdToken.name}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="no-borders text-right">
                                                                <strong>Token Key:</strong>
                                                            </td>
                                                            <td className="no-borders text-left">
                                                                {this.state.createdToken.token}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="no-borders text-right">
                                                                <strong>Token Secret:</strong>
                                                            </td>
                                                            <td className="no-borders text-left">
                                                                {this.state.createdToken.secret}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="no-borders text-right">
                                                                <strong>Expires:</strong>
                                                            </td>
                                                            <td className="no-borders text-left">
                                                                {moment.unix(this.state.createdToken.exp).fromNow()}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>


                                        <br />
                                        <a className="text-primary" onClick={this.toggleCreateDisplay}>Click here to view all your api tokens.</a>
                                    </div>
                                </>
                                :
                                undefined
                            }

                            {this.state.show === this.COMPONENT_STATES.CREATE ?
                                <MagicForm
                                    showCancelButton={true}
                                    onCancelButtonClick={() => {
                                        this.setState({
                                            show: this.COMPONENT_STATES.LIST,
                                        })
                                    }}
                                    submitApi={{
                                        "method": "PUT",
                                        "endpoint": "/my-account/tokens",
                                        "data": {
                                        }
                                    }}
                                    onSubmitApiSuccess={(response) => {
                                        this.setState({
                                            show: this.COMPONENT_STATES.CREATE_CONFIRMED,
                                            createdToken: response.results[0].tuples[0],
                                        });
                                    }}
                                    submitSuccessMessage="Your api token was created successfully"
                                    fields={
                                        [
                                            {
                                                "name": {
                                                    "label": "What do you want to name your token?",
                                                    "type": "text",
                                                    "placeholder": "My awesome Robostack token",
                                                    "required": true,
                                                    "position": 1,
                                                },
                                                "exp": {
                                                    "label": "When do you want your token to expire?",
                                                    "type": "datetime",
                                                    "placeholder": "Select any date or time that you want",
                                                    "required": true,
                                                    "position": 2,
                                                },
                                            }
                                        ]
                                    }
                                />
                                :
                                undefined
                            }

                            {this.state.show === this.COMPONENT_STATES.EDIT_CONFIRMED ?
                                <>
                                    <div className="text-center">
                                        <div className="pad-btm text-success"><i className="fas fa-check-circle fa-5x"></i></div>


                                        Your API token has been edited successfully. <br />
                                        <br />

                                        <div className="row justify-content-md-center">
                                            <div className="col-md-5">
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className="no-borders text-right">
                                                                <strong>Token Name:</strong>
                                                            </td>
                                                            <td className="no-borders text-left">
                                                                {this.state.tokenToEdit.name}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="no-borders text-right">
                                                                <strong>Token Key:</strong>
                                                            </td>
                                                            <td className="no-borders text-left">
                                                                {this.state.tokenToEdit.token}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="no-borders text-right">
                                                                <strong>Expires:</strong>
                                                            </td>
                                                            <td className="no-borders text-left">
                                                                {moment(this.state.tokenToEdit.exp).fromNow()}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>


                                        <br />
                                        <a className="text-primary" onClick={this.toggleCreateDisplay}>Click here to view all your api tokens.</a>
                                    </div>
                                </>
                                :
                                undefined
                            }

                            {this.state.show === this.COMPONENT_STATES.EDIT ?
                                <MagicForm
                                    showCancelButton={true}
                                    onCancelButtonClick={() => {
                                        this.setState({
                                            show: this.COMPONENT_STATES.LIST,
                                        })
                                    }}
                                    submitApi={{
                                        "method": "PATCH",
                                        "endpoint": "/my-account/tokens",
                                        "data": {
                                            "selection": {
                                                "token": this.state.tokenToEdit.token,
                                            },
                                            "updation": "$fields",
                                        }
                                    }}
                                    onSubmitApiSuccess={(response, data) => {
                                        console.log(data);

                                        this.setState({
                                            show: this.COMPONENT_STATES.EDIT_CONFIRMED,
                                            tokenToEdit: {
                                                ...this.state.tokenToEdit,

                                                name: data.updation.name,
                                                exp: data.updation.exp,
                                            }
                                        });
                                    }}
                                    submitSuccessMessage="Your api token was edited successfully"
                                    fields={
                                        [
                                            {
                                                "token": {
                                                    "label": "Token Key",
                                                    "type": "text",
                                                    "required": true,
                                                    "position": 1,
                                                    "value": this.state.tokenToEdit.token,
                                                    "disabled": true
                                                },
                                                "name": {
                                                    "label": "What do you want to name your token?",
                                                    "type": "text",
                                                    "placeholder": "My awesome Robostack token",
                                                    "required": true,
                                                    "position": 2,
                                                    "value": this.state.tokenToEdit.name,
                                                },
                                                "exp": {
                                                    "label": "When do you want your token to expire?",
                                                    "type": "datetime",
                                                    "placeholder": "Select any date or time that you want",
                                                    "required": true,
                                                    "position": 3,
                                                    "disabled": this.state.tokenToEdit.forceExpired,
                                                    "value": moment.unix(this.state.tokenToEdit.exp),
                                                },
                                            }
                                        ]
                                    }
                                />
                                :
                                undefined
                            }


                            {this.state.show === this.COMPONENT_STATES.LIST ?
                                <div className="clients-list">
                                    <div className="tab-content">

                                        <div className="table-responsive" id="tokens-list-active">
                                            <table className="table table-striped table-hover text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th className="col-2">Token Name</th>
                                                        <th>Token Key</th>
                                                        <th>Token Secret</th>
                                                        <th>Active</th>
                                                        <th id="tokens-list-expiry-column">Expires In</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.tokens.filter(token => token.forceExpired !== true).length == 0 &&
                                                        <tr className="m-2">
                                                            <td>
                                                                No tokens found
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    }

                                                    {this.state.tokens.filter(token => token.forceExpired !== true).map((token, index) => {
                                                        return (
                                                            <tr key={token.token}>
                                                                <td>{token.name}</td>
                                                                <td>
                                                                    {token.token}
                                                                </td>
                                                                <td>
                                                                    <div className="input-group">
                                                                        <input className="form-control form-control-sm" type="text" disabled={true} value={token.viewSecret !== true ? "********************" : token.secret} />

                                                                        <div className="input-group-append">
                                                                            <button onClick={() => this.viewSecret(token)} type="button" className="btn btn-primary btn-sm view-secret-button" title={`${token.viewSecret !== true ? "View Secret" : "Copy Secret"}`}>
                                                                                <span
                                                                                    className={`fa ${token.viewSecret !== true ? "fa-eye" : "fa-copy"}`}>
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className={`${token.forceExpired === false ? "text-success" : "text-danger"}`}>
                                                                    {token.forceExpired === false ? "Yes" : "No"}
                                                                </td>
                                                                <td className={`${moment.unix(token.exp).diff(new Date()) <= 0 ? 'text-danger' : ''}`}>
                                                                    {moment.unix(token.exp).fromNow()}
                                                                </td>
                                                                <td>

                                                                    <button type="button" onClick={() => this.editToken(token)} className="btn btn-info btn-sm btn-edit-token" title="Edit">
                                                                        <span className="fa fa-edit"></span>
                                                                    </button>

                                                                    <button onClick={() => this.deactivateToken(token)} type="button" className="btn btn-danger btn-sm ml-2 btn-delete-token" title="Delete">
                                                                        <span className="fa fa-trash-alt"></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                        <h2 className="mt-3">Deactivated Tokens</h2>

                                        <div className="table-responsive" id="tokens-list-deactive">
                                            <table className="table table-striped table-hover text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th className="col-2">Token Name</th>
                                                        <th>Token Key</th>
                                                        <th>Token Secret</th>
                                                        <th>Active</th>
                                                        <th>Expires In</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.state.tokens.filter(token => token.forceExpired === true).length == 0 &&
                                                        <tr className="m-2">
                                                            <td>
                                                                No deactivated tokens found
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    }

                                                    {this.state.tokens.filter(token => token.forceExpired === true).map((token, index) => {
                                                        return (
                                                            <tr key={token.token}>
                                                                <td>{token.name}</td>
                                                                <td>
                                                                    {token.token}
                                                                </td>
                                                                <td>
                                                                    <div className="input-group">
                                                                        <input className="form-control form-control-sm" type="text" disabled={true} value={token.viewSecret !== true ? "********************" : token.secret} />

                                                                        <div className="input-group-append">
                                                                            <button onClick={() => this.viewSecret(token)} type="button" className="btn btn-primary btn-sm" title={`${token.viewSecret !== true ? "View Secret" : "Copy Secret"}`}>
                                                                                <span
                                                                                    className={`fa ${token.viewSecret !== true ? "fa-eye" : "fa-copy"}`}>
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className={`${token.forceExpired === false ? "text-success" : "text-danger"}`}>
                                                                    {token.forceExpired === false ? "Yes" : "No"}
                                                                </td>
                                                                <td className={`${moment.unix(token.exp).diff(new Date()) <= 0 ? 'text-danger' : ''}`}>
                                                                    {moment.unix(token.exp).fromNow()}
                                                                </td>
                                                                <td>
                                                                    <button type="button" onClick={() => this.editToken(token)} className="btn btn-info btn-sm btn-edit-token" title="Edit">
                                                                        <span className="fa fa-edit"></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                :
                                undefined
                            }
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default connect(DataDelegator.mapStateToProps)(MyAccount);
