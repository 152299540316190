import React from "react";
import moment from "moment";
import update from "immutability-helper";

import { connect } from "react-redux";

import supportStatusLabelColor from "./supportStatusLabelColor";
import axiosBackend from "core/api/backend";
import mapStateToProps from "components/smart/delegator/mapStateToProps";
import ToTitleCase from "core/utils/toTitleCase";
import Loader from "components/Loader";
import { resolve } from "components/smart/delegator/DataDelegator";
import ErrorPage from "pages/error";
import withLoaderData from "components/withLoaderData";

class ViewSupportRequest extends React.Component {
    constructor(props) {
        super(props);

        this.COMPONENT_STATES = {
            LOADING: "LOADING",
            ERROR: "ERROR",
            READY: "READY",
            NOT_FOUND: "NOT_FOUND",
        }

        this.state = {
            state: this.COMPONENT_STATES.LOADING,

            request: null,
            messages: [],

            newMessageContent: "",
        };

        this.handleMessageContentChange = this.handleMessageContentChange.bind(this);
        this.toggleCaseClosed = this.toggleCaseClosed.bind(this);
        this.submitMessage = this.submitMessage.bind(this);
    }

    loadApplicationsIdentifiers() {
        const config = {
            method: "POST",
            url: `/apps`
        };

        return axiosBackend(config)
            .then((results) => {

                let stateToUpdate = this.state;

                const preLookedUpValue = this.state.request.site_uuid;

                // Find the value
                const lookedUpValue = results.data.results.filter((lookedUpData) => {
                    return lookedUpData.uuid == preLookedUpValue;
                }).pop();


                if (lookedUpValue !== undefined) {
                    stateToUpdate = update(stateToUpdate, {
                        request: {
                            site_uuid: {
                                $set: `${lookedUpValue.name}`,
                            }
                        }
                    })
                }

                this.setState(stateToUpdate);
            })
            .catch((err) => {
                console.error(err);
                // We still want the data to show even if the uuids are exposed so do nothing
            })
    }

    loadSupportRequest() {
        this.setState({
            state: this.COMPONENT_STATES.LOADING,
        }, () => {
            axiosBackend({
                method: "POST",
                url: `/support-requests/${this.props.router.params.uuid}`,
            })
                .then((response) => {

                    const request = response.data.results[0].request;
                    const messages = response.data.results[0].messages;

                    if (request.total === 0) {
                        this.setState({
                            state: this.COMPONENT_STATES.NOT_FOUND,
                        })
                    } else {
                        this.setState({
                            request: request,
                            messages: messages,
                        }, async () => {
                            await this.loadApplicationsIdentifiers();
                            await this.loadUsersIdentifiers();
                        })
                    }
                })
                .catch((err) => {
                    console.error(err);

                    if (err?.response?.data?.code !== undefined) {
                        if (err.response.data.code === 404) {
                            return this.setState({
                                state: this.COMPONENT_STATES.NOT_FOUND,
                            })
                        } 
                    } 

                    this.setState({
                        state: this.COMPONENT_STATES.ERROR,
                    })

                })
        })
    }



    /**
     * Load the correct values for all the user fields available for the all the support requests
     * @returns Promise
     */
    loadUsersIdentifiers() {
        const config = {
            method: "POST",
            url: `/support-requests/users`
        };

        return axiosBackend(config)
            .then((results) => {
                const usersFields = ["createdBy", "updatedBy"];

                let stateToUpdate = this.state;

                this.state.messages.forEach((row, rowIndex) => {
                    usersFields.forEach((column) => {
                        const preLookedUpValue = row[column];

                        // Find the value
                        const lookedUpValue = results.data.results.filter((lookedUpData) => {
                            return lookedUpData.uuid == preLookedUpValue;
                        }).pop();


                        if (lookedUpValue !== undefined) {
                            stateToUpdate = update(stateToUpdate, {
                                messages: {
                                    [rowIndex]: {
                                        [column]: {
                                            $set: `${lookedUpValue.first_name} ${lookedUpValue.last_name}`,
                                        }
                                    }
                                }
                            })
                        }
                    })
                })

                usersFields.forEach((column) => {
                    const preLookedUpValue = this.state.request[column];

                    // Find the value
                    const lookedUpValue = results.data.results.filter((lookedUpData) => {
                        return lookedUpData.uuid == preLookedUpValue;
                    }).pop();


                    if (lookedUpValue !== undefined) {
                        stateToUpdate = update(stateToUpdate, {
                            request: {
                                [column]: {
                                    $set: `${lookedUpValue.first_name} ${lookedUpValue.last_name}`,
                                }
                            }
                        })
                    }
                })

                stateToUpdate = update(stateToUpdate, {
                    state: {
                        $set: this.COMPONENT_STATES.READY,
                    },
                })

                this.setState(stateToUpdate);
            })
            .catch((err) => {
                console.error(err);
                // We still want the data to show even if the uuids are exposed so do nothing
            })
    }

    handleMessageContentChange(event) {
        this.setState({
            newMessageContent: event.target.value,
        })
    }

    submitMessage(event) {
        if (this.state.newMessageContent.length == 0) {
            this.props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    show: true,
                    type: "error",
                    title: "Message Required",
                    message: [
                        "Please enter a message to be sent for this support request"
                    ],
                    okayButtonText: "Okay"
                },
            });
        } else {
            this.props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    "show": true,
                    "type": "processing",
                    "title": "Submtting message",
                    "message": [
                        "This might take a few seconds.",
                        "You will be shown a confirmation screen once completed."
                    ]
                },
            });

            setTimeout(() => {
                axiosBackend({
                    method: "PATCH",
                    url: `/support-requests/`,
                    data: {
                        uuid: this.props.router.params.uuid,
                        message: this.state.newMessageContent,
                    }
                })
                    .then((response) => {
                        this.loadSupportRequest();

                        this.setState({
                            newMessageContent: "",
                        }, () => {
                            this.props.dispatch({
                                type: "ResolvedData",
                                name: "ModalData",
                                data: {
                                    show: true,
                                    type: "success",
                                    title: "Success",
                                    message: [
                                        "Your message was submitted successfully",
                                    ],
                                    okayButtonText: "Okay",
                                },
                            });
                        })
                    })
                    .catch((err) => {
                        console.error(err);

                        this.props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                show: true,
                                type: "error",
                                title: "Error",
                                message: [
                                    "Due to an error, we were unable to add your message to the support request",
                                    "‏‏‎ ‎",
                                    "Please try again in a little while."
                                ],
                                okayButtonText: "Okay"
                            },
                        });
                    })
            }, 1000);
        }
    }

    componentDidMount() {
        resolve(this.props, () => {
            this.loadSupportRequest();
        });
    }

    toggleCaseClosed() {
        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Confirm Change",
                "message": [
                    "Are you sure you would like to mark this support request as resolved?"
                ],
                yesButtonText: "Yes",
                noButtonText: "No",

                onYes: (onClose) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Updating Support Request",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "PATCH",
                            url: `/support-requests/${this.props.router.params.uuid}/status`,
                            data: {
                                status: "resolved",
                            }
                        })
                            .then((response) => {
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            "This support request was successfully resolved",
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });

                                this.loadSupportRequest();
                            })
                            .catch((err) => {
                                console.error(err);
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Error",
                                        message: [
                                            "Due to an error, we were unable to update the status for your support request",
                                            "‏‏‎ ‎",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    render() {
        return (
            <div className="col-sm-12">
                {this.state.state === this.COMPONENT_STATES.ERROR ? <ErrorPage hideSidebar={true} /> : undefined}

                {this.state.state === this.COMPONENT_STATES.LOADING ? <Loader /> : undefined}

                {this.state.state === this.COMPONENT_STATES.NOT_FOUND ? <ErrorPage 
                    icon="fa fa-robot"
                    title="Not Found" 
                    message={[
                        "The support request you are trying to view does not exist.",
                        "Please check the url and try again."
                    ]} 
                    hideSidebar={true} 
                /> : undefined}

                {this.state.state === this.COMPONENT_STATES.READY ?
                    <>
                        <div className="row">
                            <div className="col-lg-12 pb-2">
                                <button className="btn btn-gray">
                                    <span className="far fa-question-circle fa-lg"></span>
                                </button>
                            </div>
                        </div>


                        <div className="">
                            <div className="">
                                <div className="social-feed-box">
                                    <div className="social-avatar">
                                        <h4 className="inline mb-0">{this.state.request.title}</h4> for <span to={"../"}>{this.state.request.site_uuid} </span>

                                        <span className={`label ${supportStatusLabelColor(this.state.request.status)}`}>
                                            {ToTitleCase(this.state.request.status) || "Unknown"}
                                        </span>


                                        <div className="btn-group float-right">
                                            <button
                                                className="btn btn-primary btn-xs"
                                                onClick={this.toggleCaseClosed}
                                            >
                                                <i className="fas fa-check"></i>&nbsp;Mark as resolved
                                            </button>
                                        </div>

                                        <br />

                                        <small className="text-muted">
                                            Raised by {this.state.request.createdBy} - {moment(this.state.request.createdOn).local().format("LLL")}
                                        </small>
                                    </div>
                                    <div className="social-body">
                                        <p className="nl2br">
                                            {this.state.request.description}
                                        </p>


                                    </div>

                                    <div className="social-footer">
                                        {
                                            this.state.messages.length > 0 ?
                                                this.state.messages.map((message, index) => {
                                                    return (
                                                        <div key={message.uuid} className="social-comment">
                                                            <a href="social_feed.html" className="float-left">
                                                                <img alt="image" src="/img/user.svg" />
                                                            </a>
                                                            <div className="media-body">
                                                                <strong>{message.createdBy}</strong>
                                                                <br />
                                                                <span className="nl2br">
                                                                    {message.message}
                                                                </span>
                                                                <br />
                                                                <small className="text-muted">
                                                                    {moment(message.createdOn).local().fromNow()}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    )

                                                })
                                                :
                                                undefined
                                        }

                                        <div className="social-comment">
                                            <div className="media-body">
                                                <div className="input-group">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Write a new message"
                                                        onChange={this.handleMessageContentChange}
                                                        value={this.state.newMessageContent}
                                                    ></textarea>
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-primary"
                                                            type="button"
                                                            onClick={this.submitMessage}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                    :
                    undefined
                }

            </div>
        )
    }
}

export default withLoaderData(connect(mapStateToProps)(ViewSupportRequest));