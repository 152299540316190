import { useEffect } from "react";
import { 
    Outlet,
    useNavigate,
} from "react-router-dom";

import simpleAuth from "../../core/auth/simpleAuth";

export default function RootTemplate() {
    const navigate = useNavigate();

    useEffect(() => {
        
        if(!simpleAuth.isAuthenticated) {
            navigate("/login");
        }
    }, [simpleAuth.isAuthenticated]);

    return (
        <>
            <div id="wrapper">
                <div id="page-wrapper" className="default-bg dashbard-1">
                    <Outlet />
                </div>
            </div>
        </>
    );
}