import {
    Outlet,
} from "react-router-dom";

import Breadcrumbs from "../../components/Breadcrumbs";
import withLoaderData from "../../components/withLoaderData";

import { connect } from "react-redux";
import { resolve, mapStateToProps } from "../../components/smart/delegator/DataDelegator";
import React from "react";

function BreadcrumbsTemplate(props) {
    React.useEffect(() => {
        resolve(props, (err) => {
            if (err) {
                throw err;
            }
        });
    }, []);

    const appUUID = props.router.params.appUUID;
    const title = props.title;

    const transform =  {
        "title": "`data.results | search-array-for-object: uuid," + appUUID + " | pop | prop: name`",
        "uuid": appUUID,
        "root": props.root,
    };

    // If it is manually provided
    if(appUUID == undefined && title) {
        transform.title = title;
    }

    // if(props.root == undefined) {
    //     transform.root = "/app"
    // }

    return (
        <>
            <Breadcrumbs
                robostackResolveData={[
                    {
                        "name": "ListOfApps",
                        "api": {
                            "method": "POST",
                            "endpoint": "/apps",
                            "data": {}
                        },
                        "transformName": "Breadcrumbs",
                        "mergeWithSourceAfterTransform": true,
                        "transform": transform,
                    }
                ]}
            />

            <div className="row mt-2">
                <Outlet />
            </div>
        </>
    );
}

export default withLoaderData(connect(mapStateToProps)(BreadcrumbsTemplate));