import React from "react";

export default function render(value) {
    if(value !== undefined) {
        return <div className="">{value === true ? 
            <span title="Yes"><i className="fas fa-check"></i></span>
            : 
            <span title="No"><i className="fas fa-times"></i></span>
        }</div>;
    }
    
    return value;
}