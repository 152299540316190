import React from "react";

const MarkdownIt = require('markdown-it');
const HtmlToReactParser = require('html-to-react').Parser;

export default function render(value) {
    if (typeof value == "string" && value.length > 0) {
        const htmlToReactParser = new HtmlToReactParser();
        const md = new MarkdownIt({
            html: true,
        });

        try {
            const rendered = md.render(value);

            return htmlToReactParser.parse(rendered);
        } catch (err) {
            console.error(err);

            return value;
        }
    } else {
        return value;
    }
}