import CodeBlock from "./supported/CodeBlock";
import MagicForm from "./supported/MagicForm";
import MediaBlock from "./supported/MediaBlock";
import RecordViewer from "./supported/RecordViewer";
import SimpleTable from "./supported/SimpleTable";
import StatisticCard from "./supported/StatisticCard";
import UnorderedList from "./supported/UnorderedList";


export default {
    // List components
    "UnorderedList": UnorderedList,
    "MediaBlock": MediaBlock,

    "CodeBlock": CodeBlock,
    
    "StatisticCard": StatisticCard,
    
    // Robostack features
    "RecordViewer": RecordViewer,
    "MagicForm": MagicForm,
    "SimpleTable": SimpleTable,
};
