import React from 'react';
import update from "immutability-helper";

import { connect } from 'react-redux';

import { v4 as uuid } from "uuid";


import { resolve, mapStateToProps } from "../smart/delegator/DataDelegator";
import MagicForm from "../smart/supported/MagicForm";
import Page from '../smart/Page';

class DataCollectionModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            instanceIdentifier: uuid(),
            forceFormSubmission: false,
        }

        this.handleFormSubmission = this.handleFormSubmission.bind(this);
        this.forceFormSubmission = this.forceFormSubmission.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            if (this.props.show === true) {
                $(`#modal-data-collection-${this.state.instanceIdentifier}`).modal({ backdrop: 'static', keyboard: false });
            } else {
                $(`#modal-data-collection-${this.state.instanceIdentifier}`).modal('hide');
            }
        }
    }

    componentDidMount() {
        resolve(this.props, () => {
            if (this.props.show === true) {
                $(`#modal-data-collection-${this.state.instanceIdentifier}`).modal({ backdrop: 'static', keyboard: false });
            }
        });
    }

    onClose() {
        if (typeof this.props.onClose == "function") {
            this.props.onClose();
        } else if (this.props.onClose !== null && typeof this.props.onClose == "object") {
            if (this.props.onClose.type) {
                switch (this.props.onClose.type) {
                    case "redirect": {
                        if (this.props.onClose.redirect === true && this.props.onClose.redirect_url.length > 0) {
                            window.location = this.props.onClose.redirect_url;
                        }
                        break;
                    }

                    default: {
                        console.log(`Unknown onClose type ${this.props.onClose.type}`);
                        break;
                    }
                }
            }
        }

        $(`#modal-data-collection-${this.state.instanceIdentifier}`).modal('hide');
    }

    handleFormSubmission(err, tuple, onSubmitCallback) {
        let stateToUpdate = this.state;

        stateToUpdate = update(stateToUpdate, {
            forceFormSubmission: {
                $set: false,
            },
        });

        if (err) {
            onSubmitCallback(err);
        } else if (typeof this.props.onYes == "function") {
            this.props.onYes(this.onClose.bind(this), tuple);
        } else {
            this.onClose();
        }

        this.setState(stateToUpdate);
    }

    forceFormSubmission() {
        this.setState({
            forceFormSubmission: true,
        });
    }

    render() {
        console.log(this.constructor.name, "render");

        const message = Array.isArray(this.props.message) ? this.props.message : [this.props.message];

        return (
            <div id={`modal-data-collection-${this.state.instanceIdentifier}`} className="modal fade" tabIndex="-1">
                <div className={`modal-dialog ${this.props.modalSize === "large" ? "modal-xl" : ""}`}>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className={Array.isArray(this.props.modelBodyClasses) ? this.props.modelBodyClasses.join(" ") : "text-center"}>
                                <p className="h4 pb-2 text-bold">{this.props.title}</p>
                                {Array.isArray(this.props.iconClasses) ?
                                    <h1 className="error-code">
                                        <i className={this.props.iconClasses.join(" ")}></i>
                                        <br />
                                    </h1>
                                    :
                                    null
                                }

                                <div className="pad-btm">
                                    {message.map((m, index) => <div key={`confirmation_${index}_${m}`}>{m}</div>)}
                                </div>

                                {this.props.show === true ?
                                    <MagicForm
                                        forceFormSubmission={this.state.forceFormSubmission}
                                        onForcedFormSubmission={this.handleFormSubmission}
                                        {...this.props}
                                    />

                                    :

                                    undefined
                                }

                                {this.props.components !== undefined ? <Page robostackResolveData={this.props.components} /> : null}
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-start">
                            <button aria-label="No Button" onClick={() => typeof this.props.onNo == "function" ? this.props.onNo(this.onClose.bind(this)) : this.onClose()} type="button" className="btn btn-danger">
                                <i className="fas fa-times"></i>&nbsp;{this.props.noButtonText}
                            </button>

                            <button aria-label="Yes Button" onClick={this.forceFormSubmission} type="button" className="btn btn-primary ml-auto">
                                <i className="fas fa-check"></i>&nbsp;{this.props.yesButtonText}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(DataCollectionModal);