import ScheduledTasks from '../pages/app/scheduled-tasks';
import StandardLoader from './standard-loader';


export default function ScheduledTasksRouter() {
    return {
        path: "/app/:appUUID/scheduled-tasks",

        children: [
            {
                index: true,
                element: <ScheduledTasks />,
                loader: (props) => StandardLoader({
                    ...props,
                    crumbs: [
                        {
                            "name": "Scheduled Tasks Management",
                        },
                    ]
                }),
            },
        ]
    };
}