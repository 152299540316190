import React from 'react';

import { connect } from 'react-redux';

import { resolve, mapStateToProps } from "../smart/delegator/DataDelegator";

import SuccessModal from "./SuccessModal";
import ProcessingModal from "./ProcessingModal";
import ErrorModal from "./ErrorModal";
import ConfirmationModal from "./ConfirmationModal";
import DataCollectionModal from './DataCollectionModal';

class ModalSwitcher extends React.Component {
    constructor(props) {
        super(props);

        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        resolve(this.props);
    }

    onClose() {
        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                show: false,
            },
        });
    }

    render() {
        // We do not want to pass down robostackResolveData as we already have resolved it here in this component
        const propsToPassDown = {...this.props};
        delete propsToPassDown.robostackResolveData;

        return (
            <>
                <ProcessingModal
                    {...propsToPassDown}
                    show={this.props.type === "processing" && this.props.show === true}
                    onClose={this.onClose}
                />

                <ErrorModal
                    {...propsToPassDown}
                    show={this.props.type === "error" && this.props.show === true}
                    onClose={this.onClose}
                />

                <ConfirmationModal
                    {...propsToPassDown}
                    show={this.props.type === "confirmation" && this.props.show === true}
                    onClose={this.onClose}
                />

                <SuccessModal
                    {...propsToPassDown}
                    show={this.props.type === "success" && this.props.show === true}
                    onClose={this.onClose}
                />

                <DataCollectionModal
                    {...propsToPassDown}
                    show={this.props.type === "data-collection" && this.props.show === true}
                    onClose={this.onClose}
                    robostackResolveData={this.props.type === "data-collection" && this.props.show === true ? this.props.robostackResolveData : []}
                />
            </>
        );
    }
}

export default connect(mapStateToProps)(ModalSwitcher);