import React from "react";
import readValue from "./value";

export default function render(value, accessor, tuple) {
    value = readValue(value, accessor, tuple);

    if(value != null) {
        return (
            <span>
                <span title={value}><i className="fas fa-user"></i></span>&nbsp;&nbsp;{value}
            </span>
        );
    } 
    
    return ;
}