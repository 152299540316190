import React from "react";

import { connect } from "react-redux";
import * as DataDelegator from "../../../../components/smart/delegator/DataDelegator";

class SelectTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.moveToNextStep = this.moveToNextStep.bind(this);
    }

    moveToNextStep() {
        if (this.props.selectedTemplate !== undefined || this.props.selectedReport !== undefined) {
            this.props.jumpToStep(1);
        } else {
            this.props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    show: true,
                    type: "error",
                    title: "Please select a template",
                    message: [
                        "Please select an existing report or template to start designing it"
                    ],
                    okayButtonText: "Okay"
                },
            });
        }
    }

    componentDidMount() {
        DataDelegator.resolve(this.props);
    }

    selectTemplate(event, uuid) {
        this.props.dispatch({
            type: "ResolvedData",
            name: "Report_SelectedTemplate",
            data: {
                selectedTemplate: uuid,
            },
        });

        // So that only the report or the template is selected but not both
        this.props.dispatch({
            type: "ResolvedData",
            name: "Report_SelectedReport",
            data: {
                selectedReport: undefined,
            },
        });

        // To overwrite the existing template that is already there
        this.props.dispatch({
            type: "ResolvedData",
            name: "Report_Design",
            data: {
                selectedQuery: undefined,
                reportType: undefined,
                template: undefined,
                image: undefined,
            },
        });


        if (event.detail === 2) {
            this.props.jumpToStep(1);
        }
    }

    selectReport(event, report) {
        this.props.dispatch({
            type: "ResolvedData",
            name: "Report_SelectedReport",
            data: {
                selectedReport: report.uuid,
            },
        });

        // So that only the report or the template is selected but not both
        this.props.dispatch({
            type: "ResolvedData",
            name: "Report_SelectedTemplate",
            data: {
                selectedTemplate: undefined,

            },
        });

        // To overwrite the existing template that is already there
        this.props.dispatch({
            type: "ResolvedData",
            name: "Report_Design",
            data: {
                image: undefined,
                template: report.template,
                reportType: report.reportType,
                selectedQuery: report.query_uuid,
            },
        });


        if (event.detail === 2) {
            this.props.jumpToStep(1);
        }
    }

    render() {
        return (
            <>
                <div className="row mt-4 mb-2">
                    <div className="col-lg-12">
                        <div className="btn btn-primary float-right" onClick={this.moveToNextStep} disabled={this.props.selectedTemplate == undefined && this.props.selectedReport == undefined}>Next</div>
                    </div>
                </div>

                <div className="">
                    <div className="ibox-content">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3>Available Templates</h3>
                            </div>
                        </div>
                        <div className="row">
                            {this.props.templates == undefined || this.props.templates.length == 0 ? <div className="col-lg-3">No templates available</div> : undefined}

                            {this.props.templates && this.props.templates.map(template => {
                                return (
                                    <div key={template.uuid} className="col-lg-3 text-center">
                                        <div onClick={(event) => this.selectTemplate(event, template.uuid)} className={`${this.props.selectedTemplate == template.uuid ? "active" : ""} template pointer col-lg-12 border-top pad-all`}>
                                            <div>
                                                <img className="mt-2 mb-2 fh-300 img-fluid" src={template.image} />
                                                <div className="profile-content">
                                                    <h5>
                                                        {template.name}
                                                    </h5>
                                                    <p>
                                                        {template.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-12 mt-4">
                                <h3>Existing Reports</h3>
                            </div>
                        </div>
                        <div className="row">
                            {this.props.reports == undefined || this.props.reports.length == 0 ? <div className="col-lg-3">No existing reports available</div> : undefined}

                            {this.props.reports && this.props.reports.map(report => {
                                return (
                                    <div className="col-lg-3 text-center">
                                        <div onClick={(event) => this.selectReport(event, report)} className={`${this.props.selectedReport == report.uuid ? "active" : ""} template pointer col-lg-12 border-top pad-all`}>
                                            <div>
                                                <img className="mt-2 mb-2 fh-300 img-fluid" src={report.image} />
                                                <div className="profile-content">
                                                    <h5>
                                                        {report.name}
                                                    </h5>
                                                    <p>
                                                        {report.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default connect(DataDelegator.mapStateToProps)(SelectTemplate);