import BreadcrumbsTemplate from '../pages/templates/breadcrumbs';

import SupportAndTraining from "pages/support-and-training";
import ViewSupportRequest from "pages/support-and-training/ViewSupportRequest";

import StandardLoader from "./standard-loader";

export default function SupportRequestsRouter() {
    return {
        path: "/support",
        children: [
            {
                element: <BreadcrumbsTemplate
                    title="Support & Training"
                />,


                children: [

                    {
                        index: true,
                        element: <SupportAndTraining />,
                    },

                    {
                        path: "/support/request/:uuid",
                        element: <ViewSupportRequest />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "View",
                                },
                            ]
                        }),
                    },
                ]
            }
        ]
    }
}