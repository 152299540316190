import StandardLoader from './standard-loader';
import UsersManagement from '../pages/app/users-management';

export default function UsersManagementRouter() {
    return {
        path: "/app/:appUUID/users-management",

        children: [
            {
                index: true,
                element: <UsersManagement />,
                loader: (props) => StandardLoader({
                    ...props,
                    crumbs: [
                        {
                            "name": "Users & Roles Management",
                        },
                    ]
                })
            },
        ]
    };
}