export default function ToTitleCase(str) {
    if (str !== null) {
        return str.replace(
            /\w\S*/g,
            (txt) => {
                return txt.charAt(0).toUpperCase() + txt.substr(1)
            },
        );
    }

    return str;
};