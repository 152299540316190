import React from "react";
import { connect } from "react-redux";

import * as DataDelegator from "../../../components/smart/delegator/DataDelegator";

import withLoaderData from "../../../components/withLoaderData";
import ErrorPage from "pages/error";

export const introductionIdentifier = "triggers/index";

class Triggers extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="col-lg-12">
                <div className="mt-2 mb-2">
                    <ErrorPage
                        icon="fas fa-hourglass-half"
                        hideSidebar={true}
                        title="Feature Coming Soon"
                        message={[
                            "This feature is currently available to a select group of users",
                            "and will become generally available soon"
                        ]}
                    />
                </div>
            </div >
        );
    }
}

Triggers.propsInformation = {

};

export default withLoaderData(connect(DataDelegator.mapStateToProps)(Triggers));