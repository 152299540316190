import React from "react";
import * as ejs from "ejs";
import axiosFileserver from "../../../../../../core/api/fileserver";

import removeCustomProps from "../../removeCustomProps";
import defaultUpdateProps from "../../defaultUpdateProps";
import isURL from "validator/lib/isURL";

function isValidURL(value) {
    return isURL(value, {
        require_tld: "__ROBOSTACK_BUILD_NODE_ENV__" === "production",
    })
}

export const defaultValidationMessages = {
    invalid: "The provided file does not seem to be stored at a valid url",
    required: "This is a required value",
}

// The purpose of this function is to format the value as needed for the field type in the event it needs to be set exteranlly for any reason
export function formatValue({ fieldConfiguration, value }) {
    let formattedValue = value;

    return formattedValue;
}

export function process({ fieldConfiguration, value, fields }, callbackFn) {
    validate(fieldConfiguration, value, (err, updateProps) => {
        if (err) {
            callbackFn({
                error: err,
                fieldConfiguration,
            });
        } else {
            const hasError = updateProps.error !== undefined && updateProps.error.length > 0;

            if (hasError === false) {
                // This can occur when the field is not required and the file upload was not toggled at all
                if (value === undefined) {
                    callbackFn(null, {
                        valid: true,
                        fieldConfiguration,
                        value,
                    })
                } else {
                    if (typeof value === "string") {
                        // Validation for this would have been handled by the validation stage
                        callbackFn(null, {
                            valid: true,
                            fieldConfiguration,
                            value,
                        })
                    } else {
                        try {
                            let bodyFormData = new FormData();
                            bodyFormData.append('file', value);

                            // `filename` is stored on the fileserver in such a way that when the file is downloaded,
                            // the `filename` is the name given to the file when it is downloaded
                            const filename = fieldConfiguration.filename;
                            if (filename != undefined) {
                                bodyFormData.set("filename", ejs.render(filename, fields));
                            }

                            axiosFileserver({
                                method: 'PUT',
                                url: '/upload',
                                data: bodyFormData,
                                config: {
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded'
                                    }
                                }
                            })
                                .then(function (response) {
                                    callbackFn(null, {
                                        valid: true,
                                        fieldConfiguration,
                                        value: response.data.url,
                                    })
                                })
                                .catch(function (response) {
                                    console.error(response);
                                    
                                    callbackFn({
                                        error: response.data,
                                        fieldConfiguration,
                                    });
                                });
                        } catch (err) {
                            console.log(err);

                            if (err.name === "ReferenceError") {
                                callbackFn({
                                    errorMessage: true,
                                    error: new Error("The filename configuration is using unavailable fields for its name"),
                                    fieldConfiguration,
                                });
                            } else {
                                callbackFn({
                                    error: err,
                                    fieldConfiguration,
                                });
                            }
                        }
                    }
                }
            } else {
                callbackFn(null, {
                    valid: false,
                    fieldConfiguration,
                    value,
                })
            }
        }
    })
}

export function validate(fieldConfiguration, value, callbackFn) {
    let updateProps = defaultUpdateProps(fieldConfiguration);

    console.log("file", value)

    if (["", undefined].includes(value)) {
        if (fieldConfiguration.required === true) {
            updateProps.error = defaultValidationMessages.required;
        }
    } else {
        if (typeof value === "string") {
            if (isValidURL(value) === false) {
                updateProps.error = defaultValidationMessages.invalid;
            }
        }
    }

    if (typeof callbackFn == "function") {
        callbackFn(null, updateProps);
    }
}

// Clear the value that is currently being stored
export function clear(fieldConfiguration) {
    if (fieldConfiguration.value !== undefined) {
        const value = null;

        validate(fieldConfiguration, value, (err, updateProps) => {
            updateProps.manuallyChanged = true;

            fieldConfiguration.onChange(value, {
                ...updateProps,

                forceUpdate: true, // Because we depend on the value for rendering the file name being shown
            });
        })
    }
}

export function render(fieldConfiguration) {
    console.log("file render");

    // We only want to consider `value` as the official value to avoid any confusions
    delete fieldConfiguration.defaultValue;

    let props = {
        ...fieldConfiguration,

        className: fieldConfiguration.classes.concat("custom-file").join(" "),
    };

    let fileLabelText = "";
    let isEmpty = true;

    if (props.value && props.value !== undefined && props.value.name !== undefined) {
        isEmpty = false;
        fileLabelText = decodeURI(props.value.name.split('/').pop());
    } else if (props.value && props.value !== undefined && typeof props.value === "string") {
        isEmpty = false;

        if (isValidURL(props.value)) {
            fileLabelText = decodeURI(props.value.split('/').pop());
        } else {
            fileLabelText = props.value;
        }
    } else {
        fileLabelText = props.placeholder;
    }

    props.onChange = (event) => {
        const value = event.target.files[0];

        validate(fieldConfiguration, value, (err, updateProps) => {
            updateProps.manuallyChanged = true;
            
            fieldConfiguration.onChange(value, {
                ...updateProps,

                forceUpdate: true, // Because we depend on the value for rendering the file name being shown
            });
        })

        return false;
    }

    // Delete these because they throw an error otherwise
    // and we would have used them by now
    delete props.value;

    props = removeCustomProps(props);

    return (
        <div className="input-group">
            <div className="custom-file">
                <input type="file" {...props} />
                <label
                    id={`${fieldConfiguration.id}-file-text`}
                    htmlFor={props.id}
                    className={`text-left custom-file-label ${props.disabled ? "disabled" : ""} ${isEmpty ? "empty" : ""}`}
                >
                    {fileLabelText}
                </label>
            </div>

            <div className="input-group-append">
                <button
                    aria-label="Remove"
                    id={`${fieldConfiguration.id}-file-clear`}
                    type="button"
                    onClick={() => clear(fieldConfiguration)}
                    className="custom-file-close btn btn-danger"
                    disabled={props.disabled}
                >
                    Remove
                </button>
            </div>
        </div>
    );
}