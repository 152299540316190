import React from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import { resolve, mapStateToProps } from "../delegator/DataDelegator";

class StatisticCard extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        resolve(this.props);
    }

    render() {
        return (
            <Link to={this.props.link}>
                <div className={`widget style1 ${!!this.props.classes ? this.props.classes.join(" ") : ""}`}>
                    <div className="superflex">
                        <div className={!!this.props.iconParentClasses ? this.props.iconParentClasses.join(" ") : "item"}>
                            <i className={!!this.props.iconClasses ? this.props.iconClasses.join(" ") : ""}></i>
                        </div>
                        <div className="item">
                            {
                                this.props.description !== undefined
                                    ?
                                    <span className={`mar-no ${!!this.props.descriptionClasses ? this.props.descriptionClasses.join(" ") : "font-bold"}`}>{this.props.description}</span>
                                    :
                                    undefined
                            }
                            <div className={`${!!this.props.valueClasses ? this.props.valueClasses.join(" ") : "h2"}`}> {this.props.value} </div>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

StatisticCard.propsInformation = {
    link: {
        type: "STRING",
        description: "The link the card will open when clicked"
    },
    classes: {
        type: "Array",
        description: "The classes to be used to style the card"
    },
    iconParentClasses: {
        type: "Array",
        description: "The icon classes to be used to style the parent of the icon"
    },
    iconClasses: {
        type: "Array",
        description: "The icon classes to be used to render the icon"
    },
    valueClasses: {
        type: "Array",
        description: "The icon classes to be used to style the numeric value of the card"
    },
    value: {
        type: "STRING",
        description: "The numeric value to be placed at the right of the icon"
    },
    descriptionClasses: {
        type: "Array",
        description: "The icon classes to be used to style the description of the card"
    },
    description: {
        type: "STRING",
        description: "The text to be placed under the numeric value"
    },
};

export default connect(mapStateToProps)(StatisticCard);