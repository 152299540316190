export default (fieldConfiguration) => {
    return {
        error: "",
        success: "",
        warning: "",

        // These are used internally by the form
        instanceIndex: fieldConfiguration.instanceIndex,
        fieldName: fieldConfiguration.fieldName,

        // This is incase any form wants to force a re-render. 
        // This could be because it depends on the value being used for rendering such as in the `file` field
        forceUpdate: false, 

        manuallyChanged: fieldConfiguration.manuallyChanged,
    };
}