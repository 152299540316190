const renderElement = ({ attributes, children, element }) => {
    const style = { textAlign: element.align }
    
    switch (element.type) {
        case 'block-quote':
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            )
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'heading-one':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            )
        case 'heading-two':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            )
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            )
        case 'paragraph':
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
        case 'br':
            return (
                <br style={style} {...attributes} />
            )
        default:
            return (
                <span style={style} {...attributes}>
                    {children}
                </span>
            )
    }
}

export default renderElement;