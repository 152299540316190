import LogRocket from 'logrocket';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import { Provider } from "react-redux";

import ConfigureStore from "./reduxconfig/ConfigureStore";
import { setStore } from "./components/smart/delegator/DataDelegator";
import routes from './routes';

if (process.env.NODE_ENV === "production") {
    LogRocket.init('zf24ty/approbostackai');
}

let store = ConfigureStore({});
setStore(store);

function App() {
    const router = createBrowserRouter(routes());

    return (
        <div className="hidden-bar-wrapper">
            <Provider store={store}>
                <RouterProvider router={router} />

            </Provider>
        </div>
    );
}

export default App;
