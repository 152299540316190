export const MagicFieldType = (type) => {
    switch (type.toUpperCase()) {
        case "AUTONUMBER":
            return "id";

        case "DECIMAL":
        case "INTEGER":
            return "number";

        case "BOOLEAN":
            return "checkbox";

        case "CURRENCY":
            return "currency";

        case "PASSWORD":
            return "password";

        case "DATETIME":
            return "datetime";

        case "TIME":
            return "time";

        case "DATE":
            return "date";

        case "FILE":
            return "file";

        case "MARKDOWN":
            return "markdown";

        case "FIXEDLENGTH":
            return "text";

        case "EMAIL":
            return "email";

        case "STRING":
            return "textarea";

        case "LOOKUP":
            return "dropdown";

        case "APPLICATION_USER":
            return "user";
        
        case "APPLICATION_USERS":
            return "users";

        case "AGGREGATE":
            return "aggregate";
    
        case "JSON_ARRAY":
            return "array";

        case "JSON":
            return "json";

    }
};

// AUTONUMBER is not skipped because the MagicForm in the frontend might use it to redirect to the new record if an insertId is provided
export const AUTOMATIC_TYPES_TO_SKIP = [
    "AUTODATE", 
    "AUTODATE_UPDATE", 
    "AUTOUUID", 
    "USER", 
    "USER_UPDATE",
    "IP_ADDRESS",
    "IP_ADDRESS_UPDATE",
];

export const UNIQUE_FIELDS = ["AUTOUUID", "AUTONUMBER"];

export const NUMERIC_FIELDS = ["AUTONUMBER", "DECIMAL", "INTEGER", "CURRENCY", "AGGREGATE"];