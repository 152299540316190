import React from "react";
import moment from "moment";

import { connect } from "react-redux";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import axiosBackend from "../../../core/api/backend";
import steps from "./help-steps";

import { Link } from "react-router-dom";
import withLoaderData from "../../../components/withLoaderData";

import Loader from "../../../components/Loader";
import * as DataDelegator from "../../../components/smart/delegator/DataDelegator";

import MarkIntroductionTourDone from "../../../core/onboarding/mark-introduction-tour-done";
import AddApiTokenAccess from "./add-api-token-access";

export const introductionIdentifier = "api-access-management/index";

class APIAccessManagement extends React.Component {
    constructor(props) {
        super(props);

        this.COMPONENT_STATES = {
            LOADING: "LOADING",
            LIST: "LIST",

            ERROR: "ERROR",
        }

        this.PANEL_STATES = {
            NONE: "NONE",
            ADD: "ADD",
        }

        this.state = {
            show: this.COMPONENT_STATES.LOADING,
            tokens: [],

            panelShow: this.PANEL_STATES.NONE,

            showTour: false,
            stepIndex: 0,
        };

        this.deleteToken = this.deleteToken.bind(this);

        this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);
    }

    loadTokens() {
        axiosBackend({
            method: "POST",
            url: `/service/external-api-access/${this.props.router.params.appUUID}/`,
        })
            .then((response) => {
                this.setState({
                    tokens: response.data.results,
                    show: this.COMPONENT_STATES.LIST,
                });
            })
            .catch((err) => {
                console.error(err);

                this.setState({
                    show: this.COMPONENT_STATES.ERROR,
                })
            })
    }

    deleteToken(token) {
        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Confirm Delete",
                "message": [
                    `Are you sure you would like to revoke api access for the ${token.name} token from this appliction?`,
                    "‏‏‎ ‎",
                    "You will be able to add it back later if you choose."
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Deleting Token Access",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "DELETE",
                            url: `/service/external-api-access/${this.props.router.params.appUUID}/`,
                            data: {
                                token: token.token,
                            }
                        })
                            .then((response) => {
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            `The api access for the ${token.name} token was successfully removed for this application.`,
                                            "‏‏‎ ‎",
                                            "You can add it back later at any time if you choose."
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });

                                this.loadTokens();
                            })
                            .catch((err) => {
                                console.error(err);
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Could not delete token access",
                                        message: [
                                            "Due to an error, we were unable to delete the token access for the token you had selected",
                                            "‏‏‎ ‎",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    componentDidMount() {
        this.loadTokens();

        if (this.props.router.loaderData.shouldRun) {
            this.setState({
                showTour: true,
            });
        }
    }

    toggleHelp() {
        this.setState({
            showTour: true,
        });
    }

    handleJoyrideCallback(data) {
        if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.CLOSE].includes(data.status) || ACTIONS.CLOSE == data.action) {
            // Need to set our running state to false, so we can restart if we click start again.
            let stateToUpdate = {
                showTour: false,
                stepIndex: 0,
            };

            if (this.props.router.loaderData.shouldRun) {
                MarkIntroductionTourDone({ identifier: introductionIdentifier });
            }

            this.setState(stateToUpdate);
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(data.type)) {
            // Update state to advance the tour
            this.setState({
                stepIndex: data.index + (data.action === ACTIONS.PREV ? -1 : 1),
            });
        }
    }

    render() {
        return (
            <>
                <ReactJoyride
                    callback={this.handleJoyrideCallback}
                    run={this.state.showTour}
                    stepIndex={this.state.stepIndex}
                    steps={steps}
                    continuous={true}
                    showSkipButton={true}
                    showProgress={true}
                    styles={{
                        options: {
                            zIndex: 10000,
                        },
                    }}
                    disableScrollParentFix={true}
                />

                <div className="col-lg-12 pb-2">

                    <button className="btn btn-gray" onClick={this.toggleHelp}>
                        <span className="far fa-question-circle fa-lg"></span>
                    </button>
                </div>

                <div className="col-sm-8">
                    <div className="" id="api-access-tokens-list">
                        <div className="ibox-content">
                            {this.state.show !== this.COMPONENT_STATES.ERROR &&
                                <>
                                    <h2>API Access Management</h2>
                                    <p>
                                        Control what tokens can access this application via API endpoints
                                    </p>
                                </>
                            }

                            {this.state.show == this.COMPONENT_STATES.LOADING && <Loader />}

                            {this.state.show === this.COMPONENT_STATES.ERROR &&
                                <div className="pad-all">
                                    <Error500 />
                                </div>
                            }

                            {this.state.show == this.COMPONENT_STATES.LIST &&

                                <div className="table-responsive">
                                    <table className="table table-striped table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th className="col-2">Token Name</th>
                                                <th>Token Key</th>
                                                <th>Active</th>
                                                <th id="tokens-list-expiry-column">Expires In</th>
                                                <th id="tokens-list-owner-column">Owned By</th>
                                                <th>Added On</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.tokens.length == 0 &&
                                                <tr className="m-2">
                                                    <td>
                                                        No api tokens are allowed to access this application yet
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            }

                                            {this.state.tokens.map((token) => {
                                                return (
                                                    <tr key={token.token}>
                                                        <td>{token.name}</td>
                                                        <td>{token.token}</td>
                                                        <td className={`${token.forceExpired === false ? "text-success" : "text-danger"}`}>
                                                            {token.forceExpired === false ? "Yes" : "No"}
                                                        </td>
                                                        <td className={`${moment.unix(token.exp).diff(new Date()) <= 0 ? 'text-danger' : ''}`}>
                                                            {moment.unix(token.exp).fromNow()}
                                                        </td>
                                                        <td>
                                                            {token.ownedBy}
                                                        </td>
                                                        <td>
                                                            {token.addedToSiteOn}
                                                        </td>
                                                        <td>
                                                            <button
                                                                onClick={() => this.deleteToken(token)}
                                                                type="button"
                                                                className="btn btn-danger btn-sm ml-2 btn-delete-token"
                                                                title="Delete"
                                                            >
                                                                <span className="fa fa-trash-alt"></span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="col-sm-4">
                    {this.state.panelShow == this.PANEL_STATES.ADD &&
                        <AddApiTokenAccess 
                            onSuccess={this.loadTokens.bind(this)}
                        />
                    }

                    {this.state.panelShow == this.PANEL_STATES.NONE &&
                        <div className="ibox-content">
                            {/* <button id="tokens-list-create" type="button" className="mr-2 btn btn-primary float-right"><i className="fas fa-plus"></i>&nbsp;Add Token Access</button> */}

                            <h2>What is API Access?</h2>
                            <p>
                                API access allows you to use the Robostack API from your own code.
                                <br />
                                <br />
                                To use the API, you will first need to create a token from the My Account Page.

                            </p>
                            <p>
                                <Link to="/my-account" className="btn btn-success">
                                    Go to My Account
                                </Link>
                            </p>
                            <p>
                                Once you have created your own token, you must then add it to this application to allow it access.
                                <br />
                                <br />

                                <button onClick={() => this.setState({
                                    panelShow: this.PANEL_STATES.ADD,
                                })} id="tokens-list-create" type="button" className="mr-2 btn btn-primary"><i className="fas fa-plus"></i>&nbsp;Add Token Access</button>

                            </p>
                        </div>
                    }
                </div>
            </>
        );
    }
}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(APIAccessManagement));