import React from "react";
import { connect } from "react-redux";

import * as DataDelegator from "../../../../components/smart/delegator/DataDelegator";

import JSONInput from "react-json-editor-ajrm";
import Select from "react-select";

var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();

const minifier = require('string-minify');
var pretty = require('pretty');

import renderTemplate from '../render-template';
import exportToImage from "../export-to-image";
import axiosBackend from "core/api/backend";

import ReactJson from "react-json-view";
import Editor from "@monaco-editor/react";


const filterQueryOptions = (candidate, input) => {
    if (input) {
        return (
            candidate.label.toLowerCase().includes(input.toLowerCase()) ||
            (
                typeof candidate.data.description === "string" &&
                candidate.data.description.toLowerCase().includes(input.toLowerCase())
            )
        )
    }
    return true;
};

const QueryOption = props => {
    const { innerProps, isDisabled, isFocused, isSelected, data, className, cx, getStyles } = props;
    return !isDisabled
        ? <div
            style={getStyles('option', props)}
            className={cx(
                {
                    option: true,
                    'option--is-disabled': isDisabled,
                    'option--is-focused': isFocused,
                    'option--is-selected': isSelected
                },
                className
            )}
            {...innerProps}>

            <div className="issue-info" style={{ "width": "100%" }}>
                <a>
                    {props.label}
                </a>

                <small>
                    {!!data.description ? data.description : <em>No description provided</em>}
                </small>
            </div>
        </div>
        : null
}

class DesignReportMenu extends React.Component {
    constructor(props) {
        super(props);

        this.initialState = {
            locals: [],
            selectedIndex: null,

            pipelineResults: null,

            template: "",
            menuTemplate: "Click To View Report For This Record",
            errors: [],

            selectedQuery: null,
            selectedTemplate: null,
            changed: false,

            reportType: "",
            templates: [],

            panelDisplay: {
                locals: true,
                template: true,
                rendered: true,
            },

            panelDisplaySize: {
                locals: 3,
                template: 4,
                rendered: 5,
            }
        }

        this.state = this.initialState;

        this.handleLocalsDataChange = this.handleLocalsDataChange.bind(this);
        this.handleTemplateChange = this.handleTemplateChange.bind(this);

        this.handleReportTypeChange = this.handleReportTypeChange.bind(this);

        this.handleSelectedQueryChange = this.handleSelectedQueryChange.bind(this);
        this.handleSelectedTemplateChange = this.handleSelectedTemplateChange.bind(this);
        this.loadOne = this.loadOne.bind(this);
        this.loadResults = this.loadResults.bind(this);

        this.minify = this.minify.bind(this);
        this.prettify = this.prettify.bind(this);

        this.saveFieldChanged = this.saveFieldChanged.bind(this);

        this.exportTemplateAsImage = this.exportTemplateAsImage.bind(this);

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);

        this.moveToNextStep = this.moveToNextStep.bind(this);
        this.moveToPrevStep = this.moveToPrevStep.bind(this);
    }

    componentDidMount() {
        DataDelegator.resolve(this.props, (err) => {
            this.loadQueries();

            if (this.props.menuTemplate != undefined) {
                this.setState({
                    menuTemplate: this.props.menuTemplate,
                });
            }

            if (this.props.template !== undefined && this.props.reportType !== undefined) {
                this.setState({
                    reportType: this.props.reportType,
                    template: this.props.template
                });
            }
        });
    }

    moveToPrevStep() {
        this.props.dispatch({
            type: "ResolvedData",
            name: "Report_MenuDesign",
            data: {
                menuTemplate: this.state.menuTemplate,
            },
        });

        this.props.jumpToStep(1);
    }

    moveToNextStep() {
        if (this.state.menuTemplate.length == 0) {
            this.props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    show: true,
                    type: "error",
                    title: "Please design your report menu",
                    message: [
                        "Please design the menu for your report first"
                    ],
                    okayButtonText: "Okay"
                },
            });
        } else {
            this.props.dispatch({
                type: "ResolvedData",
                name: "Report_MenuDesign",
                data: {
                    menuTemplate: this.state.menuTemplate,
                },
            });

            this.props.jumpToStep(3);
        }
    }

    loadQueries() {
        const queriesConfig = {
            method: "POST",
            url: "/service/query-playground/" + this.props.router.params.appUUID,
            data: {}
        };

        axiosBackend(queriesConfig)
            .then((response) => {
                this.setState({
                    queries: response.data.results.map((query) => {
                        query.label = query.name;
                        query.description = query.description;
                        query.value = query.uuid;
                        return query;
                    }),
                }, () => {
                    if (this.props.selectedQuery !== undefined) {
                        const selectedQuery = this.state.queries.filter((query) => query.uuid == this.props.selectedQuery.uuid);

                        // If nothing was found for any reason, we simply do not select it in the dropdown
                        if (selectedQuery.length > 0) {
                            this.handleSelectedQueryChange(selectedQuery[0]);
                        }
                    }
                });
            })
            .catch((error) => {
                console.error(error);
                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Fetching Queries List of Application Failed",
                        message: [
                            "Due to a server error, we were unable to fetch the list of queries in the given application.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
    }


    exportTemplateAsImage() {
        exportToImage("rendered-template", "rendered-template")
            .catch((error) => {
                console.error(error);
                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Could Not Export To An Image",
                        message: [
                            "Due to an error, we were unable to export your template to an image.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
    }

    loadOne() {
        this.setState((prevState) => {
            let locals;
            let selectedIndex;

            if (Array.isArray(prevState.locals)) {
                selectedIndex = 0;
                locals = prevState.locals[selectedIndex];
            } else {
                locals = prevState.locals;
                selectedIndex = null;
            }

            return {
                reportType: "multiple",
                locals,
                selectedIndex,
            }
        })
    }

    loadResults() {
        this.setState((prevState) => {
            return {
                reportType: "single",
                locals: prevState.pipelineResults,
            }
        })
    }

    next() {
        if (this.state.pipelineResults.length > 0) {
            this.setState((prevState) => {
                let nextIndex = 0;

                if (prevState.selectedIndex != null) {
                    if (prevState.selectedIndex < this.state.pipelineResults.length - 1) {
                        nextIndex = prevState.selectedIndex + 1;
                    }
                }

                return {
                    selectedIndex: nextIndex,
                    locals: this.state.pipelineResults[nextIndex],
                };
            });
        }
    }

    previous() {
        if (this.state.pipelineResults.length > 0) {
            let previousIndex = this.state.pipelineResults.length - 1;

            if (this.state.selectedIndex != null) {
                if (this.state.selectedIndex > 0) {
                    previousIndex = this.state.selectedIndex - 1;
                }
            }

            this.setState({
                selectedIndex: previousIndex,
                locals: this.state.pipelineResults[previousIndex],
            })
        }
    }

    handleSelectedQueryChange(selectedQuery) {
        this.setState({
            selectedQuery
        }, () => {
            const config = {
            };

            switch (selectedQuery.operation) {
                case "read": {
                    config.method = "POST";
                    config.url = `/service/resources/${this.props.router.params.appUUID}/${selectedQuery.resource}/`;
                    config.data = selectedQuery.configuration;
                    break;
                }

                case "aggregate": {
                    config.method = "POST";
                    config.url = `/service/resources/${this.props.router.params.appUUID}/${selectedQuery.resource}/aggregation`;
                    config.data = selectedQuery.configuration.pipeline;
                    break;
                }

                default: {
                    console.log("Unknown operation: " + selectedQuery.operation)
                    break;
                }
            }

            axiosBackend(config)
                .then((response) => {
                    this.setState({
                        locals: response.data.results,
                        pipelineResults: response.data.results,
                    }, () => {
                        switch (this.props.reportType) {
                            case "multiple": {
                                this.loadOne();
                                break;
                            }

                            case "single": {
                                this.loadResults();
                                break;
                            }

                            default: {
                                console.log("Unknown reportType: " + this.props.reportType)
                            }
                        }
                    })
                })
                .catch(console.error)
        })
    }

    handleSelectedTemplateChange(selectedTemplate) {
        this.setState({
            selectedTemplate,
            templateName: selectedTemplate.name,
            templateDescription: selectedTemplate.description,
            template: selectedTemplate.template,
            reportType: selectedTemplate.reportType,
        }, () => {
            this.handleSelectedQueryChange(this.props.queries.filter(query => query.uuid == selectedTemplate.query_uuid).pop());
        })
    }

    minify() {
        this.setState((prevState) => {
            return {
                template: minifier(prevState.template),
            }
        })
    }

    prettify() {
        this.setState((prevState) => {
            return {
                template: pretty(prevState.template),
            }
        })
    }

    handleLocalsDataChange(json) {
        this.setState({
            locals: json.jsObject,
        });
    }

    handleTemplateChange(event) {
        this.setState({
            menuTemplate: event.target.value,
        });
    }

    handleReportTypeChange(reportType) {
        if (reportType.value == "single") {
            this.loadResults();
        } else if (reportType.value == "multiple") {
            this.loadOne();
        }
    }

    // For the name and description of the save query
    saveFieldChanged(name) {
        return (event) => {
            this.setState({
                [name]: event.target.value,
            })
        }
    }

    selectTab(index) {
        this.setState({
            selectedIndex: index,
            locals: this.state.pipelineResults[index],
        })
    }

    togglePanel(type) {
        return () => {
            this.setState((prevState) => {
                let panelDisplay = prevState.panelDisplay;
                panelDisplay[type] = !panelDisplay[type];

                return {
                    panelDisplay
                }
            })
        }
    }

    render() {
        let renderedTemplate;

        if (this.props.reportType == "multiple" && this.state.selectedIndex != null) {
            renderedTemplate = renderTemplate(this.state.template, this.state.locals);
        } else {
            renderedTemplate = renderTemplate(this.state.template, this.state.locals);
        }

        return (
            <>
                <div className="row mt-4 mb-2">
                    <div className="col-lg-12">
                        <div className="btn btn-primary pointer" onClick={this.moveToPrevStep}>Previous</div>
                        <div className="btn btn-primary pointer float-right" onClick={this.moveToNextStep} disabled={this.props.selectedTemplate == undefined}>Next</div>
                    </div>
                </div>

                <div className="">
                    <div className="ibox-content">
                        {
                            this.props.reportType == "single" ?
                                <div className="text-center">
                                    <div className="pad-top h6">
                                        Since you only have a single report, there is no report menu to be designed.
                                    </div>
                                </div>
                                :
                                undefined
                        }

                        {this.props.reportType == "multiple" ?
                            <>
                                <div className="row">
                                    <div className="col-sm-12">
                                        {
                                            renderedTemplate.status == "error" ?
                                                <div className="row mt-4">
                                                    <div className="col-sm-12">
                                                        <div className="alert alert-danger">
                                                            Please check your EJS data usage.<br />
                                                            The template will not render correctly if your ejs values are incorrect.
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                undefined
                                        }

                                        <div className="row mt-2">
                                            {this.state.panelDisplay.locals &&
                                                <div id="locals-panel" className={`col-sm-${this.state.panelDisplaySize.locals} panel panel-default p-0 mr-2`}>
                                                    <div className="panel-heading">
                                                        <label className="font-bold">Data:&nbsp;
                                                            <span id="data-count">
                                                                {/* {
                                                                    Array.isArray(pipelineResults) ? (
                                                                        reportType == "single" ?
                                                                            "" + pipelineResults.length + " records"
                                                                            :
                                                                            (selectedIndex + 1) + " / " + pipelineResults.length + " records"
                                                                    )
                                                                        :
                                                                        undefined
                                                                } */}
                                                            </span>
                                                        </label>

                                                        <div className="float-right">
                                                            <button onClick={this.togglePanel("locals")} className="btn btn-default btn-sm">
                                                                <i className="fa fa-times" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="row mb-2">
                                                            <div className="col-12">
                                                                <button
                                                                    disabled={this.state.reportType != "multiple"}
                                                                    onClick={this.next}
                                                                    type="button"
                                                                    className="btn btn-xs btn-white float-right mr-1"
                                                                >
                                                                    Next&nbsp;
                                                                    <i className="fa fa-arrow-right"></i>&nbsp;
                                                                </button>

                                                                <button
                                                                    disabled={this.state.reportType != "multiple"}
                                                                    onClick={this.previous}
                                                                    type="button"
                                                                    className="btn btn-xs btn-white float-right"
                                                                >
                                                                    <i className="fa fa-arrow-left"></i>&nbsp;Previous
                                                                </button>

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12">
                                                                {/* <Select
                                                                    id="select-query"
                                                                    components={{ Option: SelectWithDescriptionQueryOption }}
                                                                    filterOption={filterQueryOptionsWithDescription}
                                                                    onChange={handleSelectedQueryChange}
                                                                    options={queries && queries.map((query) => {
                                                                        query.label = query.name;
                                                                        query.description = query.description;
                                                                        query.value = query.uuid;
                                                                        return query;
                                                                    })}
                                                                    value={selectedQuery}
                                                                    noOptionsMessage={() => "No existing queries found"}
                                                                    placeholder="Select an existing query to load"
                                                                    className="mb-2"
                                                                /> */}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12">
                                                                <button
                                                                    onClick={this.toggleLocals}
                                                                    className="btn btn-xs btn-success float-right mr-2"
                                                                >
                                                                    <span className={`fas ${this.state.isResultsCollapsed ? "fa-expand-alt" : "fa-compress-alt"}`}></span>&nbsp;
                                                                    {this.state.isResultsCollapsed ? "Expand" : "Shrink"} Data
                                                                </button>
                                                            </div>
                                                            <div className="col-12">
                                                                <ReactJson
                                                                    collapsed={this.state.isResultsCollapsed}
                                                                    src={this.state.locals}
                                                                    enableClipboard={this.onLocalsCopy}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            }

                                            {this.state.panelDisplay.template &&
                                                <div id="template-panel" className={`col-sm-${this.state.panelDisplaySize.template} panel panel-default p-0 mr-2`}>
                                                    <div className="panel-heading">
                                                        <label className="font-bold">EJS Template:</label>

                                                        <div className="float-right">
                                                            <button onClick={this.togglePanel("template")} className="btn btn-default btn-sm">
                                                                <i className="fa fa-times" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <button
                                                                    onClick={() => this.insertEJSForLoop({
                                                                    })}
                                                                    className="btn btn-xs"
                                                                >
                                                                    <i className="fa fa-code" />
                                                                </button>


                                                                <div className="float-right">

                                                                    <button
                                                                        className="btn btn-xs btn-white"
                                                                        onClick={this.clear}
                                                                    >
                                                                        Clear
                                                                    </button>

                                                                    <button
                                                                        className="btn btn-xs btn-white"
                                                                        onClick={this.minify}
                                                                    >
                                                                        Minify
                                                                    </button>

                                                                    <button
                                                                        className="btn btn-xs btn-white"
                                                                        onClick={this.prettify}
                                                                    >
                                                                        Prettify
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12">
                                                                {/* <Editor
                                                                    onMount={handleEditorDidMount}
                                                                    ref={editorRef}
                                                                    language="html"
                                                                    height="10vh"
                                                                    value={template}
                                                                    onChange={(value) => setTemplate(value)}
                                                                    options={{
                                                                        minimap: {
                                                                            enabled: false
                                                                        }
                                                                    }}
                                                                /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {this.state.panelDisplay.rendered &&

                                                <div id="rendered-panel" className={`col-sm-${this.state.panelDisplaySize.rendered} panel panel-default p-0`} style={{ flexBasis: ((100 / 12 * this.state.panelDisplaySize.rendered) - 1) + "%" }}>
                                                    <div className="panel-heading">
                                                        <label className="font-bold">Rendered Report:</label>

                                                        <div className="float-right">
                                                            <button onClick={this.togglePanel("rendered")} className="btn btn-default btn-sm">
                                                                <i className="fa fa-times" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body">

                                                        <div className="row mb-2">
                                                            <div className="col-12">

                                                                <button
                                                                    className="float-right btn btn-xs btn-white"
                                                                    onClick={this.exportTemplateAsImage}
                                                                >
                                                                    Export As Image
                                                                </button>
                                                            </div>
                                                        </div>

                                                        {/* <div className="row">
                                                            <div className="col-12">

                                                                <div id="rendered-template" className="border-left-right border-top-bottom">
                                                                    {htmlToReactParser.parse(
                                                                        renderedTemplate.rendered
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        {
                                            renderedTemplate.status == "error" ?
                                                <div className="row mt-4">
                                                    <div className="col-sm-12">
                                                        <div className="alert alert-danger">
                                                            Please check your EJS data usage.<br />
                                                            The template will not render correctly if your ejs values are incorrect.
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                undefined
                                        }

                                        <div className="row mt-4">
                                            <div className="col-sm-3">
                                                <label className="font-bold">Locals Data: {
                                                    Array.isArray(this.state.pipelineResults) ? (
                                                        this.props.reportType == "single" ?
                                                            "(" + this.state.pipelineResults.length + ")"
                                                            :
                                                            "(" + (this.state.selectedIndex + 1) + " / " + this.state.pipelineResults.length + ")"
                                                    )
                                                        :
                                                        undefined
                                                }</label>



                                                <button
                                                    disabled={this.props.reportType != "multiple"}
                                                    onClick={this.next}
                                                    type="button"
                                                    className="btn btn-xs btn-white float-right mr-1"
                                                >
                                                    Next&nbsp;
                                                    <i className="fa fa-arrow-right"></i>&nbsp;
                                                </button>

                                                <button
                                                    disabled={this.props.reportType != "multiple"}
                                                    onClick={this.previous}
                                                    type="button"
                                                    className="btn btn-xs btn-white float-right"
                                                >
                                                    <i className="fa fa-arrow-left"></i>&nbsp;Previous
                                                </button>

                                                {/* <button
                                                className="float-right btn btn-xs btn-white"
                                                onClick={this.loadOne}
                                                disabled={!(Array.isArray(this.state.locals) && this.state.locals.length > 0)}
                                            >
                                                Load One
                                            </button>

                                            <button
                                                className="float-right btn btn-xs btn-white"
                                                onClick={this.loadResults}
                                                disabled={this.state.pipelineResults == null || !Array.isArray(this.state.pipelineResults)}
                                            >
                                                Load Results
                                            </button> */}

                                                <Select
                                                    components={{ Option: QueryOption }}
                                                    filterOption={filterQueryOptions}
                                                    isDisabled={true}
                                                    options={this.state.queries && this.state.queries.map((query) => {
                                                        query.label = query.name;
                                                        query.description = query.description;
                                                        query.value = query.uuid;
                                                        return query;
                                                    })}
                                                    value={this.state.selectedQuery}
                                                    noOptionsMessage={() => "No existing queries found"}
                                                    placeholder="Select an existing query to load"
                                                    className="mb-2"
                                                />

                                                <JSONInput
                                                    height="100vh"
                                                    width="100%"
                                                    viewOnly={true}
                                                    onChange={this.handleLocalsDataChange}
                                                    onBlur={this.handleLocalsDataChange}
                                                    placeholder={this.state.locals}
                                                />

                                                <button
                                                    className="float-right btn btn-xs btn-white"
                                                    onClick={this.loadOne}
                                                    disabled={!(Array.isArray(this.state.locals) && this.state.locals.length > 0)}
                                                >
                                                    Load One
                                                </button>

                                                <button
                                                    className="float-right btn btn-xs btn-white"
                                                    onClick={this.loadResults}
                                                    disabled={this.state.pipelineResults == null || !Array.isArray(this.state.pipelineResults)}
                                                >
                                                    Load Results
                                                </button>
                                            </div>
                                            <div className="col-sm-4">
                                                <label className="font-bold">Record Selection Template:</label>

                                                {/* <button
                                                className="float-right btn btn-xs btn-white"
                                                onClick={this.minify}
                                            >
                                                Minify
                                            </button>

                                            <button
                                                className="float-right btn btn-xs btn-white"
                                                onClick={this.prettify}
                                            >
                                                Prettify
                                            </button> */}

                                                <textarea
                                                    placeholder="Design your template here"
                                                    onChange={this.handleTemplateChange}
                                                    style={{ "height": "20vh" }}
                                                    className="form-control"
                                                    value={this.state.menuTemplate}></textarea>

                                                <label className="mt-4 font-bold">Select A Record To View Report:</label>

                                                <div className="full-height-scroll full-min-height">
                                                    <ul className="list-group elements-list">
                                                        {Array.isArray(this.state.pipelineResults) && this.state.pipelineResults.map((element, index) => {
                                                            let renderedElementTemplate = renderTemplate(this.state.menuTemplate, element);

                                                            return (
                                                                <li key={element.uuid} onClick={() => this.selectTab(index)} className="list-group-item">
                                                                    <a className={`nav-link ${this.state.selectedIndex == index ? "active" : ""}`}>
                                                                        {htmlToReactParser.parse(
                                                                            renderedElementTemplate.rendered
                                                                        )}
                                                                    </a>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-sm-5">
                                                <label className="font-bold">Rendered Template:</label>

                                                <button
                                                    className="float-right btn btn-xs btn-white"
                                                    onClick={this.exportTemplateAsImage}
                                                >
                                                    Export As Image
                                                </button>

                                                <div id="rendered-template" className="border-left-right border-top-bottom">
                                                    {htmlToReactParser.parse(
                                                        renderedTemplate.rendered
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            undefined
                        }
                    </div>
                </div>
            </>
        );
    }
}

DesignReportMenu.propsInformation = {
};

export default connect(DataDelegator.mapStateToProps)(DesignReportMenu);