import React from "react";
import { useLoaderData, useParams } from "react-router-dom";

import RenderComponents from "../../components/smart/RenderComponents";

export default function Page(props) {
    const params = useParams();
    
    const propsToRender = {
        ...params,
        ...props,
    };

    delete propsToRender.robostackResolveData;

    const components = RenderComponents(props.robostackResolveData, propsToRender)

    return (
        <>
            {components}
        </>
    )
}