import React, { useEffect, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";

import axiosBackend from "../core/api/backend";

import ImageWithFallback from "../components/root/ImageWithFallback";
import setWindowTitle from "../core/utils/setWindowTitle";

import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import steps from "./help-steps";
import ShouldIntroductionTourRun from "../core/onboarding/should-introduction-tour-run";
import MarkIntroductionTourDone from "../core/onboarding/mark-introduction-tour-done";

const introductionIdentifier = "applications/index";

export async function loader({ params }) {
    const [sites, shouldRun] = await Promise.all([
        axiosBackend.post("/apps"),
        ShouldIntroductionTourRun({ identifier: introductionIdentifier })
    ])

    return {
        list: sites.data,
        shouldRun,
    };
}

export default function Landing(props) {
    const {
        list: loaderList,
        shouldRun,
    } = useLoaderData();

    useEffect(() => {
        if (shouldRun) {
            setShowTour(true);
        }
    }, [shouldRun]);

    setWindowTitle("");

    const searchKeys = ["name", "uuid"];

    const [searchText, setSearchText] = React.useState("");
    const [searchResults, setSearchResults] = React.useState("");

    const search = React.useCallback((event) => {
        const value = event.target.value.trim().toLowerCase();
        setSearchText(value);

        if (loaderList.status == "success" && loaderList.results.length > 0) {
            const searchResultList = [...loaderList.results]
                .map((item) => {
                    item.hidden = (
                        value.length > 0 &&
                        searchKeys.every((search) => item[search] !== undefined && item[search] !== null && item[search].toLowerCase().indexOf(value) == -1)
                    );
                    return item;
                });

            setSearchResults(searchResultList);
        }

    }, [searchText]);

    let list;

    if (loaderList?.status == "success") {
        list = searchText == "" ? loaderList.results : searchResults;
    }

    const [showTour, setShowTour] = React.useState(false);
    const [stepIndex, setStepIndex] = React.useState(0);

    const toggleHelp = () => {
        setShowTour(true);
    }

    const handleJoyrideCallback = (data) => {
        if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.CLOSE].includes(data.status) || ACTIONS.CLOSE == data.action) {
            // Need to set our running state to false, so we can restart if we click start again.
            setShowTour(false);
            setStepIndex(0);

            if(shouldRun) {
                MarkIntroductionTourDone({ identifier: introductionIdentifier });
            }
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(data.type)) {
            // Update state to advance the tour
            setStepIndex(data.index + (data.action === ACTIONS.PREV ? -1 : 1));
        }
    }

    return (
        <>
            <ReactJoyride
                // scrollToFirstStep={true}
                callback={handleJoyrideCallback}
                run={showTour}
                stepIndex={stepIndex}
                steps={steps}
                continuous={true}
                showSkipButton={true}
                showProgress={true}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
            />



            <div className="row">
                <div className="col-lg-12 pb-2">

                    <button id="toggle-help" className="btn btn-gray" onClick={toggleHelp}>
                        <span className="far fa-question-circle fa-lg"></span>
                    </button>
                </div>

                <div className="col-sm-12">
                    <div className="form-group">
                        <input
                            id="search-applications"
                            autoFocus={true}
                            onChange={search}
                            placeholder={props.searchText ? props.searchText : "Type to search your applications"}
                            className="form-control input-lg search"
                            type="text"
                        />
                    </div>

                </div>
            </div>

            <div className="row " id="applications-list">

                {list == undefined || !Array.isArray(list) || list.filter(item => !item.hidden).length == 0 ?
                    <div className="col-sm-12 pb-3 pt-3">{props.noDataMessage || "No applications found"}</div>
                    :
                    undefined
                }

                {!!list && Array.isArray(list) && list.filter((item) => !item.hidden).map((item) => {
                    let labelType = "live";

                    if (typeof item.status == "string") {
                        switch (item.status.toLowerCase()) {
                            case "draft":
                                labelType = "draft";
                                break;

                            case "offline":
                            case "maintenance":
                                labelType = "danger";
                                break;
                        }
                    }

                    return (
                        <Link key={item.uuid} className="col-lg-3 mb-4 pb-2" to={`/app/${item.uuid}/`}>
                            <div className="card">
                                <div className="widget-header bg-primary">
                                    <ImageWithFallback
                                        className="card-img-top widget-bg img-responsive"
                                        fallbackSrc="/img/app.jpg"
                                        src={item.image}
                                        alt={item.name}
                                    />

                                    <span className={`label label-sm label-${labelType}`} style={{ "position": "absolute", "left": "1%", "top": "4%", "borderRadius": "0" }}>{item.status.toUpperCase()}</span>
                                </div>
                                <div className="card-body text-center">
                                    <h4 className="mar-no text-main">{item.name}</h4>
                                    <p className="text-muted mar-no">{item.type}</p>
                                </div>
                            </div>
                        </Link>
                    );
                })}


                <div className="col-sm-3" id='create-new-application'>
                    <Link to="/create-new-application" className="text-inherit">
                        <div className="card">
                            <div className="widget-header d-flex align-content-center justify-content-center flex-wrap p-0">
                                <i className="fas fa-plus fa-4x" />
                            </div>
                            <div className="card-body text-center">
                                <h4 className="mar-no text-main">Create New Application</h4>
                                <p className="text-muted mar-no">&nbsp;</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}