import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from "uuid";



import { resolve, mapStateToProps } from "../smart/delegator/DataDelegator";
import Page from '../smart/Page';

class ErrorModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            instanceIdentifier: uuid(),
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            if (this.props.show === true) {
                $(`#modal-error-${this.state.instanceIdentifier}`).modal({ backdrop: 'static', keyboard: false });
            } else {
                $(`#modal-error-${this.state.instanceIdentifier}`).modal('hide');
            }
        }
    }

    componentDidMount() {
        resolve(this.props, () => {
            if (this.props.show === true) {
                $(`#modal-error-${this.state.instanceIdentifier}`).modal({ backdrop: 'static', keyboard: false });
            }
        });
    }

    onClose() {
        if (typeof this.props.onClose == "function") {
            this.props.onClose();
        }

        $(`#modal-error-${this.state.instanceIdentifier}`).modal('hide');
    }

    render() {
        const message = Array.isArray(this.props.message) ? this.props.message : [this.props.message];

        return (
            <div id={`modal-error-${this.state.instanceIdentifier}`} className="modal fade" tabIndex="-1">
                <div className={`modal-dialog ${this.props.modalSize === "large" ? "modal-xl" : ""}`}>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-center">
                                <h1 className="error-code text-danger"><i className="fas fa-exclamation-triangle fa-3x"></i></h1>
                                <br />
                                <p className="h4 text-uppercase text-bold">{this.props.title}</p>
                                <div className="pad-btm">
                                    {message.map((m, index) => <div key={`error_${index}_${m}`}>{m}</div>)}
                                </div>

                                {this.props.components !== undefined ? <Page robostackResolveData={this.props.components} /> : null}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button aria-label="Okay Button" onClick={this.onClose.bind(this)} type="button" className="btn btn-primary" data-dismiss="modal">{this.props.okayButtonText}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ErrorModal);