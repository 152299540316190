import React from "react";

import MagicForm from "../../../components/smart/supported/MagicForm";
import mapStateToProps from "../../../components/smart/delegator/mapStateToProps";

import { connect } from "react-redux";
import withLoaderData from "../../../components/withLoaderData";
import axiosBackend from "../../../core/api/backend";

class EditUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            roles: [],
        };

        this.editUser = this.editUser.bind(this);
    }

    editUser({ tuple }, callback) {
        axiosBackend({
            method: "PATCH",
            url: `/service/sites-that-users-control/${this.props.router.params.appUUID}/update-roles-mapped-to-user`,
            data: {
                selection: {
                    user_uuid: this.props.user_uuid,
                },
                updation: {
                    roles: tuple.roles
                },
            },
        })
            .then((response) => {
                this.props.onComplete && this.props.onComplete();
                
                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "success",
                        title: "Success",
                        message: [
                            "The user roles was edited successfully.",
                        ],
                        okayButtonText: "Okay",
                    },
                });
            })
            .catch((err) => {
                console.error(err);

                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Could not edit user roles",
                        message: err?.response?.data?.messages || [
                            "Due to an error, we were unable to edit the roles of the user for your application.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
            .finally(callback);
    }

    loadRoles() {
        axiosBackend({
            method: "POST",
            url: `/service/sites-that-users-control/roles/${this.props.router.params.appUUID}/`,
        })
            .then((response) => {
                this.setState({
                    roles: response.data.results.map((role) => {
                        role.display = role.name;
                        role.value = role.uuid;
                        return role;
                    }),
                });
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    error: true,
                })
            })
    }

    componentDidMount() {
        this.loadRoles();
    }

    render() {
        return (
            <div className="col-sm-12">
                <h2>Edit Existing User</h2>
                <p>
                    Change the assigned roles or privileges for the user
                </p>
                <MagicForm
                    onSubmit={this.editUser}
                    fields={[
                        {
                            "email": {
                                "label": "Email Address",
                                "type": "email",
                                "placeholder": "user@yourcompany.com",
                                "classes": [],
                                "value": this.props.email,
                                "disabled": true
                            },
                            "roles": {
                                "label": "Roles",
                                "type": "dropdown",
                                "placeholder": "Select the roles to assign",
                                "classes": [],
                                "options": this.state.roles,
                                "isMulti": true,
                                "value": this.props.roles,
                            }   
                        }
                    ]}
                />
            </div>
        );
    }
}

export default withLoaderData(connect(mapStateToProps)(EditUser));
