import React from "react";
import readValue from "./value";

export default function render(value) {
    return (
        <code>
            <pre>
                {readValue(value)}
            </pre>
        </code>
    );
}