import React from "react";

import Currency_Simple_Render from "../../components/datatypes/currency/simple.js";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import * as DataDelegator from "../../components/smart/delegator/DataDelegator";

import moment from "moment";

import { Line } from 'react-chartjs-2';


class BillingStackManagement extends React.Component {
    constructor(props) {
        super(props);

        this.countdownStates = {
            "SHOW": "show",
            "CANCELLED": "cancelled",
            "LOADING": "loading",
            "COUNTING_DOWN": "counting_down",
        }


        this.state = {
            countdownToShowBilling: 6,
            showBilling: this.countdownStates.LOADING,
        };

        this.countdownInterval = null;

        this.cancelBillingCountdown = this.cancelBillingCountdown.bind(this);
    }

    cancelBillingCountdown() {
        this.setState({
            showBilling: this.countdownStates.CANCELLED,
        });

        clearInterval(this.countdownInterval);
    }

    componentDidMount() {
        DataDelegator.resolve(this.props, async () => {

            if (this.countdownInterval == null) {
                this.countdownInterval = setInterval(() => {
                    this.setState((prevState) => {
                        const stateToUpdate = {
                        };

                        if (prevState.showBilling == this.countdownStates.LOADING) {
                            stateToUpdate.showBilling = this.countdownStates.COUNTING_DOWN;
                        }

                        if (prevState.countdownToShowBilling == 1) {
                            clearInterval(this.countdownInterval);

                            stateToUpdate.showBilling = this.countdownStates.SHOW;
                        }

                        stateToUpdate.countdownToShowBilling = prevState.countdownToShowBilling - 1;

                        return stateToUpdate;
                    })
                }, 1000);
            }
        });
    }

    render() {
        const invoicesChartData = {
            labels: [],
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                    },

                }
            }
        }

        if (Array.isArray(this.props.invoicesList6Months)) {
            this.props.invoicesList6Months && this.props.invoicesList6Months.map((invoice) => {
                invoicesChartData.labels.push(moment(invoice._id).format("MMMM"));
            });

            invoicesChartData.datasets = [
                {
                    label: 'Invoice Value',
                    data: this.props.invoicesList6Months.map((invoice) => invoice.sum),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ];
        }

        return (
            <>
                <div className="col-lg-12">
                    <div className="ibox-title pl-4">
                        <h3><i className="fas fa-chart-line"></i>&nbsp;Billing &amp; Invoices</h3>
                        <div className="ibox-tools">
                            <a className="collapse-link" data-toggle="collapse" data-target="#billing">
                                <i className="fa fa-chevron-up"></i>
                            </a>
                        </div>
                    </div>
                    <div className="collapse show ibox-content" id="billing">
                        {this.state.showBilling == this.countdownStates.SHOW &&
                            <div className="row">
                                <div className="col-sm-4">

                                    <div className="row m-t-xs">
                                        <div className="col-6">
                                            <h5 className="m-b-xs">Invoices - Last 30 Days</h5>
                                            <h1 className="mt-1 ml-0 mr-0 mb-0">{Currency_Simple_Render(this.props.monthlyInvoicesTotal || 0)}</h1>
                                            {/* <div className="font-bold text-navy">98% <i className="fa fa-bolt"></i></div> */}
                                        </div>
                                        <div className="col-6 text-right">
                                            <h5 className="m-b-xs">Pending Invoices</h5>
                                            <h1 className="mt-1 ml-0 mr-0 mb-0">{Currency_Simple_Render(this.props.pendingInvoicesTotal || 0)}</h1>
                                            {/* <div className="font-bold text-navy">98% <i className="fa fa-bolt"></i></div> */}
                                        </div>
                                    </div>


                                    <table className="table small m-t-sm">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Link className="text-info" to="./resources/invoices">
                                                        <strong>{this.props.numberOfInvoices || ""}</strong> Invoices
                                                    </Link>

                                                </td>
                                                <td className="text-right">
                                                    <Link className="text-info" to="./resources/invoices">
                                                        <strong>{this.props.numberOfUnpaidInvoices || ""}</strong> Pending Invoices
                                                    </Link>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <Link className="text-info" to="./resources/billing-customers">
                                                        <strong>{this.props.numberOfCustomers || ""}</strong> Customer Accounts
                                                    </Link>
                                                </td>
                                                <td className="text-right">
                                                    <Link className="text-info" to="./resources/billing-access">
                                                        <strong>{this.props.numberOfCustomersAccess || ""}</strong> Billing Access
                                                    </Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Link className="text-info" to="./resources/billing-subscriptions">
                                                        <strong>{this.props.numberOfBillingSubscriptions || ""}</strong> Billing Subscriptions
                                                    </Link>
                                                </td>
                                                <td className="text-right">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-sm-8">
                                    <h1 className="m-b-xs text-right">
                                        {Currency_Simple_Render(Array.isArray(this.props.invoicesList6Months) ? this.props.invoicesList6Months.reduce((sum, invoice) => sum + invoice.sum, 0) : "-")}
                                    </h1>
                                    <div className="text-right">
                                        Sales in the last 6 months
                                    </div>

                                    {invoicesChartData.labels.length > 0 && <Line options={invoicesChartData.options} data={invoicesChartData} />}
                                </div>
                            </div>
                        }

                        {this.state.showBilling == this.countdownStates.COUNTING_DOWN &&
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    Billing will show in {this.state.countdownToShowBilling} seconds

                                    <br />
                                    <br />

                                    <button onClick={this.cancelBillingCountdown} className="btn btn-sm btn-primary">
                                        Prevent display
                                    </button>
                                </div>
                            </div>
                        }

                        {this.state.showBilling == this.countdownStates.LOADING &&
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    Loading billing information
                                </div>
                            </div>
                        }

                        {this.state.showBilling == this.countdownStates.CANCELLED &&
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    You have prevented the billing section from being displayed
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default connect(DataDelegator.mapStateToProps)(BillingStackManagement);