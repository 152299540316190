import React from "react";

import JSON_ARRAY_Simple_Render from "../../../components/datatypes/json_array/simple";
import Boolean_Simple_Render from "../../../components/datatypes/boolean/simple";
import JSON_Simple_Render from "../../../components/datatypes/json/simple";
import File_Simple_Render from "../../../components/datatypes/file/simple";
import Email_Simple_Render from "../../../components/datatypes/email/simple";
import Currency_Simple_Render from "../../../components/datatypes/currency/simple";
import User_Simple_Render from "../../../components/datatypes/user/simple";
import Lookup_Simple_Render from "../../../components/datatypes/lookup/simple";
import ToTitleCase from "../../../core/utils/toTitleCase";

function SimpleTable(props) {
    return (
        <table className="table table-striped table-bordered table-responsive">
            <thead>
                <tr>
                    {props.columns.map((column, index) => {
                        return (
                            <td key={`thead-${column.fieldName}-${index}`}>
                                <div className="text-center font-weight-bold">
                                    <span className="pointer" onClick={(event) => this.toggleSort(event, column)}>
                                        <span className="pl-4 pr-4 ">{ToTitleCase(column.fieldName)}&nbsp;</span>
                                    </span>
                                </div>
                            </td>
                        );
                    })}

                </tr>
            </thead>

            <tbody>
                {(!Array.isArray(props.data) || props.data.length == 0) ?
                    <div className="pad-all">
                        No data found
                    </div>
                    :
                    props.columns.length == 0 ?
                        "Show at least one column to view data"
                        :
                        props.data.map((row, index) => {
                            return (
                                <tr key={index}>
                                    {props.columns.map((column, colIndex) => {
                                        let renderedColumn = row[column.fieldName];

                                        if (column.fieldType) {
                                            if (column.fieldType == "FILE") {
                                                renderedColumn = File_Simple_Render(row[column.fieldName], column.fieldName);
                                            }

                                            else if (column.fieldType == "EMAIL") {
                                                renderedColumn = Email_Simple_Render(row[column.fieldName]);
                                            }

                                            else if (column.fieldType == "CURRENCY") {
                                                renderedColumn = Currency_Simple_Render(row[column.fieldName]);
                                            }

                                            else if (column.fieldType == "BOOLEAN") {
                                                renderedColumn = Boolean_Simple_Render(row[column.fieldName]);
                                            }

                                            else if (column.fieldType == "JSON_ARRAY") {
                                                renderedColumn = JSON_ARRAY_Simple_Render(row[column.fieldName]);
                                            }

                                            else if (column.fieldType == "LOOKUP") {
                                                let value = row[column.fieldName];

                                                if (Array.isArray(column.resourceDisplayKeysMultiple)) {
                                                    value = column.resourceDisplayKeysMultiple;
                                                }

                                                renderedColumn = Lookup_Simple_Render(value, column.fieldName, row);
                                            }

                                            else if (column.fieldType == "JSON") {
                                                renderedColumn = JSON_Simple_Render(row[column.fieldName]);
                                            }

                                            else if (["USER", "USER_UPDATE"].includes(column.fieldType)) {
                                                renderedColumn = User_Simple_Render(row[column.fieldName], column.fieldName, row);
                                            }
                                        }

                                        return (
                                            <td key={`${index}-${colIndex}`}>
                                                <div className="text-wrap">
                                                    {renderedColumn}
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
            </tbody>
        </table>
    )
}

export default SimpleTable;