import jwt_decode from "jwt-decode";
import LogRocket from 'logrocket';

const simpleAuth = {
    JWT_TOKEN: "jwtToken",
    isAuthenticated: false,
    first_name: () => {
        try {
            const token = localStorage.getItem(simpleAuth.JWT_TOKEN);

            const decoded = jwt_decode(token);

            return decoded.first_name;
        } catch (err) {
            console.error(err);
            return "";
        }
    },
    last_name: () => {
        try {
            const token = localStorage.getItem(simpleAuth.JWT_TOKEN);

            const decoded = jwt_decode(token);

            return decoded.last_name;
        } catch (err) {
            console.error(err);
            return "";
        }
    },
    username: () => {
        try {
            const token = localStorage.getItem(simpleAuth.JWT_TOKEN);

            const decoded = jwt_decode(token);

            LogRocket.identify(decoded.uuid, {
                name: `${decoded.first_name} ${decoded.last_name}`,
            });

            return decoded.first_name + " " + decoded.last_name;
        } catch (err) {
            console.error(err);
            return "";
        }
    },
    isAdmin: () => {
        try {
            const token = localStorage.getItem(simpleAuth.JWT_TOKEN);

            const decoded = jwt_decode(token);

            return decoded.role == "admin";
        } catch (err) {
            return false;
        }
    },
    login: (token) => {
        return new Promise((resolve, reject) => {
            try {
                localStorage.setItem(simpleAuth.JWT_TOKEN, token);

                simpleAuth.isAuthenticated = true;

                window.location.reload();

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    },
    logout: () => {
        return new Promise((resolve, reject) => {
            try {
                localStorage.removeItem(simpleAuth.JWT_TOKEN);

                simpleAuth.isAuthenticated = false;

                window.location = "/";
                window.location.reload();

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }
};

simpleAuth.isAuthenticated = localStorage.getItem(simpleAuth.JWT_TOKEN) !== null;

export default simpleAuth;