import React from "react";

import { connect } from "react-redux";

import * as DataDelegator from "../../../components/smart/delegator/DataDelegator";

import MagicForm from "../../../components/smart/supported/MagicForm";

import withLoaderData from "../../../components/withLoaderData";

class AddAPITokenAccess extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        DataDelegator.resolve(this.props);
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="">
                        <div className="ibox-content">
                            <h2>Add Token Access</h2>
                            <p>
                                Select the token you want to enable api access for this application and its resources
                            </p>
                            <MagicForm
                                robostackResolveData={[
                                    {
                                        "name": "ListOfTokens",
                                        "api": {
                                            "method": "POST",
                                            "endpoint": "/my-account/tokens",
                                            "data": {}
                                        },
                                        "transformMergeIfArray": true,
                                        "transformMerge": true,
                                        "transformArrayMergeStratergy": "overwriteTarget",
                                        "transform": {
                                            "fields": {
                                                "token": {
                                                    "options": "`data.results | sort:name | map | set-prop:display,'<%- name %> - <%- token %>' | set-prop:value,$token`",
                                                    "forceUpdate": true
                                                },
                                            }
                                        }
                                    }
                                ]}
                                submitSuccessMessage="The token was successfully added for api access for this application"
                                submitApi={{
                                    "method": "PUT",
                                    "endpoint": `/service/external-api-access/${this.props.router.params.appUUID}/`,
                                    "data": {
                                    }
                                }}
                                onSubmitApiSuccess={this.props.onSuccess}
                                fields={
                                    [
                                        {
                                            "token": {
                                                "label": "Your tokens",
                                                "placeholder": "Select a token",
                                                "type": "dropdown",
                                                "options": [],
                                                "position": 1,
                                                "required": true
                                            }
                                        }
                                    ]
                                }
                            />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(AddAPITokenAccess));
