import axios from "axios";
import simpleAuth from "../auth/simpleAuth";

let baseURL = "http://localhost:7000"

if (process.env.NODE_ENV === "production") {
    baseURL = "https://files.robostack.ai"
}

const axiosFileserver = axios.create({
    baseURL: baseURL,
});

axiosFileserver.interceptors.request.use((config) => {
    config.headers.Authorization = localStorage.getItem(simpleAuth.JWT_TOKEN);

    return config;
});

axiosFileserver.interceptors.response.use((response) => {
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default axiosFileserver;

export function upload(file, fileName) {
    let bodyFormData = new FormData();
    bodyFormData.append('file', file);

    if(fileName) {
        bodyFormData.set("filename", fileName);
    }

    return axiosFileserver({
        method: 'PUT',
        url: '/upload',
        data: bodyFormData,
        config: {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    });
}