import React from "react";
import removeCustomProps from "../../removeCustomProps";
import defaultUpdateProps from "../../defaultUpdateProps";

function isValidNumber(n) {
    return typeof n == 'number' && !isNaN(n) && isFinite(n);
}

export const defaultValidationMessages = {
    required: "This is a required value",
    invalidInitialValue: "The value provided is not a valid number and cannot be displayed",
    minimumValueError: (min) => `The minimum value is ${min}`,
    maximumValueError: (max) => `The maximum value is ${max}`,
}

// The purpose of this function is to format the value as needed for the field type in the event it needs to be set exteranlly for any reason
export function formatValue({ fieldConfiguration, value }) {
    let formattedValue = value;

    return formattedValue;
}

// The purpose of the process function is to handle the value as needed after validation and then return the processed value as well as information if it is valid or not
export function process({fieldConfiguration, value}, callbackFn) {
    validate(fieldConfiguration, value, (err, updateProps) => {
        if (err) {
            callbackFn(err);
        } else {
            const hasError = updateProps.error !== undefined && updateProps.error.length > 0;

            callbackFn(null, {
                valid: hasError === false,
                fieldConfiguration,
                value,
            })
        }
    })
}

export function validate(fieldConfiguration, value, callbackFn) {
    let updateProps = defaultUpdateProps(fieldConfiguration);

    if(["", undefined].includes(value)) {
        if(fieldConfiguration.required === true) {
            updateProps.error = defaultValidationMessages.required;
        }
    } else {
        if(isValidNumber(Number(value)) === false) {
            updateProps.error = defaultValidationMessages.invalidInitialValue;
        }
    }
    
    if(fieldConfiguration.min) {
        if(Number(value) < Number(fieldConfiguration.min)) {
            updateProps.error = defaultValidationMessages.minimumValueError(fieldConfiguration.min);
        }
    } 
    
    if(fieldConfiguration.max) {
        if(Number(value) > Number(fieldConfiguration.max)) {
            updateProps.error = defaultValidationMessages.maximumValueError(fieldConfiguration.max);
        }
    }

    if(typeof callbackFn == "function") {
        callbackFn(null, updateProps);
    }
}

export function render(fieldConfiguration) {
    console.log("number render");

    // We only want to consider `value` as the official value to avoid any confusions
    delete fieldConfiguration.defaultValue;

    // But we still merge it back to defaultValue here because that is what react expects
    if(fieldConfiguration.value !== undefined) {
        if(isValidNumber(fieldConfiguration.value)) {
            fieldConfiguration.defaultValue = fieldConfiguration.value;
        }

        delete fieldConfiguration.value;
    }

    let props = {
        ...fieldConfiguration,

        className: fieldConfiguration.classes.join(" "),
    };

    props.onChange = (event) => {
        const value = event.target.value;

        validate(fieldConfiguration, value, (err, updateProps) => {
            updateProps.manuallyChanged = true;
            
            fieldConfiguration.onChange(value, updateProps);
        })

        return false;
    }

    props = removeCustomProps(props);

    return <input {...props} />;
}