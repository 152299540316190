const steps = [

    // {
    //     target: '#user-profile',
    //     title: "Your profile information",
    //     content: (
    //         <div>
    //             This your name and email address that is registered with Robostack.<br />
    //         </div>
    //     ),
    //     disableBeacon: true,
    // },

    // {
    //     target: '#user-profile-edit-button',
    //     title: "Edit your profile",
    //     content: (
    //         <div>
    //             Click on this button to change your profile information at any time.<br />
    //         </div>
    //     ),
    // },

    {
        target: "#toggle-help",
        content: (
            <div>
                Click on this button to view this tutorial at any time.<br />
            </div>
        ),
        disableBeacon: true,
    }
];

export default steps;