import axiosBackend from "../api/backend";

export default function ShouldIntroductionTourRun({ identifier }) {
    return axiosBackend({
        method: "POST",
        url: "/onboarding/check",
        data: {
            identifier,
        },
    })
    .then((response) => {
        return response.data.results.length == 0;
    })
}