import React from "react";

import {
    Outlet,
} from "react-router-dom";

import Navbar from "../../components/Navbar";

import withLoaderData from "../../components/withLoaderData";

import { resolve } from "../../components/smart/delegator/DataDelegator";
import mapStateToProps from "../../components/smart/delegator/mapStateToProps";
import { connect } from "react-redux";

import ModalSwitcher from "../../components/modals/ModalSwitcher";

function NavbarTemplate(props) {
    React.useEffect(() => {
        resolve(props, (err) => {
            if (err) {
                throw err;
            }
        });
    }, []);

    return (
        <>
            <header>
                <Navbar />
            </header>

            <ModalSwitcher
                robostackResolveData={[
                    {
                        "name": "ModalData",
                        "props": {
                            "show": false
                        },
                        "transformName": "ModalData",
                        "transform": {}
                    }
                ]}
            />

            <div className="p-4">
                <Outlet />
            </div>
        </>
    );
}

export default withLoaderData(connect(mapStateToProps)(NavbarTemplate));