import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import validator from "validator";

import simpleAuth from "../../core/auth/simpleAuth";
import axiosBackend from "../../core/api/backend";

import setWindowTitle from "../../core/utils/setWindowTitle";

import "../../scss/style.scss";
import "../../scss/components/login.scss";

export default function SetFirstAndLastName(props) {
    setWindowTitle("Complete Signup");

    useEffect(() => {
        if (simpleAuth.first_name().trim() != "" && simpleAuth.last_name().trim() != "") {
            navigate("/");
        }
    }, [simpleAuth.first_name(), simpleAuth.last_name()]);

    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState("");

    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState("");

    const [signingInStatus, setSigningInStatus] = useState(false);
    const [signInError, setSignInError] = useState("");

    const navigate = useNavigate();

    const onSubmit = async (e) => {
        e.preventDefault();

        let allOkay = true;

        setSignInError("");

        if (!validator.isLength(firstName, { min: 1 })) {
            allOkay = false;
            setFirstNameError("Please enter your first name");
        }

        if (!validator.isLength(lastName, { min: 1 })) {
            allOkay = false;
            setLastNameError("Please enter your last name");
        }

        if (allOkay) {
            setSigningInStatus(true);
            setFirstNameError("");
            setLastNameError("");
            setSignInError("");

            let response;

            try {
                response = await axiosBackend.post("authentication/set-first-name-and-last-name", {
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                });

                if (response.data.status === "success") {
                    if (response.data.token) {
                        simpleAuth.login(response.data.token);

                        navigate("/");

                        return true;
                    }
                }

                // If we come to this point, the login was not successful for some reason anyway
                setSigningInStatus(false);
                setSignInError(
                    response.data.error || "An error occurred. Please try again in some time."
                );
            } catch (err) {
                console.error(err);

                setSigningInStatus(false);

                let errorSet = false;

                if (err.name == "AxiosError") {
                    if (err.response?.data?.status == "error") {
                        errorSet = true;

                        const errors = err.response.data.errors;
                        const messages = err.response.data.messages;

                        if (Array.isArray(errors)) {
                            const error = errors[0];

                            if (error.firstName) {
                                setFirstNameError(error.firstName.messages.join(" "));
                            }

                            if (error.lastName) {
                                setLastNameError(error.lastName.messages.join(" "));
                            }
                        }

                        if (Array.isArray(messages)) {
                            setSignInError(messages.join(" "));
                        }
                    }
                }

                if (!errorSet) {
                    setSignInError("An error occurred. Please try again in some time.");
                }
            }
        }

        return false;
    };

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
        setFirstNameError("");
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
        setLastNameError("");
    };

    return (
        <div>
            <div className="middle-box animated">
                <div className="ibox ibox-content mb-2 text-black">
                    <h1 className="mt-2 text-center">Complete Signup</h1>


                    <form className="m-t" role="form" onSubmit={onSubmit}>
                        {signInError.length > 0 && (
                            <div className="alert alert-danger">{signInError}</div>
                        )}

                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-addon">
                                    <i className="fas fa-user-tie"></i>
                                </div>

                                <input
                                    onChange={handleFirstNameChange}
                                    type="text"
                                    className={
                                        "form-control " +
                                        (firstNameError.length > 0 ? "is-invalid" : "")
                                    }
                                    name="firstName"
                                    placeholder="Enter your first name"
                                />
                                <div className="invalid-feedback text-normal">{firstNameError}</div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-addon">
                                    <i className="fas fa-user"></i>
                                </div>

                                <input
                                    onChange={handleLastNameChange}
                                    type="text"
                                    className={
                                        "form-control " +
                                        (lastNameError.length > 0 ? "is-invalid" : "")
                                    }
                                    name="lastName"
                                    placeholder="Enter your last name"
                                />
                                <div className="invalid-feedback text-normal">{lastNameError}</div>
                            </div>
                        </div>

                        <button
                            disabled={signingInStatus}
                            type="submit"
                            className={`btn btn-login ${signingInStatus ? "btn-danger" : ""
                                }  block full-width`}>
                            {signingInStatus ? "Completing Signup..." : "Complete Signup"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
