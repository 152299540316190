import React from "react";

import { connect } from "react-redux";

import moment from "moment";
import update from "immutability-helper";
import Loader from "components/Loader"

import copy from 'copy-to-clipboard';

import * as DataDelegator from "components/smart/delegator/DataDelegator";
import axiosBackend from "core/api/backend";
import MagicForm from "components/smart/supported/MagicForm";

class AdminAPIKeysManagement extends React.Component {
    constructor(props) {
        super(props);

        this.COMPONENT_STATES = {
            CREATE_CONFIRMED: "CREATE_CONFIRMED",
            CREATE: "CREATE",

            EDIT: "EDIT",

            LIST: "LIST",
            LIST_LOADING: "LIST_LOADING",

            ERROR: "ERROR",
        }

        this.state = {
            show: this.COMPONENT_STATES.LIST_LOADING,

            tokens: [],
            createdToken: {},
            tokenToEdit: null,

            showEditProfile: false,
            userProfile: {},

            stepIndex: 0,

            selectedTokens: {},
        };

        this.viewSecret = this.viewSecret.bind(this);
        this.deactivateToken = this.deactivateToken.bind(this);
        this.editToken = this.editToken.bind(this);
        this.toggleCreateDisplay = this.toggleCreateDisplay.bind(this);
    }

    toggleTokenSelections({ token }) {
        if (this.state.selectedTokens[token.token]) {
            this.setState(update(this.state, {
                selectedTokens: {
                    $unset: [token.token],
                }
            }));
        } else {
            this.setState(update(this.state, {
                selectedTokens: {
                    [token.token]: {
                        $set: token,
                    }
                }
            }));
        }
    }

    toggleAllTokensSelection() {
        if (Object.keys(this.state.selectedTokens).length == this.state.tokens.length) {
            this.setState({
                selectedTokens: {},
            });
        } else {
            let selectedTokens = {};

            this.state.tokens.forEach(token => {
                selectedTokens[token.token] = token;
            });

            this.setState({
                selectedTokens,
            });
        }

    }

    deactivateToken(token) {
        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Confirm Deactivation",
                "message": [
                    "Are you sure you would like to deactivate the token " + token.name + "?",
                    "‏‏‎ ‎",
                    "This action is permanant and cannot be undone."
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Deactivating Token",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "DELETE",
                            url: `/my-account/tokens`,
                            data: {
                                token: token.token,
                            },
                        })
                            .then((response) => {
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            " Your token was deactivated successfully",
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });

                                this.loadTokens();
                            })
                            .catch((err) => {
                                console.error(err);
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Could not deactivate token",
                                        message: [
                                            "Due to an error, we were unable to deactivate the token",
                                            "‏‏‎ ‎",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    editToken(token) {
        this.setState({
            tokenToEdit: token,
            show: this.COMPONENT_STATES.EDIT,
        })
    }

    loadTokens() {
        axiosBackend({
            method: "POST",
            url: `/administration/management/api-tokens`,
        })
            .then((response) => {
                if (response.data.status == "success") {
                    this.setState({
                        tokens: response.data.results.sort((tokenA, tokenB) => {
                            return tokenB.exp - tokenA.exp;
                        }),
                        show: this.COMPONENT_STATES.LIST,
                    });
                } else {
                    this.setState({
                        show: this.COMPONENT_STATES.ERROR,
                    })
                }
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    show: this.COMPONENT_STATES.ERROR,
                })
            })
    }

    componentDidMount() {
        this.loadTokens();
    }

    viewSecret(token) {
        const index = this.state.tokens.findIndex(currentToken => currentToken.token == token.token);

        if (index > -1) {

            if (this.state.tokens[index].viewSecret === true) {
                copy(this.state.tokens[index].secret);
            } else {
                this.setState(update(this.state, {
                    tokens: {
                        [index]: {
                            viewSecret: {
                                $set: true,
                            }
                        }
                    }
                }));
            }
        }
    }

    toggleCreateDisplay() {
        // this.setState((currentState) => {
        //     let show = this.COMPONENT_STATES.CREATE;

        //     let statesToSwitchToList = [
        //         this.COMPONENT_STATES.CREATE,
        //         this.COMPONENT_STATES.CREATE_CONFIRMED,
        //         this.COMPONENT_STATES.EDIT,
        //         this.COMPONENT_STATES.EDIT_CONFIRMED,
        //     ]

        //     if (statesToSwitchToList.includes(currentState.show)) {
        //         this.loadTokens();

        //         show = this.COMPONENT_STATES.LIST;
        //     }

        //     return {
        //         show
        //     }
        // })

        $('#import-csv').modal({
            keyboard: false
        })
    }

    render() {
        return (
            <>
                <div id="import-csv" className="modal show fade" tabIndex="-1">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="">
                                    <div className="d-flex flex-row border-bottom">
                                        <div className="p-2 flex-fill">
                                            <h4 className="inline">Select the users you want to create new tokens for</h4>

                                        </div>
                                        <div className="p-2 flex-fill">

                                        </div>
                                    </div>

                                    <div className="p-3 mb-3 border-bottom">
                                        <MagicForm
                                            submitApi={{
                                                method: "PUT",
                                                endpoint: "/administration/management/api-tokens",
                                            }}
                                            onSubmitApiSuccess={(response, data) => {
                                                console.log({response})

                                                try {
                                                    const fileName = "Generated Token Results";
                                        
                                                    const json = JSON.stringify(response?.results[0]?.exportableResults);
                                        
                                                    const blob = new Blob([json], { type: 'application/json' });
                                                    const href = URL.createObjectURL(blob);
                                                    const link = document.createElement('a');
                                        
                                                    link.href = href;
                                                    link.download = fileName + ".json";
                                        
                                                    document.body.appendChild(link);
                                                    link.click();
                                                    document.body.removeChild(link);
                                                } catch (err) {
                                                    console.error(err);
                                                    this.props.dispatch({
                                                        type: "ResolvedData",
                                                        name: "ModalData",
                                                        data: {
                                                            show: true,
                                                            type: "error",
                                                            title: "Export Failed",
                                                            message: [
                                                                "Due to an unexpected error, we were unable to export the results of the generated tokens.",
                                                                "Please try again in a little while."
                                                            ],
                                                            okayButtonText: "Okay"
                                                        },
                                                    });
                                                }
                                            }}
                                            robostackResolveData={[
                                                {
                                                    "name": "ListOfCustomerAccounts",
                                                    "api": {
                                                        "method": "POST",
                                                        "endpoint": "/administration/resources/billing-customers",
                                                        "data": {}
                                                    },
                                                    "transformMergeIfArray": true,
                                                    "transformMerge": true,
                                                    "transformArrayMergeStratergy": "overwriteTarget",
                                                    "transform": {
                                                        "fields": {
                                                            "company": {
                                                                "options": "`data.results | sort:name | map | set-prop:display,'<%- name %>' | set-prop:value,$uuid`",
                                                                "forceUpdate": true
                                                            },
                                                        }
                                                    }
                                                },
                                            ]}
                                            fields={[
                                                {
                                                    "company": {
                                                        "label": "Pre-populate from a specific company",
                                                        "type": "dropdown",
                                                        "classes": [],
                                                        "placeholder": "Select the company to prepopulate from",
                                                        "onChange": {
                                                            "instanceAction": [
                                                                {
                                                                    fields: ["users"],
                                                                    $set: {
                                                                        dynamicOptionsLoaded: true,
                                                                        disabled: false,
                                                                        options: {
                                                                            $resolve: `ArrayOfUsers`,
                                                                        },
                                                                        value: {
                                                                            $resolve: `ArrayOfUsers`,
                                                                        },
                                                                    },
                                                                },
                                                                {
                                                                    fields: ["applications"],
                                                                    $set: {
                                                                        dynamicOptionsLoaded: true,
                                                                        disabled: false,
                                                                        options: {
                                                                            $resolve: `ArrayOfApplications`,
                                                                        },
                                                                        value: {
                                                                            $resolve: `ArrayOfApplications`,
                                                                        },
                                                                    },
                                                                },
                                                            ],
                                                            "robostackResolveData": [
                                                                {
                                                                    name: `ListOfUsersForCompany`,
                                                                    api: {
                                                                        method: "POST",
                                                                        endpoint: "/administration/resources/sites-that-users-control/aggregation",
                                                                        data: [
                                                                            // ?Commented out because the magic form now uses value directly in the lookup
                                                                            // ?Keeping this code commented since it might have an application in the future
                                                                            // [field.resourceFilterKey]: `<%= ${!!field.resourceFilterValueProperty ? field.resourceFilterValueProperty : "value"} %>`,
                                                                            {
                                                                                $join: {
                                                                                    as: {
                                                                                        "sites": {
                                                                                            customerAccountUUID: "customerAccountUUID",
                                                                                            name: "name"
                                                                                        },
                                                                                        "sites-that-users-control": {
                                                                                            "user_uuid": "user_uuid",
                                                                                        },
                                                                                        'users': {
                                                                                            email: "email"
                                                                                        }
                                                                                    },
                                                                                    on: [
                                                                                        {
                                                                                            joinType: "inner",
                                                                                            from: {
                                                                                                "previousTable": true,
                                                                                                "field": "site_uuid"
                                                                                            },
                                                                                            to: {
                                                                                                "table": "sites",
                                                                                                "field": "uuid"
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            joinType: "inner",
                                                                                            from: {
                                                                                                "table": "sites-that-users-control",
                                                                                                "field": "user_uuid"
                                                                                            },
                                                                                            to: {
                                                                                                "table": "users",
                                                                                                "field": "uuid"
                                                                                            },
                                                                                        },
                                                                                    ]
                                                                                }
                                                                            },
                                                                            {
                                                                                $match: {
                                                                                    "customerAccountUUID": `<%= value %>`,
                                                                                }
                                                                            },
                                                                            {
                                                                                $group: {
                                                                                    "_id": "<%= '$email' %>"
                                                                                }
                                                                            },
                                                                        ],

                                                                    },
                                                                    forceApiReload: false,
                                                                    transformName: `ArrayOfUsers`,
                                                                    transformKey: "options",
                                                                    transform: {
                                                                        options: "`data.results | map | set-prop:value,'<%= _id %>' | set-prop:display,'<%= _id %>'`",
                                                                    },
                                                                },
                                                                {
                                                                    name: `ListOfApplicationsForCompany`,
                                                                    api: {
                                                                        method: "POST",
                                                                        endpoint: "/administration/resources/sites/aggregation",
                                                                        data: [
                                                                            // ?Commented out because the magic form now uses value directly in the lookup
                                                                            // ?Keeping this code commented since it might have an application in the future
                                                                            // [field.resourceFilterKey]: `<%= ${!!field.resourceFilterValueProperty ? field.resourceFilterValueProperty : "value"} %>`,
                                                                            {
                                                                                $match: {
                                                                                    "customerAccountUUID": `<%= value %>`,
                                                                                }
                                                                            },
                                                                        ],

                                                                    },
                                                                    forceApiReload: false,
                                                                    transformName: `ArrayOfApplications`,
                                                                    transformKey: "options",
                                                                    transform: {
                                                                        options: "`data.results | map | set-prop:value,'<%= uuid %>' | set-prop:display,'<%= name %>'`",
                                                                    },
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "users": {
                                                        "dynamicOptions": true,
                                                        "label": "Users",
                                                        "type": "dropdown",
                                                        "classes": [],
                                                        "placeholder": "Select the users you want to create tokens for",
                                                        "isMulti": true,
                                                        "options": [],
                                                        "forceUpdate": true,
                                                    },
                                                    "applications": {
                                                        "dynamicOptions": true,
                                                        "label": "Applications",
                                                        "type": "dropdown",
                                                        "classes": [],
                                                        "placeholder": "Select the company applications you want to assign tokens to",
                                                        "isMulti": true,
                                                        "options": [],
                                                        "forceUpdate": true,
                                                    },
                                                    "token_name": {
                                                        "type": "text",
                                                        "label": "Token Name",
                                                        "placeholder": "Enter the name of the token to be generated for the selected users",
                                                        "value": "Generated token"
                                                    },
                                                    "token_reason": {
                                                        "type": "textarea",
                                                        "label": "Why are you generating this token?",
                                                        "placeholder": "Enter the purpose of generating these tokens for the selected users and applications",
                                                    },
                                                    "exp": {
                                                        "label": "When do you want your token to expire?",
                                                        "type": "datetime",
                                                        "placeholder": "Select any date or time that you want",
                                                        "required": true,
                                                        "position": 2,
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>






                                    {/* <div className="d-flex flex-row bd-highlight mb-3">
                                                <div className="p-2 flex-fill bd-highlight">CSV Column</div>
                                                <div className="p-2 flex-fill bd-highlight">Resource Column</div>
                                                <div className="p-2 flex-fill bd-highlight">Flex item 3</div>
                                            </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="">
                        <div className="ibox-content" id="tokens-list">

                            <button
                                id="tokens-list-create"
                                onClick={this.toggleCreateDisplay}
                                type="button"
                                className="mr-2 btn btn-success btn-xs float-right"
                            >
                                <i className="fas fa-plus"></i>&nbsp;Create New Tokens
                            </button>

                            <button
                                disabled={Object.keys(this.state.selectedTokens).length == 0}
                                id="tokens-list-create"
                                type="button"
                                className="mr-2 btn btn-success btn-xs float-right"
                            >
                                <i className="fas fa-edit"></i>&nbsp;Assign/Unassign
                            </button>

                            <button
                                disabled={Object.keys(this.state.selectedTokens).length == 0}
                                id="tokens-list-create"
                                type="button"
                                className="mr-2 btn btn-danger btn-xs float-right"
                            >
                                <i className="fas fa-trash"></i>&nbsp;Deactivate
                            </button>

                            <button
                                disabled={Object.keys(this.state.selectedTokens).length == 0}
                                id="tokens-list-create"
                                type="button"
                                className="mr-2 btn btn-warning btn-xs float-right"
                            >
                                <i className="fas fa-edit"></i>&nbsp;Edit Token(s)
                            </button>

                            <h2>
                                {this.state.show === this.COMPONENT_STATES.LIST && "API Tokens"}

                                {this.state.show === this.COMPONENT_STATES.CREATE && "New API Tokens"}

                                {this.state.show === this.COMPONENT_STATES.EDIT && "Edit API Tokens"}
                            </h2>

                            {this.state.show === this.COMPONENT_STATES.LIST_LOADING ? <Loader /> : undefined}

                            {this.state.show === this.COMPONENT_STATES.LIST ?
                                <div className="clients-list">
                                    <div className="tab-content">
                                        {this.state.selectedTokens.length}
                                        <div className="table-responsive" id="tokens-list-active">
                                            <table className="table table-striped table-hover text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th><input checked={Object.keys(this.state.selectedTokens).length == this.state.tokens.length} onClick={() => this.toggleAllTokensSelection()} type="checkbox" /></th>
                                                        <th>Token Owner</th>
                                                        <th>Token Name</th>
                                                        <th>Token Key</th>
                                                        <th>Token Secret</th>
                                                        <th>Active</th>
                                                        <th id="tokens-list-expiry-column">Expires In</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.tokens.filter(token => token.forceExpired !== true).length == 0 &&
                                                        <tr className="m-2">
                                                            <td>
                                                                No tokens found
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    }

                                                    {this.state.tokens.filter(token => token.forceExpired !== true).map((token, index) => {
                                                        return (
                                                            <tr key={token.token}>
                                                                <td><input checked={this.state.selectedTokens[token.token] != undefined} onClick={() => this.toggleTokenSelections({ token: token })} type="checkbox" /></td>
                                                                <td>{token.owner}</td>
                                                                <td>{token.name}</td>
                                                                <td>
                                                                    {token.token}
                                                                </td>
                                                                <td>
                                                                    <div className="input-group">
                                                                        <input className="form-control form-control-sm" type="text" disabled={true} value={token.viewSecret !== true ? "********************" : token.secret} />

                                                                        <div className="input-group-append">
                                                                            <button onClick={() => this.viewSecret(token)} type="button" className="btn btn-primary btn-sm view-secret-button" title={`${token.viewSecret !== true ? "View Secret" : "Copy Secret"}`}>
                                                                                <span
                                                                                    className={`fa ${token.viewSecret !== true ? "fa-eye" : "fa-copy"}`}>
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className={`${token.forceExpired === false ? "text-success" : "text-danger"}`}>
                                                                    {token.forceExpired === false ? "Yes" : "No"}
                                                                </td>
                                                                <td className={`${moment.unix(token.exp).diff(new Date()) <= 0 ? 'text-danger' : ''}`}>
                                                                    {moment.unix(token.exp).fromNow()}
                                                                </td>
                                                                <td>

                                                                    <button type="button" onClick={() => this.editToken(token)} className="btn btn-info btn-sm btn-edit-token" title="Edit">
                                                                        <span className="fa fa-edit"></span>
                                                                    </button>

                                                                    <button onClick={() => this.deactivateToken(token)} type="button" className="btn btn-danger btn-sm ml-2 btn-delete-token" title="Delete">
                                                                        <span className="fa fa-trash-alt"></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                :
                                undefined
                            }
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default connect(DataDelegator.mapStateToProps)(AdminAPIKeysManagement);
