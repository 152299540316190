import merge from "deepmerge";
import hash from "object-hash";
import ejs from "ejs";

export default function(state, ownProps) {
    let neededProps = {};

    let props = Object.keys(ownProps);

    // Move robostackResolveData to the end of the array
    // so that it gets processed last
    // ?This is so that any provided fields in ownProps do not get the final priority 
    // ?and end up overwriting the resolved values from robostackResolveData
    let robostackResolveDataIndex = props.indexOf("robostackResolveData");
    if (robostackResolveDataIndex !== -1) {
        props.splice(robostackResolveDataIndex, 1);
        props.push("robostackResolveData");
    }

    props.forEach((prop) => {
        if (prop == "robostackResolveData") {
            for (let resolve of ownProps.robostackResolveData) {
                try {
                    let name = ejs.render(resolve.name, ownProps);

                    // propsKey will make the entire state get set into that one particular key in the props rather than directly in the props
                    if (resolve.propsKey && resolve.name && typeof resolve.propsKey === "string" && typeof resolve.name === "string") {

                        if (resolve.transform) {
                            name = resolve.transformName || (name + "_transformed_" + hash(resolve.transform));
                        }

                        let stateToUse = state;

                        if (resolve.propsType) {
                            switch (resolve.propsType) {
                                case "props": {
                                    stateToUse = ownProps;
                                    break;
                                }

                                default: {
                                    console.log(`Unknown propsType ${resolve.propsType}`);
                                    break;
                                }
                            }
                        }

                        const stateValues = name.split(".");
                        neededProps[resolve.propsKey] = stateValues.reduce((acc, stateKey) => {
                            // This is to prevent errors if the state was not set previously
                            if (acc == undefined) {
                                return acc;
                            }

                            return acc[stateKey];
                        }, stateToUse);
                    } else {
                        // The props key is missing so lets see if there is a transformed name
                        // ?Only if there is a transformed name and it is an object will all keys of that object be passed as props
                        if (resolve.transform) {
                            name = resolve.transformName || (name + "_transformed_" + hash(resolve.transform));

                            if (state[name] !== null && typeof state[name] === "object") {
                                Object.keys(state[name]).map((propName) => {
                                    if (resolve.transformMerge === true) {
                                        // Lets check if something was already merged earlier and if so, use that 
                                        let mergedData = {};
                                        let existingProps = neededProps[propName] !== undefined ? neededProps[propName] : ownProps[propName];

                                        if (existingProps == undefined) {
                                            existingProps = {};
                                        }

                                        /*
                                            ? When transformMergeIfArray is true, the data for each key inside the transform object will get merged 
                                            ? with each element of the existing array. 
                                            
                                            ? So if there are two elements, each of the elements in the existing data will be merged with the given transform data
                                            ? for that key in the transform data and then returned as the new value for that key.

                                            ? If there are multiple keys inside of transform, this will happen for each key if the data that it has to merged with
                                            ? is an array.

                                            ? If it is not an array, it will merge the objects directly.

                                            ? transformArrayMergeStratergy is default to be concatenation. It can be overwriteTarget, combineArraysAtSameIndex as well
                                        */

                                         // Ref: https://www.npmjs.com/package/deepmerge#arraymerge-example-overwrite-target-array
                                        let mergeOptions = undefined;

                                        if(resolve.transformArrayMergeStratergy === "overwriteTarget") {
                                            mergeOptions = {
                                                arrayMerge: (destinationArray, sourceArray, options) => sourceArray,
                                            }
                                        }

                                        if(resolve.transformArrayMergeStratergy === "combineArraysAtSameIndex") {
                                            mergeOptions = {
                                                arrayMerge: (target, source, options) => {
                                                    const destination = target.slice()

                                                    source.forEach((item, index) => {
                                                        if (typeof destination[index] === 'undefined') {
                                                            destination[index] = options.cloneUnlessOtherwiseSpecified(item, options)
                                                        } else if (options.isMergeableObject(item)) {
                                                            destination[index] = merge(target[index], item, options)
                                                        } else if (target.indexOf(item) === -1) {
                                                            destination.push(item)
                                                        }
                                                    });

                                                    return destination
                                                },
                                            }
                                        }
                                        
                                        if (resolve.transformMergeIfArray === true && Array.isArray(existingProps)) {
                                            if (resolve.transformTargetIsArray === true && Array.isArray(state[name][propName])) {
                                                // ?NOTE: merges only equivalent indexes unless transformMergeRecursivelyIfTargetIsLarger is specified
                                                // If the new data source (the target) is larger and transformMergeRecursivelyIfTargetIsLarger is 
                                                // specified, we must switch to the larger data source
                                                let primaryDataSource = existingProps;
                                                let toMergeWithDataSource = state[name][propName];

                                                if (resolve.transformMergeRecursivelyIfTargetIsLarger === true) {
                                                    primaryDataSource = state[name][propName].length > existingProps.length ? state[name][propName] : existingProps;
                                                    toMergeWithDataSource = state[name][propName].length > existingProps.length ? existingProps : state[name][propName];
                                                }

                                                mergedData = primaryDataSource.map((instance, index) => {
                                                    if (toMergeWithDataSource[index] !== undefined) {
                                                        return merge(instance, toMergeWithDataSource[index], mergeOptions);
                                                    } else {
                                                        if (resolve.transformMergeRecursivelyIfTargetIsLarger === true) {
                                                            return merge(instance, toMergeWithDataSource[index % toMergeWithDataSource.length], mergeOptions);
                                                        } else {
                                                            return instance;
                                                        }
                                                    }
                                                });
                                            } else {
                                                mergedData = existingProps.map((instance) => {
                                                    return merge(instance, state[name][propName], mergeOptions);
                                                });
                                            }
                                        } else {
                                            if (state[name] !== undefined && state[name][propName] !== undefined) {
                                                mergedData = merge(existingProps, state[name][propName], mergeOptions);
                                            } else {
                                                mergedData = existingProps;
                                            }
                                        }

                                        neededProps[propName] = mergedData;
                                    } else {
                                        neededProps[propName] = state[name][propName];
                                    }
                                })
                            }
                        }
                    }
                } catch(err) {
                    console.error(err);
                }
            }
        } else {
            neededProps[prop] = ownProps[prop];
        }
    });

    return neededProps;
};