import React from "react";
import { Link } from "react-router-dom";

import { resolve, mapStateToProps } from "../components/smart/delegator/DataDelegator";
import { connect } from "react-redux";

import setWindowTitle from "../core/utils/setWindowTitle";
import ToTitleCase from "../core/utils/toTitleCase";
import withLoaderData from "./withLoaderData";

class Breadcrumbs extends React.Component {
    componentDidMount() {
        resolve(this.props, (err) => {
            if (err) {
                throw err;
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.router?.location?.key !== this.props?.router?.location?.key) {
            resolve(this.props, (err) => {
                if (err) {
                    throw err;
                }
            });
        }
    }

    render() {        
        let title = "";
        let crumbs = this.props.crumbs;

        if (this.props.title) {
            title = this.props.title;
        } else if (Array.isArray(crumbs) && crumbs.length > 0) {
            title = crumbs[crumbs.length - 1].name;

            crumbs = crumbs.slice(0, crumbs.length - 1);
        }

        setWindowTitle(ToTitleCase(title || ""));

        let parentPath = "../";

        return (
            <div className="row border-bottom light-bg page-heading d-print-none">
                <div className="col-lg-9">
                    <h2>{title}</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>

                        {title !== undefined ?
                            <li className="breadcrumb-item">
                                <Link to={this.props.rootUrl || (this.props.root + parentPath.repeat(crumbs?.length || 0))}>
                                        <span className={`${!Array.isArray(crumbs) || crumbs.length == 0 ? "font-weight-bold" : ""}`}>{title}</span>
                                    </Link>
                            </li>
                            : null}

                        {crumbs && crumbs.map((crumb, index) => {
                            return (
                                <li className="breadcrumb-item" key={index}>
                                    <Link to={this.props.root + (crumb.url || parentPath.repeat(crumbs.length - index - 1))}>
                                        <span className={`text-capitalize ${index == crumbs.length - 1 ? "font-weight-bold" : ""}`}>{crumb.name}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ol>
                </div>
            </div>
        );
    }
}

export default withLoaderData(connect(mapStateToProps)(Breadcrumbs));