export default function mapLookupData({ primaryLookupData, secondaryLookupFields }) {

    const lookupDataMappedToTableWithSite = primaryLookupData.reduce((acc, tableResult) => {
        if (acc[tableResult.siteUUID] == undefined) {
            acc[tableResult.siteUUID] = {};
        }

        acc[tableResult.siteUUID][tableResult.tableName] = tableResult.results;
        return acc;
    }, {});

    const lookupFieldsMapped = Object.keys(secondaryLookupFields).reduce((siteAcc, siteUUID) => {
        const resources = secondaryLookupFields[siteUUID]
        const resourcesList = Object.keys(resources);

        const lookedUpDataForThisSite = resourcesList.reduce((acc, table) => {
            const fields = resources[table];

            Object.keys(fields).reduce((innerAcc, field) => {
                const fieldData = fields[field];

                if (innerAcc[field] == undefined) {
                    innerAcc[field] = {
                        linksToSiteUUID: fieldData.linksToSiteUUID,
                        tableName: fieldData.linksToResource,
                        resourceLinkingKey: fieldData.resourceLinkingKey,
                    }
                }

                return innerAcc;
            }, acc);

            return acc;
        }, {});

        siteAcc[siteUUID] = lookedUpDataForThisSite;

        return siteAcc;
    }, {});


    const applications = Object.keys(lookupFieldsMapped);

    const secondaryLookedupData = applications.reduce((acc, siteUUID) => {
        const resourcesForThisSite = lookupFieldsMapped[siteUUID];        

        const secondaryLookedupData = Object.keys(resourcesForThisSite).reduce((resourcesAcc, fieldName) => {
            const tableName = resourcesForThisSite[fieldName].tableName;

            resourcesAcc[tableName] = Object.values(lookupDataMappedToTableWithSite[siteUUID][tableName]).reduce((innerAcc, row) => {
                const linkingKey = resourcesForThisSite[fieldName].resourceLinkingKey;

                innerAcc[row[linkingKey]] = row;
                return innerAcc;
            }, {});

            return resourcesAcc;
        }, {});

        acc[siteUUID] = secondaryLookedupData;

        return acc;
    }, {});

    return {
        lookupDataMappedToTable: lookupDataMappedToTableWithSite,
        secondaryLookedupData,
    }
}