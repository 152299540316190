import React from "react";

import Select from 'react-select';

import removeCustomProps from "../../removeCustomProps";
import defaultUpdateProps from "../../defaultUpdateProps";
import axiosBackend from "../../../../../../core/api/backend";
import mapLookupData from "../../../../../../core/utils/datatypes/lookup/mapLookupData";

export const defaultValidationMessages = {
    required: "This is a required value",

    notFound: "The provided existing value was not found in the given options"
}

export async function load(fieldConfiguration, callbackFn) {
    try {
        const lookupResults = await axiosBackend({
            method: "POST",
            url: `/service/resources/${fieldConfiguration.siteUUID}/${fieldConfiguration.belongsToResource}/lookup`,
            data: {

            }
        });

        fieldConfiguration.loadedData = lookupResults;

        callbackFn(null, fieldConfiguration)
    } catch (err) {
        callbackFn(err);
    }
}

// The purpose of this function is to format the value as needed for the field type in the event it needs to be set exteranlly for any reason
export function formatValue({ fieldConfiguration, value }) {
    let formattedValue = value;

    return formattedValue;
}

// The process function will validate the value and then return if it is valid or not along with the value itself
export function process({ fieldConfiguration, value: selectedOption }, callbackFn) {
    const value = typeof selectedOption == "object" && selectedOption !== null ? selectedOption.value : selectedOption;

    validate(fieldConfiguration, value, (err, updateProps) => {
        if (err) {
            callbackFn(err);
        } else {
            const hasError = updateProps.error !== undefined && updateProps.error.length > 0;

            callbackFn(null, {
                valid: hasError === false,
                fieldConfiguration,
                value,
            })
        }
    })
}

export function validate(fieldConfiguration, selectedOption, callbackFn) {
    const value = typeof selectedOption == "object" && selectedOption !== null ? selectedOption.value : selectedOption;

    let updateProps = defaultUpdateProps(fieldConfiguration);

    if (["", undefined].includes(value)) {
        if (fieldConfiguration.required === true) {
            updateProps.error = defaultValidationMessages.required;
        }
    } else {
        // Check if the given value is in the options
        if (Array.isArray(fieldConfiguration.options)) {
            const found = fieldConfiguration.options.filter((currentObject) => currentObject.value == value);

            if (found.length == 0) {
                updateProps.error = defaultValidationMessages.notFound;
            }
        }

    }

    if (typeof callbackFn == "function") {
        callbackFn(null, updateProps);
    }
}

export function render(fieldConfiguration) {
    console.log("lookup render");

    // We only want to consider `value` as the official value to avoid any confusions
    delete fieldConfiguration.defaultValue;

    let props = {
        ...fieldConfiguration,

        // We do not want form-control with this dropdown because it does not gel well with react-select's styles
        className: fieldConfiguration.classes.filter(className => className != "form-control").join(" "),
    };

    /**
     *? Check if there is an error here so that we can style the component as needed
     *? This is usually handled by bootstrap for normal fields using the label and css
     *? but because this is using another component, we have to handle it here
     */
    if (fieldConfiguration.error !== undefined && fieldConfiguration.error !== "") {
        props.styles = {
            control: (base, state) => ({
                ...base,
                border: '1px solid #ed5565',
            })
        }
    }

    props.isDisabled = props.disabled;

    props.isClearable = true;

    props.placeholder = props.placeholder || "Select an option";
    props.getOptionValue = (option) => typeof option !== "object" ? option : option.value;
    props.getOptionLabel = (option) => typeof option !== "object" ? option : option.display;

    props.classNamePrefix = "react-select";

    props.onChange = (selectedOption, event) => {
        const value = typeof selectedOption == "object" && selectedOption !== null ? selectedOption.value : selectedOption;

        validate(fieldConfiguration, value, (err, updateProps) => {
            updateProps.manuallyChanged = true;
            
            fieldConfiguration.onChange(selectedOption, {
                ...updateProps,

                forceUpdate: true, // Because we depend on the value for rendering the react-select component to show the currently selected option
            });
        })

        return false;
    }

    if (props.lookup !== undefined) {

        const { lookupDataMappedToTable, secondaryLookedupData } = mapLookupData({
            primaryLookupData: props.lookup.result,
            secondaryLookupFields: props.lookup.secondaryLookupFields,
        })

        const siteUUIDToLookupTo = props.linksToSiteUUID || props.siteUUID;
        const lookedupOptions = lookupDataMappedToTable[siteUUIDToLookupTo][props.linksToResource];

        let optionsToUse = lookedupOptions;

        if(props.dynamicOptions == true && props.dynamicOptionsLoaded == true) {
            optionsToUse = fieldConfiguration.options;
        }

        if (Array.isArray(optionsToUse)) {
            const correctOptions = optionsToUse.map((option) => {
                let value = option[props.resourceLinkingKey];
                let display = undefined;

                if (props.resourceDisplayKey !== undefined && props.resourceDisplayKey !== null) {
                    display = option[props.resourceDisplayKey];

                    if (display == null || display == undefined) {
                        display = "";
                    }

                    if (typeof display !== "string") {
                        display = display.toString();
                    }
                }

                else if (Array.isArray(props.resourceDisplayKeysMultiple) && props.resourceDisplayKeysMultiple.length > 0) {
                    display = props.resourceDisplayKeysMultiple.reduce((acc, key) => {
                        if (key[0] == "$") {
                            const correctKey = key.substr(1);
                            let lookedUpValue = option[correctKey];

                            if (lookedUpValue !== null) {
                                // Check if the key is something that had to be looked up from another resource
                                // This is possible if the second key was a lookup value as well
                                const secondaryData = props.lookup.secondaryLookupFields?.[siteUUIDToLookupTo]?.[props.linksToResource]?.[correctKey]

                                if (secondaryData) {
                                    const secondaryLookupResource = secondaryData.linksToResource;
                                    const secondaryLinkingKey = secondaryData.resourceLinkingKey;
                                    const secondaryDisplayKey = secondaryData.resourceDisplayKey;

                                    lookedUpValue = secondaryLookedupData?.[siteUUIDToLookupTo]?.[secondaryLookupResource]?.[lookedUpValue]?.[secondaryDisplayKey] || "";
                                }
                            }

                            return `${acc}${lookedUpValue}`;
                        }

                        return `${acc}${key}`;
                    }, "");
                }

                return {
                    display,
                    value,
                };
            });

            if(props.dynamicOptions != true || props.dynamicOptionsLoaded == true) {
                props.options = correctOptions;
            }

        } else {
            let updateProps = defaultUpdateProps(fieldConfiguration);

            updateProps.error = "Invalid lookup data. Please check the form configuration.";

            fieldConfiguration.onChange(props.value, {
                ...updateProps,

                forceUpdate: false, // Because this is an error and we don't want to enter an infinite loop
            });
        }
    }

    if (props.sortOptions === true && Array.isArray(props.options)) {
        //? Sort the object if there is a display key only otherwise it remains unsorted
        if (typeof props.options[0] === "object") {
            if (props.options[0].display !== undefined) {
                props.options.sort((a, b) => {
                    return ('' + a.display).localeCompare(b.display)
                });
            }
        } else {
            props.options.sort();
        }
    }

    // Because we want to work with only a singular value in this field
    if (props.value !== undefined && typeof props.value !== "object" && Array.isArray(props.options)) {
        const found = props.options.filter((currentObject) => currentObject.value == props.value);

        // If there are multiple values found, take the first one
        if (found.length > 0) {
            props.value = found.pop();
        }
    }

    //? We do not removeCustomProps here because the Select component will handle that for us
    return <Select {...props} />;
}