import QueryPlayground, { introductionIdentifier } from "../pages/app/query-playground";
import StandardLoader from "./standard-loader";

export default function QueryPlaygroundRouter() {
    return {
        path: "/app/:appUUID/query-playground",

        children: [
            {
                index: true,
                element: <QueryPlayground />,
                loader: (props) => StandardLoader({
                    ...props,
                    crumbs: [
                        {
                            "name": "Query Playground",
                        },
                    ],
                    introductionIdentifier,
                }),
            },
        ]
    };
}