import React from "react";

import moment from "moment";

import { connect } from "react-redux";
import axiosBackend from "core/api/backend";
import mapStateToProps from "components/smart/delegator/mapStateToProps";

import Currency_Simple_Render from "components/datatypes/currency/simple";
import File_Simple_Render from "components/datatypes/file/simple";
import MagicForm from "components/smart/supported/MagicForm";
import API_STATES from "api-states";
import Loader from "components/Loader";

class Billing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            attachments: "-",
            applications: "-",
            pipelines: "-",
            users: "-",
            smart_applications: "-",
            emails: "-",
            sms: "-",
            api: "-",

            loading: API_STATES.IDLE,

            invoices: null,
            billingDetails: null,

            editingDetails: false,
            editingGst: false,

            errors: {
                statistics: false,
                invoices: false,
            },

            errorMessage: "",
        };

        this.updateAddress = this.updateAddress.bind(this);
        this.updateGST = this.updateGST.bind(this);
    }

    loadCompanyBillingStatistics() {
        this.setState({
            loading: API_STATES.LOADING,
        });

        return axiosBackend({
            method: "POST",
            url: "/company/billing/statistics",
        })
            .then((response) => {
                this.setState({
                    ...response.data.results[0],
                    loading: API_STATES.LOADED,
                });
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    loading: API_STATES.ERROR,
                    errors: {
                        ...this.state.errors,
                        statistics: true,
                    },
                })
            })
    }

    loadInvoices() {
        this.setState({
            loading: API_STATES.LOADING,
        });

        return axiosBackend({
            method: "POST",
            url: "/company/billing/invoices",
        })
            .then((response) => {
                this.setState({
                    invoices: response.data.results,
                    loading: API_STATES.LOADED,
                });
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    loading: API_STATES.ERROR,
                    errors: {
                        ...this.state.errors,
                        invoices: true,
                    },
                })
            })
    }

    async componentDidMount() {
        await this.loadCompanyBillingStatistics();

        await this.loadInvoices();
    }

    updateAddress(fields, callback) {
        axiosBackend({
            method: "PATCH",
            url: "/company/billing/address",
            data: fields.tuple,
        })
            .then((response) => {
                if (response.data.status == "success") {
                    this.setState({ editingDetails: false });

                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "success",
                            title: "Success",
                            message: [
                                "Your name and address information was successfully updated.",
                                "It will reflect from your next invoice onwards."
                            ],
                            okayButtonText: "Okay",
                        },
                    });

                    this.loadCompanyBillingStatistics();
                } else {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "error",
                            title: "Could not update address information",
                            message: [
                                "Due to an error, we were unable to update the name and address on your company account.",
                                "Please try again in a little while."
                            ],
                            okayButtonText: "Okay"
                        },
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Could not update address information",
                        message: [
                            "Due to an error, we were unable to update the name and address on your company account.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
            .finally(callback);
    }

    updateGST(fields, callback) {
        axiosBackend({
            method: "PATCH",
            url: "/company/billing/gst",
            data: fields.tuple,
        })
            .then((response) => {
                if (response.data.status == "success") {
                    this.setState({
                        editingGst: false,

                    });

                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "success",
                            title: "Success",
                            message: [
                                "Your GST information was successfully updated.",
                                "It will reflect from your next invoice onwards."
                            ],
                            okayButtonText: "Okay",
                        },
                    });

                    this.loadCompanyBillingStatistics();
                } else {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "error",
                            title: "Could not update GST information",
                            message: [
                                "Due to an error, we were unable to update the GST information on your company account.",
                                "Please try again in a little while."
                            ],
                            okayButtonText: "Okay"
                        },
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Could not update GST information",
                        message: [
                            "Due to an error, we were unable to update the GST information on your company account.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
            .finally(callback);
    }

    render() {
        return (
            <div className="row mt-4">
                <div className="col-sm-12">
                    {this.state.errors.statistics && this.state.errors.invoices ?
                        <div className="text-center">
                            <h1 className="error-code text-danger"><i className="fas fa-exclamation-triangle fa-3x"></i></h1>
                            <br />
                            <p className="h4 text-uppercase text-bold">Oh Snap :(</p>
                            <div className="pad-btm">
                                {this.state.errorMessage || <span>
                                    An error occurred while loading your your company dashboard. <br />
                                    Please try again after a few minutes.
                                </span>}
                            </div>
                        </div>
                        :
                        <div className="ibox-content">

                            <div className="row">
                                <div className="col-sm-12 mt-3 ml-3">
                                    {this.state.loading === API_STATES.LOADING && <Loader />}
                                </div>

                                {this.state.billingDetails &&
                                    <div className="col-sm-12">
                                        <h1>{this.state.billingDetails.name}</h1>
                                        <hr />
                                        <h2 className="mt-4">Current Usage</h2>

                                        <div className="row">

                                            <div className="col-lg-3 mb-4 mt-4">
                                                <div className="">
                                                    <div className="">
                                                        <h5>Applications</h5>
                                                        <h2>{this.state.applications}</h2>
                                                        <div className="progress progress-mini">
                                                            <div style={{ "width": "68%" }} className="progress-bar"></div>
                                                        </div>

                                                        <div className="m-t-sm small">Unlimited Capacity</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 mb-4 mt-4">
                                                <div className="">
                                                    <div className="">
                                                        <h5>Users</h5>
                                                        <h2>{this.state.users}</h2>
                                                        <div className="progress progress-mini">
                                                            <div style={{ "width": "68%" }} className="progress-bar"></div>
                                                        </div>

                                                        <div className="m-t-sm small">Unlimited Capacity</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 mb-4 mt-4">
                                                <div className="">
                                                    <div className="">
                                                        <h5>Smart Applications</h5>
                                                        <h2>{this.state.smart_applications}</h2>
                                                        <div className="progress progress-mini">
                                                            <div style={{ "width": "68%" }} className="progress-bar"></div>
                                                        </div>

                                                        <div className="m-t-sm small">Unlimited Capacity</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 mb-4 mt-4">
                                                <div className="">
                                                    <div className="">
                                                        <h5>Automation Pipelines</h5>
                                                        <h2>{this.state.pipelines}</h2>
                                                        <div className="progress progress-mini">
                                                            <div style={{ "width": "68%" }} className="progress-bar"></div>
                                                        </div>

                                                        <div className="m-t-sm small">Unlimited Capacity</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 mb-4 mt-4">
                                                <div className="">
                                                    <div className="">
                                                        <h5>Attachments</h5>
                                                        <h2>{this.state.attachments}</h2>
                                                        <div className="progress progress-mini">
                                                            <div style={{ "width": "68%" }} className="progress-bar"></div>
                                                        </div>

                                                        <div className="m-t-sm small">Unlimited Capacity</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 mb-4 mt-4">
                                                <div className="">
                                                    <div className="">
                                                        <h5>Emails Sent</h5>
                                                        <h2>{this.state.emails}</h2>
                                                        <div className="progress progress-mini">
                                                            <div style={{ "width": "68%" }} className="progress-bar"></div>
                                                        </div>

                                                        <div className="m-t-sm small">Unlimited Capacity</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 mb-4 mt-4">
                                                <div className="">
                                                    <div className="">
                                                        <h5>SMS Sent</h5>
                                                        <h2>{this.state.sms}</h2>
                                                        <div className="progress progress-mini">
                                                            <div style={{ "width": "68%" }} className="progress-bar"></div>
                                                        </div>

                                                        <div className="m-t-sm small">Unlimited Capacity</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 mb-4 mt-4">
                                                <div className="">
                                                    <div className="">
                                                        <h5>API Calls</h5>
                                                        <h2>{this.state.api}</h2>
                                                        <div className="progress progress-mini">
                                                            <div style={{ "width": "68%" }} className="progress-bar"></div>
                                                        </div>

                                                        <div className="m-t-sm small">Unlimited Capacity</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {this.state.invoices &&
                                    <div className="col-sm-12 mt-4">
                                        <h2>Billing History</h2>

                                        <div className="row mt-4">
                                            <div className="col-sm-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>

                                                                <th>Invoice ID</th>
                                                                <th>Date </th>
                                                                <th>Amount </th>
                                                                <th>Status </th>
                                                                <th>Payment Method </th>
                                                                <th>Payment Date </th>
                                                                <th>Invoice File</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.invoices.length == 0 ? <tr>
                                                                <td>No invoices found</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr> : undefined}

                                                            {this.state.invoices.map((invoice => {
                                                                return (
                                                                    <tr key={invoice.uuid}>
                                                                        <td>{invoice.invoiceID}</td>
                                                                        <td>{invoice.invoiceSentDate !== null ? moment(invoice.invoiceSentDate).format("DD MMM YYYY") : "-"}</td>
                                                                        <td>{Currency_Simple_Render(invoice.total)}</td>
                                                                        <td className={invoice.isPaid === true ? "text-success" : "text-danger"}>
                                                                            {invoice.isPaid === true ? "Paid" : "Pending"}
                                                                        </td>
                                                                        <td>{invoice.paidVia !== null ? invoice.paidVia : "-"}</td>
                                                                        <td>{invoice.paidOn !== null ? moment(invoice.paidOn).format("DD MMM YYYY") : "-"}</td>
                                                                        <td>{File_Simple_Render(invoice.invoiceFile, invoice.invoiceID)}</td>
                                                                    </tr>
                                                                )
                                                            }))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {this.state.billingDetails &&
                                    <div className="col-sm-12">
                                        <h2>Billing Settings</h2>

                                        <div className="row">
                                            <div className="col-sm-6">
                                                <h4 className="mt-4 text-uppercase">Address</h4>
                                                <p>
                                                    This address appears on your invoices and should be the legal address of your home or business
                                                </p>
                                                {this.state.editingDetails ?
                                                    <MagicForm
                                                        onSubmit={this.updateAddress}
                                                        fields={[{
                                                            "name": {
                                                                "label": "Name",
                                                                "type": "text",
                                                                "placeholder": "Your name or your company's name",
                                                                "classes": [],
                                                                "parentClasses": [],
                                                                "sizeClass": 2,
                                                                "value": this.state.billingDetails.name,
                                                            },
                                                            "address1": {
                                                                "label": "Address Line 1",
                                                                "type": "text",
                                                                "placeholder": "Address Line 1",
                                                                "classes": [],
                                                                "parentClasses": [],
                                                                "sizeClass": 2,
                                                                "value": this.state.billingDetails.address1
                                                            },
                                                            "address2": {
                                                                "label": "Address Line 2",
                                                                "type": "text",
                                                                "placeholder": "Address Line 2",
                                                                "classes": [],
                                                                "parentClasses": [],
                                                                "sizeClass": 2,
                                                                "value": this.state.billingDetails.address2
                                                            },
                                                            "address3": {
                                                                "label": "Address Line 3",
                                                                "type": "text",
                                                                "placeholder": "Address Line 3",
                                                                "classes": [],
                                                                "parentClasses": [],
                                                                "sizeClass": 2,
                                                                "value": this.state.billingDetails.address3
                                                            },
                                                            "city": {
                                                                "label": "City",
                                                                "type": "text",
                                                                "placeholder": "City",
                                                                "classes": [],
                                                                "parentClasses": [],
                                                                "sizeClass": 2,
                                                                "value": this.state.billingDetails.city
                                                            },
                                                            "state": {
                                                                "label": "State",
                                                                "type": "text",
                                                                "placeholder": "State",
                                                                "classes": [],
                                                                "parentClasses": [],
                                                                "sizeClass": 2,
                                                                "value": this.state.billingDetails.state
                                                            },
                                                            "pin": {
                                                                "label": "Pin",
                                                                "type": "text",
                                                                "placeholder": "Pin",
                                                                "classes": [],
                                                                "parentClasses": [],
                                                                "sizeClass": 2,
                                                                "value": this.state.billingDetails.pin
                                                            },
                                                            "country": {
                                                                "label": "Country",
                                                                "type": "text",
                                                                "placeholder": "Country",
                                                                "classes": [],
                                                                "parentClasses": [],
                                                                "sizeClass": 2,
                                                                "value": this.state.billingDetails.country
                                                            },
                                                        }]}
                                                    />
                                                    :
                                                    <address>
                                                        {this.state.billingDetails.name}<br />
                                                        {this.state.billingDetails.address1}<br />
                                                        {this.state.billingDetails.address2}<br />
                                                        {this.state.billingDetails.address3}<br />
                                                        {this.state.billingDetails.city}<br />
                                                        {this.state.billingDetails.state} - {this.state.billingDetails.pin}<br />
                                                        {this.state.billingDetails.country}<br />
                                                    </address>
                                                }

                                                {!this.state.editingDetails ?
                                                    <button onClick={() => this.setState({ editingDetails: !this.state.editingDetails })} className="btn btn-small btn-default">Edit Details</button>
                                                    :
                                                    <button onClick={() => this.setState({ editingDetails: !this.state.editingDetails })} className="btn btn-small btn-danger">Cancel</button>
                                                }
                                            </div>

                                            <div className="col-sm-6">
                                                <h4 className="mt-4 text-uppercase">Tax Information</h4>
                                                <p>
                                                    Registered business can enter their GST ID here.
                                                </p>

                                                {this.state.editingGst ?
                                                    <MagicForm
                                                        onSubmit={this.updateGST}
                                                        fields={[{
                                                            "gstRegistration": {
                                                                "label": "GST ID",
                                                                "type": "text",
                                                                "placeholder": "GSTID",
                                                                "classes": [],
                                                                "parentClasses": [],
                                                                "sizeClass": 2,
                                                                "value": this.state.billingDetails.gstRegistration,
                                                            },
                                                        }]}
                                                    />
                                                    :
                                                    <address>
                                                        {this.state.billingDetails.gstRegistration ?
                                                            <span className="label label-default">{this.state.billingDetails.gstRegistration}</span>
                                                            :
                                                            <span>  No GST registration available</span>
                                                        }
                                                    </address>
                                                }

                                                {!this.state.editingGst ?
                                                    <button onClick={() => this.setState({ editingGst: !this.state.editingGst })} className="btn btn-small btn-default">Edit Details</button>
                                                    :
                                                    <button onClick={() => this.setState({ editingGst: !this.state.editingGst })} className="btn btn-small btn-danger">Cancel</button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    }
                            </div>
                        </div>
                    }
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps)(Billing);
