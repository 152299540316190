import {render as ejsRender} from "ejs";

export default (value, accessor, tuple) => {
    if(Array.isArray(value)) {
        const toDisplay = value.reduce((acc, key) => {
            if(key[0] == "$") {
                return `${acc}<%= locals["${accessor}_${key.substr(1).replace(".", "_")}"] %>`;
            }

            return `${acc}${key}`;
        }, "");

        return ejsRender(toDisplay, tuple);
    } else if(!!tuple[`__ROBOSTACK_LOOKED_UP_${accessor}`]) {
        return tuple[`__ROBOSTACK_LOOKED_UP_${accessor}`];
    } else {
        return value;
    }
}