import React from "react";

import { connect } from "react-redux";

import axiosBackend from "../../../core/api/backend";

import withLoaderData from "../../../components/withLoaderData";

import Loader from "../../../components/Loader";
import * as DataDelegator from "../../../components/smart/delegator/DataDelegator";

import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

import AddUser from "./add-user";
import AddRoles from "./add-roles";
import EditUser from "./edit-user";
import API_STATES from "../../../api-states";

import { Editor } from "@monaco-editor/react";

import { permissionLabels, permissionTitles } from "./permissions/list";

class UsersManagement extends React.Component {
    constructor(props) {
        super(props);

        this.defaultPicture = "/img/user.svg";

        this.actionTypes = [
            {
                value: "allow",
                label: "Allow access"
            },
            {
                value: "deny",
                label: "Deny access"
            }
        ]

        this.tabs = {
            "USERS": "users",
            "ROLES": "roles",
        };

        this.rolesTab = {
            "NONE": "NONE",
            "VIEW_ROLE": "VIEW_ROLE",
            "ADD_ROLE": "ADD_ROLE",
        }

        this.usersTab = {
            "NONE": "NONE",
            "VIEW_USER": "VIEW_USER",
            "ADD_USER": "ADD_USER",
            "EDIT_USER": "EDIT_USER"
        }

        this.addPermissionViews = {
            ...Object.keys(permissionTitles).reduce((acc, key) => {
                acc[key] = key;

                return acc;
            }, {
                "RESOURCES_FIELDS": "RESOURCES_FIELDS",
                "RESOURCES_ROWS": "RESOURCES_ROWS",

                "NONE": "NONE",
            }),
        }

        this.addPermissionViewTypesOpen = {
            "NONE": "NONE",
            "ADD_PERMISSION": "ADD_PERMISSION",
            "EDIT_PERMISSION": "EDIT_PERMISSION",
        }

        this.state = {
            selectedTab: this.tabs.USERS,
            rolesTabView: this.rolesTab.NONE,
            usersTabView: this.usersTab.NONE,

            addPermissionViewOpen: this.addPermissionViews.NONE,
            addPermissionViewTypeOpen: this.addPermissionViewTypesOpen.NONE,
            addPermissionAPIState: API_STATES.IDLE,

            users: [],
            roles: [],
            usersMappedToRoles: {},
            permissions: [],
            permissionsGrouped: {},

            selectedUser: null,
            selectedRole: null,
            selectedPermission: null,

            showAddUser: false,

            permissionTargets: Object.entries(permissionTitles).map(([key, value]) => {
                return {
                    value: key,
                    label: value,
                }
            }),
            subPermissionTargets: [],

            selectedPermissionTarget: null,
            selectedSubPermissionsType: null,

            rowLevelPermissionsPipeline: "[]",
            rowLevelPermissionsPipelineValid: true,

            resources: [],
            selectedResource: null,
            fields: [],

            attributes: [],
            actionType: null,

            searchText: "",

            permissionsExpanded: {
                "resource_rows": false,
                "resource_fields": false,
                ...Object.keys(permissionTitles).reduce((acc, target) => {
                    acc[target] = false;
                    return acc;
                }, {}),
            },

            usersListLoading: true,
            usageActivityLoading: true,

            userActivity: [],
        };

        this.selectUser = this.selectUser.bind(this);
        this.selectRole = this.selectRole.bind(this);
        this.selectTab = this.selectTab.bind(this);

        this.editUser = this.editUser.bind(this);

        this.updateSearchText = this.updateSearchText.bind(this);

        this.handleResourceChange = this.handleResourceChange.bind(this);
        this.onAttributesChange = this.onAttributesChange.bind(this);
        this.onActionTypeChange = this.onActionTypeChange.bind(this);

        this.toggleAddUser = this.toggleAddUser.bind(this);
        this.toggleAddRole = this.toggleAddRole.bind(this);

        this.addPermissionToRole = this.addPermissionToRole.bind(this);
        this.toggleAddPermission = this.toggleAddPermission.bind(this);

        this.handlePermissionTargetChange = this.handlePermissionTargetChange.bind(this);
        this.handleSubPermissionTypeChange = this.handleSubPermissionTypeChange.bind(this);
        this.handleRowLevelPermissionsPipelineChange = this.handleRowLevelPermissionsPipelineChange.bind(this);
        this.handleRowLevelPermissionsPipelineValidation = this.handleRowLevelPermissionsPipelineValidation.bind(this);

        this.toggleExpandPermissions = this.toggleExpandPermissions.bind(this);

        this.closeAddPermissionView = this.closeAddPermissionView.bind(this);
    }

    roleSuccessfullyAdded(role) {
        this.selectRole(role, () => {
            this.loadRoles();
        })
    }

    toggleAddUser() {
        this.setState((state) => {
            return {
                usersTabView: this.usersTab.ADD_USER,
            }
        })
    }

    toggleAddRole() {
        this.setState((state) => {
            return {
                rolesTabView: this.rolesTab.ADD_ROLE,
            }
        })
    }

    onAttributesChange(attributes) {
        this.setState({
            attributes,
        })
    }

    onActionTypeChange(actionType) {
        this.setState({
            actionType,
        })
    }

    updateSearchText(event) {
        this.setState({
            searchText: event.target.value,
        })
    }

    handleResourceChange(selectedResource, callback) {
        const stateToUpdate = {
            selectedResource
        }

        this.setState(stateToUpdate, () => {
            this.loadFieldsForSelectedResource(callback);
        });
    }

    loadFieldsForSelectedResource(callback) {
        const config = {
            method: "POST",
            url: `/service/resources/${this.props.router.params.appUUID}/${this.state.selectedResource.value}/fields`,
            data: {}
        };

        axiosBackend(config)
            .then((response) => {
                this.setState({
                    fields: [{
                        label: "All Fields",
                        value: "*",
                    }].concat(response.data.results.map((field) => {
                        field.label = field.fieldName;
                        field.value = field.fieldName;
                        return field;
                    }).sort((a, b) => {
                        if (a.displayPosition === null) {
                            return 1; // move `a` to a higher index
                        } else if (b.displayPosition === null) {
                            return -1; // move `b` to a higher index
                        } else {
                            return a.displayPosition - b.displayPosition;
                        }
                    })),
                }, () => {
                    typeof callback === "function" && callback();
                });
            })
            .catch((error) => {
                console.error(error);
                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Fetching Fields List of Resources Failed",
                        message: [
                            "Due to a server error, we were unable to fetch the list of fields for the selected resource.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
    }

    loadResources(callback) {
        const config = {
            method: "POST",
            url: "/service/resources/" + this.props.router.params.appUUID,
            data: {}
        };

        return axiosBackend(config)
            .then((response) => {
                this.setState({
                    resources: [
                        {
                            label: "All Resources",
                            value: "*",
                        }
                    ].concat(response.data.results.map((resource) => {
                        resource.label = resource.name;
                        resource.value = resource.name;
                        return resource;
                    }).sort((a, b) => a.label.localeCompare(b.label))),
                }, () => {
                    typeof callback === "function" && callback();
                });
            })
            .catch((error) => {
                console.error(error);
                this.props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Fetching Resource List of Application Failed",
                        message: [
                            "Due to a server error, we were unable to fetch the list of resources in the given application.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            })
    }

    selectUser(user) {
        this.setState({
            selectedUser: user,
            usersTabView: this.usersTab.VIEW_USER,
        })
    }

    editUser({ user, event }) {
        event.stopPropagation();

        this.setState({
            selectedUser: user,
            usersTabView: this.usersTab.EDIT_USER,
        })
    }

    selectRole(role, callback) {
        this.setState({
            selectedRole: role,
            rolesTabView: this.rolesTab.VIEW_ROLE,
        }, () => {
            this.loadResources(() => {
                this.loadPermissionsForRole(() => {
                    typeof callback === "function" && callback();
                });
            });
        })
    }

    loadUsers() {
        axiosBackend({
            method: "POST",
            url: `/service/sites-that-users-control/${this.props.router.params.appUUID}/`,
        })
            .then((response) => {
                this.setState({
                    users: response.data.results,
                });
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    error: true,
                })
            })
    }

    loadRoles(callback) {
        axiosBackend({
            method: "POST",
            url: `/service/sites-that-users-control/roles/${this.props.router.params.appUUID}/`,
        })
            .then((response) => {
                this.setState({
                    roles: response.data.results,
                }, () => {
                    typeof callback === "function" && callback();
                });
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    error: true,
                })
            })
    }

    loadUsersMappedToRoles() {
        axiosBackend({
            method: "POST",
            url: `/service/sites-that-users-control/${this.props.router.params.appUUID}/users-mapped-to-roles/`,
        })
            .then((response) => {
                this.setState({
                    usersMappedToRoles: response.data.results.reduce((acc, map) => {
                        if (acc[map.user_uuid] == undefined) {
                            acc[map.user_uuid] = [];
                        }

                        acc[map.user_uuid].push(map);

                        return acc;
                    }, {}),
                });
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    error: true,
                })
            })
    }

    loadPermissionsForRole(callback) {
        axiosBackend({
            method: "POST",
            url: `/service/sites-that-users-control/${this.props.router.params.appUUID}/permissions-in-role/`,
            data: {
                roleUUID: this.state.selectedRole.uuid,
            }
        })
            .then((response) => {
                this.setState({
                    permissions: response.data.results,
                    permissionsGrouped: response.data.results.reduce((acc, permission) => {
                        const [target, subTarget] = permission.action.split(":");

                        if (acc[target] == undefined) {
                            acc[target] = {};
                        }

                        if (acc[target][subTarget] == undefined) {
                            acc[target][subTarget] = [];
                        }

                        acc[target][subTarget].push(permission);

                        return acc;
                    }, {}),
                }, () => {
                    typeof callback === "function" && callback();
                })
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    error: true,
                })
            })
    }

    addPermissionToRole() {
        this.setState({
            addPermissionAPIState: API_STATES.LOADING,
        }, () => {
            let config = {
                data: {
                    roleUUID: this.state.selectedRole.uuid,

                    resource: this.state.selectedResource?.value, // Can be null when adding a service level permission
                    action_type: this.state.actionType?.value, // Can be null when adding a row level permission

                    attributes: this.state.attributes.map((attribute) => attribute.value),
                }
            }

            if (this.state.addPermissionViewOpen == this.addPermissionViews.RESOURCES_FIELDS) {
                config.data.action = "resources:fields";
            } else if (this.state.addPermissionViewOpen == this.addPermissionViews.RESOURCES_ROWS) {
                config.data.action = "resources:rows";
                config.data.action_type = "allow"; // Because row level permissions are always allowed
                config.data["row-level-permissions"] = this.state.rowLevelPermissionsPipeline;
            } else {
                config.data.action = this.state.selectedSubPermissionsType?.value;

            }

            if (this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.ADD_PERMISSION) {
                config.method = "PUT";
                config.url = `/service/sites-that-users-control/${this.props.router.params.appUUID}/permissions-in-role/`;
            } else if (this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.EDIT_PERMISSION) {
                config.method = "PATCH";
                config.url = `/service/sites-that-users-control/${this.props.router.params.appUUID}/permissions-in-role/`;

                config.data.permissionUUID = this.state.selectedPermission.uuid;
            }

            axiosBackend(config)
                .then((response) => {
                    this.setState({
                        addPermissionAPIState: API_STATES.LOADED,
                    });
                    this.loadPermissionsForRole();
                })
                .catch((err) => {
                    console.error(err);

                    this.setState({
                        addPermissionAPIState: API_STATES.IDLE,
                    });

                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "error",
                            title: "Could not add permissions",
                            message: err?.response?.data?.messages || [
                                "Due to an error, we were unable to add permission to the role",
                                "‏‏‎ ‎",
                                "Please try again in a little while."
                            ],
                            okayButtonText: "Okay"
                        },
                    });
                })
        });
    }

    componentDidMount() {
        DataDelegator.resolve(this.props, (err) => {
            if (err) {
                throw err;
            } else {
                this.selectTab(this.tabs.USERS);
            }
        });
    }

    confirmDelete({ user, event }) {

        event.stopPropagation();

        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                show: true,
                type: "confirmation",
                title: "Confirm Access Removal",
                iconClasses: [
                    "fas",
                    "fa-user-times",
                    "fa-3x",
                    "mb-4",
                    "mt-2"
                ],
                message: [
                    "Are you sure you would like to revoke access for " + user.email + "?",
                    "‏‏‎ ‎",
                    "This will not remove any of the created records, data trail records or pipelines that the user has created.",
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    // onClose();
                    this.onConfirmDelete(user)
                }
            },
        });
    }

    onConfirmDelete(user) {
        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "processing",
                "title": "Revoking Access",
                "message": [
                    "This might take a few seconds.",
                    "You will be shown a confirmation screen once completed."
                ]
            },
        });

        setTimeout(() => {
            axiosBackend({
                method: "DELETE",
                url: `/service/sites-that-users-control/${this.props.router.params.appUUID}/`,
                data: {
                    uuid: user.uuid,
                }
            })
                .then((response) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "success",
                            title: "Success",
                            message: [
                                "Access to " + user.email + " was revoked successfully.",
                                "‏‏‎ ‎",
                                "They will no longer be able to access your application resources and it's associated smart applications."
                            ],
                            okayButtonText: "Okay",
                        },
                    });

                    if (this.state.selectedUser !== null && this.state.selectedUser.uuid === user.uuid) {
                        this.setState({
                            selectedUser: null,
                        })
                    }

                    this.loadUsers();
                })
                .catch((err) => {
                    console.error(err);
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "error",
                            title: "Could not revoke access",
                            message: [
                                "Due to an error, we were unable to revoke access for " + user.email,
                                "‏‏‎ ‎",
                                "Please try again in a little while."
                            ],
                            okayButtonText: "Okay"
                        },
                    });
                })
        }, 1000);
    }

    confirmRoleDelete({ role, event }) {

        event.stopPropagation();

        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                show: true,
                type: "confirmation",
                title: "Confirm Role Removal",
                iconClasses: [
                    "fas",
                    "fa-user-times",
                    "fa-3x",
                    "mb-4",
                    "mt-2"
                ],
                message: [
                    "Are you sure you would like to remove the role " + role.name + "?",
                    "‏‏‎ ‎",
                    "This will remove the role from all the assigned users as well.",
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    // onClose();
                    console.log(role)
                    this.onConfirmRoleDelete(role)
                }
            },
        });
    }

    onConfirmRoleDelete(role) {
        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "processing",
                "title": "Removing Role",
                "message": [
                    "This might take a few seconds.",
                    "You will be shown a confirmation screen once completed."
                ]
            },
        });

        setTimeout(() => {
            axiosBackend({
                method: "DELETE",
                url: `/service/sites-that-users-control/roles/${this.props.router.params.appUUID}/`,
                data: {
                    uuid: role.uuid,
                }
            })
                .then((response) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "success",
                            title: "Success",
                            message: [
                                "The " + role.name + " role was removed successfully. All users who had the role assigned had the role removed.",
                                "‏‏‎ ‎",
                                "They will no longer be able to access your application based on the settings defined inside that role but will still be able to access things based on other assigned roles."
                            ],
                            okayButtonText: "Okay",
                        },
                    });

                    if (this.state.selectedRole !== null && this.state.selectedRole.uuid === role.uuid) {
                        this.setState({
                            selectedRole: null,
                            rolesTabView: this.rolesTab.NONE,
                        })
                    }

                    this.loadRoles();
                })
                .catch((err) => {
                    console.error(err);

                    let messages = [
                        "Due to an error, we were unable to remove the " + role.name + " role",
                        "‏‏‎ ‎",
                        "Please try again in a little while."
                    ];

                    if (err?.response?.data?.messages) {
                        messages = err.response.data.messages;
                    }

                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            show: true,
                            type: "error",
                            title: "Could not remove role",
                            message: messages,
                            okayButtonText: "Okay"
                        },
                    });
                })
        }, 1000);
    }

    selectTab(tab) {
        if (tab === this.tabs.USERS) {
            this.setState({
                selectedTab: this.tabs.USERS,
            }, () => {
                this.loadUsers();
                this.loadUsersMappedToRoles();
            });
        }

        if (tab === this.tabs.ROLES) {
            this.setState({
                selectedTab: this.tabs.ROLES,
            }, () => {
                this.loadRoles();
            });
        }
    }

    toggleAddPermission({ permissionView, viewType, target }) {
        let selectedTarget = target;
        if (selectedTarget) {
            selectedTarget = this.state.permissionTargets.find((permissionTarget) => permissionTarget.value == target);

            this.handlePermissionTargetChange(selectedTarget);
        }

        this.setState({
            addPermissionViewOpen: permissionView,
            addPermissionViewTypeOpen: viewType,
            addPermissionAPIState: API_STATES.IDLE,
        })
    }

    closeAddPermissionView() {
        this.setState({
            addPermissionViewOpen: this.addPermissionViewTypesOpen.NONE,

            actionType: null,

            selectedResource: null,
            attributes: [],

            selectedSubPermissionsType: null,
            rowLevelPermissionsPipeline: "[]",
        })
    }

    onEditPermission({ event, permission }) {
        event.stopPropagation();

        this.loadResources(() => {
            this.handleResourceChange((this.state.resources.find((resource) => resource.value == permission.resource)), () => {
                this.setState({
                    selectedPermission: permission,
                    actionType: this.actionTypes.find((actionType) => actionType.value == permission.action_type),
                    attributes: this.state.fields.filter((field) => permission.attributes.includes(field.value)),

                    addPermissionViewOpen: this.addPermissionViews.RESOURCES_FIELDS,
                    addPermissionViewTypeOpen: this.addPermissionViewTypesOpen.EDIT_PERMISSION,
                });
            });
        });
    }

    onDeletePermission({ event, permission }) {
        event.stopPropagation();

        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Delete Permission",
                "message": [
                    "Are you sure you would like to delete this set of permissions?"
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                components: [],
                onYes: (onClose) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Deleting Permissions",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "DELETE",
                            url: `/service/sites-that-users-control/${this.props.router.params.appUUID}/permissions-in-role/`,
                            data: {
                                permissionUUID: permission.uuid,
                                roleUUID: this.state.selectedRole.uuid,
                            }
                        })
                            .then((response) => {
                                this.loadPermissionsForRole();

                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            "The permissions were successfully deleted"
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });
                            })
                            .catch((err) => {
                                console.error(err);
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Could not delete permissions",
                                        message: err?.response?.data?.messages || [
                                            "Due to an error, we were unable to delete the selected permissions",
                                            "‏‏‎ ‎",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    handlePermissionTargetChange(target) {
        //Find all permission labels whose key starts with the target
        const subPermissionTargets = Object.entries(permissionLabels).filter(([key]) => {
            return key.startsWith(target.value + ":");
        }).map(([key, labels]) => {
            return {
                value: key,
                label: labels.generic,
            }
        });

        this.setState({
            selectedPermissionTarget: target,
            subPermissionTargets
        })
    }

    handleSubPermissionTypeChange(subTarget) {
        this.setState({
            selectedSubPermissionsType: subTarget,
        })
    }

    handleRowLevelPermissionsPipelineChange(json) {
        this.setState({
            rowLevelPermissionsPipeline: json,
        });
    }

    handleRowLevelPermissionsPipelineValidation(markers) {
        this.setState({
            rowLevelPermissionsPipelineValid: markers.length == 0,
        })
    }

    toggleExpandPermissions({ event, target }) {
        event.preventDefault();

        this.setState((state) => {
            // If there is a target, toggle that target
            if (target) {
                return {
                    permissionsExpanded: {
                        ...state.permissionsExpanded,
                        [target]: !state.permissionsExpanded[target],
                    }
                }
            }

            // If there is no target, check if any are expanded and if so, collapse all
            // If all are collapsed, expand all
            const anyExpanded = Object.values(state.permissionsExpanded).some((value) => value == true);

            let expand = true;
            if (anyExpanded) {
                expand = false;
            }

            return {
                permissionsExpanded: Object.keys(state.permissionsExpanded).reduce((acc, target) => {
                    acc[target] = expand;
                    return acc;
                }, {})
            }
        })
    }

    render() {
        return (
            <>
                <div className="col-sm-7">
                    <div className="">
                        <div className="ibox-content">
                            <h2>Users &amp; Roles Management</h2>
                            <p>
                                Control who can access this application and what they can see and do
                            </p>
                            <div className="clients-list">
                                <ul className="nav nav-tabs">
                                    <li>
                                        <a
                                            className={`nav-link ${this.state.selectedTab == this.tabs.USERS && "active show"}`}
                                            onClick={() => this.selectTab(this.tabs.USERS)}
                                        >
                                            <i className="fa fa-user"></i> Users
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className={`nav-link ${this.state.selectedTab == this.tabs.ROLES && "active show"}`}
                                            onClick={() => this.selectTab(this.tabs.ROLES)}
                                        >
                                            <i className="fa fa-lock"></i> Roles
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div id="tab-users" className={`tab-pane ${this.state.selectedTab == this.tabs.USERS && "active"}`}>
                                        <div className="table-responsive">
                                            <table className="table table-striped table-hover">
                                                <tbody>
                                                    {this.state.users.length == 0 ? <tr>
                                                        <td>
                                                            No users found
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr> : undefined}

                                                    {this.state.users.map((user) => {
                                                        return (
                                                            <tr key={user.uuid} className="pointer" onClick={() => this.selectUser(user)}>
                                                                <td className="client-avatar"><img alt="image" src={user.picture || this.defaultPicture} /> </td>
                                                                <td><a className="client-link">{user.first_name} {user.last_name}</a></td>
                                                                <td className="contact-type"><i className="fa fa-envelope"> </i></td>
                                                                <td className="contact-type"> {user.email}</td>
                                                                <td>
                                                                    {this.state.usersMappedToRoles[user.uuid]?.map((role) => {
                                                                        return <span key={role.uuid} className="label label-info mr-2">{role.name}</span>
                                                                    })}
                                                                </td>
                                                                <td className="client-status text-right">

                                                                    {/* <button className="btn btn-success btn-xs mr-2">
                                                                                <span className="fa fa-eye"></span>&nbsp;View Activity
                                                                            </button> */}

                                                                    <button onClick={(event) => this.editUser({ user, event })} className="btn btn-warning btn-xs mr-2">
                                                                        <span className="fa fa-lock"></span>&nbsp;Edit Roles
                                                                    </button>

                                                                    <button className="btn btn-danger btn-xs" onClick={(event) => this.confirmDelete({ event, user })}>
                                                                        <span className="fa fa-times"></span>&nbsp;Remove
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>

                                            <button type="button" className="btn btn-primary btn-block" onClick={this.toggleAddUser}>
                                                <i className="fas fa-plus"></i>&nbsp;Add User
                                            </button>

                                        </div>
                                    </div>

                                    <div id="tab-roles" className={`tab-pane ${this.state.selectedTab == this.tabs.ROLES && "active"}`}>
                                        <div className="table-responsive">
                                            <table className="table table-striped table-hover">
                                                <tbody>
                                                    {this.state.roles.length == 0 && <>
                                                        <tr className="p-2">
                                                            <td>
                                                                No roles found
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </>}

                                                    {this.state.roles.map((role) => {
                                                        return (
                                                            <tr key={role.uuid} className="pointer" onClick={() => this.selectRole(role)}>
                                                                <td><a className="client-link">{role.name}</a></td>
                                                                <td className="client-status text-right">

                                                                    {/* <button className="btn btn-success btn-xs mr-2">
                                                                                <span className="fa fa-eye"></span>&nbsp;View Activity
                                                                            </button> */}

                                                                    {/* <button className="btn btn-warning btn-xs mr-2">
                                                                                <span className="fa fa-lock"></span>&nbsp;Edit Privileges
                                                                            </button> */}

                                                                    <button className="btn btn-danger btn-xs" onClick={(event) => this.confirmRoleDelete({ event, role })}>
                                                                        <span className="fa fa-times"></span>&nbsp;Remove
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>

                                            <button onClick={this.toggleAddRole} type="button" className="btn btn-primary btn-block"><i className="fas fa-plus"></i>&nbsp;Add Role</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-5">
                    <div className="selected">

                        <div className="ibox-content">
                            <div className="tab-content">
                                {this.state.selectedTab == this.tabs.ROLES &&
                                    <>
                                        <div className="tab-pane active">
                                            {this.state.rolesTabView == this.rolesTab.NONE &&
                                                <div>
                                                    Click on a role to view its configured privileges.
                                                </div>
                                            }

                                            {this.state.rolesTabView == this.rolesTab.ADD_ROLE &&
                                                <AddRoles onComplete={(role) => this.roleSuccessfullyAdded(role)} siteUUID={this.props.router.params.appUUID} />
                                            }

                                            {this.state.rolesTabView == this.rolesTab.VIEW_ROLE &&
                                                <div>
                                                    <div className="row m-b-lg">
                                                        <div className="col-12">

                                                            <h3 className="mb-0">
                                                                <strong>{this.state.selectedRole.name}</strong>
                                                                <div className="float-right">
                                                                    <button onClick={(event) => this.toggleExpandPermissions({ event })} className="btn btn-xs btn-default">
                                                                        <span className={`fas fa-expand-alt`}></span>&nbsp;
                                                                        Toggle Expand Permissions
                                                                    </button>
                                                                </div>
                                                            </h3>
                                                            <hr />

                                                            {this.state.permissions.length == 0 &&
                                                                <div className="row">
                                                                    <div className="col-sm-12 mb-2">
                                                                        No permissions configured for this role
                                                                    </div>
                                                                </div>
                                                            }

                                                            <div id="accordion">
                                                                {Object.keys(this.state.permissionsGrouped).map((target) => {
                                                                    if (permissionTitles[target] != undefined) {
                                                                        return (
                                                                            <div className="card mb-2">
                                                                                <div className="card-header p-0 no-borders pointer" onClick={(event) => this.toggleExpandPermissions({ event, target })}>
                                                                                    <h5 className="mb-0">
                                                                                        <button className="btn btn-link">
                                                                                            {permissionTitles[target]}
                                                                                        </button>
                                                                                    </h5>
                                                                                </div>

                                                                                <div id={`${target}`} className={`collapse ${this.state.permissionsExpanded[target] ? "show" : ""}`}>
                                                                                    <div className="card-body">
                                                                                        {Object.keys(this.state.permissionsGrouped[target]).map((subTarget) => {
                                                                                            return this.state.permissionsGrouped[target][subTarget].map((permission) => {
                                                                                                return (
                                                                                                    <div key={`${permission.uuid}`} className="col-sm-12 mb-2">
                                                                                                        <div className={permission.action_type == "deny" ? "text-danger" : "text-success"}>
                                                                                                            {permissionLabels[permission.action][permission.action_type]}
                                                                                                            <i onClick={(event) => this.onDeletePermission({ event, permission })} className="pointer ml-2 fa fa-trash text-danger text-right"></i>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }


                                                                    if (target == 'resources') {
                                                                        return (
                                                                            <div>
                                                                                {Object.keys(this.state.permissionsGrouped[target]).map((subTarget) => {
                                                                                    const permissionsByResources = this.state.permissionsGrouped[target][subTarget].reduce((acc, permission) => {
                                                                                        if (acc[permission.resource] == undefined) {
                                                                                            acc[permission.resource] = [];
                                                                                        }

                                                                                        acc[permission.resource].push(permission);

                                                                                        return acc;
                                                                                    }, {});

                                                                                    if (subTarget == "fields") {

                                                                                        return (
                                                                                            <div className="card mb-2">
                                                                                                <div className="card-header p-0 no-borders pointer" onClick={(event) => this.toggleExpandPermissions({ event, target: "resource_fields" })}>
                                                                                                    <h5 className="mb-0">
                                                                                                        <button className="btn btn-link">
                                                                                                            Field Levels Permissions
                                                                                                        </button>
                                                                                                    </h5>
                                                                                                </div>

                                                                                                <div id={`resource_fields`} className={`collapse ${this.state.permissionsExpanded['resource_fields'] ? "show" : ""}`}>
                                                                                                    <div className="card-body">
                                                                                                        <div className="row">
                                                                                                            {Object.keys(permissionsByResources).map((resource) => {
                                                                                                                const permissions = permissionsByResources[resource];

                                                                                                                return (
                                                                                                                    <div className="col-sm-6">
                                                                                                                        <div>
                                                                                                                            <strong>
                                                                                                                                {resource == "*" ? <u>All Resources</u> : resource}
                                                                                                                            </strong>
                                                                                                                        </div>
                                                                                                                        {permissions.map((permission) => {
                                                                                                                            return (
                                                                                                                                <div key={`${permission.uuid}`} className="ml-4 col-sm-12 mb-2">
                                                                                                                                    <i onClick={(event) => this.onEditPermission({ event, permission })} className="pointer mr-2 fa fa-edit text-primary text-right"></i>
                                                                                                                                    <i onClick={(event) => this.onDeletePermission({ event, permission })} className="pointer mr-2 fa fa-trash text-danger text-right"></i>

                                                                                                                                    <div className={permission.action_type == "deny" ? "text-danger" : "text-success"}>
                                                                                                                                        {permissionLabels[permission.action][permission.action_type]}
                                                                                                                                    </div>

                                                                                                                                    <ul className="ml-2">
                                                                                                                                        {permission.attributes.map((attribute) => {
                                                                                                                                            return <li key={attribute}>
                                                                                                                                                {attribute == "*" ? <u>All Fields</u> : attribute}
                                                                                                                                            </li>
                                                                                                                                        })}
                                                                                                                                    </ul>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        })}
                                                                                                                    </div>
                                                                                                                );
                                                                                                            })}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }

                                                                                    if (subTarget == "rows") {

                                                                                        return (
                                                                                            <div className="card mb-2">
                                                                                                <div className="card-header p-0 no-borders pointer" onClick={(event) => this.toggleExpandPermissions({ event, target: "resource_rows" })}>
                                                                                                    <h5 className="mb-0">
                                                                                                        <button className="btn btn-link">
                                                                                                            Row Level Permissions
                                                                                                        </button>
                                                                                                    </h5>
                                                                                                </div>

                                                                                                <div id={`resource_rows`} className={`collapse ${this.state.permissionsExpanded['resource_rows'] ? "show" : ""}`}>
                                                                                                    <div className="card-body">
                                                                                                        <div className="row">
                                                                                                            {Object.keys(permissionsByResources).map((resource) => {
                                                                                                                const permissions = permissionsByResources[resource];

                                                                                                                return (
                                                                                                                    <div className="col-sm-12">
                                                                                                                        <div>
                                                                                                                            <strong>
                                                                                                                                {resource == "*" ? <u>All Resources</u> : resource}
                                                                                                                            </strong>
                                                                                                                        </div>
                                                                                                                        {permissions.map((permission) => {
                                                                                                                            return (
                                                                                                                                <div key={`${permission.uuid}`} className="ml-4 col-sm-12 mb-2">
                                                                                                                                    {/* <i onClick={(event) => this.onEditPermission({ event, permission })} className="pointer mr-2 fa fa-edit text-primary text-right"></i> */}
                                                                                                                                    <i onClick={(event) => this.onDeletePermission({ event, permission })} className="pointer mr-2 fa fa-trash text-danger text-right"></i>

                                                                                                                                    <div className={permission.action_type == "deny" ? "text-danger" : "text-success"}>
                                                                                                                                        {permissionLabels[permission.action][permission.action_type]}
                                                                                                                                    </div>

                                                                                                                                    <pre>
                                                                                                                                        <code>
                                                                                                                                            {JSON.stringify(permission["row-level-permissions"], null, 4)}
                                                                                                                                        </code>
                                                                                                                                    </pre>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        })}
                                                                                                                    </div>
                                                                                                                );
                                                                                                            })}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        );
                                                                    }
                                                                })}
                                                            </div>


                                                            <hr className="mt-0" />

                                                            {this.state.addPermissionViewOpen == this.addPermissionViews.RESOURCES_FIELDS &&
                                                                <div className="row mt-2">
                                                                    <div className="col-sm-12 mb-2">
                                                                        <strong>
                                                                            {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.ADD_PERMISSION && "Add"}
                                                                            {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.EDIT_PERMISSION && "Edit"}
                                                                            &nbsp;field level permissions for resources
                                                                        </strong>
                                                                    </div>

                                                                    {this.state.addPermissionAPIState === API_STATES.LOADED &&
                                                                        <div className="col-sm-12">
                                                                            <div className="alert alert-success">
                                                                                Permission successfully&nbsp;
                                                                                {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.ADD_PERMISSION && "added"}
                                                                                {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.EDIT_PERMISSION && "edited"}
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    <div className="col-sm-12 mb-2">
                                                                        <Select
                                                                            id="resource-fieldType"
                                                                            placeholder="Select the resource"
                                                                            value={this.state.selectedResource}
                                                                            onChange={this.handleResourceChange}
                                                                            options={this.state.resources}
                                                                        />
                                                                    </div>

                                                                    <div className="col-sm-12 mb-2">
                                                                        Permission Type:
                                                                        <Select
                                                                            id="resource-fieldType"
                                                                            placeholder="Select if you want to allow or deny access"
                                                                            value={this.state.actionType}
                                                                            onChange={this.onActionTypeChange}
                                                                            options={this.actionTypes}
                                                                        />
                                                                    </div>

                                                                    <div className="col-sm-12 mb-2">
                                                                        Fields:
                                                                        <CreatableSelect
                                                                            placeholder="Select the field from the resource or type to add a field name"
                                                                            isMulti
                                                                            formatCreateLabel={(inputString) => `Generic match "${inputString}"`}
                                                                            value={this.state.attributes}
                                                                            onChange={this.onAttributesChange}
                                                                            options={this.state.fields}
                                                                            getOptionLabel={option => {
                                                                                if (option.__isNew__) {
                                                                                    return option.label
                                                                                } else {
                                                                                    return option.label
                                                                                }
                                                                            }}

                                                                            closeMenuOnSelect={false}
                                                                        // styles={colourStyles}
                                                                        />
                                                                    </div>

                                                                    <div className="mt-0 col-sm-12">
                                                                        <button
                                                                            onClick={this.addPermissionToRole}
                                                                            className="btn btn-primary mb-2"
                                                                            disabled={this.state.addPermissionAPIState == API_STATES.LOADING}
                                                                        >
                                                                            {this.state.addPermissionAPIState == API_STATES.LOADING ?
                                                                                <>
                                                                                    {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.ADD_PERMISSION && "Adding Permission..."}
                                                                                    {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.EDIT_PERMISSION && "Editing Permission..."}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.ADD_PERMISSION && "Add Permission"}
                                                                                    {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.EDIT_PERMISSION && "Update Permission"}
                                                                                </>
                                                                            }
                                                                        </button>

                                                                        <button
                                                                            disabled={this.state.addPermissionAPIState == API_STATES.LOADING}
                                                                            onClick={this.closeAddPermissionView}
                                                                            className="btn btn-danger mb-2 float-right"
                                                                        >
                                                                            Close
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {this.state.addPermissionViewOpen == this.addPermissionViews.RESOURCES_ROWS &&
                                                                <div className="row mt-2">
                                                                    <div className="col-sm-12 mb-2">
                                                                        <strong>
                                                                            {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.ADD_PERMISSION && "Add"}
                                                                            {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.EDIT_PERMISSION && "Edit"}
                                                                            &nbsp;field level permissions for resources
                                                                        </strong>
                                                                    </div>

                                                                    {this.state.addPermissionAPIState === API_STATES.LOADED &&
                                                                        <div className="col-sm-12">
                                                                            <div className="alert alert-success">
                                                                                Permission successfully&nbsp;
                                                                                {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.ADD_PERMISSION && "added"}
                                                                                {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.EDIT_PERMISSION && "edited"}
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    <div className="col-sm-12 mb-2">
                                                                        <Select
                                                                            id="resource-fieldType"
                                                                            placeholder="Select the resource"
                                                                            value={this.state.selectedResource}
                                                                            onChange={this.handleResourceChange}
                                                                            options={this.state.resources}
                                                                        />
                                                                    </div>

                                                                    <div className="col-sm-12 mb-2">
                                                                        Pipeline:

                                                                        {!this.state.rowLevelPermissionsPipelineValid &&

                                                                            <div className="alert alert-danger" role="alert">
                                                                                <div className="row">
                                                                                    <div className="col-9">
                                                                                        Invalid JSON
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        <Editor
                                                                            height="30vh"
                                                                            defaultLanguage="json"
                                                                            theme="vs-dark"
                                                                            autoIndent={true}
                                                                            onChange={this.handleRowLevelPermissionsPipelineChange}
                                                                            onValidate={this.handleRowLevelPermissionsPipelineValidation}
                                                                            value={this.state.rowLevelPermissionsPipeline}

                                                                        />
                                                                    </div>

                                                                    <div className="mt-0 col-sm-12">
                                                                        <button
                                                                            onClick={this.addPermissionToRole}
                                                                            className="btn btn-primary mb-2"
                                                                            disabled={this.state.addPermissionAPIState == API_STATES.LOADING}
                                                                        >
                                                                            {this.state.addPermissionAPIState == API_STATES.LOADING ?
                                                                                <>
                                                                                    {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.ADD_PERMISSION && "Adding Permission..."}
                                                                                    {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.EDIT_PERMISSION && "Editing Permission..."}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.ADD_PERMISSION && "Add Permission"}
                                                                                    {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.EDIT_PERMISSION && "Update Permission"}
                                                                                </>
                                                                            }
                                                                        </button>

                                                                        <button
                                                                            disabled={this.state.addPermissionAPIState == API_STATES.LOADING}
                                                                            onClick={this.closeAddPermissionView}
                                                                            className="btn btn-danger mb-2 float-right"
                                                                        >
                                                                            Close
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }


                                                            {![this.addPermissionViews.RESOURCES_FIELDS, this.addPermissionViews.RESOURCES_ROWS, this.addPermissionViews.NONE].includes(this.state.addPermissionViewOpen) &&

                                                                <div className="row mt-2">
                                                                    <div className="col-sm-12 mb-2">
                                                                        <strong>
                                                                            Add service level permissions
                                                                        </strong>
                                                                    </div>

                                                                    {this.state.addPermissionAPIState === API_STATES.LOADED &&
                                                                        <div className="col-sm-12">
                                                                            <div className="alert alert-success">
                                                                                Permission successfully&nbsp;
                                                                                {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.ADD_PERMISSION && "added"}
                                                                                {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.EDIT_PERMISSION && "edited"}
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    <div className="col-sm-12 mb-2">
                                                                        <Select
                                                                            id="resource-service"
                                                                            placeholder="Select the service"
                                                                            value={this.state.selectedPermissionTarget}
                                                                            onChange={this.handlePermissionTargetChange}
                                                                            options={this.state.permissionTargets}
                                                                        />
                                                                    </div>

                                                                    <div className="col-sm-12 mb-2">
                                                                        Permission Type:
                                                                        <Select
                                                                            id="resource-fieldType"
                                                                            placeholder="Select the field from the resource"
                                                                            value={this.state.actionType}
                                                                            onChange={this.onActionTypeChange}
                                                                            options={this.actionTypes}
                                                                        />
                                                                    </div>

                                                                    <div className="col-sm-12 mb-2">
                                                                        Select the permission:
                                                                        <Select
                                                                            id="resource-service"
                                                                            placeholder="Select the permission"
                                                                            value={this.state.selectedSubPermissionsType}
                                                                            onChange={this.handleSubPermissionTypeChange}
                                                                            options={this.state.subPermissionTargets}
                                                                        />
                                                                    </div>

                                                                    <div className="mt-0 col-sm-12">
                                                                        <button
                                                                            onClick={this.addPermissionToRole}
                                                                            className="btn btn-primary mb-2"
                                                                            disabled={this.state.addPermissionAPIState == API_STATES.LOADING}
                                                                        >
                                                                            {this.state.addPermissionAPIState == API_STATES.LOADING ?
                                                                                <>
                                                                                    {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.ADD_PERMISSION && "Adding Permission..."}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {this.state.addPermissionViewTypeOpen == this.addPermissionViewTypesOpen.ADD_PERMISSION && "Add Permission"}
                                                                                </>
                                                                            }
                                                                        </button>

                                                                        <button
                                                                            disabled={this.state.addPermissionAPIState == API_STATES.LOADING}
                                                                            onClick={this.closeAddPermissionView}
                                                                            className="btn btn-danger mb-2 float-right"
                                                                        >
                                                                            Close
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {this.state.addPermissionViewOpen == this.addPermissionViewTypesOpen.NONE &&
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="btn-group btn-block">

                                                                            <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                                                <i className="fas fa-plus"></i>&nbsp;Add Permissions
                                                                            </button>
                                                                            <div className="dropdown-menu btn-block">
                                                                                <a
                                                                                    className="dropdown-item"
                                                                                    onClick={() => this.toggleAddPermission({ permissionView: this.addPermissionViews.RESOURCES_FIELDS, viewType: this.addPermissionViewTypesOpen.ADD_PERMISSION })}
                                                                                >
                                                                                    Add Field Level Permissions
                                                                                </a>

                                                                                <a
                                                                                    className="dropdown-item"
                                                                                    onClick={() => this.toggleAddPermission({ permissionView: this.addPermissionViews.RESOURCES_ROWS, viewType: this.addPermissionViewTypesOpen.ADD_PERMISSION })}
                                                                                >
                                                                                    Add Row Level Permissions
                                                                                </a>
                                                                                <div role="separator" className="dropdown-divider"></div>
                                                                                <a
                                                                                    className="dropdown-item"
                                                                                    onClick={() => this.toggleAddPermission({ target: "roles", permissionView: this.addPermissionViews["roles"], viewType: this.addPermissionViewTypesOpen.ADD_PERMISSION })}
                                                                                >
                                                                                    Add Roles Management Permissions
                                                                                </a>
                                                                                <a
                                                                                    className="dropdown-item"
                                                                                    onClick={() => this.toggleAddPermission({ target: "users", permissionView: this.addPermissionViews["users"], viewType: this.addPermissionViewTypesOpen.ADD_PERMISSION })}
                                                                                >
                                                                                    Add Users Management Permissions
                                                                                </a>
                                                                                <a
                                                                                    className="dropdown-item"
                                                                                    onClick={() => this.toggleAddPermission({ target: "roles_mapping", permissionView: this.addPermissionViews["roles_mapping"], viewType: this.addPermissionViewTypesOpen.ADD_PERMISSION })}
                                                                                >
                                                                                    Add Roles Assignment Permissions
                                                                                </a>
                                                                                <a
                                                                                    className="dropdown-item"
                                                                                    onClick={() => this.toggleAddPermission({ target: "permissions", permissionView: this.addPermissionViews["permissions"], viewType: this.addPermissionViewTypesOpen.ADD_PERMISSION })}
                                                                                >
                                                                                    Add Permissions Management Permissions
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }

                                {this.state.selectedTab == this.tabs.USERS &&
                                    <>
                                        <div className="tab-pane active">

                                            {this.state.usersTabView == this.usersTab.NONE &&
                                                <div>
                                                    Click on a user to view their usage activity and data access privileges.
                                                </div>
                                            }

                                            {this.state.usersTabView == this.usersTab.ADD_USER &&
                                                <AddUser onComplete={() => this.loadUsers()} siteUUID={this.props.router.params.appUUID} />
                                            }

                                            {this.state.usersTabView == this.usersTab.EDIT_USER &&
                                                <EditUser
                                                    key={this.state.selectedUser.uuid}
                                                    onComplete={() => this.selectTab(this.tabs.USERS)}
                                                    siteUUID={this.props.router.params.appUUID}

                                                    user_uuid={this.state.selectedUser.uuid}
                                                    email={this.state.selectedUser.email}
                                                    roles={this.state.usersMappedToRoles[this.state.selectedUser.uuid]?.map((role) => role.uuid)}
                                                />
                                            }

                                            {this.state.usersTabView == this.usersTab.VIEW_USER &&
                                                <div>
                                                    <div className="row m-b-lg">
                                                        <div className="col-4">
                                                            <div className="text-center">
                                                                <img className="rounded-circle m-t-xs img-fluid" alt={`${this.state.selectedUser.first_name} ${this.state.selectedUser.last_name}`} src={this.state.selectedUser.picture || this.defaultPicture} />
                                                                {/* <div className="m-t-xs font-bold"></div> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-8">
                                                            <h3 className="mb-0"><strong>{this.state.selectedUser.first_name} {this.state.selectedUser.last_name}</strong></h3>
                                                            <p><i className="fa fa-email"></i> {this.state.selectedUser.email}</p>
                                                            <address>
                                                                <strong>Roles</strong><br />
                                                                {(this.state.usersMappedToRoles[this.state.selectedUser.uuid] == undefined || this.state.usersMappedToRoles[this.state.selectedUser.uuid].length == 0) &&
                                                                    <span>No roles assigned</span>
                                                                }

                                                                {this.state.usersMappedToRoles[this.state.selectedUser.uuid]?.map((role) => {
                                                                    return <span key={role.uuid} className="label label-info mr-2">{role.name}</span>
                                                                })}
                                                            </address>
                                                        </div>
                                                    </div>
                                                    <div className="client-detail">

                                                        <hr />

                                                        <strong>Usage activity</strong>

                                                        {this.state.usageActivityLoading ?
                                                            <div className="mt-4 pad-lft">
                                                                <Loader />
                                                            </div>
                                                            :
                                                            <div id="vertical-timeline" className="vertical-container dark-timeline">
                                                                <div className="vertical-timeline-block">
                                                                    <div className="vertical-timeline-icon gray-bg">
                                                                        <i className="fa fa-coffee"></i>
                                                                    </div>
                                                                    <div className="vertical-timeline-content">
                                                                        <p>Conference on the sales results for the previous year.
                                                                        </p>
                                                                        <span className="vertical-date small text-muted"> 2:10 pm - 12.06.2014 </span>
                                                                    </div>
                                                                </div>
                                                                <div className="vertical-timeline-block">
                                                                    <div className="vertical-timeline-icon gray-bg">
                                                                        <i className="fa fa-briefcase"></i>
                                                                    </div>
                                                                    <div className="vertical-timeline-content">
                                                                        <p>Many desktop publishing packages and web page editors now use Lorem.
                                                                        </p>
                                                                        <span className="vertical-date small text-muted"> 4:20 pm - 10.05.2014 </span>
                                                                    </div>
                                                                </div>
                                                                <div className="vertical-timeline-block">
                                                                    <div className="vertical-timeline-icon gray-bg">
                                                                        <i className="fa fa-bolt"></i>
                                                                    </div>
                                                                    <div className="vertical-timeline-content">
                                                                        <p>There are many variations of passages of Lorem Ipsum available.
                                                                        </p>
                                                                        <span className="vertical-date small text-muted"> 06:10 pm - 11.03.2014 </span>
                                                                    </div>
                                                                </div>
                                                                <div className="vertical-timeline-block">
                                                                    <div className="vertical-timeline-icon navy-bg">
                                                                        <i className="fa fa-warning"></i>
                                                                    </div>
                                                                    <div className="vertical-timeline-content">
                                                                        <p>The generated Lorem Ipsum is therefore.
                                                                        </p>
                                                                        <span className="vertical-date small text-muted"> 02:50 pm - 03.10.2014 </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div >
                </div >
            </>
        );
    }
}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(UsersManagement));