import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

export default function exportToImage(domID, fileName) {
    generateBlob(domID)
    .then((blob) => {
        return saveAs(blob, `${fileName}.png`);
    })
}

export function generateImage(domID) {
    return domtoimage.toPng(document.getElementById(domID), {
        bgcolor: "#fff"
    })
}

export function generateBlob(domID) {
    return domtoimage.toBlob(document.getElementById(domID), {
        bgcolor: "#fff"
    })
}