import fetchRequiredValue from "./fetchRequiredValue";

export default function recursiveRender(data, dataProps, removeAllEmptyValues = false) {
    let renderedData = {};

    if (!Array.isArray(data)) {
        Object.keys(data).forEach((value) => {
            const type = typeof data[value];

            if (type === "string") {
                renderedData[value] = fetchRequiredValue(dataProps, data[value]);

                if (removeAllEmptyValues === true) {
                    if (renderedData[value].length == 0) {
                        delete renderedData[value];
                    }
                }
            } else if (type === "object" && type !== null && !Array.isArray(type)) {
                renderedData[value] = recursiveRender(data[value], dataProps);
            } else {
                renderedData[value] = data[value];
            }
        });
    } else {
        let newData = data.map((item) => {
            return recursiveRender(item, dataProps, removeAllEmptyValues);
        });

        return newData;
    }

    return renderedData;
}