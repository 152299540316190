import React from "react";
import moment from "moment-timezone";
import copy from 'copy-to-clipboard';

import { connect } from "react-redux";

import axiosBackend from "../../../core/api/backend";

import withLoaderData from "../../../components/withLoaderData";

import * as DataDelegator from "../../../components/smart/delegator/DataDelegator";

import AttachTrailingSlash from "../../../core/utils/attachTrailingSlash";

import Select from "react-select";

export async function SettingsLoader(props) {
    return new Promise((resolve, reject) => {
        const { params } = props;
        const url = new URL(props.request.url);

        DataDelegator.resolve({
            robostackResolveData:
                [
                    {
                        "name": "ListOfApps",
                        "api": {
                            "method": "POST",
                            "endpoint": "/apps",
                            "data": {}
                        },
                        "transformName": "Breadcrumbs",
                        "transform": {
                            "title": "`data.results | search-array-for-object: uuid," + params.appUUID + " | pop | prop: name`",
                            "uuid": params.appUUID,
                            "root": AttachTrailingSlash(url.pathname)
                        }
                    },
                    {
                        "name": "Breadcrumbs",
                        "forceApiReload": true,
                        "mergeWithSourceAfterTransform": true,
                        "transformArrayMergeStratergy": "overwriteTarget",
                        "props": {
                            crumbs: [
                                {
                                    "name": "Application Settings",
                                },
                            ]
                        }
                    },
                ]
        }, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    })
}

class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.COMPONENT_STATES = {
            LOADING: "LOADING",
            LOADED: "LOADED",

            ERROR: "ERROR",
        }

        this.timezones = moment.tz.names().map((name) => {
            return {
                value: name,
                label: name,
            }
        })

        this.state = {
            items: [],
            internalItems: [],

            showAddForm: false,

            settingName: "",
            settingNameError: "",

            settingValue: "",
            settingValueError: "",

            editIndex: null,
            editInternalIndex: null,

            status: this.COMPONENT_STATES.LOADING,
        };

        this.toggleShowAddForm = this.toggleShowAddForm.bind(this);
        this.onChange = this.onChange.bind(this);
        this.saveNewSetting = this.saveNewSetting.bind(this);
        this.updateExistingSetting = this.updateExistingSetting.bind(this);
        this.updateInternalSetting = this.updateInternalSetting.bind(this);
    }

    editSetting(index) {
        this.setState({
            editIndex: index,

            settingName: this.state.items[index].name,
            settingValue: this.state.items[index].value,

            settingNameError: "",
            settingValueError: "",

            showAddForm: false,
            editInternalIndex: null,

        })
    }

    editInternalSetting(index) {
        this.setState({
            editInternalIndex: index,

            settingName: this.state.internalItems[index].name,
            settingValue: this.state.internalItems[index].value,

            settingNameError: "",
            settingValueError: "",

            showAddForm: false,
            editIndex: null,

        })
    }

    onChange(event) {
        if (event?.target) {
            this.setState({
                [event.target.name]: event.target.value,
            })
        } else {
            if (this.state.editInternalIndex != null && this.state.internalItems[this.state.editInternalIndex].name == "Timezone") {
                this.setState({
                    settingValue: event.value,
                })
            }
        }
    }

    toggleShowAddForm() {
        this.setState((state) => {
            let stateToUpdate = {
                showAddForm: !state.showAddForm,

            };

            if (stateToUpdate.showAddForm == true) {
                stateToUpdate = {
                    ...stateToUpdate,

                    editIndex: null,
                    editInternalIndex: null,
                    settingName: "",
                    settingValue: "",
                    settingNameError: "",
                    settingValueError: "",
                }
            }

            return stateToUpdate;
        })
    }

    componentDidMount() {
        DataDelegator.resolve(this.props, (err) => {
            if (err) {
                throw err;
            } else {
                this.loadData();
            }
        });
    }

    loadData() {
        axiosBackend({
            method: "POST",
            url: `/service/settings/${this.props.router.params.appUUID}/`,
        })
            .then((response) => {
                const items = response.data.results.filter((setting) => setting.internal == false);
                const internalItems = response.data.results.filter((setting) => setting.internal == true);

                this.setState({
                    status: this.COMPONENT_STATES.LOADED,
                    items,
                    internalItems,
                });
            })
            .catch((err) => {
                console.error(err);

                this.setState({
                    status: this.COMPONENT_STATES.ERROR,
                })
            })
    }

    deleteSetting(setting) {
        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Confirm Deletion",
                "message": [
                    "Are you sure you would like to delete the setting with the name " + setting.name + "?",
                    "‏‏‎ ‎",
                    "This action is permanant and cannot be undone."
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Deleting Setting",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "DELETE",
                            url: `/service/settings/${this.props.router.params.appUUID}/${setting.uuid}`,
                        })
                            .then((response) => {
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            " Your setting was deleted successfully",
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });

                                this.loadData();
                            })
                            .catch((err) => {
                                console.error(err);
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Could not delete setting",
                                        message: [
                                            "Due to an error, we were unable to delete the setting",
                                            "‏‏‎ ‎",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    saveNewSetting() {
        let hasError = false;

        const stateToUpdate = {
            settingNameError: "",
            settingValueError: "",
        };

        if (this.state.settingName.length == 0) {
            hasError = true;
            stateToUpdate.settingNameError = "Please enter the name of the setting";
        }

        if (this.state.settingValue.length == 0) {
            hasError = true;
            stateToUpdate.settingValueError = "Please enter the value of the setting";
        }

        if (!hasError) {
            this.props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    "show": true,
                    "type": "processing",
                    "title": "Saving Setting",
                    "message": [
                        "This might take a few seconds.",
                        "You will be shown a confirmation screen once completed."
                    ]
                },
            });

            setTimeout(() => {
                axiosBackend({
                    method: "PUT",
                    url: `/service/settings/${this.props.router.params.appUUID}/`,
                    data: {
                        name: this.state.settingName,
                        value: this.state.settingValue,
                    }
                })
                    .then((response) => {
                        this.props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                show: true,
                                type: "success",
                                title: "Success",
                                message: [
                                    " Your setting was saved successfully",
                                ],
                                okayButtonText: "Okay",
                            },
                        });

                        stateToUpdate.settingName = "";
                        stateToUpdate.settingValue = "";

                        stateToUpdate.showAddForm = false;

                        this.setState(stateToUpdate);

                        this.loadData();
                    })
                    .catch((err) => {
                        console.error(err);
                        this.props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                show: true,
                                type: "error",
                                title: "Could not save setting",
                                message: [
                                    "Due to an error, we were unable to save the setting",
                                    "‏‏‎ ‎",
                                    "Please try again in a little while."
                                ],
                                okayButtonText: "Okay"
                            },
                        });
                    })
            }, 1000);
        } else {
            this.setState(stateToUpdate);
        }
    }

    updateExistingSetting() {
        let hasError = false;

        const stateToUpdate = {
            settingNameError: "",
            settingValueError: "",
        };

        if (this.state.settingName.length == 0) {
            hasError = true;
            stateToUpdate.settingNameError = "Please enter the name of the setting";
        }

        if (this.state.settingValue.length == 0) {
            hasError = true;
            stateToUpdate.settingValueError = "Please enter the value of the setting";
        }

        if (!hasError) {
            this.props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    "show": true,
                    "type": "processing",
                    "title": "Updating Setting",
                    "message": [
                        "This might take a few seconds.",
                        "You will be shown a confirmation screen once completed."
                    ]
                },
            });

            setTimeout(() => {
                axiosBackend({
                    method: "PATCH",
                    url: `/service/settings/${this.props.router.params.appUUID}/`,
                    data: {
                        uuid: this.state.items[this.state.editIndex].uuid,

                        name: this.state.settingName,
                        value: this.state.settingValue,
                    }
                })
                    .then((response) => {
                        this.props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                show: true,
                                type: "success",
                                title: "Success",
                                message: [
                                    " Your setting was updated successfully",
                                ],
                                okayButtonText: "Okay",
                            },
                        });

                        stateToUpdate.settingName = "";
                        stateToUpdate.settingValue = "";

                        stateToUpdate.editIndex = null;

                        this.setState(stateToUpdate);

                        this.loadData();
                    })
                    .catch((err) => {
                        console.error(err);
                        this.props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                show: true,
                                type: "error",
                                title: "Could not update setting",
                                message: [
                                    "Due to an error, we were unable to update the setting",
                                    "‏‏‎ ‎",
                                    "Please try again in a little while."
                                ],
                                okayButtonText: "Okay"
                            },
                        });
                    })
            }, 1000);
        } else {
            this.setState(stateToUpdate);
        }
    }

    updateInternalSetting() {
        let hasError = false;

        const stateToUpdate = {
            settingNameError: "",
            settingValueError: "",
        };

        if (this.state.editInternalIndex == null) {
            hasError = true;
            stateToUpdate.settingNameError = "Please select the setting to edit";
        }

        if (this.state.settingValue.length == 0) {
            hasError = true;
            stateToUpdate.settingValueError = "Please enter the value of the setting";
        }

        if (!hasError) {
            this.props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    "show": true,
                    "type": "processing",
                    "title": "Updating Setting",
                    "message": [
                        "This might take a few seconds.",
                        "You will be shown a confirmation screen once completed."
                    ]
                },
            });

            setTimeout(() => {
                axiosBackend({
                    method: "PATCH",
                    url: `/service/settings/${this.props.router.params.appUUID}/`,
                    data: {
                        name: this.state.internalItems[this.state.editInternalIndex].name,
                        value: this.state.settingValue,
                    }
                })
                    .then((response) => {
                        this.props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                show: true,
                                type: "success",
                                title: "Success",
                                message: [
                                    " Your setting was updated successfully",
                                ],
                                okayButtonText: "Okay",
                            },
                        });

                        stateToUpdate.settingName = "";
                        stateToUpdate.settingValue = "";

                        stateToUpdate.editInternalIndex = null;

                        this.setState(stateToUpdate);

                        this.loadData();
                    })
                    .catch((err) => {
                        console.error(err);
                        this.props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                show: true,
                                type: "error",
                                title: "Could not update setting",
                                message: [
                                    "Due to an error, we were unable to update the setting",
                                    "‏‏‎ ‎",
                                    "Please try again in a little while."
                                ],
                                okayButtonText: "Okay"
                            },
                        });
                    })
            }, 1000);
        } else {
            this.setState(stateToUpdate);
        }
    }

    render() {
        return (
            <>
                <div className="mt-1 mb-3 col-sm-12">
                    <div className="row">
                        <div className="col-sm-8">
                            <button id="toggle-help" className="btn btn-gray" onClick={this.toggleHelp}>
                                <span className="far fa-question-circle fa-lg"></span>
                            </button>
                        </div>
                    </div>

                </div>

                <div className="col-sm-12">
                    <div className="">
                        <div className="ibox-content">

                            <h2>Application Settings</h2>
                            <p>
                                Change values of settings used in your application. You can also create your own custom settings.
                            </p>


                            <table className="mt-4 mb-0 table table-striped">
                                <thead className="font-bold">
                                    <td>Name</td>
                                    <td>Value</td>
                                    <td className="text-center">Actions</td>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="col-sm-2">
                                            appUUID
                                        </td>
                                        <td className="col-sm-8">
                                            <code>
                                                {this.props.router.params.appUUID}
                                            </code>
                                        </td>
                                        <td className="col-sm-2 text-right">
                                            <button onClick={() => copy(this.props.router.params.appUUID)} type="button" className="btn btn-default btn-xs ml-2">
                                                <span
                                                    className="fa fa-copy">
                                                </span> Copy
                                            </button>
                                        </td>
                                    </tr>

                                    {this.state.internalItems.length == 0 ?
                                        <tr className="mt-2">
                                            <td>
                                                No internal settings found
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        :
                                        this.state.internalItems.map((item, index) => {
                                            return (
                                                <tr key={item.uuid}>
                                                    <td className="col-sm-2">
                                                        {item.name}
                                                    </td>
                                                    <td className="col-sm-8">
                                                        {this.state.editInternalIndex == index ?
                                                            <>
                                                                {item.name == "Timezone" &&
                                                                    <Select
                                                                        options={this.timezones}
                                                                        placeholder="Select a timezone for your application"
                                                                        value={this.timezones.find((timezone) => timezone.value == this.state.settingValue)}
                                                                        onChange={this.onChange}
                                                                    />
                                                                }
                                                            </>
                                                            :
                                                            <code>
                                                                {item.value}
                                                            </code>
                                                        }
                                                    </td>
                                                    {this.state.editInternalIndex == index ?
                                                        <td className="col-sm-2 text-left">

                                                            <button onClick={this.updateInternalSetting} className="btn btn-primary">
                                                                <span className="fa fa-save"></span>&nbsp;Save
                                                            </button>
                                                        </td>
                                                        :
                                                        <td className="col-sm-2 text-right">
                                                            
                                                            <button onClick={() => this.editInternalSetting(index)} className="btn btn-success btn-xs ml-2">
                                                                <span className="fa fa-edit"></span>&nbsp;Edit
                                                            </button>

                                                            <button onClick={() => copy(item.value)} type="button" className="btn btn-default btn-xs ml-2">
                                                                <span
                                                                    className="fa fa-copy">
                                                                </span> Copy
                                                            </button>
                                                            
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }

                                    {this.state.items.map((item, index) => {
                                        if (index == this.state.editIndex) {
                                            return (
                                                <tr key={item.uuid}>
                                                    <td className={`col-sm-2 ${this.state.settingNameError.length > 0 && "has-error"}`}>
                                                        <input
                                                            onChange={this.onChange}
                                                            value={this.state.settingName}
                                                            name="settingName"
                                                            className="form-control"
                                                            placeholder="Setting name"
                                                        />

                                                        {this.state.settingNameError.length > 0 &&
                                                            <span className={`form-text m-b-none text-danger`}>{this.state.settingNameError}</span>
                                                        }
                                                    </td>

                                                    <td className={`col-sm-9 ${this.state.settingValueError.length > 0 && "has-error"}`}>
                                                        <div class="input-group">
                                                            <input
                                                                onChange={this.onChange}
                                                                value={this.state.settingValue}
                                                                name="settingValue"
                                                                className="form-control"
                                                                placeholder="Setting value"
                                                            />
                                                        </div>

                                                        {this.state.settingValueError.length > 0 &&
                                                            <span className={`form-text m-b-none text-danger`}>{this.state.settingValueError}</span>
                                                        }
                                                    </td>

                                                    <td className="col-sm-1 text-left">
                                                        <button onClick={this.updateExistingSetting} className="btn btn-primary">
                                                            <span className="fa fa-save"></span>&nbsp;Save
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        }

                                        return (
                                            <tr key={item.uuid}>
                                                <td className="col-sm-2">
                                                    {item.name}
                                                </td>
                                                <td className="col-sm-8">
                                                    <code>
                                                        {item.value}
                                                    </code>
                                                </td>
                                                <td className="col-sm-2 text-right">
                                                    <button onClick={() => this.editSetting(index)} className="btn btn-success btn-xs ml-2">
                                                        <span className="fa fa-edit"></span>&nbsp;Edit
                                                    </button>

                                                    <button onClick={() => this.deleteSetting(item)} className="btn btn-danger btn-xs ml-2">
                                                        <span className="fa fa-times"></span>&nbsp;Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                            {this.state.showAddForm === true ?
                                <>
                                    <label className="font-bold mt-2">Add New Setting:</label>
                                    <table className="table table-borderless">
                                        <tr>
                                            <td className={`col-sm-2 p-0 ${this.state.settingNameError.length > 0 && "has-error"}`}>
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.settingName}
                                                    name="settingName"
                                                    className="form-control"
                                                    placeholder="Setting name"
                                                />

                                                {this.state.settingNameError.length > 0 &&
                                                    <span className={`form-text m-b-none text-danger`}>{this.state.settingNameError}</span>
                                                }
                                            </td>

                                            <td className={`col-sm-10 p-0 ${this.state.settingValueError.length > 0 && "has-error"}`}>
                                                <div class="input-group">
                                                    <input
                                                        onChange={this.onChange}
                                                        value={this.state.settingValue}
                                                        name="settingValue"
                                                        className="form-control"
                                                        placeholder="Setting value"
                                                    />

                                                    <span class="input-group-append">
                                                        <button onClick={this.saveNewSetting} className="btn btn-primary btn-xs">
                                                            <span className="fa fa-save"></span>&nbsp;Save
                                                        </button>
                                                    </span>
                                                </div>

                                                {this.state.settingValueError.length > 0 &&
                                                    <span className={`form-text m-b-none text-danger`}>{this.state.settingValueError}</span>
                                                }
                                            </td>
                                        </tr>
                                    </table>
                                </>

                                :

                                <button onClick={this.toggleShowAddForm} className="btn btn-default btn-block mt-4">
                                    <span className="fa fa-plus"></span>&nbsp;Add Custom Setting
                                </button>
                            }
                        </div>
                    </div>
                </div>


            </>
        );
    }
}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(Settings));